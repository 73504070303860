import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
///////////////////////////////////////////////////

export const truckReservationAPI = createApi({
  baseQuery: H.baseQueryWithReAuth,
  reducerPath: 'truckReservationAPI',
  tagTypes: ['TruckReservationList'],
  endpoints: build => ({
    reserveTrucks: build.mutation({
      extraOptions: { useLoader: true },
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'TruckReservationList'),
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.truckReservation,
      }),
    }),
    fetchReserveTrucksInfo: build.mutation({
      invalidatesTags: ['TruckReservationInfo'],
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.truckReservationInfo,
      }),
    }),
  }),
})

export const {
  useReserveTrucksMutation,
  useFetchReserveTrucksInfoMutation,
} = truckReservationAPI

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// endpoints
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const companyAPI = createApi({
  tagTypes: ['Company'],
  reducerPath: 'companyAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchCompany: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.company,
      }),
      providesTags: (result, error, id) => [{ type: 'Company', id }],
    }),
    updateCompany: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.company,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Company', id }],
    }),
    getCompanyPublicByCompanyId: build.query({
      query: companyId => ({
        companyId,
        method: E.METHODS.GET,
        url: `${E.ENDPOINTS.companyPublic}/${companyId}`,
        providesTags: (result, error, id) => [{ type: 'Company', id }],
      }),
    }),
  }),
})

export const {
  useFetchCompanyQuery,
  useUpdateCompanyMutation,
  useGetCompanyPublicByCompanyIdQuery,
} = companyAPI

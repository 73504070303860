import * as R from 'ramda'
import { createSlice, createSelector } from '@reduxjs/toolkit'
// constants
import { ROLE_USER } from '../../constants'
//////////////////////////////////////////////////

const scope = 'auth'

const initialState = {
  user: null,
  role: ROLE_USER,
  loggedIn: false,
  access_token: null,
  refresh_token: null,
  isAdminToken: false,
  isExpiredRefreshToken: false,
}

export const authSlice = createSlice({
  initialState,
  name: `${scope}`,
  reducers: {
    setAuthInfo: (state, { payload }) => R.mergeDeepRight(state, payload),
    setLoggedIn: (state, { payload }) => {
      state.loggedIn = payload
    },
  },
})

export const { setAuthInfo, setLoggedIn } = authSlice.actions

const store = ({ auth }) => auth

export const makeSelectAuthStore = createSelector(
  store,
  auth => auth,
)

export const makeSelectIsAdminToken = createSelector(
  store,
  ({ isAdminToken }) => isAdminToken,
)

export default authSlice.reducer

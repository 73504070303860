import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// endpoints
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const userAPI = createApi({
  tagTypes: ['User'],
  reducerPath: 'userAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchProfile: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.profile,
      }),
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),
    updateProfile: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.profile,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
  }),
})

export const {
  useFetchProfileQuery,
  useUpdateProfileMutation,
} = userAPI

import { createSlice, createSelector } from '@reduxjs/toolkit'
//////////////////////////////////////////////////

const initialState = {
  groups: [],
}

export const mailListSlice = createSlice({
  initialState,
  name: 'mailList',
  reducers: {
    addGroup: (state, { payload }) => {
      state.groups.push({ name: payload, value: payload })
    },
  },
})

export const { addGroup } = mailListSlice.actions

const store = ({ mailList }) => mailList

export const makeSelectCurrentGroup = createSelector(
  store,
  ({ groups }) => groups,
)

export default mailListSlice.reducer

import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
// material
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AlertTitle from '@mui/material/AlertTitle'
// icons-material
import CloseIcon from '@mui/icons-material/Close'
import OpenIcon from '@mui/icons-material/OpenInFullOutlined'
// helpers
import * as H from '../helpers'
//////////////////////////////////////////////////

export const AlertInfoPair = ({ text, style, textColor = 'primary' }) => (
  <Typography
    align='left'
    variant='subtitle2'
    sx={{
      color: t => R.equals(textColor, 'primary') ? t.palette.text.primary : t.palette.text.secondary,
      fontSize: { lg: '14px !important', md: '14px !important', sm: '11px !important', xs: '9px !important' },
      ...style,
    }}
  >
    {text}
  </Typography>
)

export const AlertComponent = ({
  title,
  scroll,
  iconType,
  setOpened,
  infoSettings,
  opened = true,
  isModal = false,
  textColor = 'primary',
  isDeleteModal = false,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        overflowY: { xs: scroll ? 'scroll' : 'hidden', md: 'hidden' },
        maxHeight: { xs: scroll ? '300px' : 'auto', md: 'max-content' },
      }}
    >
      <Alert
        severity={iconType ? iconType : 'error'}
        sx={{
          mb: 2,
          borderRadius: isModal ? '16px' : '8px',
          background: t => t.palette.mode === 'light'
            ? isDeleteModal ? t.rgbaColors.red00_8 : t.palette.background.middleGreen
            : isDeleteModal ? t.rgbaColors.red00_8 : t.rgbaColors.lightGrey132_8,
          '& .MuiAlert-icon': {
            color: t => isDeleteModal ? t.palette.primary.error : t.palette.primary.main,
            '&:hover': { color: t => isDeleteModal ? t.palette.primary.error : t.palette.primary.main },
            '&.Mui-selected': { color: t => isDeleteModal ? t.palette.primary.error : t.palette.primary.main },
            '.MuiSvgIcon-root': {
              p: 0,
              cursor: 'default',
              alignSelf: 'auto',
            },
          },
        }}
        action={
          <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='flex-end'
            flexDirection='column'
            justifyContent='space-between'
          >
            {
              setOpened && (
                <IconButton
                  size='small'
                  color='primary'
                  onClick={setOpened}
                  title={opened ? t('actions.close') : t('actions.viewFullText')}
                >
                  {opened ? <CloseIcon size='small' /> : <OpenIcon size='small' />}
                </IconButton>
              )
            }
          </Box>
        }
      >
        {
          title && (
            <AlertTitle
              sx={{
                m: 0,
                textTransform: 'uppercase',
                mb: opened ? 2 : 0, textAlign: 'start',
                color: t => R.equals(textColor, 'primary') ? t.palette.text.primary : t.palette.text.secondary,
              }}
            >
              {t(title)}!
            </AlertTitle>
          )
        }
        {
          H.isTrue(opened) && infoSettings.map((info, index) => {
            const text = H.getPropFromObject('text', info)
            const style = H.getPropFromObject('textStyle', info)
            const important = H.getPropFromObject('important', info)
            const textComponent = H.getPropFromObject('textComponent', info)

            if (textComponent) return <Box>{textComponent}</Box>

            if (text) {
              if (H.isTrue(important)) return (
                <AlertInfoPair
                  key={index}
                  style={style}
                  textColor={textColor}
                  text={<strong><p>* {`${t(`errors.${text}`)}`}</p></strong>}
                />
              )

              return <AlertInfoPair key={index} style={style} textColor={textColor} text={`${t(`errors.${text}`)}`} />
            }
          })
        }
      </Alert>
    </Box>
  )
}

export default AlertComponent

// constants
import * as C from '../constants'
//////////////////////////////////////////////////

export const EMPTY_OPTION_OBJECT = { label: '', value: '' }

export const SELECT_DRIVER_TYPE_OPTIONS = [
  {
    label: 'Single',
    value: C.ENUM_SINGLE,
  },
  {
    label: 'Team',
    value: C.ENUM_TEAM,
  },
]

export const SELECT_TRUCK_TYPE_OPTIONS = [
  {
    label: 'Heavy Haul',
    value: C.ENUM_HEAVY_HAUL,
  },
  {
    label: 'Local Haul',
    value: C.ENUM_LOCAL_HAUL,
  },
  {
    label: 'Long Haul',
    value: C.ENUM_LONG_HAUL,
  },
  {
    label: 'Specialty Trucks',
    value: C.ENUM_SPECIALTY_TRUCKS,
  },
]

export const SELECT_EQUIPMENT_TYPE_OPTIONS = [
  {
    label: 'Auto Carrier',
    value: C.ENUM_AUTO_CARRIER,
  },
  {
    label: 'Container',
    value: C.ENUM_CONTAINER,
  },
  {
    label: 'Container Insulated',
    value: C.ENUM_CONTAINER_INSULATED,
  },
  {
    label: 'Container Refrigerated',
    value: C.ENUM_CONTAINER_REFRIGERATED,
  },
  {
    label: 'Double Drop',
    value: C.ENUM_DOUBLE_DROP,
  },
  {
    label: 'Drop Deck Landoll',
    value: C.ENUM_DROP_DECK_LANDOLL,
  },
  {
    label: 'Dump Trailer',
    value: C.ENUM_DUMP_TRAILER,
  },
  {
    label: 'Dry Van',
    value: C.ENUM_DRY_VAN,
  },
  {
    label: 'Flatbed',
    value: C.ENUM_FLATBED,
  },
  {
    label: 'Flatbed Air Ride',
    value: C.ENUM_FLATBED_AIR_RIDE,
  },
  {
    label: 'Flatbed By Train',
    value: C.ENUM_FLATBED_B_TRAIN,
  },
  {
    label: 'Flatbed Double',
    value: C.ENUM_FLATBED_DOUBLE,
  },
  {
    label: 'Flatbed Haz Mat',
    value: C.ENUM_FLATBED_HAZ_MAT,
  },
  {
    label: 'Flatbed Hotshot',
    value: C.ENUM_FLATBED_HOTSHOT,
  },
  {
    label: 'Flatbed Maxi',
    value: C.ENUM_FLATBED_MAXI,
  },
  {
    label: 'Flatbed With Sides',
    value: C.ENUM_FLATBED_W_SIDES,
  },
  {
    label: 'Flatbed With Tarps',
    value: C.ENUM_FLATBED_W_TARPS,
  },
  {
    label: 'Flatbed With Team',
    value: C.ENUM_FLATBED_W_TEAM,
  },
  {
    label: 'Flatbed Or Step Deck',
    value: C.ENUM_FLATBED_OR_STEP_DECK,
  },
  {
    label: 'Flatbed Van Reefer',
    value: C.ENUM_FLATBED_VAN_REEFER,
  },
  {
    label: 'Hopper Bottom',
    value: C.ENUM_HOPPER_BOTTOM,
  },
  {
    label: 'Lowboy',
    value: C.ENUM_LOWBOY,
  },
  {
    label: 'Moving Van',
    value: C.ENUM_MOVING_VAN,
  },
  {
    label: 'Pneumatic',
    value: C.ENUM_PNEUMATIC,
  },
  {
    label: 'Power Only',
    value: C.ENUM_POWER_ONLY,
  },
  {
    label: 'Reefer',
    value: C.ENUM_REEFER,
  },
  {
    label: 'Reefer Air Ride',
    value: C.ENUM_REEFER_AIR_RIDE,
  },
  {
    label: 'Reefer Double',
    value: C.ENUM_REEFER_DOUBLE,
  },
  {
    label: 'Reefer Haz Mat',
    value: C.ENUM_REEFER_HAZ_MAT,
  },
  {
    label: 'Reefer Intermodal',
    value: C.ENUM_REEFER_INTERMODAL,
  },
  {
    label: 'Reefer Logistics',
    value: C.ENUM_REEFER_LOGISTICS,
  },
  {
    label: 'Reefer With Team',
    value: C.ENUM_REEFER_W_TEAM,
  },
  {
    label: 'Removable Gooseneck',
    value: C.ENUM_REMOVABLE_GOOSENECK,
  },
  {
    label: 'Step Deck',
    value: C.ENUM_STEP_DECK,
  },
  {
    label: 'Stretch Trailer',
    value: C.ENUM_STRETCH_TRAILER,
  },
  {
    label: 'Tanker Aluminum',
    value: C.ENUM_TANKER_ALUMINUM,
  },
  {
    label: 'Tanker Intermodal',
    value: C.ENUM_TANKER_INTERMODAL,
  },
  {
    label: 'Tanker Steel',
    value: C.ENUM_TANKER_STEEL,
  },
  {
    label: 'Truck And Trailer',
    value: C.ENUM_TRUCK_AND_TRAILER,
  },
  {
    label: 'Van',
    value: C.ENUM_VAN,
  },
  {
    label: 'Van AirRide',
    value: C.ENUM_VAN_AIR_RIDE,
  },
  {
    label: 'Van Conestoga',
    value: C.ENUM_VAN_CONESTOGA,
  },
  {
    label: 'Van Curtain',
    value: C.ENUM_VAN_CURTAIN,
  },
  {
    label: 'Van Double',
    value: C.ENUM_VAN_DOUBLE,
  },
  {
    label: 'Van Haz Mat',
    value: C.ENUM_VAN_HAZ_MAT,
  },
  {
    label: 'Van Hotshot',
    value: C.ENUM_VAN_HOTSHOT,
  },
  {
    label: 'Van Insulated',
    value: C.ENUM_VAN_INSULATED,
  },
  {
    label: 'Van Intermodal',
    value: C.ENUM_VAN_INTERMODAL,
  },
  {
    label: 'Van Lift Gate',
    value: C.ENUM_VAN_LIFT_GATE,
  },
  {
    label: 'Van Logistics',
    value: C.ENUM_VAN_LOGISTICS,
  },
  {
    label: 'Van Open Top',
    value: C.ENUM_VAN_OPEN_TOP,
  },
  {
    label: 'Van Roller Bed',
    value: C.ENUM_VAN_ROLLER_BED,
  },
  {
    label: 'Van Triple',
    value: C.ENUM_VAN_TRIPLE,
  },
  {
    label: 'Van Vented',
    value: C.ENUM_VAN_VENTED,
  },
  {
    label: 'Van With Team',
    value: C.ENUM_VAN_W_TEAM,
  },
  {
    label: 'Van Or Flatbed With Tarps',
    value: C.ENUM_VAN_OR_FLATBED_W_TARPS,
  },
  {
    label: 'Van Or Flatbed',
    value: C.ENUM_VAN_OR_FLATBED,
  },
  {
    label: 'Van Or Reefer',
    value: C.ENUM_VAN_OR_REEFER,
  },
  {
    label: 'Insulated Van Or Reefer',
    value: C.ENUM_INSULATED_VAN_OR_REEFER,
  },
  {
    label: 'Reefer Or Vented Van',
    value: C.ENUM_REEFER_OR_VENTED_VAN,
  },
  {
    label: 'Flatbed With Chains',
    value: C.ENUM_FLATBED_W_CHAINS,
  },
  {
    label: 'Reefer With Pallet Exchange',
    value: C.ENUM_REEFER_W_PALLET_EXCHANGE,
  },
  {
    label: 'Van With Blanket Wrap',
    value: C.ENUM_VAN_W_BLANKET_WRAP,
  },
  {
    label: 'Lowboy Or Rgn',
    value: C.ENUM_LOWBOY_OR_RGN,
  },
  {
    label: 'Van With Pallet Exchange',
    value: C.ENUM_VAN_W_PALLET_EXCHANGE,
  },
  {
    label: 'Step Deck Or Rgn',
    value: C.ENUM_STEP_DECK_OR_RGN,
  },
  {
    label: 'Conveyor',
    value: C.ENUM_CONVEYOR,
  },
  {
    label: 'Flatbed Over Dimension',
    value: C.ENUM_FLATBED_OVER_DIMENSION,
  },
  {
    label: 'Lowboy Over Dimension',
    value: C.ENUM_LOWBOY_OVER_DIMENSION,
  },
  {
    label: 'Conestoga',
    value: C.ENUM_CONESTOGA,
  },
  {
    label: 'Flatbed Conestoga',
    value: C.ENUM_FLATBED_CONESTOGA,
  },
  {
    label: 'Stepdeck Conestoga',
    value: C.ENUM_STEPDECK_CONESTOGA,
  },
  {
    label: 'Straight Box Truck',
    value: C.ENUM_STRAIGHT_BOX_TRUCK,
  },
]

export const SELECT_DAYS_OPTIONS = [
  { label: 'Sunday', value: C.ENUM_SUNDAY },
  { label: 'Monday', value: C.ENUM_MONDAY },
  { label: 'Tuesday', value: C.ENUM_TUESDAY },
  { label: 'Wednesday', value: C.ENUM_WEDNESDAY },
  { label: 'Thursday', value: C.ENUM_THURSDAY },
  { label: 'Friday', value: C.ENUM_FRIDAY },
  { label: 'Saturday', value: C.ENUM_SATURDAY },
]

export const SELECT_HOURS_OPTIONS = [
  { label: '0:00', value: C.ENUM_H_0 },
  { label: '1:00', value: C.ENUM_H_1 },
  { label: '2:00', value: C.ENUM_H_2 },
  { label: '3:00', value: C.ENUM_H_3 },
  { label: '4:00', value: C.ENUM_H_4 },
  { label: '5:00', value: C.ENUM_H_5 },
  { label: '6:00', value: C.ENUM_H_6 },
  { label: '7:00', value: C.ENUM_H_7 },
  { label: '8:00', value: C.ENUM_H_8 },
  { label: '9:00', value: C.ENUM_H_9 },
  { label: '10:00', value: C.ENUM_H_10 },
  { label: '11:00', value: C.ENUM_H_11 },
  { label: '12:00', value: C.ENUM_H_12 },
  { label: '13:00', value: C.ENUM_H_13 },
  { label: '14:00', value: C.ENUM_H_14 },
  { label: '15:00', value: C.ENUM_H_15 },
  { label: '16:00', value: C.ENUM_H_16 },
  { label: '17:00', value: C.ENUM_H_17 },
  { label: '18:00', value: C.ENUM_H_18 },
  { label: '19:00', value: C.ENUM_H_19 },
  { label: '20:00', value: C.ENUM_H_20 },
  { label: '21:00', value: C.ENUM_H_21 },
  { label: '22:00', value: C.ENUM_H_22 },
  { label: '23:00', value: C.ENUM_H_23 },
]

export const TRUCK_LIST_GLOBAL_SEARCH_OPTIONS = [
  // TODO: clarify if it needed
  // { label: 'driverName', value: C.FIELD_DRIVER_NAME },
  // { label: 'numberOfUnits', value: C.FIELD_QUANTITY },
  // { label: 'truckNumber', value: C.FIELD_TRUCK_NUMBER },
  // { label: 'trailerNumber', value: C.FIELD_TRAILER_NUMBER },
  { label: 'all', value: 'all' },
  { label: 'truckId', value: C.FIELD_TRUCK_ID },
  { label: 'availableDate', value: C.FIELD_PICK_UP_DATE_TIME },
  { label: 'truckLocation', value: C.FIELD_ORIGIN_LOCATION },
  { label: 'destinationOfTruck', value: C.FIELD_DESTINATION_LOCATIONS },
  { label: 'destinationZones', value: C.FIELD_DESTINATION_ZONES },
  { label: 'trailerType', value: C.FIELD_EQUIPMENT_TYPE },
  { label: 'truckType', value: C.FIELD_TRUCK_TYPE },
  { label: 'driverType', value: C.FIELD_DRIVER_TYPE },
]

export const ADMIN_PANEL_SORT_OPTIONS = [
  { label: 'none', value: 'none' },
  { label: 'freeAccess', value: C.FIELD_FREE_ACCESS },
]

export const BOOLEAN_OPTIONS = [
  { label: 'YES', value: true },
  { label: 'NO', value: false },
]

export const RESERVED_STATUSES_OPTIONS = [
  { label: C.RESERVATION_STATUSES.NEW, value: C.ENUM_NEW },
  { label: C.RESERVATION_STATUSES.COMPLETED, value: C.ENUM_COMPLETED },
  { label: C.RESERVATION_STATUSES.IN_PROGRESS, value: C.ENUM_IN_PROGRESS },
]

import * as R from 'ramda'
// helpers/constants
import * as H from './index'
import * as C from '../constants'
import { FIELD_ID, FIELD_NAME, FIELD_VALUE } from '../constants'
//////////////////////////////////////////////////

export const notEquals = R.complement(R.equals)
export const isNaN = R.equals(NaN)
export const isTrue = R.equals(true)
export const isNotNaN = notEquals(NaN)
export const isNot = R.complement(R.is)
export const notHas = R.complement(R.has)
export const mapIndexed = R.addIndex(R.map)
export const isNotNil = R.complement(R.isNil)
export const filterIndexed = R.addIndex(R.filter)
export const isNotEmpty = R.complement(R.isEmpty)
export const isZero = value => R.equals(value, 0)
export const notInclude = R.complement(R.includes)
export const isNotZero = value => notEquals(value, 0)
export const isFalse = value => R.equals(value, false)
export const isAllTrue = (...arg) => R.all(isTrue, arg)
export const isAnyTrue = (...arg) => R.any(isTrue, arg)
export const isAllFalse = (...arg) => R.all(isFalse, arg)
export const isNilOrZero = value => R.or(R.isNil(value), isZero(value))
export const isNilOrFalse = value => R.or(R.isNil(value), isFalse(value))
export const isNilOrEmpty = value => R.or(R.isNil(value), R.isEmpty(value))
export const isEmptyOrFalse = value => R.or(R.isEmpty(value), isFalse(value))
export const isNotNilAndNotZero = value => R.and(isNotNil(value), isNotZero(value))
export const isNotNilAndNotEmpty = value => R.and(isNotNil(value), isNotEmpty(value))
export const isAllNilOrEmpty = R.all(isNilOrEmpty)
export const isAnyNilOrEmpty = R.any(isNilOrEmpty)
export const isArray = R.is(Array)
export const isObject = R.is(Object)
export const isString = R.is(String)
export const isNumber = R.is(Number)
export const isBoolean = R.is(Boolean)
export const isNotArray = isNot(Array)
export const isNotObject = isNot(Object)
export const isNotString = isNot(String)
export const isFunction = R.is(Function)
export const isArrayNotEmptyAndContainsObjects = arr =>
  R.allPass([R.complement(R.isEmpty), R.all(R.is(Object))])(arr)

// conditions
export const ifElse = (predicate, ifSt, elseSt) => {
  if (predicate) return ifSt

  return elseSt
}

export const ifTrueOrValue = predicate => {
  if (predicate) return true

  return predicate
}

export const isAllEquals = argsArr => {
  if (R.lt(R.length(argsArr), 2)) return true

  return R.all(R.equals(R.head(argsArr)), argsArr)
}

// strings
export const isEmptyString = value => R.and(isString(value), R.isEmpty(value))

export const cleanStringFromLinesAndSpaces = R.compose(
  R.trim,
  R.replace(/\s+/g, ' '),
  value => {
    if (isString(value)) return value

    if (isNumber(value)) return R.toString(value)

    return ''
  },
  R.defaultTo(''),
)

export const capitalizeFirstStrCharacter = s => {
  if (isNilOrEmpty(s)) return

  return s[0].toUpperCase() + s.slice(1)
}

export const capitalizeEveryFirstStrCharacter = s => {
  if (isNilOrEmpty(s)) return

  return R.compose(
    R.join(' '),
    R.map(R.compose(
      R.join(''),
      R.over(R.lensIndex(0), R.toUpper),
    )),
    R.split(' '),
  )(s)
}

export const fromEmptyStringToNull = value => ifElse(isEmptyString(value), null, value)

export const createStringFromArray = (joiner = ' ', arr = []) => {
  const check = item => R.and(isNotEmpty(item), isNotNil(item))

  return arr.filter(check).join(joiner)
}

export const toNumber = R.compose(Number)

export const isFirstStrCharacterVowel = char => R.includes(R.toLower(char), ['a', 'e', 'i', 'o', 'u'])

export const replacePhrases = (phrases, input) => {
  const replaceKeys = (value, key) => R.replace(new RegExp(`\\b${key}\\b`, 'g'), value)
  const replacements = R.mapObjIndexed(replaceKeys, phrases)
  const replaceAll = R.pipe(...R.values(replacements))

  return replaceAll(input)
}

export const formatFilterStr = R.compose(
  R.toLower,
  R.replace(/\s+/g, '_'),
)

export const transformEnumToString = str => {
  if (H.isNilOrEmpty(str)) return ''

  return R.compose(
    R.join(' '),
    R.map(R.toLower),
    R.split('_'),
  )(str)
}

// getters
export const getIdFromObject = object => R.path([FIELD_ID], object)
export const getNameFromObject = object => R.path([FIELD_NAME], object)
export const getValueFromObject = object => R.path([FIELD_VALUE], object)
export const getPropFromObject = (propName, object) => R.path([propName], object)
export const getObjectById = (id, arr = []) => R.find(R.propEq(FIELD_ID, id), arr)

// mappers
export const mapEmptyStringToNullObjectFields = R.mapObjIndexed(
  value => fromEmptyStringToNull(value),
)

// rest
export const jsonParse = string => JSON.parse(string)
export const jsonStringify = string => JSON.stringify(string)
export const spreadUiStyles = styles => isObject(styles) ? styles : {}
export const createObjectURL = blob => window.URL.createObjectURL(blob) // eslint-disable-line
export const stringifyAndParseJson = value => JSON.parse(JSON.stringify(value))
export const isDarkTheme = theme => R.equals(R.path(['palette', 'mode'], theme), 'dark')

// transform
export const getSelectTransformedOptions = arr => R.map(
  option => ({
    [C.FIELD_LABEL]: R.propOr('', C.FIELD_NAME, option),
    [C.FIELD_VALUE]: R.propOr(null, C.FIELD_ID, option),
  }),
  R.or(arr, []),
)

// options
export const getSelectOptions = arr => R.map(
  option => ({
    [C.FIELD_LABEL]: R.propOr('', C.FIELD_LABEL, option),
    [C.FIELD_VALUE]: R.propOr(null, C.FIELD_LABEL, option),
  }),
  R.or(arr, []),
)

const pickValueLabel = R.pick([C.FIELD_VALUE, C.FIELD_LABEL])

export const hasEquivalentOptions = (options1, options2) => {
  const mappedOptions2 = R.map(pickValueLabel, R.or(options2), [])

  return R.all(R.flip(R.includes())(mappedOptions2), R.map(pickValueLabel, R.or(options1), []))
}

// event
export const getEventTargetName = event => R.path(['target', 'name'], event)
export const getEventTargetValue = event => R.path(['target', 'value'], event)

export const getRequiredCellClassName = ({ rowData, columnId }, conditionColumnId) => {
  const checkColumns = R.filter(isNotNilAndNotEmpty, [columnId, conditionColumnId])

  const condition = isAnyTrue(...R.map(
    column => isNotNilAndNotEmpty(getPropFromObject(column, rowData)),
    checkColumns,
  ))

  return ifElse(condition, '', 'error-cell')
}

export const getRequiredCellClassNameTodayDate = ({ rowData, columnId }) => {
  const todayDate = H.getDateWithFormat(H.getCurrentDate(), C.DEFAULT_DATE_FORMAT)
  const date = H.getDateWithFormatOrNull(R.pathOr(null, [columnId], rowData), C.DEFAULT_DATE_FORMAT)

  return ifElse(R.gte(date, todayDate), '', 'error-cell')
}

export const getCellClassNameEndDate = ({ rowData, columnId }) => {
  const endDate = H.getDateWithFormatOrNull(R.pathOr(null, [columnId], rowData), C.DEFAULT_DATE_FORMAT)
  const startDate = H.getDateWithFormatOrNull(R.pathOr(null, [C.FIELD_START_DATE], rowData), C.DEFAULT_DATE_FORMAT)

  return ifElse(R.gt(endDate, startDate), '', 'error-cell')
}

export const getRequiredCellClassNameUniqueTruckId = ({ rowData, columnId }, newData, existingData) => {
  const truckId = R.path([columnId], rowData)

  const truckIds = R.pluck(columnId, newData)
  const duplicates = R.length(R.filter(R.equals(truckId), truckIds))

  const condition = isAnyTrue(
    R.gt(duplicates, 1),
    isNilOrEmpty(truckId),
    R.includes(truckId, R.map(item => R.path([columnId], item), existingData)),
  )

  return ifElse(condition, 'error-cell', '')
}

export const getRequiredHeaderClassName = (data, conditionColumnId) => {
  const condition = isAllTrue(...R.map(item => isNotNilAndNotEmpty(R.prop(conditionColumnId, item)), R.or(data, [])))

  return ifElse(condition, '', 'required')
}

export const getCoords = element => {
  const box = element?.getBoundingClientRect()

  const body = getPropFromObject('body', document) // eslint-disable-line
  const docEl = getPropFromObject('documentElement', document) // eslint-disable-line

  const scrollTop = R.or(
    getPropFromObject('pageYOffset', window), // eslint-disable-line
    R.pathOr(getPropFromObject('scrollTop', docEl), ['scrollTop'], body),
  )
  const scrollLeft = R.or(
    getPropFromObject('pageXOffset', window), // eslint-disable-line
    R.pathOr(getPropFromObject('scrollLeft', docEl), ['scrollLeft'], body),
  )

  const clientTop = R.or(R.pathOr(0, ['clientTop'], docEl), R.pathOr(0, ['clientTop'], body))
  const clientLeft = R.or(R.pathOr(0, ['clientLeft'], docEl), R.pathOr(0, ['clientLeft'], body))

  const top = R.subtract(R.add(getPropFromObject('top', R.or(box, {})), scrollTop), clientTop)
  const left = R.subtract(R.add(getPropFromObject('left', R.or(box, {})), scrollLeft), clientLeft)

  return { top: Math.round(top), left: Math.round(left) }
}

// template placeholders
export const getPlaceholderValue = ({ values, identifier }) => {
  if (isNotNilAndNotEmpty(R.prop(identifier, values))) {
    if (R.and(
      R.not(R.startsWith(C.HTTPS_PREFIX, R.prop(identifier, values))),
      R.includes(
        identifier,
        [
          C.TEMPLATE_PLACEHOLDERS.WEBSITE,
          C.TEMPLATE_PLACEHOLDERS.AVATAR_URL,
          C.TEMPLATE_PLACEHOLDERS.COMPANY_LOGO_URL,
          C.TEMPLATE_PLACEHOLDERS.MY_COMPANY_CHANNEL,
          C.TEMPLATE_PLACEHOLDERS.MY_COMPANY_TWITTER,
          C.TEMPLATE_PLACEHOLDERS.MY_COMPANY_FACEBOOK,
          C.TEMPLATE_PLACEHOLDERS.MY_COMPANY_LINKEDIN,
          C.TEMPLATE_PLACEHOLDERS.MY_COMPANY_INSTAGRAM,
        ],
      ),
    )) {
      return R.concat(C.HTTPS_PREFIX, R.prop(identifier, values))
    }

    if (R.includes(identifier, [C.FIELD_USE_AVATAR, C.FIELD_USE_COMPANY_LOGO])) {
      return R.prop(identifier, values) ? 'yes' : 'no'
    }
  }

  return R.prop(identifier, values)
}

// colors
const getRandomDarkColor = () => {
  const randomChannel = () => Math.floor(Math.random() * 150) // Random value between 0 and 149 (darker colors)

  const r = randomChannel()
  const g = randomChannel()
  const b = randomChannel()

  return `rgb(${r},${g},${b})`
}

const getFirstString = input => {
  const commaIndex = input.indexOf(',')

  if (notEquals(commaIndex, -1)) return input.substring(0, commaIndex).trim()

  return input.trim()
}

export const getColorForString = string => {
  const firstString = getFirstString(string)

  if (notHas('stringColors', getColorForString)) {
    getColorForString.stringColors = {}
  }

  if (notHas(firstString, getColorForString.stringColors)) {
    getColorForString.stringColors[firstString] = getRandomDarkColor()
  }

  return getColorForString.stringColors[firstString]
}

// URLs
export const getUrlValue = value => {
  if (R.and(isString(value), isNotNilAndNotEmpty(value))) {
    if (R.not(R.startsWith(C.HTTPS_PREFIX, value))) {
      return R.concat(C.HTTPS_PREFIX, value)
    }
  }

  return value
}

import * as R from 'ramda'
import { useEffect } from 'react'
import Joyride from 'react-joyride'
import a11yChecker from 'a11y-checker'
import { useMount, useSetState } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
// material
import { useTheme } from '@mui/material/styles'
// helpers
import * as H from '../../helpers'
// features tour
import { TOUR_ACTIONS } from './constants'
import { renderDefaultTourStyles } from './ui'
import {
  setOpenMap,
  setOpenModal,
  setTourRoute,
  setOpenGroups,
  setTourModalId,
  setOpenAddMode,
  setOpenAsideMenu,
  setDisableButtons,
  setOpenAccountMenu,
  setDisableScrolling,
  setTourHiddenObjects,
  makeSelectStartedTourStep,
  makeSelectDisableTourScrolling,
} from './tourSlice'
// styles
import './index.css'
//////////////////////////////////////////////////

const Tour = ({ pathname, tourSteps, renderTour, handleTourCallback }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const startedTourStep = useSelector(makeSelectStartedTourStep)
  const disableScrolling = useSelector(makeSelectDisableTourScrolling)

  const { colors, palette } = theme

  const [{ run, steps, stepIndex }, setState] = useSetState({ // eslint-disable-line
    run: false,
    steps: tourSteps,
    stepIndex: startedTourStep,
  })

  const tourSetters = {
    dispatch,
    setState,
    setOpenMap,
    setTourRoute,
    setOpenModal,
    setOpenGroups,
    setTourModalId,
    setOpenAddMode,
    setOpenAsideMenu,
    setDisableButtons,
    setOpenAccountMenu,
    setDisableScrolling,
    setTourHiddenObjects,
  }

  useEffect(() => {
    setState({ run: true, steps: tourSteps, stepIndex: startedTourStep })
    renderTour && renderTour({
      ...tourSetters,
      pathname,
      startedTourStep,
    })
  }, [pathname, tourSteps, startedTourStep]) // eslint-disable-line

  useMount(() => {
    setState({ steps, run: true })
    a11yChecker()
  })

  useEffect(() => {
    const handleKeyboardNavigation = event => {
      if (R.equals(H.getPropFromObject('key', event), 'ArrowRight')) {
        setState(prevState => ({
          ...prevState,
          action: TOUR_ACTIONS.NEXT,
          stepIndex: R.inc(H.getPropFromObject('stepIndex', prevState)),
        }))
      }

      if (R.equals(H.getPropFromObject('key', event), 'ArrowLeft')) {
        setState(prevState => ({
          ...prevState,
          action: TOUR_ACTIONS.PREV,
          stepIndex: R.dec(H.getPropFromObject('stepIndex', prevState)),
        }))
      }
    }

    document.addEventListener('keydown', handleKeyboardNavigation) // eslint-disable-line

    return () => {
      document.removeEventListener('keydown', handleKeyboardNavigation) // eslint-disable-line
    }
  }, [setState])

  return (
    <Joyride
      run={run}
      continuous
      showProgress
      steps={steps}
      showSkipButton
      scrollToFirstStep
      stepIndex={stepIndex}
      disableOverlay={false}
      disableScrollParentFix
      disableScrolling={disableScrolling}
      styles={renderDefaultTourStyles({ colors, palette })}
      callback={data => handleTourCallback({
        ...tourSetters,
        data,
        startedTourStep: R.or(startedTourStep, 0),
      })}
    />
  )
}

export default Tour

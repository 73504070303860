import * as R from 'ramda'
import i18next from 'i18next'
// helpers/constants
import * as H from '../../../helpers'
import * as C from '../../../constants'
// features tour
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import {
  defaultStep,
  renderTitle,
  getStateObj,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

export const defaultMailingLogTourRows = [
  {
    [C.FIELD_ID]: 1,
    [C.FIELD_PROVIDER]: C.ENUM_GMAIL,
    [C.FIELD_CAMPAIGN_NAME]: 'CAMPAIGN A',
    [C.FIELD_FROM_EMAIL]: 'companybmail@gmail.com',
    [C.FIELD_STATUS]: C.MAILING_LOG_STATUSES.COMPLETED,
    [C.FIELD_DESCRIPTION]: 'There are no trucks in the area',
    [C.FIELD_CREATED_DATE]: H.getDateWithFormat(
      '2023-03-28T10:33:12.855Z',
      C.DEFAULT_DATE_FORMAT,
    ),
  },
]

export const openMailingLogTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
]

export const mailingLogTourPreviewSteps = [
  getDefaultFirstStepSettings('mailingLog'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_MAILING_LOG),
    content: renderTitle(i18next.t('labels.mailingLog'), i18next.t('labels.welcomeMailingLog')),
  },
]

export const mailingLogTourMailSteps = [
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.campaignName')),
    content: renderContent(i18next.t('labels.yourCampaignName')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(2)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.status')),
    content: renderContent(i18next.t('labels.yourCampaignStatus')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.provider')),
    content: renderContent(i18next.t('labels.yourCampaignProvider')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.fromEmail')),
    content: renderContent(i18next.t('labels.yourCampaignService')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.description')),
    content: renderContent(i18next.t('labels.yourCampaignDescription')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(6)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.createdDate')),
    content: renderContent(i18next.t('labels.yourCampaignCreatedDate')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(7)',
  },
]

export const mailingLogFullTourSteps = [
  ...mailingLogTourPreviewSteps,
  ...mailingLogTourMailSteps,
]

export const handleMailingLogTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenAsideMenu(false))
      dispatch(setDisableButtons(false))
      dispatch(setStartedTourStep(null))
      dispatch(setDisableScrolling(false))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.MAILING_LOG,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        dispatch(setOpenAsideMenu(true))
        dispatch(setDisableButtons(true))
        dispatch(setDisableScrolling(true))
        dispatch(setTourDefaultDataStep(defaultMailingLogTourRows))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 0, 400)
        }, 400)
      }

      if (R.equals(index, 1) && H.notEquals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAddMode(true))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex, 400)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      defaultStep(setState, nextStepIndex, 400)
    }
  }

  consoleShowTourLogs(data, type, status)
}

export const renderMailingLogTour = ({
  pathname,
  dispatch,
  setState,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_MAILING_LOG)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenAddMode(true))
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(mailingLogFullTourSteps))
      dispatch(setTourDefaultDataStep(defaultMailingLogTourRows))
    }
  }
}

import * as R from 'ramda'
// material
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
// helpers/constants
import * as C from '../../constants'
//////////////////////////////////////////////////

const countries = [
  {
    phone: '1',
    value: 'US',
    suggested: true,
    label: C.UNITED_STATES,
  },
  {
    phone: '1',
    value: 'CA',
    label: C.CANADA,
    suggested: true,
  },
  // { code: 'UA', label: 'Ukraine', phone: '380' },
]

const Input = params => (
  <TextField
    {...params}
    label='Choose a country'
    inputProps={{
      ...params.inputProps,
      autoComplete: 'new-password',
    }}
  />
)

const Option = (props, { value, label, phone }) => (
  <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
    <img
      alt=''
      width='20'
      loading='lazy'
      src={`https://flagcdn.com/w20/${R.toLower(value)}.png`}
      srcSet={`https://flagcdn.com/w40/${R.toLower(value)}.png 2x`}
    />
    {label} ({value}) +{phone}
  </Box>
)

const CountrySelect = props => (
  <Autocomplete
    {...props}
    fullWidth
    size='small'
    autoHighlight
    options={countries}
    renderInput={Input}
    renderOption={Option}
    name={C.FIELD_COUNTRY}
    getOptionLabel={R.pathOr('', ['label'])}
    defaultValue={R.or(R.find(R.propEq('value', 'US'), countries), null)}
  />
)

export default CountrySelect

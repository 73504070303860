import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const companyTemplateAPI = createApi({
  tagTypes: ['CompanyTemplateList'],
  reducerPath: 'companyTemplateAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchCompanyTemplates: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.companyTemplate,
      }),
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'CompanyTemplateList'),
    }),
    updateCompanyTemplate: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.companyTemplate,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CompanyTemplateList', id }],
    }),
    deleteCompanyTemplate: build.mutation({
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.companyTemplate}/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CompanyTemplateList', id }],
    }),
    createCompanyTemplate: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.companyTemplate,
      }),
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'CompanyTemplateList'),
    }),
    getCompanyTemplate: build.query({
      query: id => ({
        id,
        method: E.METHODS.GET,
        url: `${E.ENDPOINTS.companyTemplate}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'CompanyTemplateList', id }],
    }),
  }),
})

export const {
  useGetCompanyTemplateQuery,
  useFetchCompanyTemplatesQuery,
  useCreateCompanyTemplateMutation,
  useDeleteCompanyTemplateMutation,
  useUpdateCompanyTemplateMutation,
} = companyTemplateAPI

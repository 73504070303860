import * as R from 'ramda'
import i18next from 'i18next'
// helpers/constants
import * as H from '../../../helpers'
import * as C from '../../../constants'
// features tour
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import {
  defaultStep,
  renderTitle,
  buttonTitle,
  getStateObj,
  renderContent,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

const tourRoute = {
  pathname: C.ROUTE_PATH_PROFILE,
}

export const defaultProfileMailingConfigTourRows = [
  {
    [C.FIELD_ID]: 1,
    [C.FIELD_PROVIDER]: C.ENUM_GMAIL,
    [C.FIELD_EMAIL]: 'mymailingconfig@gmail.com',
  },
]

export const openProfileTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
  {
    title: 'labels.editCompanyInfo',
    action: setStartedTourStep(TOUR_STEPS.STEP1),
  },
  {
    title: 'labels.editProfileInfo',
    action: setStartedTourStep(TOUR_STEPS.STEP2),
  },
  {
    title: 'actions.editSocials',
    action: setStartedTourStep(TOUR_STEPS.STEP3),
  },
  {
    title: 'labels.manageSubscription',
    action: setStartedTourStep(TOUR_STEPS.STEP5),
  },
  {
    title: 'labels.clickAuthorization',
    action: setStartedTourStep(TOUR_STEPS.STEP4),
  },
]

export const profileTourPreviewSteps = [
  getDefaultFirstStepSettings('profile'),
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#Profile_button',
    content: renderTitle(i18next.t('labels.profile'), i18next.t('labels.welcomeToProfile')),
  },
]

export const profileTourCompanySteps = [
  {
    ...defaultStepsSettings,
    placement: 'right',
    target: '#company_block',
    styles: { options: { zIndex: 999 } },
    title: renderTitle(i18next.t('labels.companyInfo')),
    content: renderContent(i18next.t('labels.enterInformationAboutYourself')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#editCompany_button',
    title: renderTitle(`${i18next.t('actions.editCompany')} ${buttonTitle}`),
    content: renderContent(i18next.t('labels.enterInformationAboutEditCompany')),
  },
  {
    ...defaultStepsSettings,
    target: '#name',
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.name')),
    content: renderContent(i18next.t('labels.changeNameOfYourCompany')),
  },
  {
    ...defaultStepsSettings,
    target: '#mcNumber',
    title: renderTitle(i18next.t('labels.mcNumber')),
    content: renderContent(i18next.t('labels.inputMCNumber')),
  },
  {
    ...defaultStepsSettings,
    target: '#dotNumber',
    title: renderTitle(i18next.t('labels.dotNumber')),
    content: renderContent(i18next.t('labels.inputDOTNumber')),
  },
  {
    ...defaultStepsSettings,
    target: '#phone',
    title: renderTitle(i18next.t('labels.companyPhoneNumber')),
    content: renderContent(i18next.t('labels.inputCompanyPhoneNumber')),
  },
  {
    ...defaultStepsSettings,
    target: '#extension',
    title: renderTitle(i18next.t('labels.companyPhoneNumberExt')),
    content: renderContent(i18next.t('labels.inputCompanyPhoneNumberExt')),
  },
  {
    ...defaultStepsSettings,
    target: '#address1',
    title: renderTitle(i18next.t('labels.address1')),
    content: renderContent(i18next.t('labels.inputCompanyAddress1')),
  },
  {
    ...defaultStepsSettings,
    target: '#address2',
    title: renderTitle(i18next.t('labels.address2')),
    content: renderContent(i18next.t('labels.inputCompanyAddress2')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#timeZone',
    title: renderTitle(i18next.t('labels.timeZone')),
    content: renderContent(i18next.t('labels.selectYourTimeZones')),
  },
  {
    ...defaultStepsSettings,
    target: '#Save',
    placement: 'top',
    content: renderContent(i18next.t('labels.clickSaveToEditYourCompany')),
    title: renderTitle(`${i18next.t('actions.save')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const profileTourEditYourInfoSteps = [
  {
    ...defaultStepsSettings,
    placement: 'right',
    target: '#info_block',
    styles: { options: { zIndex: 999 } },
    title: renderTitle(i18next.t('labels.personalInfo')),
    content: renderContent(i18next.t('labels.enterInformationAboutYourself')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#editProfile_button',
    title: renderTitle(`${i18next.t('actions.editProfile')} ${buttonTitle}`),
    content: renderContent(i18next.t('labels.enterInformationAboutEditProfile')),
  },
  {
    ...defaultStepsSettings,
    target: '#firstName',
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.firstName')),
    content: renderContent(i18next.t('labels.inputFirstName')),
  },
  {
    ...defaultStepsSettings,
    target: '#lastName',
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.lastName')),
    content: renderContent(i18next.t('labels.inputLastName')),
  },
  {
    ...defaultStepsSettings,
    target: '#middleName',
    title: renderTitle(i18next.t('labels.middleName')),
    content: renderContent(i18next.t('labels.inputMiddleName')),
  },
  {
    ...defaultStepsSettings,
    target: '#position',
    title: renderTitle(i18next.t('labels.position')),
    content: renderContent(i18next.t('labels.inputPosition')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#phone',
    title: renderTitle(i18next.t('labels.phoneNumber')),
    content: renderContent(i18next.t('labels.inputPhoneNumber')),
  },
  {
    ...defaultStepsSettings,
    target: '#Save',
    placement: 'top',
    content: renderContent(i18next.t('labels.clickSaveToEditYourInfo')),
    title: renderTitle(`${i18next.t('actions.save')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const profileTourEditYourSocialsSteps = [
  {
    ...defaultStepsSettings,
    placement: 'right',
    target: '#socials_block',
    styles: { options: { zIndex: 999 } },
    title: renderTitle(i18next.t('labels.socialNetworks')),
    content: renderContent(i18next.t('labels.enterInformationAboutSocials')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#editSocials_button',
    title: renderTitle(`${i18next.t('actions.editSocials')} ${buttonTitle}`),
    content: renderContent(i18next.t('labels.enterInformationAboutEditSocialLinks')),
  },
  {
    ...defaultStepsSettings,
    target: '#website',
    placement: 'bottom',
    content: renderContent(i18next.t('labels.inputWebsiteUrl')),
    title: renderTitle(i18next.t('templatePlaceholders.website')),
  },
  {
    ...defaultStepsSettings,
    target: '#channel',
    placement: 'bottom',
    content: renderContent(i18next.t('labels.inputYoutubeUrl')),
    title: renderTitle(i18next.t('templatePlaceholders.MyCompanyChannel')),
  },
  {
    ...defaultStepsSettings,
    target: '#twitter',
    content: renderContent(i18next.t('labels.inputTwitterUrl')),
    title: renderTitle(i18next.t('templatePlaceholders.MyCompanyTwitter')),
  },
  {
    ...defaultStepsSettings,
    target: '#linkedin',
    content: renderContent(i18next.t('labels.inputLinkedinUrl')),
    title: renderTitle(i18next.t('templatePlaceholders.MyCompanyLinkedin')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#facebook',
    content: renderContent(i18next.t('labels.inputFacebookUrl')),
    title: renderTitle(i18next.t('templatePlaceholders.MyCompanyFacebook')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#instagram',
    content: renderContent(i18next.t('labels.inputInstagramUrl')),
    title: renderTitle(i18next.t('templatePlaceholders.MyCompanyInstagram')),
  },
  {
    ...defaultStepsSettings,
    target: '#Save',
    placement: 'top',
    content: renderContent(i18next.t('labels.clickSaveToEditYourInfo')),
    title: renderTitle(`${i18next.t('actions.save')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const profileSubscriptionSteps = [
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#profile_payments',
    styles: { options: { zIndex: 9998 } },
    content: renderContent(i18next.t('labels.paymentSection')),
    title: renderTitle(i18next.t('labels.subscriptionAndPayments')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#add_cancel_subscription_button',
    content: renderContent(i18next.t('labels.byClickingAddSubscription')),
    title: renderTitle(
      `${i18next.t('actions.addSubscription')}/${i18next.t('actions.cancelSubscription')} ${
        buttonTitle}`,
    ),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#paymentHistory_button',
    content: renderContent(i18next.t('labels.byClickingPaymentHistory')),
    title: renderTitle(`${i18next.t('actions.paymentHistory')} ${buttonTitle}`),
  },
]

export const profileAuthorizationSteps = [
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#authorization_info',
    styles: { options: { zIndex: 9998 } },
    title: renderTitle(i18next.t('labels.emailAuthorizationsInfo')),
    content: renderContent(i18next.t('labels.ifYouHaveEmailAccount')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#authorize_button',
    content: renderContent(i18next.t('labels.byClickingAuthorize')),
    title: renderTitle(`${i18next.t('actions.authorize')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#smtpAuthorize_button',
    content: renderContent(i18next.t('labels.byClickingSMTPAuthorize')),
    title: renderTitle(`${i18next.t('actions.smtpAuthorize')} ${buttonTitle}`),
  },
]

export const profileFullTourSteps = [
  ...profileTourPreviewSteps,
  ...profileTourCompanySteps,
  ...profileTourEditYourInfoSteps,
  ...profileTourEditYourSocialsSteps,
  ...profileSubscriptionSteps,
  ...profileAuthorizationSteps,
]

export const handleProfileTourCallback = ({
  data,
  setState,
  dispatch,
  setTourRoute,
  setOpenModal,
  setTourModalId,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setOpenAccountMenu,
  setTourHiddenObjects,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setOpenAsideMenu(false))
      dispatch(setDisableButtons(false))
      dispatch(setStartedTourStep(null))
      dispatch(setTourHiddenObjects([]))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.PROFILE,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    if (R.equals(index, 0)) {
      dispatch(setTourDefaultDataStep(defaultProfileMailingConfigTourRows))

      setTimeout(() => { // eslint-disable-line
        setState({ run: true })
      }, 400)
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP1)) {
      if (R.equals(index, 0)) {
        dispatch(setDisableButtons(true))
        dispatch(setTourDefaultDataStep(defaultProfileMailingConfigTourRows))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.not(isPrevAction)) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 2, 2000)
      }

      if (R.equals(index, 10) && R.not(isPrevAction)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 10) && isPrevAction) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [10, 11, 12]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 10 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 10 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP2)) {
      if (R.equals(index, 0)) {
        dispatch(setDisableButtons(true))
        dispatch(setTourDefaultDataStep(defaultProfileMailingConfigTourRows))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.not(isPrevAction)) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 2, 2000)
      }

      if (R.equals(index, 7) && R.not(isPrevAction)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 7) && isPrevAction) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [7, 8, 9]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 7 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 7 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP3)) {
      if (R.equals(index, 0)) {
        dispatch(setDisableButtons(true))
        dispatch(setTourDefaultDataStep(defaultProfileMailingConfigTourRows))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.not(isPrevAction)) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 2, 2000)
      }

      if (R.equals(index, 8) && R.not(isPrevAction)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 8) && isPrevAction) {
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [8, 9, 10]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 8 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 8 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          dispatch(setDisableButtons(true))
        }, 400)
      }

      if (R.equals(index, 0) &&
        R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND) &&
        H.notInclude(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])
      ) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
          dispatch(setOpenAccountMenu(true))
          dispatch(setTourRoute(H.jsonStringify(tourRoute)))
          dispatch(setTourHiddenObjects([
            'hideLogo',
            'hideAvatar',
            'hideSocials',
            'hideCheckList',
            'hideAuthorizationInfo',
          ]))
        }, 100)
        defaultStep(setState, 0, 2000)
      }

      if (R.and(R.equals(index, 0), H.notInclude(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED]))) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
          dispatch(setOpenAccountMenu(true))
          dispatch(setTourRoute(H.jsonStringify(tourRoute)))
          dispatch(setTourHiddenObjects([
            'hideLogo',
            'hideAvatar',
            'hideSocials',
            'hideCheckList',
            'hideProfileInfo',
            'hideAuthorizationInfo',
          ]))
        }, 400)
      }

      if (R.any(R.equals(index), [0, 1, 2]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 1 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 1 }))
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setDisableButtons(true))
        dispatch(setOpenAccountMenu(false))
        dispatch(setTourModalId('hideLogo'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAsideMenu(true))
        dispatch(setOpenAccountMenu(true))
        dispatch(setTourRoute(H.jsonStringify(tourRoute)))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2)) {
        dispatch(setTourModalId('openCompanyModal'))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAsideMenu(true))
        dispatch(setOpenAccountMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [1, 2]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 1, 100)
      }

      if (R.equals(index, 3) && R.not(isPrevAction)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          dispatch(setTourModalId('openCompanyModal'))
        }, 100)
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideSocials',
          'hideCheckList',
          'hideProfileInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, 3, 200)
      }

      if (R.any(R.equals(index), [3, 4, 5]) && R.not(isPrevAction) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 4 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 4 }))
        }, 400)
      }

      if (R.equals(index, 4) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [4, 5]) && R.not(isPrevAction) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 4, 100)
      }

      if (R.equals(index, 11)) {
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideSocials',
          'hideCheckList',
          'hideCompanyInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [10, 11, 12]) && R.not(isPrevAction) &&
        R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setTourModalId('openProfileModal'))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 12 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setTourModalId('openProfileModal'))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 12 }))
        }, 400)
      }

      if (R.equals(index, 12)) {
        dispatch(setOpenModal(false))
        dispatch(setTourModalId('openProfileModal'))
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideSocials',
          'hideCheckList',
          'hideCompanyInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [11, 12]) && isPrevAction) {
        dispatch(setTourModalId('openCompanyModal'))
        dispatch(setOpenModal(true))
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideSocials',
          'hideCheckList',
          'hideProfileInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 14)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
        }, 100)
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideSocials',
          'hideCheckList',
          'hideCompanyInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [14, 15]) && isPrevAction) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
        }, 100)
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 15) && isPrevAction) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
        }, 100)
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [14, 15]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 14, 100)
      }

      if (R.equals(index, 20)) {
        dispatch(setOpenModal(false))
        dispatch(setTourModalId('openSocialsModal'))
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideCheckList',
          'hideProfileInfo',
          'hideCompanyInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 20) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setTourModalId('openSocialsModal'))
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideSocials',
          'hideCheckList',
          'hideCompanyInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 20) && isPrevAction) {
        dispatch(setTourModalId('openProfileModal'))
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
        }, 100)
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [20, 21]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 20, 100)
      }

      if (R.equals(index, 23)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
        }, 100)
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 23) && isPrevAction) {
        dispatch(setTourModalId('openSocialsModal'))
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
        }, 100)
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [23, 24]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 23, 100)
      }

      if (R.equals(index, 29)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 29) && isPrevAction) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [29, 30]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 29, 100)
      }

      if (R.equals(index, 31)) {
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hidePayments',
          'hideCheckList',
          'hideCompanyInfo',
          'hideProfileInfo',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 31) && isPrevAction) {
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideCheckList',
          'hideProfileInfo',
          'hideCompanyInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 32) && isPrevAction) {
        dispatch(setTourHiddenObjects([
          'hideLogo',
          'hideAvatar',
          'hideCheckList',
          'hideCompanyInfo',
          'hideProfileInfo',
          'hideAuthorizationInfo',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 33)) {
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 34) && isPrevAction) {
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 35)) {
        defaultStep(setState, nextStepIndex, 200)
      }
    }

    consoleShowTourLogs(data, type, status)
  }
}

export const renderProfileTour = ({
  pathname,
  dispatch,
  setState,
  setTourRoute,
  setTourModalId,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setOpenAccountMenu,
  setTourHiddenObjects,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_PROFILE)) {
    dispatch(setTourRoute(H.jsonStringify(tourRoute)))

    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setOpenAccountMenu(true))
      setState(getStateObj(profileFullTourSteps))
      dispatch(setTourRoute(H.jsonStringify(tourRoute)))
      dispatch(setTourHiddenObjects([
        'hideLogo',
        'hideAvatar',
        'hideSocials',
        'hideCheckList',
        'hideProfileInfo',
        'hideAuthorizationInfo',
      ]))
    }

    if (R.equals(TOUR_STEPS.STEP1, startedTourStep)) {
      dispatch(setDisableButtons(true))
      dispatch(setTourModalId('openCompanyModal'))
      setState(getStateObj(profileTourCompanySteps))
      dispatch(setTourHiddenObjects([
        'hideLogo',
        'hideAvatar',
        'hideSocials',
        'hidePayments',
        'hideCheckList',
        'hideProfileInfo',
      ]))
    }

    if (R.equals(TOUR_STEPS.STEP2, startedTourStep)) {
      dispatch(setDisableButtons(true))
      dispatch(setTourModalId('openProfileModal'))
      setState(getStateObj(profileTourEditYourInfoSteps))
      dispatch(setTourHiddenObjects([
        'hideLogo',
        'hideAvatar',
        'hideSocials',
        'hidePayments',
        'hideCheckList',
        'hideCompanyInfo',
      ]))
    }

    if (R.equals(TOUR_STEPS.STEP3, startedTourStep)) {
      dispatch(setDisableButtons(true))
      dispatch(setTourModalId('openSocialsModal'))
      setState(getStateObj(profileTourEditYourSocialsSteps))
      dispatch(setTourHiddenObjects([
        'hideLogo',
        'hideAvatar',
        'hidePayments',
        'hideCheckList',
        'hideProfileInfo',
        'hideCompanyInfo',
      ]))
    }

    if (R.equals(TOUR_STEPS.STEP4, startedTourStep)) {
      dispatch(setDisableButtons(true))
      dispatch(setTourModalId('hidePayments'))
      setState(getStateObj(profileAuthorizationSteps))
      dispatch(setTourHiddenObjects([
        'hideLogo',
        'hideAvatar',
        'hideSocials',
        'hidePayments',
        'hideCheckList',
        'hideProfileInfo',
      ]))
      dispatch(setTourDefaultDataStep(defaultProfileMailingConfigTourRows))
    }

    if (R.equals(TOUR_STEPS.STEP5, startedTourStep)) {
      dispatch(setDisableButtons(true))
      dispatch(setTourModalId('hideAuthentication'))
      setState(getStateObj(profileSubscriptionSteps))
      dispatch(setTourRoute(H.jsonStringify(tourRoute)))
      dispatch(setTourHiddenObjects([
        'hideLogo',
        'hideAvatar',
        'hideSocials',
        'hideCheckList',
        'hideProfileInfo',
        'hideAuthorizationInfo',
      ]))
      dispatch(setTourDefaultDataStep(defaultProfileMailingConfigTourRows))
    }
  }
}

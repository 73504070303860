import * as R from 'ramda'
import provinces from 'provinces-ca'
import { useStates } from 'react-us-states'
import Select, { components } from 'react-select'
import { memo, useRef, useMemo, useState, useLayoutEffect } from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
// material
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
// material-icons
import PublicIcon from '@mui/icons-material/PublicOutlined'
// helpers/constants
import * as H from '../../helpers'
import * as C from '../../constants'
// translations
import i18n from './../../translations'
//////////////////////////////////////////////////

const sortOptions = (options, order = 'ASC') => {
  const sortByValue = R.sortBy(R.prop(C.FIELD_LABEL))

  if (R.equals(order, 'DESC')) {
    return R.reverse(sortByValue(options))
  } else {
    return sortByValue(options)
  }
}

const getDefaultStyles = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors, palette, rgbaColors } = useTheme()

  const optionColorsMap = {
    transparent: 'transparent',
    '#2684FF': palette.background.multiInputTextBg,
    '#DEEBFF': palette.background.secondaryMainColor,
  }

  const input = {
    maxWidth: '120px',
    color: palette.text.primary,
  }
  const menuList = {
    maxHeight: 220,
    background: palette.background.menuListBg,
  }
  const valueContainer = {
    display: 'flex',
    alignItems: 'center',
  }
  const indicatorSeparator = {
    opacity: 0,
    color: palette.text.primary,
  }
  const indicatorsContainer = {
    display: 'none',
  }
  const placeholder = active => ({
    opacity: active ? 1 : 0,
    color: palette.text.primary,
  })
  const container = {
    flex: 1,
    alignSelf: 'stretch',
    textTransform: 'none',
  }
  const multiValue = {
    width: 50,
    fontSize: 15,
    borderRadius: '16px',
    justifyContent: 'center',
    background: palette.background.multiInputTextBg,
  }
  const control = {
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    position: 'relative',
    color: palette.text.primary,
  }
  const option = (provided, state) => ({
    fontSize: 13,
    display: 'flex',
    padding: '5px 10px',
    alignItems: 'center',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    height: state.data.label ? `${state.data.label.length + 30}px` : 'auto',
    color: state.isSelected ? palette.primary.main : palette.text.greyColor,
    background: R.pathOr(null, [provided.backgroundColor], optionColorsMap),
    ':active': {
      background: colors.transparentMain,
    },
    ':hover': {
      background: H.ifElse(R.propEq('backgroundColor', palette.background.darkGrey, provided), rgbaColors.violet0_12),
    },
  })
  const singleValue = {
    color: `${palette.text.primary} !important`,
  }
  const multiValueLabel = {
    color: palette.text.primary,
  }
  const multiValueRemove = {
    width: 14,
    padding: 0,
    height: 15,
    cursor: 'pointer',
    alignSelf: 'center',
    ':hover': {
      color: colors.white,
      borderRadius: '50%',
      background: `${palette.text.primary} !important`,
    },
  }

  return ({
    input,
    option,
    control,
    menuList,
    container,
    multiValue,
    singleValue,
    placeholder,
    valueContainer,
    multiValueLabel,
    multiValueRemove,
    indicatorSeparator,
    indicatorsContainer,
  })
}

const getDropdownPlacement = ref => {
  if (H.isTrue(R.gte(R.path(['top'], H.getCoords(R.path(['inputRef'], ref))), 400))) return 'top'

  return 'auto'
}

const DropdownIndicator = () => {
  const { palette } = useTheme()

  return (
    <svg width='6' height='5' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill={palette.text.primary || '#787878'}
        // eslint-disable-next-line max-len
        d='M0.257807 1.75844L2.41614 3.91677C2.74114 4.24177 3.26614 4.24177 3.59114 3.91677L5.74947 1.75844C6.27447 1.23344 5.89947 0.333435 5.15781 0.333435H0.84114C0.099473 0.333435 -0.267193 1.23344 0.257807 1.75844Z'
      />
    </svg>
  )
}

export const SelectColumn = memo(function SelectColumn (props) {
  const {
    focus,
    active,
    rowData,
    propStyles = {},
    setRowData,
    stopEditing,
    isDisabled = false,
    deletedOptions = [],
  } = props

  const options = R.compose(
    R.map(({ name, label, value, isDisabled }) =>
      ({ value, isDisabled: R.or(isDisabled, false), label: R.or(label, name) })),
    R.pathOr([], ['options']),
  )(props)

  const optionsForSelect = H.removeEnumsFromOptions(options, R.or(deletedOptions, []))

  const ref = useRef()

  const [menuPlacement, setMenuPlacement] = useState('bottom')

  const handleFocus = () => {
    if (ref?.current) setMenuPlacement(getDropdownPlacement(ref.current))
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  const { colors, palette, rgbaColors } = useTheme()

  const {
    input,
    option,
    control,
    menuList,
    container,
    singleValue,
    placeholder,
    indicatorSeparator,
  } = getDefaultStyles()

  const styles = useMemo(() => R.mergeRight(
    {
      container: provided => ({
        ...provided,
        ...container,
        fontSize: 13,
        display: 'flex',
        margin: '0 5px',
        alignSelf: 'center',
        justifyContent: 'center',
        pointerEvents: focus ? null : 'none',
      }),
      control: provided => ({
        ...provided,
        ...control,
        minHeight: 30,
        maxHeight: 30,
        width: '100%',
        border: 'none',
        boxShadow: 'none',
        background: palette.background.multiInputTextBg,
      }),
      singleValue: provided => ({
        ...provided,
        ...singleValue,
      }),
      indicatorSeparator: provided => ({
        ...provided,
        ...indicatorSeparator,
      }),
      indicatorsContainer: provided => ({
        ...provided,
        height: 30,
        paddingRight: '10px',
      }),
      placeholder: provided => ({
        ...provided,
        ...placeholder(active),
      }),
      option: (base, state) => ({
        ...base,
        ...option(base, state),
        minHeight: 30,
        maxWidth: '200px',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word !important',
      }),
      input: provided => ({
        ...provided,
        ...input,
        maxWidth: '20px',
      }),
      menuList: base => ({
        ...base,
        ...menuList,
      }),
    },
    propStyles,
  ), [focus, active, colors, rgbaColors, propStyles]) // eslint-disable-line

  const defaultValue = H.ifElse(
    R.and(H.isNilOrEmpty(rowData), H.hasEquivalentOptions(C.BOOLEAN_OPTIONS)),
    true,
    rowData,
  )

  const value = R.or(R.find(R.propEq('value',
    H.ifElse(H.isObject(rowData), H.getIdFromObject(defaultValue), defaultValue)), options),
  null,
  )

  return (
    <Select
      ref={ref}
      value={value}
      styles={styles}
      menuIsOpen={focus}
      menuPosition='fixed'
      onFocus={handleFocus}
      isDisabled={isDisabled}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body} // eslint-disable-line
      components={{ DropdownIndicator }}
      options={sortOptions(optionsForSelect)}
      onMenuClose={() => stopEditing({ nextRow: false })}
      isOptionDisabled={option => H.getPropFromObject('isDisabled', option)}
      onKeyDown={event => {
        if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) {
          event.preventDefault()
          stopEditing({ nextRow: false })
        }
      }}
      onChange={option => {
        if (R.isNil(option)) return

        setRowData(option.value)
        setTimeout(stopEditing, 0) // eslint-disable-line
      }}
    />
  )
})

const getOriginDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return null

  const { id, city } = rowData

  return {
    id,
    label: city,
    suggestion: rowData,
  }
}

const getTooltipTitle = rowData => {
  if (H.isNilOrEmpty(rowData)) return null

  const { city, state, country, formatted_address } = rowData

  const orValue = R.compose(
    R.join(', '),
    R.filter(H.isNotNilAndNotEmpty),
  )([city, state, country])

  return R.or(formatted_address, orValue)
}

const ControlForPlacesAutocomplete = ({ children, ...props }) => (
  <components.Control {...props}>
    <Tooltip title={R.pathOr('', ['selectProps', 'tooltipTitle'], props)}>
      <div>
        {children}
      </div>
    </Tooltip>
  </components.Control>
)

export const PlacesAutoCompleteSelectColumn = props => {
  const { focus, active, rowData, setRowData, stopEditing } = props

  const {
    setValue,
    clearSuggestions,
    suggestions: { data },
  } = usePlacesAutocomplete({ debounce: 200 })

  const ref = useRef()
  const { palette } = useTheme()

  const [menuPlacement, setMenuPlacement] = useState('bottom')

  const handleFocus = () => {
    if (ref?.current) setMenuPlacement(getDropdownPlacement(ref.current))
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  const {
    input,
    option,
    control,
    menuList,
    container,
    singleValue,
    placeholder,
    indicatorSeparator,
    indicatorsContainer,
  } = getDefaultStyles()

  const styles = useMemo(() => ({
    container: provided => ({
      ...provided,
      ...container,
      pointerEvents: focus ? null : 'none',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      ...singleValue,
      color: state.selectProps.changeInput,
    }),
    control: provided => ({
      ...provided,
      ...control,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      ...indicatorSeparator,
    }),
    indicatorsContainer: provided => ({
      ...provided,
      ...indicatorsContainer,
    }),
    placeholder: provided => ({
      ...provided,
      ...placeholder(active),
    }),
    option: (base, state) => ({
      ...base,
      ...option(base, state),
    }),
    input: provided => ({
      ...provided,
      ...input,
    }),
    menuList: base => ({
      ...base,
      ...menuList,
    }),
  }), [focus, active]) // eslint-disable-line

  const handleSelect = ({ value }) => {
    const { description } = value

    clearSuggestions()
    setTimeout(stopEditing, 0) // eslint-disable-line
    setValue(description, false)

    getGeocode({ address: description }).then(results => {
      const result = results[0]
      const rowDataId = H.getIdFromObject(rowData)

      if (H.isNilOrEmpty(H.getPropFromObject(C.FIELD_STATE, H.getGoogleResultFields(result))))
        return H.showToastrMessageSimple('error', i18n.t('errors.pleaseSelectLocationWithState'))

      if (H.isNotNilAndNotEmpty(rowDataId)) {
        const newRowData = R.assoc('apiId', rowDataId, result)

        return setRowData(newRowData)
      }

      setRowData(result)
    })
  }

  const options = data.map(suggestion => {
    const {
      structured_formatting: { main_text, secondary_text },
    } = suggestion

    const label = `${R.or(main_text, '')} ${R.or(secondary_text, '')}`

    return { label, value: suggestion }
  })

  return (
    <Select
      ref={ref}
      styles={styles}
      isDisabled={false}
      onFocus={handleFocus}
      onChange={handleSelect}
      menuPlacement={menuPlacement}
      options={sortOptions(options)}
      menuPortalTarget={document.body} // eslint-disable-line
      changeInput={palette.text.primary}
      tooltipTitle={getTooltipTitle(rowData)}
      onInputChange={value => setValue(value)}
      defaultValue={getOriginDefaultValue(rowData)}
      value={H.ifElse(H.isNilOrEmpty(rowData), null)}
      placeholder={`${i18n.t('placeholders.search')}...`}
      onMenuClose={() => stopEditing({ nextRow: false })}
      onKeyDown={event => {
        if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) {
          event.preventDefault()
          stopEditing({ nextRow: false })
        }
      }}
      components={{
        Control: ControlForPlacesAutocomplete,
        SingleValue: props => <components.SingleValue {...props}>{getTooltipTitle(rowData)}</components.SingleValue>,
      }}
      noOptionsMessage={({ inputValue } = {}) => `${i18n.t(H.ifElse(
        H.isNotNilAndNotEmpty(inputValue),
        'placeholders.loading',
        'placeholders.typeToSearch',
      ))}...`}
    />
  )
}

export const MultiValuePopover = ({ count, multiValues }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  return (
    <>
      <Box
        fontSize={14}
        margin='0 5px'
        sx={{ cursor: 'pointer' }}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        + {count}
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        id={H.ifElse(open, 'simple-popover')}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Box
          p='6px'
          maxWidth={287}
          display='flex'
          flexWrap='wrap'
          maxHeight={120}
          overflow='auto'
          borderRadius='4px'
        >
          {multiValues}
        </Box>
      </Popover>
    </>
  )
}

const getSelectName = (t, name, noTranslation) => {
  if (R.isEmpty(name)) return

  if (R.or(H.isTrue(noTranslation), H.isNumber(name))) return name

  return H.capitalizeFirstStrCharacter(t(`labels.${name}`))
}

const InputOption = ({
  value,
  maxLimit,
  children,
  getStyles,
  isFocused,
  isDisabled,
  isSelected,
  innerProps,
  noTranslation,
  isAllSelected = false,
  ...rest
}) => {
  const { palette } = useTheme()
  const [isActive, setIsActive] = useState(false)

  const onMouseDown = () => setIsActive(true)
  const onMouseUp = () => setIsActive(false)
  const onMouseLeave = () => setIsActive(false)

  // styles
  let bg = 'transparent'

  if (isFocused) bg = palette.background.multiInputTextBg

  if (isActive) bg = palette.background.secondaryMainColor

  const style = {
    display: 'flex ',
    color: 'inherit',
    backgroundColor: bg,
    alignItems: 'center',
  }

  const props = {
    ...innerProps,
    style,
    onMouseUp,
    onMouseDown,
    onMouseLeave,
  }

  if (R.equals(value, 'all')) {
    if (H.isNotNilAndNotEmpty(maxLimit)) return null

    return (
      <components.Option
        {...rest}
        innerProps={props}
        isFocused={isFocused}
        getStyles={getStyles}
        isSelected={isSelected}
        isDisabled={isDisabled}
      >
        <ListItem
          disabled={H.isNotNilAndNotEmpty(maxLimit)}
          sx={{
            maxHeight: 30,
            padding: '5px',
            borderBottom: '1px solid',
            borderColor: `${palette.text.disabled} !important`,
            '& .MuiSvgIcon-root': {
              color: isAllSelected ? `${palette.primary.main} !important` : `${palette.text.primary} !important`,
            },
          }}
        >
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={R.not(isAllSelected)}
              disabled={H.isNotNilAndNotEmpty(maxLimit)}
              style={{ indeterminate: { color: palette.primary.main } }}
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                  cursor: 'not-allowed',
                  '&:hover': { backgroundColor: 'transparent' },
                  borderColor: `${palette.text.disabled} !important`,
                  '& [class*=MuiSvgIcon-root]': { fill: palette.text.disabled },
                },
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={i18n.t('labels.selectAll')}
            primaryTypographyProps={{
              fontSize: 13,
              padding: '5px',
              marginLeft: '-30px',
              color: isAllSelected ? `${palette.primary.main} !important` : `${palette.text.primary} !important`,
            }}
          />
        </ListItem>
      </components.Option>
    )
  }

  return (
    <components.Option
      {...rest}
      innerProps={props}
      isFocused={isFocused}
      getStyles={getStyles}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      <ListItem
        disabled={isDisabled}
        sx={{
          maxHeight: 30,
          padding: '0 5px',
          '& .MuiSvgIcon-root': {
            color: isSelected ? `${palette.primary.main} !important` : `${palette.text.primary} !important`,
          },
          '&.Mui-disabled': {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            '&:hover': { backgroundColor: 'transparent' },
            borderColor: `${palette.text.disabled} !important`,
            '& [class*=MuiSvgIcon-root]': { fill: palette.text.disabled },
          },
        }}
      >
        <ListItemIcon>
          <Checkbox
            checked={isSelected}
            disabled={isDisabled}
            style={{ indeterminate: { color: palette.primary.main } }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{ padding: '5px', marginLeft: '-30px' }}
          primary={
            <Typography
              variant='subtitle1'
              sx={{
                color: isSelected ? `${palette.primary.main} !important` : `${palette.text.primary} !important`,
              }}
              style={{
                fontSize: 13,
                textTransform: 'none',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word !important',
              }}
            >
              {getSelectName(i18n.t, children, noTranslation)}
            </Typography>
          }
        />
      </ListItem>
    </components.Option>
  )
}

const ValueContainer = props => {
  const { children } = props

  const value = props.getValue()
  const length = R.length(value)
  const count = R.subtract(length, 2)
  const multiValues = H.ifElse(H.isNotNilAndNotEmpty(value), R.head(children), [])

  return (
    <components.ValueContainer {...props}>
      {R.take(2, multiValues)}
      {
        R.gt(length, 2) ? <MultiValuePopover count={count} multiValues={multiValues} /> : null
      }
      {R.tail(children)}
    </components.ValueContainer>
  )
}

const getOriginMultipleDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return []

  return R.map(item => {
    const { id, city, state } = item

    return ({
      suggestion: item,
      id: R.or(id, state),
      value: R.or(city, state),
      label: R.or(city, state),
    })
  }, rowData)
}

export const PlacesAutoCompleteMultipleSelectColumn = props => {
  const { focus, active, rowData, disabled, setRowData, stopEditing } = props

  const {
    setValue,
    clearSuggestions,
    suggestions: { data },
  } = usePlacesAutocomplete({ debounce: 300 })

  const ref = useRef()

  const [menuPlacement, setMenuPlacement] = useState('bottom')

  const handleFocus = () => {
    if (ref?.current) setMenuPlacement(getDropdownPlacement(ref.current))
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  const { colors, rgbaColors } = useTheme()

  const {
    input,
    option,
    control,
    menuList,
    container,
    multiValue,
    placeholder,
    valueContainer,
    multiValueLabel,
    multiValueRemove,
    indicatorSeparator,
    indicatorsContainer,
  } = getDefaultStyles()

  const styles = useMemo(() => ({
    container: provided => ({
      ...provided,
      ...container,
    }),
    control: provided => ({
      ...provided,
      ...control,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      ...indicatorSeparator,
    }),
    indicatorsContainer: provided => ({
      ...provided,
      ...indicatorsContainer,
    }),
    placeholder: provided => ({
      ...provided,
      ...placeholder(active),
    }),
    multiValue: provided => ({
      ...provided,
      ...multiValue,
      width: 90,
    }),
    option: (provided, state) => ({
      ...provided,
      ...option(provided, state),
    }),
    input: provided => ({
      ...provided,
      ...input,
    }),
    multiValueLabel: provided => ({
      ...provided,
      ...multiValueLabel,
    }),
    multiValueRemove: provided => ({
      ...provided,
      ...multiValueRemove,
      margin: '0 3px 0 0',
    }),
    valueContainer: provided => ({
      ...provided,
      ...valueContainer,
    }),
    menuList: provided => ({
      ...provided,
      ...menuList,
    }),
  }), [active, colors, rgbaColors]) // eslint-disable-line

  const handleSelect = ({ action, option, removedValue }) => {
    const description = R.path([C.FIELD_VALUE, C.FIELD_DESCRIPTION], R.or(option, {}))

    clearSuggestions()
    setTimeout(stopEditing({ nextRow: false }), 0) // eslint-disable-line
    setValue(description, false)

    if (R.equals(action, 'remove-value')) {
      const state = H.getPropFromObject(C.FIELD_VALUE, removedValue)
      const newRowData = R.reject(R.propEq(C.FIELD_CITY, state), rowData)
      setTimeout(stopEditing({ nextRow: false }), 0) // eslint-disable-line

      return setRowData(newRowData)
    }

    getGeocode({ address: description }).then(results => {
      const newRowData = R.append(H.getGoogleResultFields(results[0]), R.or(rowData, []))
      setTimeout(stopEditing({ nextRow: false }), 0) // eslint-disable-line

      setRowData(newRowData.filter((value, index, rows) => R.equals(R.findIndex(
        row => {
          if (H.isNilOrEmpty(H.getPropFromObject(C.FIELD_STATE, row)))
            return H.showToastrMessageSimple('error', i18n.t('errors.pleaseSelectLocationWithState'))

          return R.equals(H.getPropFromObject(C.FIELD_CITY, row), H.getPropFromObject(C.FIELD_CITY, value))
        }, rows,
      ), index)))
    })
  }

  const options = useMemo(() => data?.map(suggestion => {
    const {
      structured_formatting: { main_text, secondary_text },
    } = suggestion

    const label = `${R.or(main_text, '')} ${R.or(secondary_text, '')}`

    return { label, value: suggestion }
  }), [data])

  const getLabel = (city, state, country) => R.compose(
    R.join(', '),
    R.filter(H.isNotNilAndNotEmpty),
  )([city, state, country])

  return (
    <Select
      isMulti
      ref={ref}
      styles={styles}
      menuIsOpen={focus}
      onFocus={handleFocus}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPlacement={menuPlacement}
      options={sortOptions(options)}
      menuPortalTarget={document.body} // eslint-disable-line
      isDisabled={R.or(disabled, false)}
      tooltipTitle={getTooltipTitle(rowData)}
      onInputChange={value => setValue(value)}
      onMenuClose={() => stopEditing({ nextRow: false })}
      defaultValue={getOriginMultipleDefaultValue(rowData)}
      onChange={(event, newValue) => handleSelect(newValue)}
      onKeyDown={event => {
        if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) {
          event.preventDefault()
          stopEditing({ nextRow: false })
        }
      }}
      value={R.map(
        ({ city, state, country }) => ({ value: R.or(city, state), label: getLabel(city, state, country) }),
        R.or(rowData, []),
      )}
      components={{
        ValueContainer,
        MultiValueLabel: props => (
          <components.MultiValueLabel {...props}>
            {R.path(['data', C.FIELD_LABEL], props)}
          </components.MultiValueLabel>
        ),
      }}
      noOptionsMessage={({ inputValue } = {}) => `${i18n.t(H.ifElse(
        H.isNotNilAndNotEmpty(inputValue),
        'placeholders.loading',
        'placeholders.typeToSearch',
      ))}...`}
    />
  )
}

const getStateDestinationDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return []

  return R.map(({ id, state, country }) => ({ country, label: state, value: state, id: R.or(id, state) }), rowData)
}

const renderStatesLabel = label => (
  <Box mb={1} mt={-1}>
    <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
      <PublicIcon sx={{ mr: 1, cursor: 'default', color: t => t.palette.primary.secondary }} />
      <Typography
        color='default'
        variant='subtitle1'
        style={{
          fontSize: 13,
          textTransform: 'none',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word !important',
        }}
      >
        {i18n.t(`labels.${label}`)}
      </Typography>
    </ListItem>
    <Divider sx={{ width: '95%', margin: '0 auto' }} />
  </Box>
)

export const StatesAutoCompleteMultiSelectColumn = props => {
  const { focus, active, rowData, disabled, setRowData, stopEditing } = props

  const ref = useRef()

  const [menuPlacement, setMenuPlacement] = useState('bottom')

  const handleFocus = () => {
    if (ref?.current) setMenuPlacement(getDropdownPlacement(ref.current))
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  const usStates = useStates()

  const mapper = (list, country) => R.map(({ name: label, abbreviation: value }) => ({
    value,
    country,
    id: value,
    state: value,
    label: `${label} (${value})`,
  }), R.or(list, []))

  const options = useMemo(() => R.concat(mapper(usStates, C.UNITED_STATES), mapper(provinces, C.CANADA)), [usStates])

  const optionsForSelect = useMemo(() => {
    const usaStates = R.of({
      label: renderStatesLabel('unitedStates'),
      options: sortOptions(mapper(usStates, C.UNITED_STATES)),
    })

    const canadianStates = R.of({
      label: renderStatesLabel('canada'),
      options: sortOptions(mapper(provinces, C.CANADA)),
    })

    return R.concat(usaStates, canadianStates)
  }, [usStates])

  const { colors, rgbaColors } = useTheme()

  const {
    input,
    option,
    control,
    menuList,
    container,
    multiValue,
    placeholder,
    valueContainer,
    multiValueLabel,
    multiValueRemove,
    indicatorSeparator,
    indicatorsContainer,
  } = getDefaultStyles()

  const styles = useMemo(() => ({
    container: provided => ({
      ...provided,
      ...container,
    }),
    control: provided => ({
      ...provided,
      ...control,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      ...indicatorSeparator,
    }),
    indicatorsContainer: provided => ({
      ...provided,
      ...indicatorsContainer,
    }),
    placeholder: provided => ({
      ...provided,
      ...placeholder(active),
    }),
    multiValue: provided => ({
      ...provided,
      ...multiValue,
    }),
    option: (provided, state) => ({
      ...provided,
      ...option(provided, state),
    }),
    input: provided => ({
      ...provided,
      ...input,
    }),
    multiValueLabel: provided => ({
      ...provided,
      ...multiValueLabel,
    }),
    multiValueRemove: provided => ({
      ...provided,
      ...multiValueRemove,
    }),
    valueContainer: provided => ({
      ...provided,
      ...valueContainer,
    }),
    menuList: base => ({
      ...base,
      ...menuList,
    }),
  }), [active, colors, rgbaColors]) // eslint-disable-line

  return (
    <Select
      isMulti
      ref={ref}
      styles={styles}
      menuIsOpen={focus}
      onFocus={handleFocus}
      onChange={setRowData}
      closeMenuOnSelect={false}
      options={optionsForSelect}
      hideSelectedOptions={false}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body} // eslint-disable-line
      isDisabled={R.or(disabled, false)}
      onMenuClose={() => stopEditing({ nextRow: false })}
      defaultValue={getStateDestinationDefaultValue(rowData)}
      onKeyDown={event => {
        if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) {
          event.preventDefault()
          stopEditing({ nextRow: false })
        }
      }}
      value={R.map(
        ({ state }) => R.find(R.propEq(C.FIELD_STATE, state), options),
        R.or(rowData, []),
      )}
      components={{
        ValueContainer,
        MultiValueLabel: props => (
          <components.MultiValueLabel {...props}>
            {R.path(['data', 'value'], props)}
          </components.MultiValueLabel>
        ),
      }}
    />
  )
}

const getMultipleSelectDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return []

  return R.map(({ label, value }) => ({ label, value }), rowData)
}

export const MultipleSelectColumn = props => {
  const {
    focus,
    active,
    rowData,
    options,
    disabled,
    maxLimit,
    setRowData,
    stopEditing,
    noTranslation = true,
    withEmptyOptionObject = false,
  } = props
  const ref = useRef()
  const { palette } = useTheme()

  const [menuPlacement, setMenuPlacement] = useState('bottom')

  const handleFocus = () => {
    if (ref?.current) setMenuPlacement(getDropdownPlacement(ref.current))
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  const newOptions = useMemo(() => H.ifElse(
    withEmptyOptionObject,
    R.prepend(C.EMPTY_OPTION_OBJECT, options),
    options,
  ), [options, withEmptyOptionObject])

  const transformedArr = arr => R.compose(
    R.uniq,
    R.map(item => {
      const option = R.find(R.propEq(C.FIELD_VALUE, item), options)

      const option2 = R.find(R.propEq(C.FIELD_VALUE, R.prop(C.FIELD_ID, item)), options)

      if (H.isNilOrEmpty(option)) return option2

      return option
    }),
  )(R.or(arr, []))

  const rowValue = transformedArr(rowData)

  const isLengthsEquals = R.equals(R.length(rowValue), R.length(options))
  const isAllSelected = R.and(R.gt(R.length(options), 0), isLengthsEquals)

  const handleChange = (event, child) => {
    const childValue = R.path(['option', 'value'], child)

    if (R.and(R.equals('all', childValue), H.isTrue(isAllSelected))) {
      return setRowData([])
    }

    if (R.and(R.equals('all', childValue), H.isFalse(isAllSelected))) {
      const allValues = R.map(R.prop(C.FIELD_VALUE), options)

      return setRowData(allValues)
    }

    setRowData(R.map(R.prop(C.FIELD_VALUE), event))
  }

  const { colors, rgbaColors } = useTheme()

  const {
    input,
    option,
    control,
    menuList,
    container,
    multiValue,
    placeholder,
    valueContainer,
    multiValueLabel,
    multiValueRemove,
    indicatorSeparator,
    indicatorsContainer,
  } = getDefaultStyles()

  const styles = useMemo(() => {
    return ({
      container: provided => ({
        ...provided,
        ...container,
      }),
      control: provided => ({
        ...provided,
        ...control,
      }),
      indicatorSeparator: provided => ({
        ...provided,
        ...indicatorSeparator,
      }),
      indicatorsContainer: provided => ({
        ...provided,
        ...indicatorsContainer,
      }),
      placeholder: provided => ({
        ...provided,
        ...placeholder(active),
      }),
      multiValue: provided => ({
        ...provided,
        ...multiValue,
        width: 70,
      }),
      option: (provided, state) => ({
        ...provided,
        ...option(provided, state),
        padding: 5,
        width: 'auto',
        wordBreak: 'break-all',
        ':hover': {
          backgroundColor:
           H.isFalse(R.or(isAllSelected, state.isSelected)) && `${palette.background.secondaryMainColor} !important`,
        },
        backgroundColor: R.or(isAllSelected, state.isSelected)
          ? `${palette.background.default} !important`
          : `${palette.background.menuListBg} !important`,
      }),
      input: provided => ({
        ...provided,
        ...input,
      }),
      multiValueLabel: provided => ({
        ...provided,
        ...multiValueLabel,
      }),
      multiValueRemove: provided => ({
        ...provided,
        ...multiValueRemove,
        margin: '0 3px 0 0',
      }),
      valueContainer: provided => ({
        ...provided,
        ...valueContainer,
        fontSize: 13,
        padding: '5px 0',
      }),
      menuList: base => ({
        ...base,
        ...menuList,
        padding: '5px 0',
        overflowX: 'hidden',
      }),
    })
  }, [active, colors, rgbaColors]) // eslint-disable-line

  const setIsItemDisabled = value => R.and(
    R.gte(R.length(rowValue), maxLimit),
    R.compose(
      R.not,
      R.find(R.propEq(C.FIELD_VALUE, value)),
    )(R.or(rowValue, [])),
  )

  return (
    <Select
      isMulti
      ref={ref}
      styles={styles}
      value={rowValue}
      menuIsOpen={focus}
      onFocus={handleFocus}
      onChange={handleChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body} // eslint-disable-line
      isDisabled={R.or(disabled, false)}
      onMenuClose={() => stopEditing({ nextRow: false })}
      defaultValue={getMultipleSelectDefaultValue(rowData)}
      isOptionDisabled={option => setIsItemDisabled(R.prop(C.FIELD_VALUE, option))}
      options={R.prepend({ label: i18n.t('labels.selectAll'), value: 'all' }, newOptions)}
      onKeyDown={event => {
        if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) {
          event.preventDefault()
          stopEditing({ nextRow: false })
        }
      }}
      components={{
        ValueContainer,
        Option: props =>
          <InputOption
            {...props}
            maxLimit={maxLimit}
            isAllSelected={isAllSelected}
            noTranslation={noTranslation}
          />,
        MultiValueLabel: props => (
          <components.MultiValueLabel {...props}>
            {R.path(['data', C.FIELD_LABEL], props)}
          </components.MultiValueLabel>
        ),
      }}
    />
  )
}

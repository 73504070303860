import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// endpoints
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

const getCacheEntryResult = async ({ id, status, dispatch, getState, cacheDataLoaded }) => {
  await cacheDataLoaded

  const { queries } = R.prop('reservationRequestAPI', getState())

  const originalArgs = R.compose(R.pluck('originalArgs'), R.values)(queries)

  if (H.isNotNilAndNotEmpty(id)) {
    for (const originalArg of originalArgs) {
      dispatch(reservationRequestAPI.util.updateQueryData(
        'fetchReservationRequests',
        originalArg,
        R.evolve({
          elements: R.map(R.when(
            R.propEq(C.FIELD_ID, id),
            R.assoc(C.FIELD_STATUS, status),
          )),
        }),
      ))
    }
  }
}

export const reservationRequestAPI = createApi({
  baseQuery: H.baseQueryWithReAuth,
  reducerPath: 'reservationRequestAPI',
  endpoints: build => ({
    fetchReservationRequests: build.query({
      extraOptions: { useLoader: true },
      query: page => ({
        method: E.METHODS.GET,
        url: E.ENDPOINTS.getTruckReservationRequests({ page, limit: 20 }),
      }),
    }),
    confirmReservationRequest: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.truckReservationRequests,
      }),
      async onCacheEntryAdded({ status, requestId }, { dispatch, getState, cacheDataLoaded }) {
        await getCacheEntryResult({ status, dispatch, getState, id: requestId, cacheDataLoaded })
      },
    }),
    getReservationInfo: build.mutation({
      extraOptions: { useLoader: true },
      query: ({ page, reservationId }) => ({
        method: E.METHODS.POST,
        url: E.ENDPOINTS.reservationInfo,
        body: {
          page,
          limit: 20,
          reservationId,
        },
      }),
    }),
    changeReservationRequestStatus: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.reservationRequestChangeStatus,
      }),
      async onCacheEntryAdded({ status, reservationId }, { dispatch, getState, cacheDataLoaded }) {
        await getCacheEntryResult({ status, dispatch, getState, cacheDataLoaded, id: reservationId })
      },
    }),
  }),
})

export const {
  useGetReservationInfoMutation,
  useFetchReservationRequestsQuery,
  useConfirmReservationRequestMutation,
  useChangeReservationRequestStatusMutation,
} = reservationRequestAPI

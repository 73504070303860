import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// endpoints
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const companySocialAPI = createApi({
  tagTypes: ['CompanySocial'],
  reducerPath: 'companySocialAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchCompanySocials: build.query({
      extraOptions: { useLoader: true },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.companySocialData,
      }),
      providesTags: (result, error, id) => [{ type: 'CompanySocial', id }],
    }),
    updateCompanySocials: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.companySocialData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CompanySocial', id }],
    }),
  }),
})

export const { useFetchCompanySocialsQuery, useUpdateCompanySocialsMutation } = companySocialAPI

// system fields
export const FIELD_ID = 'id'
export const FIELD_IDS = 'ids'
export const FIELD_ZIP = 'zip'
export const FIELD_TYPE = 'type'
export const FIELD_HOST = 'host'
export const FIELD_PORT = 'port'
export const FIELD_NAME = 'name'
export const FIELD_CITY = 'city'
export const FIELD_DAYS = 'days'
export const FIELD_DATA = 'data'
export const FIELD_NOTE = 'note'
export const FIELD_LAST4 = 'last4'
export const FIELD_BRAND = 'brand'
export const FIELD_HOURS = 'hours'
export const FIELD_EMAIL = 'email'
export const FIELD_VALUE = 'value'
export const FIELD_PHONE = 'phone'
export const FIELD_LABEL = 'label'
export const FIELD_STATE = 'state'
export const FIELD_LOGIN = 'login'
export const FIELD_LIMIT = 'limit'
export const FIELD_GROUP = 'group'
export const FIELD_TOTAL = 'total'
export const FIELD_GROUPS = 'groups'
export const FIELD_EMAILS = 'emails'
export const FIELD_TRUCKS = 'trucks'
export const FIELD_GENDER = 'gender'
export const FIELD_AMOUNT = 'amount'
export const FIELD_STATUS = 'status'
export const FIELD_NAME_LOGO = 'logo'
export const FIELD_COUNTRY = 'country'
export const FIELD_SUBJECT = 'subject'
export const FIELD_WEBSITE = 'website'
export const FIELD_CHANNEL = 'channel'
export const FIELD_TWITTER = 'twitter'
export const FIELD_RESERVE = 'reserve'
export const FIELD_GROUP_ID = 'groupId'
export const FIELD_END_DATE = 'endDate'
export const FIELD_TRUCK_ID = 'truckId'
export const FIELD_SELECTED = 'selected'
export const FIELD_CURRENCY = 'currency'
export const FIELD_LATITUDE = 'latitude'
export const FIELD_POSITION = 'position'
export const FIELD_DISABLED = 'disabled'
export const FIELD_PASSWORD = 'password'
export const FIELD_ELEMENTS = 'elements'
export const FIELD_QUANTITY = 'quantity'
export const FIELD_PROVIDER = 'provider'
export const FIELD_LINKEDIN = 'linkedin'
export const FIELD_FACEBOOK = 'facebook'
export const FIELD_TEMPLATE = 'template'
export const FIELD_REQUIRED = 'required'
export const FIELD_ADDRESS1 = 'address1'
export const FIELD_ADDRESS2 = 'address2'
export const FIELD_ORDERING = 'ordering'
export const FIELD_CUSTOMER = 'customer'
export const FIELD_GROUP_IDS = 'groupIds'
export const FIELD_TO_EMAILS = 'toEmails'
export const FIELD_LAST_NAME = 'lastName'
export const FIELD_TIME_ZONE = 'timeZone'
export const FIELD_EDIT_MODE = 'editMode'
export const FIELD_EMAIL_IDS = 'emailIds'
export const FIELD_TRUCK_IDS = 'truckIds'
export const FIELD_MC_NUMBER = 'mcNumber'
export const FIELD_LOCATIONS = 'locations'
export const FIELD_LONGITUDE = 'longitude'
export const FIELD_EXPIRE_YEAR = 'expYear'
export const FIELD_INSTAGRAM = 'instagram'
export const FIELD_FIRST_NAME = 'firstName'
export const FIELD_DOT_NUMBER = 'dotNumber'
export const FIELD_SENT_COUNT = 'sentCount'
export const FIELD_START_DATE = 'startDate'
export const FIELD_TRUCK_TYPE = 'truckType'
export const FIELD_GROUP_NAME = 'groupName'
export const FIELD_CREATED_BY = 'createdBy'
export const FIELD_FROM_EMAIL = 'fromEmail'
export const FIELD_UPDATED_BY = 'updatedBy'
export const FIELD_COMPANY_ID = 'companyId'
export const FIELD_USE_AVATAR = 'useAvatar'
export const FIELD_REQUEST_ID = 'requestId'
export const FIELD_IDENTIFIER = 'identifier'
export const FIELD_EXPIRE_MONTH = 'expMonth'
export const FIELD_SUBSCRIBED = 'subscribed'
export const FIELD_REMEMBER_ME = 'rememberMe'
export const FIELD_CELL_GUTTER = 'cellGutter'
export const FIELD_DRIVER_TYPE = 'driverType'
export const FIELD_MIDDLE_NAME = 'middleName'
export const FIELD_DRIVER_NAME = 'driverName'
export const FIELD_TEMPLATE_ID = 'templateId'
export const FIELD_FREE_ACCESS = 'freeAccess'
export const FIELD_DESCRIPTION = 'description'
export const FIELD_POSTAL_CODE = 'postal_code'
export const FIELD_PLACEHOLDER = 'placeholder'
export const FIELD_CREATED_DATE = 'createdDate'
export const FIELD_TRUCK_NUMBER = 'truckNumber'
export const FIELD_EMAIL_GROUPS = 'emailGroups'
export const FIELD_UPDATED_DATE = 'updatedDate'
export const FIELD_COMPANY_NAME = 'companyName'
export const FIELD_CONTACT_NAME = 'contactName'
export const FIELD_TRUCKS_UNITS = 'trucksUnits'
export const FIELD_PLACEHOLDERS = 'placeholders'
export const FIELD_UNSUBSCRIBED = 'unsubscribed'
export const FIELD_PHONE_EXTENSION = 'extension'
export const FIELD_CONTACT_EMAIL = 'contactEmail'
export const FIELD_CONTACT_PHONE = 'contactPhone'
export const FIELD_CAMPAIGN_NAME = 'campaignName'
export const FIELD_TEMPLATE_NAME = 'templateName'
export const FIELD_CAMPAIGN_LOGO = 'campaignLogo'
export const FIELD_DEFAULT_VALUE = 'defaultValue'
export const FIELD_MAILING_CONFIG = 'mailingConfig'
export const FIELD_REQUESTED_LIST = 'requestedList'
export const FIELD_RESERVATION_ID = 'reservationId'
export const FIELD_TRAILER_NUMBER = 'trailerNumber'
export const FIELD_EQUIPMENT_TYPE = 'equipmentType'
export const FIELD_EMAIL_TEMPLATE = 'emailTemplate'
export const FIELD_INVOICE_NUMBER = 'invoiceNumber'
export const FIELD_CANCELLED_DATE = 'cancelledDate'
export const FIELD_NUMBER_OF_UNITS = 'numberOfUnits'
export const FIELD_EMAIL_GROUP_IDS = 'emailGroupIds'
export const FIELD_ORIGIN_LOCATION = 'originLocation'
export const FIELD_CAMPAIGN_STATUS = 'campaignStatus'
export const FIELD_REPORTER_AVATAR = 'reporterAvatar'
export const FIELD_USE_COMPANY_LOGO = 'useCompanyLogo'
export const FIELD_TRIAL_EMAIL_LEFT = 'trialEmailLeft'
export const FIELD_CONFIRM_PASSWORD = 'confirmPassword'
export const FIELD_ORIGIN_LOCATIONS = 'originLocations'
export const FIELD_DESTINATION_ZONE = 'destinationZone'
export const FIELD_PICK_UP_DATE_TIME = 'pickupDateTime'
export const FIELD_COMPANY_TEMPLATE = 'companyTemplate'
export const FIELD_MAILING_CONFIG_ID = 'mailingConfigId'
export const FIELD_PAYMENT_DATE_TIME = 'paymentDateTime'
export const FIELD_TRIAL_EMAIL_COUNT = 'trialEmailCount'
export const FIELD_TRIAL_EMAIL_LIMIT = 'trialEmailLimit'
export const FIELD_TRIAL_EMAILS_LEFT = 'trialEmailsLeft'
export const FIELD_SECURITY_PROTOCOL = 'securityProtocol'
export const FIELD_DESTINATION_ZONES = 'destinationZones'
export const FIELD_LAST_MODIFIED_DATE = 'lastModifiedDate'
export const FIELD_REPORTER_LAST_NAME = 'reporterLastName'
export const FIELD_CAMPAIGN_DATE_TIME = 'campaignDateTime'
export const FIELD_CAMPAIGN_SENT_FROM = 'campaignSentFrom'
export const FIELD_HOSTED_INVOICE_URL = 'hostedInvoiceUrl'
export const FIELD_COMPANY_TEMPLATE_ID = 'companyTemplateId'
export const FIELD_DESTINATION_ZONE_ID = 'destinationZoneId'
export const FIELD_REPORTER_FIRST_NAME = 'reporterFirstName'
export const FIELD_DESTINATION_ZONE_IDS = 'destinationZonesIds'
export const FIELD_DESTINATION_LOCATION = 'destinationLocation'
export const FIELD_COMPANY_TEMPLATE_NAME = 'companyTemplateName'
export const FIELD_DESTINATION_LOCATIONS = 'destinationLocations'
export const FIELD_TRUCK_ORIGIN_LOCATIONS = 'truckOriginLocations'
export const FIELD_TEMPLATE_PLACEHOLDER_ID = 'templatePlaceholderId'
export const FIELD_COMPANY_SUBSCRIPTION_DTO = 'companySubscriptionDto'

// lists
export const LIST_TRUCKS = 'trucks'
export const LIST_EMAILS = 'emails'
export const LIST_COMPANIES = 'companies'
export const LIST_EMAIL_CAMPAIGNS = 'jobs'
export const LIST_MAILING_LOG = 'mailing_log'
export const LIST_EMAIL_GROUPS = 'email_groups'
export const LIST_EMAIL_TEMPLATES = 'email_templates'
export const LIST_DESTINATION_ZONES = 'destination_zones'

//list type
export const LIST_TYPE_ADD_MAILS = 'addMails'
export const LIST_TYPE_ADD_TRUCKS = 'addTrucks'
export const LIST_TYPE_ADD_MAIL_GROUP = 'addMailGroup'
export const LIST_TYPE_ADD_EMAIL_CAMPAIGNS = 'addEmailCampaigns'
export const LIST_TYPE_ADD_DESTINATION_ZONE = 'addDestinationZone'

// routes
export const ROUTE_PATH_TRUCKS = '/'
export const ROUTE_PATH_MAILS = '/mails'
export const ROUTE_PATH_LOGIN = '/login'
export const ROUTE_PATH_PROFILE = '/profile'
export const ROUTE_PATH_SIGN_UP = '/sign_up'
export const ROUTE_PATH_MAILING_LOG = '/mailing_log'
export const ROUTE_PATH_ADMIN_PANEL = '/admin-panel'
export const ROUTE_PATH_CONFIRM_EMAIL = '/confirm_email'
export const ROUTE_PATH_CONFIRM_GMAIL = '/confirm_gmail'
export const ROUTE_PATH_PRIVACY_POLICY = '/privacy_policy'
export const ROUTE_PATH_FORGOT_PASSWORD = '/forgot_password'
export const ROUTE_PATH_EMAIL_TEMPLATES = '/email_templates'
export const ROUTE_PATH_CHANGE_PASSWORD = '/forgot-password'
export const ROUTE_PATH_EMAIL_CAMPAIGNS = '/email_campaigns'
export const ROUTE_PATH_AVAILABLE_TRUCKS = '/available_trucks'
export const ROUTE_PATH_DESTINATION_ZONES = '/destination_zones'
export const ROUTE_PATH_TERMS_AND_CONDITIONS = '/terms_&_conditions'
export const ROUTE_PATH_UNSUBSCRIBE_EMAIL = '/email/unsubscribe_url'
export const ROUTE_PATH_RESERVATION_REQUESTS = '/reservation_requests'

// event key codes
export const EVENT_KEY_CODE_TAB = 9

// prefix
export const HTTPS_PREFIX = 'https://'

// template placeholders
export const TEMPLATE_PLACEHOLDERS = {
  BODY: 'body',
  TITLE: 'title',
  EMAIL: 'email',
  PHONE: 'phone',
  MOBILE: 'mobile',
  TITLE2: 'title2',
  WEBSITE: 'website',
  ADDRESS1: 'address1',
  ADDRESS2: 'address2',
  FAREWELL: 'farewell',
  LAST_NAME: 'lastName',
  FIRST_NAME: 'firstName',
  AVATAR_URL: 'userAvatarUrl',
  PHONE_EXTENSION: 'phoneExtension',
  COMPANY_LOGO_URL: 'companyLogoUrl',
  MY_COMPANY_CHANNEL: 'MyCompanyChannel',
  MY_COMPANY_TWITTER: 'MyCompanyTwitter',
  MY_COMPANY_LINKEDIN: 'MyCompanyLinkedin',
  MY_COMPANY_FACEBOOK: 'MyCompanyFacebook',
  MY_COMPANY_INSTAGRAM: 'MyCompanyInstagram',
}

// template groupName
export const GROUP_NAME = {
  MAIN: 'main',
  SOCIAL: 'social',
  SIGNATURE: 'signature',
}

// languages
export const LANGUAGES = {
  EN: 'EN',
  UA: 'UA',
}

// mailing log statuses
export const MAILING_LOG_STATUSES = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
}

// subscription statuses
export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
}

// reservation statuses
export const RESERVATION_STATUSES = {
  NEW: 'NEW',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN PROGRESS',
}

// error status types
export const ERROR_STATUS_TYPES = {
  OK: 200,
  SERVER: 500,
  NO_ACCESS: 403,
  VALIDATION: 400,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
}

// images
export const MAX_FILE_SIZE = 5242880 // 5MB
export const IMG_EXTENSIONS = ['.jpg', '.png', '.jpeg', '.svg']

// file/excel
export const EXCEL_EXTENSIONS = '.xls, .xlsx'
export const BYTE_UNITS = ['KB', 'MB', 'GB', 'TB']

// emails
export const DEFAULT_COMPANY_EMAIL = 'companya@example.test'
export const CUSTOMER_SUPPORT = 'customer.service@mytrucklist.com'

// links
export const MYTRUCKLIST_WEBSITE = 'https://mytrucklist.com'
export const AMOUS_INTERNATIONAL = 'https://amousinternational.com'
export const MYTRUCKLIST_WEBSITE_SIGN_UP = `${MYTRUCKLIST_WEBSITE}/?signup=true`
export const MYTRUCKLIST_WEBSITE_CONTACT_US = `${MYTRUCKLIST_WEBSITE}/contact-us`

// formats
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'
export const TRANSFORMED_DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm'
export const DATE_TIME_FORMAT_FOR_SEARCH = 'YYYY-MM-DDTHH:mmZ'
export const DATE_TIME_FORMAT_FOR_NEXT_DATE_VALIDATION = 'YYYY-MM-DDT00:01Z'

// reg-expressions
export const NUMBER_REGEXP = /^\d+$/ // eslint-disable-line
export const EMAIL_REGEXP = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/ // eslint-disable-line
export const PASSWORD_REGEXP = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,50}$/ // eslint-disable-line
export const DEFAULT_INTERPOLATION_REGEXP = /^(?!.*\$\{(?!(?:CompanyName|FirstName|LastName)\}).*?\}).*$/ // eslint-disable-line
export const PORT_NUMBER_REGEXP = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/ // eslint-disable-line
export const HOST_NAME_REGEXP = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/ // eslint-disable-line
export const PHONE_NUMBER_REGEXP
  = /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/; // eslint-disable-line
export const DATE_AND_DATE_TIME_REGEXP
  = /(?=\d)^(?:(?!(?:10\D(?:0?[5-9]|1[0-4])\D(?:1582))|(?:0?9\D(?:0?[3-9]|1[0-3])\D(?:1752)))((?:0?[13578]|1[02])|(?:0?[469]|11)(?!\/31)(?!-31)(?!\.31)|(?:0?2(?=.?(?:(?:29.(?!000[04]|(?:(?:1[^0-6]|[2468][^048]|[3579][^26])00))(?:(?:(?:\d\d)(?:[02468][048]|[13579][26])(?!\x20BC))|(?:00(?:42|3[0369]|2[147]|1[258]|09)\x20BC))))))|(?:0?2(?=.(?:(?:\d\D)|(?:[01]\d)|(?:2[0-8])))))([-.\/])(0?[1-9]|[12]\d|3[01])\2(?!0000)((?=(?:00(?:4[0-5]|[0-3]?\d)\x20BC)|(?:\d{4}(?!\x20BC)))\d{4}(?:\x20BC)?)(?:$|(?=\x20\d)\x20))?((?:(?:0?[1-9]|1[012])(?::[0-5]\d){0,2}(?:\x20[aApP][mM]))|(?:[01]\d|2[0-3])(?::[0-5]\d){1,2})?$/ // eslint-disable-line

// company-logos
export const DEFAULT_IMAGE_URL = 'https://amous-front-dev.s3.amazonaws.com/'
export const DEFAULT_LOGO_ROUNDED_URL = `${DEFAULT_IMAGE_URL}MTL-logo-3x.png`
export const DEFAULT_LOGO_URL = `${DEFAULT_IMAGE_URL}logo/MyTruckListLogo_violet.png`
export const DEFAULT_AMOUS_TMS_LOGO_ROUNDED_URL = `${DEFAULT_IMAGE_URL}newAmousLogo.svg`
export const DEFAULT_LOGO_DARK_URL = `${DEFAULT_IMAGE_URL}logo/MyTruckListLogo_green.png`

// country
export const USA = 'USA'
export const CANADA = 'Canada'
export const COUNTRY = 'country'
export const UNITED_STATES = 'United States'

// toolkit
export const DEFAULT_SLICE_KEYS = {
  DATA_KEY: 'data',
  ERROR_KEY: 'error',
  LOADING_KEY: 'isLoading',
}

import * as R from 'ramda'
import i18next from 'i18next'
// material
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
// helpers/constants
import * as H from '../../../helpers'
import * as C from '../../../constants'
// features tour
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import {
  defaultStep,
  renderTitle,
  buttonTitle,
  getStateObj,
  defaultStep0,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

const tourRoute = {
  icon: <PinDropOutlinedIcon />,
  label: 'labels.destinationZones',
  path: C.ROUTE_PATH_DESTINATION_ZONES,
}

export const openDestinationZonesListTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
]

export const destinationZonesListTourPreviewSteps = [
  getDefaultFirstStepSettings('destinationZones'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_DESTINATION_ZONES),
    content: renderTitle(i18next.t('labels.destinationZones'), i18next.t('labels.welcomeDestinationZonesList')),
  },
]

export const destinationZonesListTourAddMailSteps = [
  {
    ...defaultStepsSettings,
    target: '#addNewDestinationZone_button',
    content: renderContent(i18next.t('labels.clickAddToCreateDestinationZone')),
    title: renderTitle(`${i18next.t('actions.addNewDestinationZone')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.name')),
    content: renderContent(i18next.t('labels.inputNameForNewZone')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(2)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.destinationOfTruck')),
    content: renderContent(i18next.t('labels.chooseOneOrMoreLocations')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#create_row_button',
    title: renderTitle(`${i18next.t('actions.create')} ${buttonTitle}`),
    content: renderContent(i18next.t('labels.clickAddToCreateDestinationZone')),
  },
]

export const destinationZonesListFullTourSteps = [
  ...destinationZonesListTourPreviewSteps,
  ...destinationZonesListTourAddMailSteps,
]

export const handleDestinationZonesListTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenAsideMenu(false))
      dispatch(setStartedTourStep(null))
      dispatch(setDisableButtons(false))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.DESTINATION_ZONES,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    defaultStep0(index, setState)

    if (R.equals(startedTourStep, TOUR_STEPS.STEP1)) {
      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
        }, 400)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAsideMenu(true))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setOpenAddMode(true))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 5)) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 5) && isPrevAction) {
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [3, 4, 5]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 5 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 5 }))
        }, 400)
      }
    }

    defaultStep(setState, nextStepIndex, 400)
  }

  consoleShowTourLogs(data, type, status)
}

export const renderDestinationZonesListTour = ({
  pathname,
  dispatch,
  setState,
  setTourRoute,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_DESTINATION_ZONES)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      dispatch(setTourRoute(H.jsonStringify(tourRoute)))
      setState(getStateObj(destinationZonesListFullTourSteps))
    }
  }
}

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailingAPI = createApi({
  tagTypes: ['Mailing'],
  reducerPath: 'mailingAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    createMailing: build.mutation({
      extraOptions: { useLoader: true },
      query: body => (
        {
          body,
          method: E.METHODS.POST,
          url: E.ENDPOINTS.mailing,
        }
      ),
      invalidatesTags: (result, error, { id }) => [{ type: 'Mailing', id }],
    }),
  }),
})

export const {
  useCreateMailingMutation,
} = mailingAPI

import * as R from 'ramda'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
// components
import { openLoader, closeLoader } from '../components/loader/loaderSlice'
// features
import { setLoggedIn, setAuthInfo } from '../features/auth/authSlice'
// utilities
import { BASE_URL, METHODS, ENDPOINTS, CONTENT_TYPES } from '../utilities/endpoints'
// helpers/constants
import * as C from '../constants'
import { isFalse, isNilOrEmpty, isNotNilAndNotEmpty } from './common'
import {
  getFormData,
  logAPIErrorRes,
  getRequestAPIError,
  setAuthTokenToSession,
  getAuthTokenFromSession,
  setRefreshTokenToSession,
  getRefreshTokenFromSession,
} from './ajax-storage'
//////////////////////////////////////////////////

export const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL })

const baseQueryWithSetAuthTokenToHeaders = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {

    if (isFalse(headers.has('Media-Type'))) {
      headers.set('Content-Type', CONTENT_TYPES.APPLICATION_JSON)
    }

    const token = R.pathOr(getAuthTokenFromSession(), ['auth', 'access_token'], getState())

    if (isNotNilAndNotEmpty(token)) headers.set('authorization', `Bearer ${token}`)

    return headers
  },
})

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const { dispatch } = api

  const logError = R.pathEq(['logError'], true, extraOptions)
  const useLoader = R.pathEq(['useLoader'], true, extraOptions)

  if (useLoader) dispatch(openLoader())

  let result = await baseQueryWithSetAuthTokenToHeaders(args, api, extraOptions)

  if (R.pathEq(['error', 'status'], C.ERROR_STATUS_TYPES.UNAUTHORIZED, result)) {
    const refresh_token = getRefreshTokenFromSession()

    if (isNilOrEmpty(refresh_token)) {
      dispatch(setLoggedIn(false))

      getRequestAPIError({ status: C.ERROR_STATUS_TYPES.UNAUTHORIZED })

      if (useLoader) dispatch(closeLoader())

      return
    }

    const body = getFormData({ refresh_token, grant_type: 'refresh_token' })

    const refreshResult = await baseQuery(
      {
        body,
        method: METHODS.POST,
        url: ENDPOINTS.login,
        headers: {
          'Authorization': 'Basic bWFpbGluZzpRdjUjeVQvZVYvOHkmYlFh',
        },
      },
      api,
      extraOptions,
    )

    if (isNotNilAndNotEmpty(refreshResult.data)) {
      const access_token = R.path(['data', 'access_token'], refreshResult)
      const refresh_token = R.path(['data', 'refresh_token'], refreshResult)

      setAuthTokenToSession(access_token)
      setRefreshTokenToSession(refresh_token)
      dispatch(setAuthInfo({
        access_token,
        refresh_token,
        loggedIn: true,
      }))

      result = await baseQueryWithSetAuthTokenToHeaders(args, api, extraOptions)
    } else {
      if (useLoader) dispatch(closeLoader())

      setAuthTokenToSession(null)
      setRefreshTokenToSession(null)

      dispatch(setAuthInfo({
        loggedIn: false,
        isExpiredRefreshToken: true,
      }))

      return result
    }
  }

  if (isNotNilAndNotEmpty(result.error)) {
    if (logError) {
      logAPIErrorRes(result)
    } else {
      getRequestAPIError({
        data: result?.error?.data,
        status: result?.error?.status,
      })
    }
  }

  if (useLoader) dispatch(closeLoader())

  return result
}

export const providesTagList = (resultsWithIds, tagType) => resultsWithIds
  ? [
    { type: tagType, id: 'LIST' },
    ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
  ]
  : [{ type: tagType, id: 'LIST' }]

export const invalidatesTagsWithError = (result, error, tagType) => {
  if (R.and(R.isNil(result), isNotNilAndNotEmpty(error))) return []

  return [{ type: tagType, id: 'LIST' }]
}

import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
// material
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import ModalTitle from '@mui/material/DialogTitle'
// icons-material
import CloseIcon from '@mui/icons-material/Close'
// helpers
import * as H from '../helpers/common'
//////////////////////////////////////////////////

const styles = {
  p: 3,
  top: '50%',
  left: '50%',
  boxShadow: 24,
  outline: 'none',
  borderRadius: 4,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  bgcolor: t => t.colors.modalBGColor,
  width: { lg: 540, md: 540, sm: 500, xs: 360 },
}

const ModalTitleComponent = ({ t, onClose, children, isLoading, closeBtnTop, closeBtnPadding }) => (
  <ModalTitle
    fontSize={16}
    color='text.primary'
    sx={{
      p: 0,
      pb: 1,
      display: 'flex',
      justifyContent: 'space-between',
      '&.MuiTypography-root': {
        maxWidth: '80%',
        breakWord: 'world-break',
      },
    }}
  >
    {children}
    {
      onClose && (
        <IconButton
          onClick={onClose}
          aria-label='close'
          disabled={isLoading}
          title={t('actions.close')}
          sx={{
            right: 8,
            position: 'absolute',
            top: closeBtnTop || 16,
            padding: closeBtnPadding || '8px',
            color: theme => theme.palette.text.secondary,
          }}
        >
          <CloseIcon />
        </IconButton>
      )
    }
  </ModalTitle>
)

export const ModalComponent = ({
  opened,
  setOpen,
  component,
  isLoading,
  modalTitle,
  handleClose,
  closeBtnTop,
  closeBtnPadding,
  wrapperStyles = {},
  additionalStyles = {},
  componentBoxSxStyles = {},
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  let valueToPaste = R.compose(
    R.join('_'),
    R.map(R.toUpper),
    R.split('/'),
    R.trim,
  )(R.tail(pathname))

  if (H.isString(modalTitle)) {
    valueToPaste = R.compose(
      R.join('_'),
      R.map(R.toUpper),
      R.split(' '),
      R.trim,
    )(modalTitle)
  }

  return (
    <Modal
      open={opened}
      sx={wrapperStyles}
      onClose={handleClose}
      aria-labelledby='parent-modal-title'
      aria-describedby='parent-modal-description'
    >
      <Box
        id={`${valueToPaste}_modal`}
        sx={R.mergeDeepRight(styles, additionalStyles)}
      >
        <ModalTitleComponent
          t={t}
          isLoading={isLoading}
          closeBtnTop={closeBtnTop}
          id='customized-dialog-title'
          onClose={() => setOpen(false)}
          closeBtnPadding={closeBtnPadding}
        >
          {modalTitle}
        </ModalTitleComponent>
        <Box sx={componentBoxSxStyles}>{component}</Box>
      </Box>
    </Modal>
  )
}

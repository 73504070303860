import * as R from 'ramda'
import { createSlice, createSelector } from '@reduxjs/toolkit'
//////////////////////////////////////////////////

const initialState = { opened: false }

export const loaderSlice = createSlice({
  initialState,
  name: 'loader',
  reducers: {
    openLoader: state => R.assoc('opened', true, state),
    closeLoader: state => R.assoc('opened', false, state),
  },
})

export const { openLoader, closeLoader } = loaderSlice.actions

const store = ({ loader }) => loader

export const makeSelectLoaderOpened = createSelector(
  store,
  ({ opened }) => opened,
)

export default loaderSlice.reducer

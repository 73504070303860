import * as R from 'ramda'
import states from 'states-us'
import provinces from 'provinces-ca'
// constants
import * as C from '../constants'
//////////////////////////////////////////////////

export const getStatesProvincesMap = () => {
  const usaOptions = R.map(
    ({ name: label, abbreviation: value }) => ({ label, value, id: value, state: value, country: C.UNITED_STATES }),
    R.or(states, []),
  )

  const caOptions = R.map(
    ({ name: label, abbreviation: value }) => ({ label, value, id: value, state: value, country: C.CANADA }),
    R.or(provinces, []),
  )

  return R.compose(
    R.indexBy(R.prop('id')),
    R.concat(usaOptions),
  )(caOptions)
}

import * as R from 'ramda'
import { memo, useEffect } from 'react'
// material
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
// icons-material
import SearchIcon from '@mui/icons-material/Search'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// components
import { CustomSelectComponent, CustomDatePickerComponent } from './datasheet-component/custom-row-components'
//////////////////////////////////////////////////

const getInputWidth = isSelectable =>
  H.ifElse(isSelectable, '200px', { lg: '35vw', md: '45vw', sm: '55vw', xs: '85vw' })

const SearchInputSelectItem = props => {
  const { t, title, filter, options, setFilter, inputWidth } = props

  return (
    <Box width={inputWidth} mb={{ xs: 1, md: 0 }} title={t(`${title}`)}>
      <CustomSelectComponent
        rowData={filter}
        options={options}
        noTranslation={true}
        setRowData={item => setFilter(item)}
      />
    </Box>
  )
}

const SearchInput = props => {
  const {
    t,
    filter,
    filterBy,
    setFilter,
    inputWidth,
  } = props

  if (R.equals(filterBy, C.FIELD_PICK_UP_DATE_TIME)) {
    return (
      <Box width={inputWidth} mb={{ xs: 1, md: 0 }} title={t('actions.selectDate')}>
        <CustomDatePickerComponent
          inputStyles={{ width: inputWidth }}
          setRowData={date => setFilter(H.getDateWithFormat(date, C.DEFAULT_DATE_FORMAT))}
          rowData={H.ifElse(
            H.isString(filter),
            H.getDateWithFormat(
              filter,
              C.DEFAULT_DATE_FORMAT,
            ),
            H.getDateWithFormat(H.getCurrentDate(), C.DEFAULT_DATE_FORMAT),
          )}
        />
      </Box>
    )
  }

  if (R.equals(filterBy, C.FIELD_TRUCK_TYPE)) {
    return (
      <SearchInputSelectItem
        t={t}
        filter={filter}
        setFilter={setFilter}
        inputWidth={inputWidth}
        title='actions.selectTruckType'
        options={C.SELECT_TRUCK_TYPE_OPTIONS}
      />
    )
  }

  if (R.equals(filterBy, C.FIELD_EQUIPMENT_TYPE)) {
    return (
      <SearchInputSelectItem
        t={t}
        filter={filter}
        setFilter={setFilter}
        inputWidth={inputWidth}
        title='actions.selectTrailerType'
        options={C.SELECT_EQUIPMENT_TYPE_OPTIONS}
      />
    )
  }

  if (R.equals(filterBy, C.FIELD_DRIVER_TYPE)) {
    return (
      <SearchInputSelectItem
        t={t}
        filter={filter}
        setFilter={setFilter}
        inputWidth={inputWidth}
        title='actions.selectDriverType'
        options={C.SELECT_DRIVER_TYPE_OPTIONS}
      />
    )
  }

  return (
    <TextField
      type='search'
      variant='outlined'
      id='SearchableRowHeaderTitleComponent'
      placeholder={t('placeholders.search')}
      value={H.transformEnumToString(filter)}
      onChange={event => setFilter(H.getEventTargetValue(event))}
      sx={{
        '.MuiOutlinedInput-root': {
          fontSize: 12,
          maxWidth: 355,
          height: '30px',
          width: inputWidth,
        },
        'input[type="search"]::-webkit-search-cancel-button': {
          width: 0,
          height: 0,
          WebkitAppearance: 'none',
          display: 'none !important',
        },
      }}
      InputProps={{
        inputProps: { min: 1 },
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton sx={{ p: 0, left: 10 }}>
              <SearchIcon titleAccess={t('placeholders.search')} sx={{ fontSize: 24, textAlign: 'right' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export const GlobalSearchComponent = memo(props => {
  const {
    t,
    filter,
    options,
    filterBy,
    setFilter,
    setFilterBy,
    isSelectable,
  } = props

  const inputWidth = getInputWidth(isSelectable)

  useEffect(() => {
    if (H.notEquals(filterBy, C.FIELD_PICK_UP_DATE_TIME)) {
      setFilter(filter)
    }
  }, [filterBy]) // eslint-disable-line

  useEffect(() => {
    if (R.and(R.not(H.isValidDate(filter, C.DEFAULT_DATE_FORMAT)), R.equals(filterBy, C.FIELD_PICK_UP_DATE_TIME))) {
      setFilter(H.getDateWithFormat(H.getCurrentDate(), C.DEFAULT_DATE_FORMAT))
    }
  }, [filter, filterBy]) // eslint-disable-line

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      {
        isSelectable &&
        <>
          <Typography
            fontSize={12}
            mr={{ md: 1 }}
            fontWeight={400}
            variant='subtitle2'
            color='text.primary'
            mb={{ xs: 1, md: 0 }}
          >
            {t('labels.searchBy')}:
          </Typography>
          <Box width={175} mb={{ xs: 1, md: 0 }} title={t('actions.selectField')}>
            <CustomSelectComponent options={options} rowData={filterBy} setRowData={setFilterBy} />
          </Box>
        </>
      }
      <SearchInput {...props} inputWidth={inputWidth} />
    </Box>
  )
})

GlobalSearchComponent.displayName = 'GlobalSearchComponent'

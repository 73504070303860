import * as R from 'ramda'
import i18next from 'i18next'
// helpers/constants
import * as H from '../../../helpers'
import * as C from '../../../constants'
// features tour
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import {
  defaultStep,
  renderTitle,
  getStateObj,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

const defaultReservationRequestsRows = [
  {
    [C.FIELD_ID]: 1,
    [C.FIELD_STATUS]: C.ENUM_NEW,
    [C.FIELD_PHONE]: '+19999999999',
    [C.FIELD_CONTACT_NAME]: 'John Doe',
    [C.FIELD_COMPANY_NAME]: 'Company A',
    [C.FIELD_EMAIL]: 'companya@example.test',
    [C.FIELD_NOTE]: 'Your reservation request note.',
    [C.FIELD_CREATED_DATE]: H.getDateWithFormat(
      H.getCurrentDate().add(-1, 'day'), C.DATE_TIME_FORMAT_FOR_NEXT_DATE_VALIDATION,
    ),
  },
]

export const openReservationRequestsTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
]

export const reservationRequestsTourPreviewSteps = [
  getDefaultFirstStepSettings('reservationRequests'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_RESERVATION_REQUESTS),
    content: renderTitle(i18next.t('labels.reservationRequests'), i18next.t('labels.welcomeToReservationRequests')),
  },
]

export const reservationRequestsListTourSteps = [
  {
    ...defaultStepsSettings,
    target: '#reservationRequestsRows',
    title: renderTitle(i18next.t('labels.reservationRequestsList')),
    content: renderContent(i18next.t('labels.reservationRequestsStep1')),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.status')),
    target: '#datatable > div > table > tbody > tr:nth-child(1) > th:nth-child(6)',
    content: renderContent(i18next.t('labels.changeReservationRequestStatus')),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#previewReservation_btn',
    title: renderTitle(i18next.t('actions.previewReservation')),
    content: renderContent(i18next.t('labels.previewReservation')),
  },
  {
    ...defaultStepsSettings,
    target: '#contactsInfo',
    title: renderTitle(i18next.t('labels.clientInformation')),
    content: renderContent(i18next.t('labels.clientInformationIsFound')),
  },
  {
    ...defaultStepsSettings,
    target: '#contactsNote',
    title: renderTitle(i18next.t('labels.note')),
    content: renderContent(i18next.t('labels.onTheRightSide')),
  },
  {
    ...defaultStepsSettings,
    target: '#reservedTrucksDetails',
    title: renderTitle(i18next.t('labels.availableTrucksDetails')),
    content: renderContent(i18next.t('labels.atTheBottomOfThePage')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#Save_button',
    content: renderContent(i18next.t('labels.allThatIsLeft')),
    title: renderTitle(`${i18next.t('actions.save')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const reservationRequestsFullTourSteps = [
  ...reservationRequestsTourPreviewSteps,
  ...reservationRequestsListTourSteps,
]

export const handleReservationRequestsTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenModal,
  setTourModalId,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setTourHiddenObjects,
}) => {
  const { type, index, action, status } = data

  if (R.equals(status, 'finished')) dispatch(setOpenModal(false))

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setOpenAsideMenu(false))
      dispatch(setDisableButtons(false))
      dispatch(setStartedTourStep(null))
      dispatch(setTourHiddenObjects([]))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.RESERVATION_REQUESTS,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    if (R.equals(index, 0)) {
      dispatch(setTourDefaultDataStep(defaultReservationRequestsRows))

      setTimeout(() => { // eslint-disable-line
        setState({ run: true })
      }, 400)
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
        }, 400)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAsideMenu(true))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 4) && R.not(isPrevAction)) {
        dispatch(setTourModalId('openReservationPreviewModal'))
        dispatch(setOpenModal(true))
        dispatch(setOpenAsideMenu(false))
        dispatch(setTourHiddenObjects([]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [5, 6, 7]) && R.not(isPrevAction) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 5 }))
        }, 50)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 5 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 5 }))
        }, 400)
      }

      if (R.equals(index, 5) && isPrevAction) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setTourHiddenObjects(['hideModal']))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 4 }))
        }, 400)
      }

      if (R.equals(index, 8) && R.not(isPrevAction)) {
        dispatch(setTourHiddenObjects(['hideModal']))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 8) && isPrevAction) {
        dispatch(setTourHiddenObjects([]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 9) && isPrevAction) {
        dispatch(setTourHiddenObjects([]))
        dispatch(setTourModalId('openReservationPreviewModal'))
        dispatch(setOpenModal(true))
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 8 }))
        }, 400)
      }
    }

    defaultStep(setState, nextStepIndex)
  }

  consoleShowTourLogs(data, type, status)
}

export const renderReservationRequestsTour = ({
  pathname,
  dispatch,
  setState,
  setOpenModal,
  setTourModalId,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
  setTourHiddenObjects,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_RESERVATION_REQUESTS)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenModal(true))
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      dispatch(setTourHiddenObjects(['hideModal']))
      dispatch(setTourModalId('openReservationPreviewModal'))
      setState(getStateObj(reservationRequestsFullTourSteps))
      dispatch(setTourDefaultDataStep(defaultReservationRequestsRows))
    }
  }
}

import * as R from 'ramda'
import i18next from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// material
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
// icons-material
import LoginIcon from '@mui/icons-material/Login'
import CreateIcon from '@mui/icons-material/Create'
import LocationIcon from '@mui/icons-material/RoomOutlined'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import MarkEmailIcon from '@mui/icons-material/MarkEmailReadOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
// features
import { makeSelectAddCompanyTemplatesOpened } from '../features/email-templates/emailTemplatesSlice'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// api
import { useGetAdminTokenMutation } from '../api/adminAPI'
import { useDeleteCompanyTemplateMutation } from '../api/companyTemplateAPI'
import { useDeleteTruckMutation, useUpdateTruckMutation } from '../api/truckAPI'
import { useDeleteMailGroupMutation, useUpdateMailGroupMutation } from '../api/mailGroupAPI'
import { useDeleteMailingJobMutation, useUpdateMailingJobMutation } from '../api/mailingJobAPI'
import {
  useDeleteMailMutation,
  useUpdateMailMutation,
  useSubscribeEmailMutation,
  useUnsubscribeEmailMutation,
} from '../api/mailAPI'
import { useDeleteDestinationZoneMutation, useUpdateDestinationZoneMutation } from '../api/destinationZoneAPI'
// components
import AlertComponent from './alert'
import { BaseButton } from './buttons'
import { ModalComponent } from './modal'
// features auth
import { setAuthInfo } from '../features/auth/authSlice'
//////////////////////////////////////////////////

export const getGoogleResultFieldsForUpdate = data => {
  if (H.isNilOrEmpty(data)) return

  const { city, state, country } = data

  if (R.equals(city, ' ')) return

  const dataReady = H.isAllTrue(...R.map(H.isNotNilAndNotEmpty, [city, state, country]))

  const id = H.getIdFromObject(data)
  const apiId = H.getPropFromObject('apiId', data)

  if (R.or(H.isNotNilAndNotEmpty(id), dataReady)) return data

  if (H.isNotNilAndNotEmpty(apiId)) {
    return R.assoc(C.FIELD_ID, apiId, H.getGoogleResultFields(data))
  }

  return H.getGoogleResultFields(data)
}

export const getDestinationStatesFieldsForUpdate = data => {
  const { value, country, createdDate } = data

  if (H.isNotNilAndNotEmpty(createdDate)) return data

  return { country, state: value }
}

export const transformTruckUpdateRowData = rowData => R.compose(
  R.dissoc(C.FIELD_DESTINATION_ZONES),
  updatedRowData => R.mergeRight(
    updatedRowData,
    {
      [C.FIELD_PICK_UP_DATE_TIME]: H.setTimeToMidnightUTC(R.pathOr(null, [C.FIELD_PICK_UP_DATE_TIME], updatedRowData)),
      [C.FIELD_ORIGIN_LOCATION]: getGoogleResultFieldsForUpdate(
        H.getPropFromObject(C.FIELD_ORIGIN_LOCATION, updatedRowData),
      ),
      [C.FIELD_DESTINATION_LOCATIONS]: R.map(
        item => getDestinationStatesFieldsForUpdate(item),
        H.getPropFromObject(C.FIELD_DESTINATION_LOCATIONS, updatedRowData),
      ),
      [C.FIELD_DESTINATION_ZONE_IDS]: H.ifElse(
        H.isArrayNotEmptyAndContainsObjects(H.getPropFromObject(C.FIELD_DESTINATION_ZONES, updatedRowData)),
        R.path([C.FIELD_DESTINATION_ZONE_IDS], updatedRowData),
        R.path([C.FIELD_DESTINATION_ZONES], updatedRowData),
      ),
    },
  ),
)(rowData)

export const transformMailUpdateRowData = rowData => {
  const groupIds = R.map(
    item => H.ifElse(
      H.isNumber(item),
      item,
      R.path([C.FIELD_ID], item),
    ),
    R.pathOr([], [C.FIELD_GROUPS], rowData),
  )

  return R.assoc(C.FIELD_GROUP_IDS, groupIds, rowData)
}

export const transformEmailCampaignUpdateRowData = rowData => R.mergeRight(
  rowData,
  {
    [C.FIELD_EMAIL_GROUP_IDS]: R.map(
      item => R.or(H.getPropFromObject(C.FIELD_ID, item), item),
      H.getPropFromObject(C.FIELD_EMAIL_GROUPS, rowData),
    ),
    [C.FIELD_END_DATE]: H.getDateWithFormatOrNull(
      H.getPropFromObject(C.FIELD_END_DATE, rowData),
      C.TRANSFORMED_DATE_FORMAT,
    ),
    [C.FIELD_START_DATE]: H.getDateWithFormatOrNull(
      H.getPropFromObject(C.FIELD_START_DATE, rowData),
      C.TRANSFORMED_DATE_FORMAT,
    ),
    [C.FIELD_ORIGIN_LOCATIONS]: R.map(
      item => getGoogleResultFieldsForUpdate(item),
      H.getPropFromObject(C.FIELD_ORIGIN_LOCATIONS, rowData),
    ),
    [C.FIELD_COMPANY_TEMPLATE_ID]:
      R.or(R.pathOr(null, [C.FIELD_COMPANY_TEMPLATE, C.FIELD_ID], rowData),
        R.pathOr(null, [C.FIELD_COMPANY_TEMPLATE], rowData),
      ),
    [C.FIELD_MAILING_CONFIG_ID]: R.or(R.pathOr(null, [C.FIELD_MAILING_CONFIG, C.FIELD_ID], rowData),
      R.pathOr(null, [C.FIELD_MAILING_CONFIG], rowData),
    ),
  },
)

export const transformDestinationZoneUpdateRowData = rowData => ({
  [C.FIELD_ID]: H.getPropFromObject(C.FIELD_ID, rowData),
  [C.FIELD_NAME]: H.getPropFromObject(C.FIELD_NAME, rowData),
  [C.FIELD_LOCATIONS]: R.map(
    item => R.or(getDestinationStatesFieldsForUpdate(item), item),
    H.getPropFromObject(C.FIELD_LOCATIONS, rowData),
  ),
})

// TODO: uncomment and change when need handle row update
const UpdateComponent = () => null
// const UpdateComponent = ({ loading, handleUpdate }) => loading
//   ? <CircularProgress size={16} color='inherit' />
//   : <UpgradeIcon color='primary' cursor='pointer' onClick={handleUpdate} />

const EditComponent = ({ rowData, areButtonsDisabled = false, handleEditCompanyTemplate }) => (
  <CreateIcon
    cursor='pointer'
    color='secondary'
    titleAccess={i18next.t('actions.edit')}
    sx={{
      cursor: H.isTrue(areButtonsDisabled) ? 'not-allowed' : 'pointer',
    }}
    onClick={() => {
      if (H.isTrue(areButtonsDisabled)) return

      handleEditCompanyTemplate(rowData)
    }}
  />
)

export const DeleteModalAlert = ({ isLoading, submitText, cancelAction, infoSettings, submitAction }) => (
  <Box mt={3} width='100%' display='flex' flexDirection='column' justifyContent='space-between'>
    <AlertComponent
      isModal={true}
      iconType='warning'
      textColor='primary'
      isDeleteModal={true}
      title='labels.attention'
      infoSettings={infoSettings}
    />
    <Box mt={3} width='100%' display='flex' justifyContent='space-between'>
      <BaseButton
        title='cancel'
        handleClick={cancelAction}
        startIcon={<CancelOutlinedIcon />}
        sx={{ mt: 0, ml: 0, mr: 2, minWidth: { xs: '80px', sm: '100px' } }}
      />
      <BaseButton
        type='submit'
        title={submitText}
        loading={isLoading}
        handleClick={submitAction}
        startIcon={<CheckCircleOutlineIcon />}
        sx={{ mt: 0, ml: 0, minWidth: { xs: '80px', sm: '100px' } }}
      />
    </Box>
  </Box>
)

const DeleteComponent = ({ loading, disabled, handleDelete }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [opened, setOpened] = useState(false)

  const handleDeleteActions = () => {
    handleDelete()
    setOpened(false)
  }

  return (
    <>
      {
        loading
          ? <CircularProgress size={16} color='inherit' />
          : (
            <>
              <DeleteIcon
                color='secondary'
                titleAccess={i18next.t('actions.delete')}
                sx={{
                  cursor: disabled ? 'not-allowed' : 'pointer',
                }}
                onClick={() => {
                  if (disabled) return

                  setOpened(true)
                }}
              />
              <ModalComponent
                opened={opened}
                setOpen={setOpened}
                isLoading={loading}
                onClick={() => setOpened(false)}
                modalTitle={i18next.t('actions.confirmInformationDelete')}
                additionalStyles={{
                  minWidth: 300,
                  maxHeight: '100vh',
                }}
                component={<DeleteModalAlert
                  isLoading={loading}
                  submitText='confirm'
                  submitAction={handleDeleteActions}
                  cancelAction={() => setOpened(false)}
                  infoSettings={[{ text: 'confirmDelete' }]}
                />}
              />
            </>
          )
      }
    </>
  )
}

const SwitchCompanyComponent = ({ loading, handleSwitchCompany }) => loading
  ? <CircularProgress size={16} color='inherit' />
  : <LoginIcon color='secondary' cursor='pointer' onClick={handleSwitchCompany} />

const SubscribeEmailModalAlert = ({ isLoading, submitText, cancelAction, submitAction, isSubscribed }) => (
  <Box mt={3} width='100%' display='flex' flexDirection='column' justifyContent='space-between'>
    <AlertComponent
      isModal={true}
      textColor='primary'
      title='labels.attention'
      infoSettings={[{ text: isSubscribed ? 'textConfirmUnSubscribe' : 'textConfirmSubscribe' }]}
    />
    <Box mt={3} width='100%' display='flex' justifyContent='space-between'>
      <BaseButton
        title='cancel'
        handleClick={cancelAction}
        startIcon={<CancelOutlinedIcon />}
        sx={{ mt: 0, ml: 0, mr: 2, minWidth: { xs: '80px', sm: '100px' } }}
      />
      <BaseButton
        type='submit'
        title={submitText}
        loading={isLoading}
        handleClick={submitAction}
        sx={{ mt: 0, ml: 0, minWidth: { xs: '80px', sm: '100px' } }}
      />
    </Box>
  </Box>
)

const SubscribeEmailComponent = ({ loading, isSubscribed, handleSubscribeEmail }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState(false)

  return (
    <>
      {
        loading
          ? <CircularProgress size={16} color='inherit' />
          : isSubscribed
            ? (
              <UnsubscribeIcon
                cursor='pointer'
                color='secondary'
                onClick={() => setOpen(true)}
                titleAccess={i18next.t('actions.unsubscribe')}
              />
            )
            : (
              <MarkEmailIcon
                cursor='pointer'
                color='secondary'
                onClick={() => setOpen(true)}
                titleAccess={i18next.t('actions.subscribe')}
              />
            )
      }
      <ModalComponent
        opened={open}
        setOpen={setOpen}
        isLoading={loading}
        onClick={() => setOpen(false)}
        modalTitle={isSubscribed ? i18next.t('labels.confirmUnSubscribe') : i18next.t('labels.confirmSubscribe')}
        additionalStyles={{
          minWidth: 300,
          maxHeight: '100vh',
        }}
        component={<SubscribeEmailModalAlert
          isLoading={loading}
          submitText='confirm'
          isSubscribed={isSubscribed}
          submitAction={handleSubscribeEmail}
          cancelAction={() => setOpen(false)}
        />}
      />
    </>
  )
}

const TruckRowActions = ({ rowData = {}, handleOpenMap }) => {
  const [deleteTruck, { isLoading: isDeleteTruckLoading }] = useDeleteTruckMutation()
  const [updateTruck, { isLoading: isUpdateTruckLoading }] = useUpdateTruckMutation()

  const handleDelete = () => deleteTruck(R.prop('id', rowData))

  const handleUpdate = () => updateTruck(transformTruckUpdateRowData(rowData))

  return (
    <Box display='flex' alignItems='center' justifyContent='space-around'>
      <UpdateComponent handleUpdate={handleUpdate} loading={isUpdateTruckLoading} />
      <LocationIcon
        cursor='pointer'
        color='secondary'
        onClick={() => handleOpenMap(rowData)}
        titleAccess={i18next.t('actions.goMap')}
      />
      <DeleteComponent handleDelete={handleDelete} loading={isDeleteTruckLoading} />
    </Box>
  )
}

const EmailCampaignRowActions = ({ rowData = {} }) => {
  const [deleteMailingJob, { isLoading: isDeleteMailingJobLoading }] = useDeleteMailingJobMutation()
  const [updateMailingJob, { isLoading: isUpdateMailingJobLoading }] = useUpdateMailingJobMutation()

  const handleDelete = () => deleteMailingJob(R.prop('id', rowData))
  const handleUpdate = () => updateMailingJob(transformEmailCampaignUpdateRowData(rowData))

  return (
    <Box display='flex' alignItems='center' justifyContent='space-around'>
      <UpdateComponent handleUpdate={handleUpdate} loading={isUpdateMailingJobLoading} />
      <DeleteComponent handleDelete={handleDelete} loading={isDeleteMailingJobLoading} />
    </Box>
  )
}

const MailRowActions = ({ rowData = {} }) => {
  const [deleteMail, { isLoading: isDeleteMailLoading }] = useDeleteMailMutation()
  const [updateMail, { isLoading: isUpdateMailLoading }] = useUpdateMailMutation()
  const [subscribeEmail, { isLoading: subscribeEmailIsLoading }] = useSubscribeEmailMutation()
  const [unsubscribeEmail, { isLoading: unsubscribeEmailIsLoading }] = useUnsubscribeEmailMutation()

  const email = H.getPropFromObject(C.FIELD_EMAIL, rowData)
  const companyId = H.getPropFromObject(C.FIELD_COMPANY_ID, rowData)
  const unsubscribed = H.getPropFromObject(C.FIELD_UNSUBSCRIBED, rowData)
  const isSubscribed = H.isFalse(unsubscribed)

  const handleDelete = () => deleteMail(R.prop('id', rowData))
  const handleUpdate = () => updateMail(transformMailUpdateRowData(rowData))

  const isAnyLoading = R.or(subscribeEmailIsLoading, unsubscribeEmailIsLoading)

  const handleSubscribe = async () => {
    try {
      const body = {
        [C.FIELD_EMAIL]: email,
        [C.FIELD_COMPANY_ID]: companyId,
      }

      await subscribeEmail(H.mapEmptyStringToNullObjectFields(body))
        .unwrap()
        .then(() => H.showToastrMessageSimple('success', i18next.t('labels.subscriptionWasSuccessful')))
        .catch(res => H.logAPIErrorRes(res))
    } catch (e) {
      H.getCatchError(e)
    }
  }

  const handleUnsubscribeSubscribe = async () => {
    try {
      const body = {
        [C.FIELD_EMAIL]: email,
        [C.FIELD_COMPANY_ID]: companyId,
      }

      await unsubscribeEmail(H.mapEmptyStringToNullObjectFields(body))
        .unwrap()
        .then(() => H.showToastrMessageSimple('success', i18next.t('labels.unsubscriptionWasSuccessful')))
        .catch(res => H.logAPIErrorRes(res))
    } catch (e) {
      H.getCatchError(e)
    }
  }

  const handleSubscribeEmail = H.ifElse(
    isSubscribed,
    handleUnsubscribeSubscribe,
    handleSubscribe,
  )

  return (
    <Box display='flex' alignItems='center'>
      <SubscribeEmailComponent
        loading={isAnyLoading}
        isSubscribed={isSubscribed}
        handleSubscribeEmail={handleSubscribeEmail}
      />
      <UpdateComponent handleUpdate={handleUpdate} loading={isUpdateMailLoading} />
      <DeleteComponent handleDelete={handleDelete} loading={isDeleteMailLoading} />
    </Box>
  )
}

const DestinationZoneRowActions = ({ rowData = {} }) => {
  const [
    deleteDestinationZone,
    { isLoading: isDeleteDestinationZoneLoading },
  ] = useDeleteDestinationZoneMutation()
  const [
    updateDestinationZone,
    { isLoading: isUpdateDestinationZoneLoading },
  ] = useUpdateDestinationZoneMutation()

  const handleDelete = () => deleteDestinationZone(R.prop(C.FIELD_ID, rowData))
  const handleUpdate = () => updateDestinationZone(transformDestinationZoneUpdateRowData(rowData))

  return (
    <Box display='flex' alignItems='center'>
      <UpdateComponent handleUpdate={handleUpdate} loading={isUpdateDestinationZoneLoading} />
      <DeleteComponent handleDelete={handleDelete} loading={isDeleteDestinationZoneLoading} />
    </Box>
  )
}

const MailGroupRowActions = ({ rowData = {} }) => {
  const [deleteMailGroup, { isLoading: isDeleteMailGroupLoading }] = useDeleteMailGroupMutation()
  const [updateMailGroup, { isLoading: isUpdateMailGroupLoading }] = useUpdateMailGroupMutation()

  const handleUpdate = () => updateMailGroup(rowData)
  const handleDelete = () => deleteMailGroup(R.prop(C.FIELD_ID, rowData))

  return (
    <Box display='flex' alignItems='center'>
      <UpdateComponent handleUpdate={handleUpdate} loading={isUpdateMailGroupLoading} />
      <DeleteComponent handleDelete={handleDelete} loading={isDeleteMailGroupLoading} />
    </Box>
  )
}

const SwitchCompanyRowActions = ({ rowData = {} }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const companyId = H.getPropFromObject(C.FIELD_ID, rowData)
  const campaignName = H.getPropFromObject(C.FIELD_NAME, rowData)

  const [getAdminToken, { isLoading, isFetching }] = useGetAdminTokenMutation()

  const handleSwitchCompany = async () => {
    try {
      await getAdminToken({ companyId })
        .unwrap()
        .then(({ access_token, refresh_token }) => {
          const role = H.getRoleFromToken(access_token)

          dispatch(setAuthInfo({
            role,
            access_token,
            refresh_token,
            isAdminToken: true,
          }))

          H.setCompanyAuthTokenToSession(access_token)
          H.setCompanyRefreshTokenToSession(refresh_token)

          navigate(C.ROUTE_PATH_TRUCKS)
        })
        .catch(({ data, status }) => H.getRequestAPIError({ data, status }))
    } catch (e) {
      H.getCatchError(e)
    }
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-around'
      title={`${t('actions.switchCompanyTo')} ${campaignName}`}
    >
      <SwitchCompanyComponent loading={R.or(isLoading, isFetching)} handleSwitchCompany={handleSwitchCompany} />
    </Box>
  )
}

const EmailTemplatesRowActions = ({ rowData = {}, areButtonsDisabled, handleEditCompanyTemplate }) => {
  const emailTemplatesOpened = useSelector(makeSelectAddCompanyTemplatesOpened)

  const { openedTemplateProfile } = emailTemplatesOpened

  const [deleteCompanyTemplate, { isLoading: isDeleteCompanyTemplateLoading }] = useDeleteCompanyTemplateMutation()

  const handleDelete = () => {
    deleteCompanyTemplate(R.prop('id', rowData))
  }

  return (
    <Box display='flex' alignItems='center'>
      <EditComponent
        rowData={rowData}
        areButtonsDisabled={areButtonsDisabled}
        handleEditCompanyTemplate={handleEditCompanyTemplate}
      />
      <DeleteComponent
        handleDelete={handleDelete}
        loading={isDeleteCompanyTemplateLoading}
        disabled={R.or(openedTemplateProfile, H.isTrue(areButtonsDisabled))}
      />
    </Box>
  )
}

export const getRowActionsByListType = props => {
  const { listType, handleOpenMap, areButtonsDisabled, handleEditCompanyTemplate } = props

  if (R.includes(
    listType,
    [
      C.LIST_TYPE_ADD_MAILS,
      C.LIST_TYPE_ADD_TRUCKS,
      C.LIST_TYPE_ADD_EMAIL_CAMPAIGNS,
    ],
  )) {
    return {
      component: ({ deleteRow }) =>
        <DeleteIcon
          color='secondary'
          id='delete_row_button'
          titleAccess={i18next.t('actions.delete')}
          sx={{
            cursor: H.isTrue(areButtonsDisabled) ? 'not-allowed' : 'pointer',
          }}
          onClick={() => {
            if (H.isTrue(areButtonsDisabled)) return

            deleteRow()
          }}
        />,
    }
  }

  if (R.includes(
    listType,
    [
      C.LIST_TYPE_ADD_MAIL_GROUP,
      C.LIST_TYPE_ADD_DESTINATION_ZONE,
    ],
  )) {
    return {
      component: ({ deleteRow }) => (
        <Box visibility='hidden'>
          <IconButton
            aria-label='delete'
            onClick={deleteRow}
            id='delete_row_button'
            disabled={H.isTrue(areButtonsDisabled)}
          >
            <DeleteIcon
              color='secondary'
              sx={{
                cursor: H.isTrue(areButtonsDisabled) ? 'not-allowed' : 'pointer',
              }}
            />
          </IconButton>,
        </Box>
      ),
    }
  }

  if (R.equals(listType, C.LIST_TRUCKS)) {
    return {
      minWidth: 100,
      component: ({ rowData }) => <TruckRowActions rowData={rowData} handleOpenMap={handleOpenMap} />,
    }
  }

  if (R.equals(listType, C.LIST_EMAILS)) {
    return {
      minWidth: 100,
      component: ({ rowData }) => <MailRowActions rowData={rowData} />,
    }
  }

  if (R.equals(listType, C.LIST_EMAIL_CAMPAIGNS)) {
    return {
      minWidth: 50,
      component: ({ rowData }) => <EmailCampaignRowActions rowData={rowData} />,
    }
  }

  if (R.equals(listType, C.LIST_COMPANIES)) {
    return {
      minWidth: 80,
      component: ({ rowData }) => <SwitchCompanyRowActions rowData={rowData} />,
    }
  }

  if (R.equals(listType, C.LIST_DESTINATION_ZONES)) {
    return {
      minWidth: 50,
      component: ({ rowData }) => <DestinationZoneRowActions rowData={rowData} />,
    }
  }

  if (R.equals(listType, C.LIST_EMAIL_GROUPS)) {
    return {
      minWidth: 50,
      component: ({ rowData }) => <MailGroupRowActions rowData={rowData} />,
    }
  }

  if (R.equals(listType, C.LIST_EMAIL_TEMPLATES)) {
    return {
      minWidth: 100,
      component: ({ rowData }) => (
        <EmailTemplatesRowActions
          rowData={rowData}
          areButtonsDisabled={areButtonsDisabled}
          handleEditCompanyTemplate={handleEditCompanyTemplate}
        />
      ),
    }
  }
}

// material
import { styled } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
//////////////////////////////////////////////////

const getErrorColor = theme => `${theme.palette.background.error} !important`

export const setDefaultErrorStyles = ({ error, theme }) => ({
  '.MuiInputLabel-root': { color: error && getErrorColor(theme) },
  '.MuiFormHelperText-root': { fontSize: 13, color: error && getErrorColor(theme) },
  '.MuiOutlinedInput-notchedOutline': {
    borderWidth: error && '1.5px',
    borderColor: error && getErrorColor(theme),
  },
})

export const StyledTextField = styled(TextField)(({ theme, error }) => ({
  ...setDefaultErrorStyles({ error, theme }),
}))

export const StyledFormControl = styled(FormControl)(({ theme, error }) => ({
  ...setDefaultErrorStyles({ error, theme }),
}))

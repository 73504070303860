import * as R from 'ramda'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

const exportKeyNameArray = [
  {
    key: 'labels.truckId',
    name: C.FIELD_TRUCK_ID,
  },
  // TODO: clarify if it needed
  // {
  //   name: C.FIELD_QUANTITY,
  //   key: 'labels.numberOfUnits',
  // },
  {
    key: 'labels.availableDate',
    name: C.FIELD_PICK_UP_DATE_TIME,
  },
  {
    key: 'labels.truckLocation',
    name: C.FIELD_ORIGIN_LOCATION,
  },
  {
    name: C.FIELD_CITY,
    key: 'labels.truckLocationCity',
  },
  {
    name: C.FIELD_STATE,
    key: 'labels.truckLocationState',
  },
  {
    name: C.FIELD_COUNTRY,
    key: 'labels.truckLocationCountry',
  },
  {
    key: 'labels.destinationOfTruck',
    name: C.FIELD_DESTINATION_LOCATIONS,
  },
  {
    key: 'labels.destinationZones',
    name: C.FIELD_DESTINATION_ZONES,
  },
  {
    key: 'labels.trailerType',
    name: C.FIELD_EQUIPMENT_TYPE,
  },
  {
    key: 'labels.truckType',
    name: C.FIELD_TRUCK_TYPE,
  },
  {
    key: 'labels.driverType',
    name: C.FIELD_DRIVER_TYPE,
  },
  {
    key: 'labels.companyName',
    name: C.FIELD_COMPANY_NAME,
  },
  {
    key: 'labels.mcNumber',
    name: C.FIELD_MC_NUMBER,
  },
  {
    key: 'labels.dotNumber',
    name: C.FIELD_DOT_NUMBER,
  },
  {
    key: 'labels.firstName',
    name: C.FIELD_FIRST_NAME,
  },
  {
    key: 'labels.lastName',
    name: C.FIELD_LAST_NAME,
  },
  {
    name: C.FIELD_EMAIL,
    key: 'labels.emailAddress',
  },
  {
    key: 'labels.groups',
    name: C.FIELD_GROUPS,
  },
  {
    key: 'labels.position',
    name: C.FIELD_POSITION,
  },
]

const setRowsExample = rowsExample => R.map(
  row => {
    if (R.has(C.FIELD_PICK_UP_DATE_TIME, row)) return R.assoc(
      C.FIELD_PICK_UP_DATE_TIME,
      H.getDateWithFormat(H.getCurrentDate(), C.DEFAULT_DATE_FORMAT),
      row,
    )

    return row
  },
  R.or(rowsExample, []),
)

export const renameExportColumns = (columns, t) => {
  const indexedKeyNames = R.indexBy(R.prop(C.FIELD_NAME), exportKeyNameArray)

  return R.map(column => {
    const { key } = column

    const localeKey = R.pathOr(null, [key, 'key'], indexedKeyNames)

    if (R.isNil(localeKey)) return column

    const newValue = t(localeKey)

    return R.mergeDeepLeft({
      key: newValue,
      header: newValue,
    }, column)
  }, columns)
}

export const renameExportRows = (rows, t) => {
  const keysMap = R.compose(
    R.map(({ key }) => t(key)),
    R.indexBy(R.prop(C.FIELD_NAME)),
  )(exportKeyNameArray)

  return R.map(
    row => R.reduce(
      (acc, key) => R.assoc(R.or(keysMap[key], key), row[key], acc),
      {},
      R.keys(row),
    ),
    rows,
  )
}

export const renameImportTitles = (row, t) => {
  const nameKeyMap = R.compose(
    R.indexBy(R.prop('localeValue')),
    R.map(item => R.assoc('localeValue', t(item.key), item)),
  )(exportKeyNameArray)

  return R.map(
    name => {
      const newName = R.path([name, C.FIELD_NAME], nameKeyMap)

      if (R.isNil(newName)) return name

      return newName
    },
    row,
  )
}

export const saveExcelImportExample = (t, listType) => {
  const sheetNm = 'export'
  const fileNm = `${t('actions.downloadTemplate')}_${listType}.xlsx`

  const rowColumnsByListMap = {
    [C.LIST_TRUCKS]: [C.truckListColumnsExample, setRowsExample(C.truckListRowsExample)],
    [C.LIST_EMAILS]: [C.emailListColumnsExample, C.emailListRowsExample],
  }

  const rows = renameExportRows(R.path([listType, 1], rowColumnsByListMap), t)
  const columns = renameExportColumns(R.path([listType, 0], rowColumnsByListMap), t)

  H.makeExcel(t, columns, rows, sheetNm, fileNm, t('errors.downloadFail'), t('errors.downloadSuccess'))
}

export const selectImportExcel = (e, t, setFileObj) => {
  const fileObj = e.target.files[0]

  const type = R.path(['type'], fileObj)
  const size = R.path(['size'], fileObj)

  if (R.not(fileObj)) return H.showToastrMessageSimple('error', t('errors.noFileUploaded'))

  if (R.not(R.divide(R.divide(size, 1024), R.lt(1024, 2)))) {
    return H.showToastrMessageSimple('error', t('errors.fileMustBeSmallerThan2MB'))
  }

  if (R.not(R.or(
    R.equals(type, E.CONTENT_TYPES.APPLICATION_EXCEL),
    R.equals(type, E.CONTENT_TYPES.APPLICATION_SPREADSHEETML),
  ))) return H.showToastrMessageSimple('error', t('errors.youCanOnlyUploadExcelFile'))

  setFileObj(fileObj)
}

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const adminAPI = createApi({
  reducerPath: 'adminAPI',
  tagTypes: ['CompanyList'],
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchCompanies: build.mutation({
      extraOptions: { useLoader: true },
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'CompanyList'),
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.adminCompanies,
      }),
    }),
    getAdminToken: build.mutation({
      query: companyId => ({
        method: E.METHODS.GET,
        url: E.ENDPOINTS.getAdminToken(companyId),
      }),
    }),
    fetchAdminUser: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.adminUser,
      }),
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'CompanyList'),
    }),
    trial: build.mutation({
      extraOptions: { useLoader: true },
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'CompanyList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.adminTrial,
      }),
    }),
    getAdminSettings: build.query({
      providesTags: ['CompanyList'],
      extraOptions: { useLoader: true },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.adminSettings,
      }),
    }),
    updateAdminSettings: build.mutation({
      invalidatesTags: ['CompanyList'],
      extraOptions: { useLoader: true },
      query: trialEmailCount => ({
        method: E.METHODS.PUT,
        body: { trialEmailCount },
        url: E.ENDPOINTS.adminSettings,
      }),
    }),
    updateCompanyNote: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'CompanyList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.companyNote,
      }),
    }),
  }),
})

export const {
  useTrialMutation,
  useFetchAdminUserQuery,
  useGetAdminTokenMutation,
  useGetAdminSettingsQuery,
  useFetchCompaniesMutation,
  useUpdateCompanyNoteMutation,
  useUpdateAdminSettingsMutation,
} = adminAPI

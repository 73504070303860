import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const templateAPI = createApi({
  tagTypes: ['TemplateList'],
  reducerPath: 'templateAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchTemplates: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.templateName,
      }),
      providesTags: result => H.providesTagList(result, 'TemplateList'),
      transformResponse: response => R.sort(R.ascend(R.prop(C.FIELD_NAME)), R.or(response.elements, [])),
    }),
    getTemplate: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: id => ({
        id,
        method: E.METHODS.GET,
        url: `${E.ENDPOINTS.template}/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: 'TemplateList', id }],
    }),
    createTemplatePreview: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.templatePreview,
      }),
    }),
  }),
})

export const {
  useGetTemplateQuery,
  useFetchTemplatesQuery,
  useCreateTemplatePreviewMutation,
} = templateAPI

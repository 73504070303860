import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// endpoints
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

const getHandlerResponse = async response => {
  const handleResponse = await response.blob()

  if (R.equals(R.path(['size'], handleResponse), 0)) return null

  return H.createObjectURL(handleResponse)
}

const getCacheEntryResult = async (dispatch, getCacheEntry, cacheDataLoaded) => {
  await cacheDataLoaded

  const data = R.path(['data'], getCacheEntry())

  if (H.isNotNilAndNotEmpty(data)) {
    dispatch(
      avatarAPI.util.updateQueryData(
        'getAvatar',
        undefined,
        () => data,
      ))
  }
}

export const avatarAPI = createApi({
  tagTypes: ['Avatar'],
  reducerPath: 'avatarAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    getAvatar: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        providesTags: ['Avatar'],
        url: E.ENDPOINTS.userAvatar,
        responseHandler: response => getHandlerResponse(response),
        transformResponse: response => H.stringifyAndParseJson(response),
      }),
    }),
    addAvatar: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.userAvatar,
        invalidatesTags: ['Avatar'],
        responseHandler: response => getHandlerResponse(response),
        transformResponse: response => H.stringifyAndParseJson(response),
        headers: {
          'Media-Type': E.MEDIA_TYPES.IMAGE_JPEG,
        },
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, getCacheEntry }) {
        await getCacheEntryResult(dispatch, getCacheEntry, cacheDataLoaded)
      },
    }),
    deleteAvatar: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.DELETE,
        url: E.ENDPOINTS.userAvatar,
        invalidatesTags: ['Avatar'],
      }),
      async onCacheEntryAdded(arg, { dispatch }) {
        dispatch(
          avatarAPI.util.updateQueryData(
            'getAvatar',
            undefined,
            () => null,
          ))
      },
    }),
    getAvatarByAvatarUuid: build.query({
      query: avatarUuid => ({
        avatarUuid,
        method: E.METHODS.GET,
        providesTags: ['Avatar'],
        url: `${E.ENDPOINTS.userAvatar}/${avatarUuid}`,
        responseHandler: response => getHandlerResponse(response),
        transformResponse: response => H.stringifyAndParseJson(response),
      }),
    }),
  }),
})

export const {
  useGetAvatarQuery,
  useAddAvatarMutation,
  useDeleteAvatarMutation,
  useGetAvatarByAvatarUuidQuery,
} = avatarAPI

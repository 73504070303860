import * as R from 'ramda'
import * as ExcelJS from 'exceljs'
import i18n from '../translations'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'
// material
import { styled } from '@mui/material'
// utilities
import * as E from '../utilities/endpoints'
// helpers/constants
import * as H from './'
import * as C from '../constants'
//////////////////////////////////////////////////

const { SERVER, NO_ACCESS, VALIDATION, UNAUTHORIZED, UNPROCESSABLE_ENTITY } = C.ERROR_STATUS_TYPES

// localStorage
export const getTheme = () => JSON.parse(localStorage.getItem('theme')) // eslint-disable-line
export const setTheme = theme => localStorage.setItem('theme', JSON.stringify(theme)) // eslint-disable-line
export const getTourSessions = () => JSON.parse(localStorage.getItem('tourSessions')) // eslint-disable-line
export const setTourSessions = session => localStorage.setItem('tourSessions', JSON.stringify(session)) // eslint-disable-line
export const getGoogleAuthorizeMessage = () => JSON.parse(localStorage.getItem('googleAuthorizeMessage')) // eslint-disable-line
export const setGoogleAuthorizeMessage = status => localStorage.setItem('googleAuthorizeMessage', JSON.stringify(status)) // eslint-disable-line

// sessionStorage
export const setAuthTokenToSession = token => sessionStorage.setItem('mytrucklisttoken', JSON.stringify(token)) // eslint-disable-line
export const getAuthTokenFromSession = () => JSON.parse(sessionStorage.getItem('mytrucklisttoken')); // eslint-disable-line
export const removeAuthTokenFromSession = () => sessionStorage.removeItem('mytrucklisttoken'); // eslint-disable-line
export const setRefreshTokenToSession = token => sessionStorage.setItem('refreshToken', JSON.stringify(token)) // eslint-disable-line
export const getRefreshTokenFromSession = () => JSON.parse(sessionStorage.getItem('refreshToken')); // eslint-disable-line
export const removeRefreshTokenFromSession = () => sessionStorage.removeItem('refreshToken'); // eslint-disable-line
export const removeCompanyAuthTokenFromSession = () => sessionStorage.removeItem('companyToken') // eslint-disable-line
export const getCompanyAuthTokenFromSession = () => JSON.parse(sessionStorage.getItem('companyToken')) // eslint-disable-line
export const setCompanyAuthTokenToSession = token => sessionStorage.setItem('companyToken', JSON.stringify(token)) // eslint-disable-line
export const removeCompanyRefreshTokenFromSession = () => sessionStorage.removeItem('companyRefreshToken') // eslint-disable-line
export const getCompanyRefreshTokenFromSession = () => JSON.parse(sessionStorage.getItem('companyRefreshToken')) // eslint-disable-line
export const setCompanyRefreshTokenToSession = token => sessionStorage.setItem('companyRefreshToken', JSON.stringify(token)) // eslint-disable-line

export const getToken = () => getAuthTokenFromSession()

export const makeRequestHeaders = customHeaders => {
  let token
  let sessionHeaders = {}

  const customHeadersToUse = customHeaders ?? {
    'Content-Type': E.CONTENT_TYPES.APPLICATION_JSON,
  }

  try {
    token = getToken()
  } catch (error) {
    token = null
  }

  if (token)
    sessionHeaders = {
      Authorization: `Bearer ${token}`,
    }

  return { ...sessionHeaders, ...customHeadersToUse }
}

export const buildQueryAPI = (build, method, url, headers, urlQueryName, providesTags) => {
  const getURL = body =>
    H.ifElse(H.isNotNilAndNotEmpty(urlQueryName), `${url}/${(R.or(body, R.prop(urlQueryName, body)))}`, url)

  if (R.equals(method, E.METHODS.GET)) {
    return build.query({ query: body => ({ method, headers, url: getURL(body), providesTags }) })
  }

  return build.mutation({ query: body => ({ body, method, headers, url: getURL(body), providesTags }) })
}

export const getFormData = object => {
  // eslint-disable-next-line no-undef
  const formData = new FormData()

  R.compose(
    R.forEach(key => formData.append(key, R.prop(key, object))),
    R.keys,
  )(object)

  return formData
}

const StyledTextToast = styled('p')(({ theme }) => ({
  zIndex: 99999,
  maxWidth: 350,
  minWidth: 250,
  textAlign: 'start',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  fontFamily: 'Roboto, sans-serif',

  [theme.breakpoints.down('md')]: {
    fontSize: 12,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 9,
  },
  '&:first-letter': {
    textTransform: 'capitalize',
  },
}))

// use with wait/async
export const showToastrMessageSimple = (type, message, autoClose) => {
  toast(
    <StyledTextToast>
      {message}
    </StyledTextToast>, {
      type,
      rtl: false,
      draggable: true,
      closeOnClick: true,
      newestOnTop: false,
      pauseOnHover: true,
      position: 'top-left',
      hideProgressBar: false,
      pauseOnFocusLoss: true,
      autoClose: autoClose || 10000,
    })
}

// TODO: use logging system like Sentry
export const logAPIErrorRes = res => console.log('**************************************************-API_ERROR', res) // eslint-disable-line

export const getRequestAPIError = ({ data, status }) => {
  let transformedMessage
  const selectedFields = [
    C.FIELD_HOURS,
    C.FIELD_END_DATE,
    C.FIELD_START_DATE,
    C.FIELD_EMAIL_GROUP_IDS,
    C.FIELD_PICK_UP_DATE_TIME,
    C.FIELD_MAILING_CONFIG_ID,
    C.FIELD_COMPANY_TEMPLATE_ID,
  ]

  const fields = R.propOr([], 'fields', data)
  const error = R.propOr(null, 'error', data)
  const messageFromAPI = R.propOr(null, 'message', data)

  const transformString = R.pipe(R.replace(/Email with {email: (.*?), .*?} not found/, 'email $1 not found'))

  if (/Email with {email: (.*?), .*?} not found/.test(messageFromAPI)) {
    transformedMessage = transformString(messageFromAPI)
  } else {
    transformedMessage = messageFromAPI
  }

  const renderError = statusLabel => {
    const errorCodeExist = i18n.exists(`errorCodes.${error}`)
    const errorCodeT = i18n.t(`errorCodes.${error}`)
    const errorCodeTClean = H.cleanStringFromLinesAndSpaces(errorCodeT)

    if (errorCodeExist) return showToastrMessageSimple('error', errorCodeTClean)

    if (H.isNotNilAndNotEmpty(fields)) {
      let message = ''

      if (R.gt(R.length(fields), 1)) {
        message = i18n.t('errors.pleaseEnterRequiredInfo')
      } else {
        message = H.createStringFromArray(
          ', \r\n',
          R.compose(
            R.map(field => {
              const { name, message } = field

              const getExtraMessage = field => {
                const firstChar = R.head(field)
                const messagePrefixForEnteredFields = H.ifElse(
                  H.isFirstStrCharacterVowel(firstChar),
                  i18n.t('errors.pleaseEnterAn'),
                  i18n.t('errors.pleaseEnterA'),
                )
                const messagePrefixForSelectedFields = H.ifElse(
                  H.isFirstStrCharacterVowel(firstChar),
                  i18n.t('errors.pleaseSelectAn'),
                  i18n.t('errors.pleaseSelectA'),
                )

                if (R.includes(field, selectedFields)) return `${messagePrefixForSelectedFields} ${field}`

                return `${messagePrefixForEnteredFields} ${field}`
              }

              const getPart2 = splittedValue => {
                const splittedValueNumber = H.toNumber(R.last(splittedValue))

                if (H.isNotNaN(splittedValueNumber)) return R.toString(R.add(splittedValueNumber, 1))

                return R.last(splittedValue)
              }

              const splittedName = R.split('].', name)
              const splitted2 = R.split('[', R.head(splittedName))
              const part1 = R.head(splitted2)
              const part2 = getPart2(splitted2)
              const part3 = R.last(splittedName)
              const getReplacedMessage = field =>
                `${field} ${H.replacePhrases(C.ERROR_MESSAGES_PHRASES_TO_RENAME, message)} ${getExtraMessage(field)}`

              if (H.isAllEquals([part1, part2, part3])) return getReplacedMessage(part1)

              return getReplacedMessage(part3)
            }),
            R.sort(R.ascend(R.prop(C.FIELD_NAME))),
          )(fields),
        )
      }

      showToastrMessageSimple('error', H.replacePhrases(C.ERROR_MESSAGES_PHRASES_TO_RENAME, message))
    } else if (H.isNotNilAndNotEmpty(error?.message)) {
      showToastrMessageSimple('error', error?.message)
    } else if (H.isNotNilAndNotEmpty(error?.error)) {
      showToastrMessageSimple('error', error?.error)
    } else if (H.isNotNilAndNotEmpty(messageFromAPI)) {
      showToastrMessageSimple('error', messageFromAPI)
    } else if (H.isNotNilAndNotEmpty(error)) {
      showToastrMessageSimple('error', error)
    } else {
      showToastrMessageSimple('error', i18n.t(`errors.${statusLabel}`))
    }
  }

  switch (R.or(error?.status, status)) {
  case SERVER:
    renderError('serverError')
    break
  case UNAUTHORIZED:
    renderError('noPermission')
    break
  case VALIDATION:
    renderError('validationError')
    break
  case UNPROCESSABLE_ENTITY:
    showToastrMessageSimple('error', transformedMessage)
    break
  case NO_ACCESS:
    renderError('sorryYouHaveNoAccess')
    break
  default:
    H.isNotNilAndNotEmpty(R.or(error?.status, status)) &&
    renderError('unknownError')
  }
}

export const getCatchError = err => {
  H.getRequestAPIError({
    data: err,
    status: C.ERROR_STATUS_TYPES.VALIDATION,
  })
}

export const getRoleFromToken = token => {
  const decoded = jwtDecode(token)

  if (R.includes(C.ROLE_ADMIN, R.pathOr([], ['scope'], decoded))) {
    return C.ROLE_ADMIN
  }

  return C.ROLE_USER
}

export const isAdmin = role => H.isTrue(R.equals(role, C.ROLE_ADMIN))

export const getByteSize = size => {
  for (let i = 0; R.lt(i, R.length(C.BYTE_UNITS)); i++) {
    size = Math.floor(R.divide(size, 1024))

    if (R.lt(size, 1024)) return R.concat(size.toFixed(1), C.BYTE_UNITS[i])
  }
}

export async function makeExcel(t, columns, rows, sheetNm, fileNm, errorMessage, successMessage) {
  try {
    const workbook = new ExcelJS.Workbook()

    // 1. sheet
    const worksheet = workbook.addWorksheet(
      H.ifElse(H.isNotNilAndNotEmpty(sheetNm), sheetNm, 'Sheet1'),
    )

    // 2. Columns
    worksheet.columns = columns

    // 3. Rows
    rows.forEach(item => {
      const tmpRow = {}
      columns.forEach(item2 => {
        tmpRow[item2.key] = item[item2.key]
      })

      worksheet.addRow(tmpRow)
    })

    if (R.includes(C.LIST_TRUCKS, fileNm)) {
      worksheet.getColumn(2).eachCell(cell => cell.numFmt = '@')
      worksheet.getColumn(4).eachCell(cell => cell.numFmt = '@')
    }

    // header style
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell) {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' },
        }

        if (R.equals(rowNumber, 1)) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F3F5F9' },
          }
          cell.font = {
            size: 12,
            family: 4,
            bold: true,
            color: { argb: '999999' },
          }
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          }
        } else {
          // cell style
          cell.font = {
            size: 12,
            family: 4,
            color: { argb: '282828' },
          }
          cell.alignment = {
            wrapText: true,
            vertical: 'top',
            shrinkToFit: true,
            horizontal: 'center',
          }
        }
      })
    })
    const mimeType = {
      type: E.CONTENT_TYPES.APPLICATION_SPREADSHEETML,
    }
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], mimeType) // eslint-disable-line

    saveAs(blob, H.ifElse(H.isNotNilAndNotEmpty(fileNm), fileNm, 'Excel'))

    H.showToastrMessageSimple('success', successMessage)
  } catch (e) {
    H.showToastrMessageSimple('error', `${errorMessage} ${e}`)
  }
}

import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
// react-datasheet-grid
import 'react-datasheet-grid/dist/style.css'
// react-toastify
import 'react-toastify/dist/ReactToastify.css'
// react-phone-number-input
import 'react-phone-number-input/style.css'
//////////////////////////////////////////////////

const container = document.getElementById('root') // eslint-disable-line
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)

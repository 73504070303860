import dayjs from 'dayjs'
import * as R from 'ramda'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// helpers/constants
import * as C from '../constants'
import { isNotNilAndNotEmpty } from './common'
//////////////////////////////////////////////////

const defaultFormats = [
  C.DEFAULT_DATE_FORMAT,
  C.TRANSFORMED_DATE_FORMAT,
  C.DATE_TIME_FORMAT_FOR_SEARCH,
]

dayjs.extend(utc)
dayjs.extend(timezone)

export const getCurrentDate = () => dayjs()

export const currentTimezone = dayjs.tz.guess()

export const getNewDate = item => new Date(item)

export const getCurrentDateWithFormat = format => dayjs().format(format)

export const getDateWithFormat = (date, format) => dayjs(date).tz(currentTimezone).format(format)

export const getDateWithFormatOrNull = (date, format) => {
  if (R.equals(date, null)) return null

  return dayjs(date).tz(currentTimezone).format(format)
}

export const setTimeToMidnightUTC = inputDate => {
  const date = getNewDate(inputDate)

  date.setUTCHours(0)
  date.setUTCMinutes(0)
  date.setUTCSeconds(0)

  return date.toISOString()
}

export const formatDate = inputDate => {
  const date = getNewDate(inputDate)
  const month = String(R.add(date.getMonth(), 1)).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  const year = date.getFullYear()

  return `${month}/${day}/${year}`
}

export const isValidDate = (str, format = null) => {
  if (isNotNilAndNotEmpty(format)) return dayjs(str, format, true).isValid()

  return defaultFormats.every(defaultFormat => dayjs(str, defaultFormat, true).isValid())
}

export const transformDateToUTC = date => {
  const inputDate = getNewDate(date)

  const utcDate = getNewDate(Date.UTC(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
    inputDate.getHours(),
    inputDate.getMinutes(),
    inputDate.getSeconds(),
    inputDate.getMilliseconds(),
  ))

  return utcDate.toISOString()
}

import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import { METHODS, ENDPOINTS } from '../utilities/endpoints'
//////////////////////////////////////////////////

export const companySubscriptionAPI = createApi({
  baseQuery: H.baseQueryWithReAuth,
  tagTypes: ['CompanySubscription'],
  reducerPath: 'companySubscriptionAPI',
  endpoints: build => ({
    fetchCompanySubscription: build.query({
      extraOptions: { logError: true, useLoader: true },
      providesTags: result => H.providesTagList(result, 'CompanySubscription'),
      transformResponse: result => H.ifElse(H.isNotNilAndNotEmpty(result), R.of(result), null),
      query: body => ({
        body,
        method: METHODS.GET,
        url: ENDPOINTS.companySubscription(),
      }),
    }),
    fetchStripePrices: build.query({
      extraOptions: { useLoader: true },
      transformResponse: R.pathOr([], ['elements']),
      providesTags: result => H.providesTagList(result, 'StripePrices'),
      query: body => ({
        body,
        method: METHODS.GET,
        url: ENDPOINTS.companySubscription('/prices'),
      }),
    }),
    fetchPaymentMethods: build.query({
      extraOptions: { useLoader: true },
      providesTags: result => H.providesTagList(result, 'PaymentMethods'),
      query: body => ({
        body,
        method: METHODS.GET,
        url: ENDPOINTS.paymentMethods,
      }),
    }),
    createCompanySubscription: build.mutation({
      extraOptions: { useLoader: true },
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'CompanySubscription'),
      query: ({ type, body }) => ({
        body,
        method: METHODS.POST,
        url: ENDPOINTS.companySubscription(`/${type}`),
      }),
    }),
    fetchCompanySubscriptionLog: build.mutation({
      extraOptions: { useLoader: true },
      transformResponse: R.pathOr([], ['elements']),
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'CompanySubscriptionLog'),
      query: () => ({
        method: METHODS.POST,
        body: { page: 0, limit: 1000 },
        url: ENDPOINTS.companySubscriptionLog,
      }),
    }),
  }),
})

export const {
  useFetchStripePricesQuery,
  useFetchPaymentMethodsQuery,
  useFetchCompanySubscriptionQuery,
  useCreateCompanySubscriptionMutation,
  useFetchCompanySubscriptionLogMutation,
} = companySubscriptionAPI

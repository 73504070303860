import * as R from 'ramda'
import * as Yup from 'yup'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// forms
import FormWrapper from './form-wrapper'
//////////////////////////////////////////////////

const getSelectTransformedOptions = arr => R.map(
  option => ({
    [C.FIELD_NAME]: R.propOr('', C.FIELD_NAME, option),
    [C.FIELD_VALUE]: R.propOr(null, C.FIELD_ID, option),
  }),
  R.or(arr, []),
)

export const validationSchemaMassAddGroupsObject = {
  [C.FIELD_EMAIL_GROUP_IDS]: Yup.array().nullable(true).min(1, H.getRequiredLocaleTxt()),
}

const initialValues = {
  [C.FIELD_EMAIL_GROUP_IDS]: [],
}

const validationSchema = Yup.object().shape(validationSchemaMassAddGroupsObject)

const fieldSettings = groupsArr => [
  {
    required: true,
    noTranslation: true,
    type: 'selectMultiple',
    label: 'labels.groups',
    fieldName: C.FIELD_EMAIL_GROUP_IDS,
    options: getSelectTransformedOptions(groupsArr),
  },
]

const massAddRequestDataSettings = ({ groupsArr }) => ({
  fieldSettings: () => fieldSettings(groupsArr),
  formikSettings: {
    initialValues,
    validationSchema,
  },
  buttonSettings: {
    useIconBtn: true,
    useCancelBtn: true,
  },
})

export const MassAddGroupsForm = props => <FormWrapper {...props} {...massAddRequestDataSettings(props)} />

import logger from 'redux-logger'
import { append, equals } from 'ramda'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
// api
import {
  authAPI,
  userAPI,
  mailAPI,
  adminAPI,
  truckAPI,
  avatarAPI,
  companyAPI,
  mailingAPI,
  templateAPI,
  mailGroupAPI,
  mailingLogAPI,
  mailingJobAPI,
  companyLogoAPI,
  mailingUsageAPI,
  mailingConfigAPI,
  companySocialAPI,
  companyPaymentAPI,
  companyTemplateAPI,
  destinationZoneAPI,
  truckReservationAPI,
  reservationRequestAPI,
  companySubscriptionAPI,
} from '../api'
// reducers
import auth from '../features/auth/authSlice'
import tour from '../components/tour/tourSlice'
import loader from '../components/loader/loaderSlice'
import trucks from '../features/truck-list/truckSlice'
import mailList from '../features/mail-list/mailListSlice'
import adminPanel from '../features/admin-panel/adminPanelSlice'
import datasheet from '../components/datasheet-component/datasheetSlice'
import companyTemplates from '../features/email-templates/emailTemplatesSlice'
import reservationRequest from '../features/reservation-requests/reservationRequestsSlice'
//////////////////////////////////////////////////

let middleware = [
  authAPI.middleware,
  userAPI.middleware,
  mailAPI.middleware,
  adminAPI.middleware,
  truckAPI.middleware,
  avatarAPI.middleware,
  mailingAPI.middleware,
  companyAPI.middleware,
  templateAPI.middleware,
  mailGroupAPI.middleware,
  mailingLogAPI.middleware,
  mailingJobAPI.middleware,
  companyLogoAPI.middleware,
  mailingUsageAPI.middleware,
  mailingConfigAPI.middleware,
  companySocialAPI.middleware,
  companyPaymentAPI.middleware,
  companyTemplateAPI.middleware,
  destinationZoneAPI.middleware,
  truckReservationAPI.middleware,
  reservationRequestAPI.middleware,
  companySubscriptionAPI.middleware,
]

if (equals(process.env.NODE_ENV, 'development')) { // eslint-disable-line
  middleware = append(logger, middleware)
}

export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
  reducer: {
    auth,
    tour,
    trucks,
    loader,
    mailList,
    datasheet,
    adminPanel,
    companyTemplates,
    reservationRequest,
    [authAPI.reducerPath]: authAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [mailAPI.reducerPath]: mailAPI.reducer,
    [adminAPI.reducerPath]: adminAPI.reducer,
    [truckAPI.reducerPath]: truckAPI.reducer,
    [avatarAPI.reducerPath]: avatarAPI.reducer,
    [mailingAPI.reducerPath]: mailingAPI.reducer,
    [companyAPI.reducerPath]: companyAPI.reducer,
    [templateAPI.reducerPath]: templateAPI.reducer,
    [mailGroupAPI.reducerPath]: mailGroupAPI.reducer,
    [mailingLogAPI.reducerPath]: mailingLogAPI.reducer,
    [mailingJobAPI.reducerPath]: mailingJobAPI.reducer,
    [companyLogoAPI.reducerPath]: companyLogoAPI.reducer,
    [mailingUsageAPI.reducerPath]: mailingUsageAPI.reducer,
    [mailingConfigAPI.reducerPath]: mailingConfigAPI.reducer,
    [companySocialAPI.reducerPath]: companySocialAPI.reducer,
    [companyPaymentAPI.reducerPath]: companyPaymentAPI.reducer,
    [companyTemplateAPI.reducerPath]: companyTemplateAPI.reducer,
    [destinationZoneAPI.reducerPath]: destinationZoneAPI.reducer,
    [truckReservationAPI.reducerPath]: truckReservationAPI.reducer,
    [reservationRequestAPI.reducerPath]: reservationRequestAPI.reducer,
    [companySubscriptionAPI.reducerPath]: companySubscriptionAPI.reducer,
  },
})

setupListeners(store.dispatch)

import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { lazy, useMemo, useState, Suspense, createContext } from 'react'
// material
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// helpers/constants
import * as H from './helpers'
import * as C from './constants'
// translations
import './translations'
// utilities
import { PrivateOutlet } from './utilities/private-outlet'
// component Loader
import { Loader } from './components/loader'
// features auth
import { makeSelectAuthStore, makeSelectIsAdminToken } from './features/auth/authSlice'
// index
import { theme } from './utilities/theme'
//////////////////////////////////////////////////

export const ColorModeContext = createContext({ toggleColorMode: () => {} })

const LoadingFallback = () => (
  <Box
    width='100vw'
    display='flex'
    height='100vh'
    alignItems='center'
    justifyContent='center'
    backgroundColor='background.default'
  >
    <Loader loaderOpened={true} />
  </Box>
)

// routes
const Auth = lazy(() => import('./features/auth'))
const Profile = lazy(() => import('./features/profile'))
const Admin = lazy(() => import('./features/admin-panel'))
const MailList = lazy(() => import('./features/mail-list'))
const TruckList = lazy(() => import('./features/truck-list'))
const MailingLog = lazy(() => import('./features/mailing-log'))
const PrivacyPolicy = lazy(() => import('./features/privacy-policy'))
const EmailCampaigns = lazy(() => import('./features/email-campaigns'))
const EmailTemplates = lazy(() => import('./features/email-templates'))
const ConfirmEmail = lazy(() => import('./features/auth/confirm-email'))
const UnsubscribeEmail = lazy(() => import('./features/unsubscribe-email'))
const TermsAndConditions = lazy(() => import('./features/terms-&-conditions'))
const AvailableTruckList = lazy(() => import('./features/available-truck-list'))
const ConfirmGmail = lazy(() => import('./features/mailing-token/confirm-gmail'))
const ReservationRequests = lazy(() => import('./features/reservation-requests'))
const DestinationZoneList = lazy(() => import('./features/destination-zone-list'))

const App = () => {
  const auth = useSelector(makeSelectAuthStore)
  const isAdminToken = useSelector(makeSelectIsAdminToken)

  const isAdminRole = H.isAdmin(H.getPropFromObject('role', auth))
  const isAdminView = R.and(isAdminRole, H.isFalse(isAdminToken))

  const [mode, setMode] = useState(R.or(H.getTheme(), 'dark'))

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => {
          if (R.equals(prevMode, 'light')) {
            H.setTheme('dark')

            return 'dark'
          }

          H.setTheme('light')

          return 'light'
        })
      },
    }), [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getTheme = useMemo(() => createTheme(theme(mode)), [mode])

  document.documentElement.style.setProperty('--primary-main', R.path(['palette', 'primary', 'main'], getTheme)) // eslint-disable-line
  document.documentElement.style.setProperty('--primary-light', R.path(['palette', 'primary', 'light'], getTheme)) // eslint-disable-line
  document.documentElement.style.setProperty('--border-button-color', R.path(['colors', 'borderButtonColor'], getTheme)) // eslint-disable-line

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={getTheme}>
        <BrowserRouter>
          <CssBaseline />
          <Suspense fallback={<LoadingFallback />}>
            <ToastContainer style={{ width: 'max-content' }} theme={R.equals(mode, 'light') ? 'light' : 'dark'} />
            <Routes>
              <Route path={C.ROUTE_PATH_LOGIN} element={<Auth />} />
              <Route path={C.ROUTE_PATH_CONFIRM_EMAIL} element={<ConfirmEmail />} />
              <Route path={C.ROUTE_PATH_CONFIRM_GMAIL} element={<ConfirmGmail />} />
              <Route path={C.ROUTE_PATH_PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={C.ROUTE_PATH_UNSUBSCRIBE_EMAIL} element={<UnsubscribeEmail />} />
              <Route path={C.ROUTE_PATH_SIGN_UP} element={<Auth isSignUpPage={true} />} />
              <Route path={C.ROUTE_PATH_AVAILABLE_TRUCKS} element={<AvailableTruckList />} />
              <Route path={C.ROUTE_PATH_TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
              <Route path={C.ROUTE_PATH_FORGOT_PASSWORD} element={<Auth isForgotPasswordPage={true} />} />
              <Route path={C.ROUTE_PATH_CHANGE_PASSWORD} element={<Auth isChangePasswordPage={true} />} />
              <Route path='/' element={<PrivateOutlet isAdminView={isAdminView} isAdminRole={isAdminRole} />}>
                <Route index element={<TruckList />} />
                <Route path={C.ROUTE_PATH_MAILS} element={<MailList />} />
                <Route path={C.ROUTE_PATH_PROFILE} element={<Profile />} />
                <Route path={C.ROUTE_PATH_ADMIN_PANEL} element={<Admin />} />
                <Route path={C.ROUTE_PATH_MAILING_LOG} element={<MailingLog />} />
                <Route path={C.ROUTE_PATH_EMAIL_CAMPAIGNS} element={<EmailCampaigns />} />
                <Route path={C.ROUTE_PATH_EMAIL_TEMPLATES} element={<EmailTemplates />} />
                <Route path={C.ROUTE_PATH_DESTINATION_ZONES} element={<DestinationZoneList />} />
                <Route path={C.ROUTE_PATH_RESERVATION_REQUESTS} element={<ReservationRequests />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App

// NOTE: Default theme viewer link:
// https://mui.com/material-ui/customization/default-theme/

// TODO: refactor with clarify naming and using colors

const SCROLL_WIDTH = '5px'
const bgColor = '#F3F5F9'
const scrollbarThumbColor = '#999999'
const tableHeaderTextColor = '#808080'
const greyColor = '#F3F5F9'

const mainColor = '#7369C5'
const blueColor = '#4285F4'
const blackColor = '#282828'
const whiteColor = '#FFFFFF'
const errorColor = '#FF0000'
const greenColor = '#008000'
const violetColor = '#6E61EA'
const menuListBg = whiteColor
const orangeColor = '#FF7400'
const darkGreyColor = '#787878'
const mainLimeColor = '#B8E900'
const lightRedColor = '#FAEFEF'
const whiteColorMain = '#FFFFFF'
const lightGreyColor = '#D5D5D5'
const lightMainColor = '#D9D9EF'
const darkVioletColor = '#601FEB'
const lightErrorColor = '#fe71b2'
const lightGreenColor = '#EBF8E8'
const lightYellowColor = '#FFFF00'
const middleGreenColor = '#dcf2e2'
const lightOrangeColor = '#FFD580'
const lighterGreyColor = '#FAFAFA'
const lightVioletColor = '#D7D7FF'
const multiInputTextBg = '#E6E6E6'
const lightPurpleColor = '#9993D4'
const transparentGreyColor = '#DADADA'
const secondaryMainColor = lightMainColor

export const lightTheme = mode => ({
  colors: {
    white: whiteColor,
    violet: violetColor,
    blueColor: blueColor,
    mainLime: mainLimeColor,
    modalBGColor: whiteColor,
    lightGrey: lightGreyColor,
    darkViolet: darkVioletColor,
    lightViolet: lightVioletColor,
    whiteColorMain: whiteColorMain,
    borderButtonColor: lightMainColor,
    secondaryTableRow: lightGreyColor,
    toolbarBackgroundColor: whiteColor,
    tableHeaderText: tableHeaderTextColor,
  },
  rgbaColors: {
    red135: 'rgb(255, 104, 135)',
    blue192: 'rgb(22, 101, 192)',
    blue249: 'rgb(144, 202, 249)',
    red00_8: 'rgba(255, 0, 0, 0.08)',
    black0_04: 'rgba(0, 0, 0, 0.04)',
    lightGrey_012: 'rgba(0, 0, 0, 0.12)',
    darkGrey0_4: 'rgba(40, 40, 40, 0.4)',
    violet00_8: 'rgba(115, 105, 197, 0.08)',
    violet0_12: 'rgba(115, 105, 197, 0.12)',
    lightGrey132_8: 'rgb(132 164 176 / 8%)',
  },
  palette: {
    mode,
    primary: {
      main: mainColor,
      error: errorColor,
      light: lightMainColor,
    },
    text: {
      primary: blackColor,
      disabled: greyColor,
      whiteColor: whiteColor,
      secondary: darkGreyColor,
    },
    background: {
      menuListBg,
      grey: greyColor,
      main: mainColor,
      lightErrorColor,
      default: bgColor,
      multiInputTextBg,
      lightPurpleColor,
      green: greenColor,
      error: errorColor,
      white: whiteColor,
      black: blackColor,
      secondaryMainColor,
      violet: violetColor,
      orange: orangeColor,
      darkGrey: darkGreyColor,
      lightRed: lightRedColor,
      lightGrey: lightGreyColor,
      darkViolet: darkVioletColor,
      lightGreen: lightGreenColor,
      middleGreen: middleGreenColor,
      lightYellow: lightYellowColor,
      lightOrange: lightOrangeColor,
      lighterGrey: lighterGreyColor,
      transparentMain: lightMainColor,
      transparentGrey: transparentGreyColor,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    },
  },
  zIndex: {
    fab: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          padding: 12,
          fontSize: 16,
          lineHeight: 3,
          borderRadius: 8,
          alignSelf: 'center',
          textTransform: 'capitalize',
        },
        contained: {
          color: whiteColor,
          backgroundColor: mainColor,
          '&.Mui-selected': {
            color: mainColor,
            backgroundColor: lightMainColor,
          },
          '&:hover': {
            color: mainColor,
            backgroundColor: lightMainColor,
          },
        },
        outlined: {
          border: 'none',
          color: mainColor,
          backgroundColor: lightMainColor,
          '&.Mui-selected': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
          '&:hover': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: 40,
          marginLeft: 12,
          marginRight: 12,
          '&.Mui-selected': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
          '&:hover': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: mainColor,
          backgroundColor: lightMainColor,
          '&.Mui-selected': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
          '&:hover': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          padding: 2,
          fontSize: 26,
          cursor: 'pointer',
          alignSelf: 'center',
        },
        colorDisabled: { color: transparentGreyColor },
        colorPrimary: {
          color: mainColor,
          '&:hover': { color: lightMainColor },
          '&.Mui-selected': { color: lightMainColor },
        },
        colorSecondary: {
          color: darkGreyColor,
          '&:hover': { color: transparentGreyColor },
          '&.Mui-selected': { color: transparentGreyColor },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            maxHeight: 40,
            width: '100%',
          },
          '.MuiOutlinedInput-input': {
            fontSize: 13,
            textTransform: 'capitalize',
          },
          fieldset: { borderColor: `${transparentGreyColor} !important` },
          '.MuiInputBase-input.MuiOutlinedInput-input': {
            paddingTop: '4px',
            paddingBottom: '4px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fieldset: { borderColor: darkGreyColor },
          '& .Mui-focused fieldset': { borderColor: `${darkGreyColor} !important` },
          '.MuiOutlinedInput-input:-webkit-autofill': { 'WebkitTransitionDelay': '9999s' },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: '100vw',
          display: 'flex',
          backgroundColor: whiteColor,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 60,
          variant: 'scrollable',
          textDecoration: 'none',
          borderColor: 'divider',
          '.Mui-disabled': { color: `${scrollbarThumbColor} !important` },
          '.MuiButtonBase-root': {
            fontWeight: 'bold',
            color: darkGreyColor,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          color: darkGreyColor,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '60px !important',
          paddingLeft: '10px !important',
          paddingRight: '10px !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          textTransform: 'capitalize',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          size: 'small',
          color: darkGreyColor,
          variant: 'contained',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: whiteColor,
          textTransform: 'capitalize',
          '.MuiSvgIcon-root': {
            fontSize: 24,
            marginRight: 5,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 4,
          '& .MuiSvgIcon-root': { fontSize: 23, color: blackColor },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: mainColor,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: SCROLL_WIDTH,
            backgroundColor: scrollbarThumbColor,
          },
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: SCROLL_WIDTH,
            height: SCROLL_WIDTH,
            color: lightGreyColor,
            borderColor: lightGreyColor,
            border: `${SCROLL_WIDTH} solid`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: 13,
          fontWeight: 400,
          marginTop: '4px',
          color: errorColor,
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          letterSpacing: 'normal',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '.MuiTableCell-body': {
            background: lighterGreyColor,
            ':last-child td, :last-child th': { border: 0 },
            ':last-child': { borderRadius: '0 8px 8px 0 !important' },
            ':first-of-type': { borderRadius: '8px 0 0 8px !important' },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 13,
          border: 'none',
          cursor: 'text',
          padding: '10px',
          overflow: 'hidden',
          fontFamily: 'Roboto',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          '&:first-of-type': {
            left: 0,
            padding: '10px 0',
            position: 'sticky',
            width: '20px !important',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: bgColor,
          textTransform: 'uppercase',
          '.MuiTableCell-head': {
            fontSize: 11,
            fontWeight: 700,
            ':first-of-type': { zIndex: 4 },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: { background: 'transparent' },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: '-4px',
          tableLayout: 'auto',
          borderSpacing: '0px 4px',
          borderCollapse: 'separate',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: { '& .MuiMenu-list': { maxHeight: 350 } },
      },
    },
  },
})

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailingConfigAPI = createApi({
  reducerPath: 'mailingConfigAPI',
  tagTypes: ['MailingConfigsList'],
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchConfigs: build.query({
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.mailingConfig,
      }),
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'MailingConfigsList'),
    }),
    refreshMailingConfig: build.mutation({
      query: id => ({
        id,
        method: E.METHODS.PUT,
        url: `${E.ENDPOINTS.mailingConfig}/refresh/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'MailingConfigsList', id }],
    }),
    getMailingConfig: build.mutation({
      extraOptions: { useLoader: true },
      query: configId => {
        return ({
          method: E.METHODS.GET,
          url: `${E.ENDPOINTS.mailingConfig}/smtp/${configId}`,
        })
      },
    }),
    deleteMailingConfig: build.mutation({
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.mailingConfig}/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'MailingConfigsList', id }],
    }),
    createMailingConfigSmtp: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: `${E.ENDPOINTS.mailingConfig}/smtp`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'MailingConfigsList', id }],
    }),
    updateMailingConfigSmtp: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: `${E.ENDPOINTS.mailingConfig}/smtp`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'MailingConfigsList', id }],
    }),
    deleteMailingConfigSmtp: build.mutation({
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.mailingConfig}/smtp/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'MailingConfigsList', id }],
    }),
    gmailAuthorization: build.mutation({
      extraOptions: {
        useLoader: true,
      },
      query: ({ data }) => ({
        body: data,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.mailingConfigGmailAuthorization,
      }),
      invalidatesTags: [{ type: 'MailingConfigsList', id: 'LIST' }],
    }),
  }),
})

export const {
  useFetchConfigsQuery,
  useGetMailingConfigMutation,
  useGmailAuthorizationMutation,
  useDeleteMailingConfigMutation,
  useRefreshMailingConfigMutation,
  useCreateMailingConfigSmtpMutation,
  useUpdateMailingConfigSmtpMutation,
  useDeleteMailingConfigSmtpMutation,
} = mailingConfigAPI

import * as R from 'ramda'
import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
// api
import { reservationRequestAPI } from '../../api'
// helpers/constants
import * as H from '../../helpers'
import * as C from '../../constants'
//////////////////////////////////////////////////

const sliceName = 'reservationRequest'
const actionTypeFetch = `${sliceName}/fetchReservationRequests`

const { DATA_KEY, ERROR_KEY, LOADING_KEY } = C.DEFAULT_SLICE_KEYS

export const fetchReservationRequests = createAsyncThunk(
  actionTypeFetch,
  async (page, { dispatch, rejectWithValue }) => {
    try {
      const response = await dispatch(reservationRequestAPI.endpoints.fetchReservationRequests.initiate(page))

      return response.data
    } catch (error) {
      return rejectWithValue({ error: error.message })
    }
  },
)

const initialState = {
  error: null,
  isLoading: false,
  data: {
    total: null,
    elements: [],
  },
}

const reservationRequestSlice = createSlice({
  initialState,
  name: sliceName,
  reducers: {
    clearData: state => {
      state[DATA_KEY].elements = []
    },
    updateReservationRequest: (state, { payload }) => {
      state[DATA_KEY].elements = R.map(
        item => H.ifElse(R.propEq(C.FIELD_ID, R.path([C.FIELD_ID], payload), item), payload, item),
        state[DATA_KEY].elements,
      )
    },
    updateStatus: (state, { payload }) => {
      state[DATA_KEY].elements = R.map(
        item => H.ifElse(
          R.propEq(C.FIELD_ID, R.path([C.FIELD_ID], payload), item),
          R.assoc(C.FIELD_STATUS, R.path([C.FIELD_STATUS], payload), item),
          item,
        ),
        state[DATA_KEY].elements,
      )
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchReservationRequests.pending, state => {
        state[LOADING_KEY] = true
      })
      .addCase(fetchReservationRequests.fulfilled, (state, { payload }) => {
        state[LOADING_KEY] = false
        state[DATA_KEY].total = payload.total
        state[DATA_KEY].elements = R.concat(state.data.elements, payload.elements)
      })
      .addCase(fetchReservationRequests.rejected, (state, { payload }) => {
        state[ERROR_KEY] = payload
        state[LOADING_KEY] = false
      })
  },
})

const store = ({ reservationRequest }) => reservationRequest

export const { clearData, updateStatus, updateReservationRequest } = reservationRequestSlice.actions

export const makeSelectReservationRequest = createSelector(
  store,
  state => state,
)

export default reservationRequestSlice.reducer

import * as R from 'ramda'
import { createSlice, createSelector } from '@reduxjs/toolkit'
//////////////////////////////////////////////////

const scope = 'adminPanel'

const initialState = {
  sortBy: 'none',
  companiesRequestBody: {
    page: 0,
    limit: 20,
    filter: '',
    orders: {},
  },
}

export const adminPanelSlice = createSlice({
  initialState,
  name: `${scope}`,
  reducers: {
    setSortBy: (state, { payload }) => R.assocPath(['sortBy'], payload, state),
    setCompaniesRequestBodyPage: (state, { payload }) =>
      R.assocPath(['companiesRequestBody', 'page'], payload, state),
    setCompaniesRequestBodyLimit: (state, { payload }) =>
      R.assocPath(['companiesRequestBody', 'limit'], payload, state),
    setCompaniesRequestBodyFilter: (state, { payload }) =>
      R.assocPath(['companiesRequestBody', 'filter'], payload, state),
    setCompaniesRequestBodyOrders: (state, { payload }) =>
      R.assocPath(['companiesRequestBody', 'orders'], payload, state),
  },
})

export const {
  setSortBy,
  setCompaniesRequestBodyPage,
  setCompaniesRequestBodyLimit,
  setCompaniesRequestBodyFilter,
  setCompaniesRequestBodyOrders,
} = adminPanelSlice.actions

const store = ({ adminPanel }) => adminPanel

export const makeSelectAdminPanelCompaniesRequestBody = createSelector(
  store,
  ({ companiesRequestBody }) => companiesRequestBody,
)

export const makeSelectAdminPanelSortBy = createSelector(
  store,
  ({ sortBy }) => sortBy,
)

export default adminPanelSlice.reducer

import * as R from 'ramda'
import i18next from 'i18next'
import { useState } from 'react'
// helpers/constants
import * as H from '../../helpers'
import * as C from '../../constants'
// material
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
//////////////////////////////////////////////////

const Locales = ({ mr, mt, ml, mb }) => {
  const [open, setOpen] = useState(false)
  const currentLocale = localStorage.getItem('locale') // eslint-disable-line
  const [language, setLanguage] = useState(R.or(currentLocale, 'en'))

  const handleChange = event => {
    const lng = H.getEventTargetValue(event)
    setLanguage(lng)
    i18next.changeLanguage(lng)
    localStorage.setItem('locale', lng)  // eslint-disable-line
  }

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <Box mr={mr} mt={mt} ml={ml} mb={mb} minWidth={30} title={i18next.t('labels.locales')}>
      <FormControl>
        <Select
          open={open}
          value={language}
          variant='standard'
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChange}
          sx={{
            fontSize: 14,
            display: 'flex',
            color: t => t.palette.text.secondary,
            '& .MuiSvgIcon-root': { color: t => t.palette.text.secondary },
          }}
        >
          <MenuItem value='en'>{C.LANGUAGES.EN}</MenuItem>
          <MenuItem value='ua'>{C.LANGUAGES.UA}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default Locales

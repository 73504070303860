import L from 'leaflet'
import * as R from 'ramda'
import i18n from '../translations'
import MarkerClusterGroup from '@changey/react-leaflet-markercluster'
import { Popup, Marker, TileLayer, MapContainer } from 'react-leaflet'
// material
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// images
import originIcon from '../assets/images/markers/origin.svg'
import defaultIcon from '../assets/images/markers/default.svg'
import destinationIcon from '../assets/images/markers/destination.svg'
import destinationZoneIcon from '../assets/images/markers/destination-zone.svg'
// helpers/constants
import * as H from '../helpers'
//////////////////////////////////////////////////

const TILE_LAYER_ATTRIBUTION = 'Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL'

const TILE_LAYER_URL = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'

const DefaultMarker = new L.Icon({
  iconSize: [35, 35],
  iconUrl: defaultIcon,
})

const DestinationMarker = new L.Icon({
  iconSize: [35, 35],
  iconUrl: destinationIcon,
})

const DestinationZoneMarker = new L.Icon({
  iconSize: [35, 35],
  iconUrl: destinationZoneIcon,
})

const OriginMarker = new L.Icon({
  iconSize: [35, 35],
  iconUrl: originIcon,
})

const getMarkerIconByLocationType = type => {
  if (H.isNilOrEmpty(type)) return DefaultMarker

  const iconsMap = {
    origin: OriginMarker,
    destination: DestinationMarker,
    destinationZone: DestinationZoneMarker,
  }

  return iconsMap[type]
}

const labelsMap = {
  origin: 'labels.truckLocation',
  destination: 'labels.destinationOfTruck',
  destinationZone: 'labels.destinationZone',
}

const renderText = (text, city, date, locationType, country, truckId, truckType) => {
  const formattedDate = H.ifElse(date, H.formatDate(date), '')
  const truckLocation = R.join(', ', R.reject(H.isNilOrEmpty, [text, city, country]))
  const combinedString = `${i18n.t(labelsMap[locationType])}: ${text}${city ? `, ${city},` : ''} ${country}`

  const formattedTruckType = R.ifElse(
    R.identity,
    R.compose(R.join(' '), R.split('_')),
    R.always(''),
  )(truckType)

  if (H.isNilOrEmpty(locationType)) {
    const elements = R.reject(H.isNilOrEmpty, [
      { label: i18n.t('labels.truckId'), value: truckId },
      { label: i18n.t('labels.truckLocation'), value: truckLocation },
      { label: i18n.t('labels.availableDate'), value: formattedDate },
      { label: i18n.t('labels.truckType'), value: formattedTruckType },
    ])

    if (R.isEmpty(elements)) return null

    return (
      <Typography>
        {
          elements.map(element => (
            <Box key={element.label}>
              {element.value && `${element.label}: ${element.value}`}
            </Box>
          ))
        }
      </Typography>
    )
  }

  if (R.isEmpty(formattedDate)) return <>{combinedString}</>

  return (
    <>
      {combinedString}
      <br />
      {`${i18n.t('labels.truckType')}: ${formattedTruckType}`}
      <br />
      {`${i18n.t('labels.availableDate')}: ${formattedDate}`}
    </>
  )
}

export const MapComponent = ({ locations }) => (
  <MapContainer
    zoom={4}
    minZoom={2}
    maxZoom={18}
    center={[38.755157, -98.269035]}
    style={{ height: 'calc(100vh - 202px)' }}
  >
    <TileLayer
      url={TILE_LAYER_URL}
      attribution={TILE_LAYER_ATTRIBUTION}
    />
    <MarkerClusterGroup>
      {
        locations?.map(({ text, type, city, date, country, truckId, truckType, position }, index) => (
          <Marker key={index} position={position} icon={getMarkerIconByLocationType(type)}>
            <Popup>
              <Box>
                {renderText(text, city, date, type, country, truckId, truckType)}
              </Box>
            </Popup>
          </Marker>
        ))
      }
    </MarkerClusterGroup>
  </MapContainer>
)

const US_COORDINATES = {
  AL: [32.318230, -86.902298],
  AK: [66.160507, -153.369141],
  AZ: [34.048927, -111.093735],
  AR: [34.799999, -92.199997],
  CA: [36.778259, -119.417931],
  CO: [39.113014, -105.358887],
  CT: [41.599998, -72.699997],
  DE: [39.000000, -75.500000],
  FL: [27.994402, -81.760254],
  GA: [33.247875, -83.441162],
  HI: [19.741755, -155.844437],
  ID: [44.068203, -114.742043],
  IL: [40.000000, -89.000000],
  IN: [40.273502, -86.126976],
  IA: [42.032974, -93.581543],
  KS: [38.500000, -98.000000],
  KY: [37.839333, -84.270020],
  LA: [30.391830, -92.329102],
  ME: [45.367584, -68.972168],
  MD: [39.045753, -76.641273],
  MA: [42.407211, -71.382439],
  MI: [44.182205, -84.506836],
  MN: [46.392410, -94.636230],
  MS: [33.000000, -90.000000],
  MO: [38.573936, -92.603760],
  MT: [46.965260, -109.533691],
  NE: [41.500000, -100.000000],
  NV: [39.876019, -117.224121],
  NH: [44.000000, -71.500000],
  NJ: [39.833851, -74.871826],
  NM: [34.307144, -106.018066],
  NY: [43.000000, -75.000000],
  NC: [35.782169, -80.793457],
  ND: [47.650589, -100.437012],
  OH: [40.367474, -82.996216],
  OK: [36.084621, -96.921387],
  OR: [44.000000, -120.500000],
  PA: [41.203323, -77.194527],
  RI: [41.742325, -71.742332],
  SC: [33.836082, -81.163727],
  SD: [44.500000, -100.000000],
  TN: [35.860119, -86.660156],
  TX: [31.000000, -100.000000],
  UT: [39.419220, -111.950684],
  VT: [44.000000, -72.699997],
  VA: [37.926868, -78.024902],
  WA: [47.751076, -120.740135],
  WV: [39.000000, -80.500000],
  WI: [44.500000, -89.500000],
  WY: [43.075970, -107.290283],
}

const CA_COORDINATES = {
  AB: [55.000000, -115.000000],
  BC: [53.726669, -127.647621],
  MB: [56.415211, -98.739075],
  NB: [46.498390, -66.159668],
  NL: [53.000000, -60.000000],
  NS: [45.000000, -63.000000],
  ON: [50.000000, -85.000000],
  PE: [46.250000, -63.000000],
  QC: [53.000000, -70.000000],
  SK: [55.000000, -106.000000],
}

export const STATE_COORDINATES_MAP = {
  'Canada': CA_COORDINATES,
  'United States': US_COORDINATES,
}

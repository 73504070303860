export const renderDefaultTourStyles = ({ colors, palette }) => ({
  tooltipContent: {
    fontSize: 14,
  },
  tooltip: {
    width: 'fit-content',
  },
  overlay: {
    width: window.innerWidth + 'px', // eslint-disable-line
  },
  buttonBack: {
    fontSize: { lg: 16, md: 14, sm: 12, xs: 12 },
  },
  bmOverlay: {
    top: '0',
    left: '0',
  },
  tooltipContainer: {
    textIndent: 10,
    textAlign: 'start',
  },
  buttonNext: {
    marginRight: 10,
    color: colors.modalBGColor,
    fontSize: { lg: 16, md: 14, sm: 12, xs: 12 },
  },
  bmMenuWrap: {
    top: '0',
    left: '0',
    height: '100%',
    position: 'fixed',
  },
  options: {
    textColor: palette.text.primary,
    arrowColor: colors.modalBGColor,
    primaryColor: palette.primary.main,
    backgroundColor: colors.modalBGColor,
  },
})

import * as R from 'ramda'
import { useState, useEffect } from 'react'
//////////////////////////////////////////////////

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: R.min(screen.width, window.innerWidth), // eslint-disable-line
        height: R.min(screen.height, window.innerHeight),  // eslint-disable-line
      })
    }

    window.addEventListener('resize', handleResize)  // eslint-disable-line

    handleResize()

    return () => window.removeEventListener('resize', handleResize)  // eslint-disable-line
  }, [])

  return windowSize
}

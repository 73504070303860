// base/ui URL
export const UI_URL = process.env.REACT_APP_UI_URL // eslint-disable-line
export const BASE_URL = process.env.REACT_APP_BASE_URL // eslint-disable-line

// export const UI_URL = 'http://localhost:3000'
// export const BASE_URL = 'http://localhost:9090'

// API methods
export const METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
}

// endpoints
export const ENDPOINTS = {
  // auth
  signUp: '/sign-up',
  login: '/oauth2/token',
  signUpConfirm: '/sign-up/confirm',
  forgotPassword: '/forgot-password',
  changePassword: '/forgot-password/confirm',
  // admin
  adminTrial: '/admin/trial',
  adminSettings: '/admin/settings',
  adminCompanies: '/admin/company',
  companyNote: '/admin/update-company-note',
  getAdminToken: ({ companyId }) => `/admin/user/token/${companyId}`,
  // truck
  truck: '/truck',
  truckBulk: '/truck/bulk',
  truckAvailable: '/truck/available',
  // email
  mail: '/email',
  mailBulk: '/email/bulk',
  mailSubscribe: '/email/subscribe',
  mailUnsubscribe: '/email/unsubscribe',
  mailMassAddGroups: '/email/groups/add',
  // email-public
  mailPublicSubscribe: '/email-public/subscribe',
  mailPublicUnsubscribe: '/email-public/unsubscribe',
  // email-group
  mailGroup: '/email-group',
  // destination-zone
  destinationZone: '/destination-zone',
  // profile
  profile: '/user',
  // mailing-job
  mailingJob: '/mailing-job',
  // mailing-token
  mailingConfig: '/mailing-config',
  mailingConfigGmailAuthorization: '/mailing-config/gmail/authorization',
  // mailing
  mailing: '/mailing/send',
  // company
  company: '/company',
  companyPublic: '/company/public',
  // company-social-data
  companySocialData: '/company-social-data',
  // company-payment
  companyPayment: '/company-payment',
  companyPaymentPage: '/company-payment/page',
  // payment-methods
  paymentMethods: '/payment-methods',
  // company-subscription
  companySubscriptionLog: '/company-subscription-log/page',
  companySubscription: (type = '') => `/company-subscription${type}`,
  // company-logo
  companyLogo: '/company-logo',
  // mailing-log
  mailingLog: '/mailing-log/page',
  // mailing-usage
  mailingUsage: '/mailing-usage',
  // admin-user
  adminUser: 'admin/user',
  // company-template
  companyTemplate: '/company-template',
  companyTemplates: '/company-template/name',
  // template
  template: '/template',
  templateName: '/template/name',
  templatePreview: '/template/preview',
  // avatar
  userAvatar: '/user-avatar',
  // truck-reservation-requests
  truckReservationRequests: '/truck-reservation-requests',
  reservationInfo: '/truck-reservation-requests/reservation-info',
  reservationRequestChangeStatus: '/truck-reservation-requests/status',
  getTruckReservationRequests: ({ page, limit }) => `/truck-reservation-requests/?page=${page}&limit=${limit}`,
  // truck-reservation
  truckReservation: '/truck-reservation',
  truckReservationInfo: '/truck-reservation/info',
}

// content types
export const CONTENT_TYPES = {
  APPLICATION_JSON: 'application/json',
  APPLICATION_EXCEL: 'application/vnd.ms-excel',
  APPLICATION_X_WWW_FORM_URLENCODED: 'application/x-www-form-urlencoded',
  APPLICATION_SPREADSHEETML: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

// media types
export const MEDIA_TYPES = {
  IMAGE_JPEG: 'image/jpeg',
}

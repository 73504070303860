import * as R from 'ramda'
// helpers
import { isTrue, ifElse, isNotNil } from './common'
//////////////////////////////////////////////////

export const getSpecificLocationValue = (components, type, nameType, alternateType) => {
  const data = R.find(component => (
    isNotNil(
      R.find(componentType => (
        R.or(R.equals(type, componentType), R.equals(alternateType, componentType))
      ),
      component.types,
      ))),
  components,
  )

  return R.path([R.or(nameType, 'long_name')], data)
}

export const getGoogleResultFields = (results, useFormattedAddress = true) => {
  const data = results.address_components
  const latitude = results.geometry.location.lat()
  const longitude = results.geometry.location.lng()
  const formattedAddress = results.formatted_address
  const route = getSpecificLocationValue(data, 'route')
  const zip = getSpecificLocationValue(data, 'postal_code')
  const country = getSpecificLocationValue(data, 'country')
  const streetNumber = getSpecificLocationValue(data, 'street_number')
  const state = getSpecificLocationValue(data, 'administrative_area_level_1', 'short_name')
  const city = R.pathOr(
    getSpecificLocationValue(data, 'locality', 'postal_town'),
    ['long_name'],
    data[0],
  )

  const address = R.trim(ifElse(
    isTrue(useFormattedAddress),
    formattedAddress,
    `${streetNumber} ${route}`,
  ))

  const resultObject = { zip, city, state, address, country, latitude, longitude }

  return resultObject
}

export const getStateShortName = result => {
  const addressComponent = result.address_components
  const data = R.find(location => (
    isNotNil(
      R.find(componentType => R.equals(componentType, 'administrative_area_level_1'),
        location.types,
      ))),
  addressComponent,
  )

  return R.path(['short_name'], data)
}

import * as R from 'ramda'
import provinces from 'provinces-ca'
import { useSelector } from 'react-redux'
import { useStates } from 'react-us-states'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Translation, useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useRef, useState, useEffect, useLayoutEffect } from 'react'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// material
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
// icons-material
import CreateIcon from '@mui/icons-material/Create'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
// helpers/constants
import * as H from '../../helpers'
import * as C from '../../constants'
// hooks
import { useWindowSize } from '../../hooks/use-window-size'
// components
import { BaseButton, ModalComponent } from '../index'
// api
import { useTrialMutation, useUpdateCompanyNoteMutation } from '../../api/adminAPI'
// features admin-panel
import { makeSelectAdminPanelCompaniesRequestBody } from '../../features/admin-panel/adminPanelSlice'
// components datasheet-components
import { MultiValuePopover } from './select'
//////////////////////////////////////////////////

const MIN_ROWS = 0
const MAX_ROWS = 9

const SelectMenuProps = {
  variant: 'menu',
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

const setInitialValue = (data, rowData, initialValueName) => R.compose(
  R.pathOr('', ['value']),
  R.find(({ name, value }) => {
    if (H.isNumber(value)) {
      if (R.and(H.isNotNilAndNotEmpty(initialValueName), R.equals(initialValueName, C.FIELD_MAILING_CONFIG))) {
        return R.startsWith(R.pathOr(rowData, ['email'], rowData), name)
      }

      return R.equals(name, R.pathOr(rowData, ['name'], rowData))
    }

    return R.equals(value, R.pathOr(rowData, ['name'], rowData))
  }),
)(data)

export const SaveCancelRow = ({ noData, isLoading, handleSave, handleCancel, areButtonsDisabled }) => (
  <Box
    pr='8px'
    display='flex'
    justifyContent='flex-end'
    mt={{ lg: 0, md: 0, sm: 0, xs: 1 }}
  >
    <BaseButton
      sx={{ mt: 0 }}
      title='cancel'
      handleClick={handleCancel}
    />
    <BaseButton
      title='create'
      variant='outlined'
      sx={{ mt: 0, ml: 1 }}
      id='create_row_button'
      handleClick={handleSave}
      disabled={R.or(H.isTrue(areButtonsDisabled), R.or(isLoading, noData))}
    />
  </Box>
)

export const CustomRowNumberComponent = ({ t, addRows, isLoading, setSelectionToFirstCell }) => {
  const [value, setValue] = useState(1)
  const [rawValue, setRawValue] = useState(String(value))

  return (
    <Box
      p={1}
      display='flex'
      alignItems='center'
      alignSelf='flex-start'
      justifyContent='space-between'
      flexDirection={{ lg: 'row', md: 'row', sm: 'row', xs: 'column' }}
    >
      <Box display='flex' alignItems='center'>
        <BaseButton
          title='add'
          sx={{ mt: 0 }}
          variant='outlined'
          loading={isLoading}
          startIcon={<AddCircleIcon />}
          handleClick={() => {
            addRows(value)
            setSelectionToFirstCell()
          }}
        />
        <TextField
          type='number'
          value={rawValue}
          label={t('labels.count')}
          onBlur={() => setRawValue(String(value))}
          InputProps={{ inputProps: { min: MIN_ROWS, max: MAX_ROWS, inputMode: 'numeric', pattern: '[0-9]*' } }}
          onKeyPress={event => {
            if (R.equals(event.key, 'Enter')) addRows(value)
          }}
          onChange={event => {
            let targetValue = parseInt(H.getEventTargetValue(event), 10)

            if (R.gt(targetValue, MAX_ROWS)) targetValue = MAX_ROWS

            if (R.lt(targetValue, MIN_ROWS)) targetValue = MIN_ROWS

            setRawValue(R.or(targetValue, ''))
            setValue(Math.max(1, Math.round(R.or(parseInt(targetValue), 0))))
          }}
          sx={{
            ml: 1,
            minWidth: 60,
            maxWidth: 70,
            '.MuiFormLabel-root': { fontSize: 13 },
            '& .MuiInputBase-input': { padding: 1 },
            '.MuiOutlinedInput-root': { width: '100%', fontSize: '12px', maxHeight: '30px' },
          }}
        />
        <Typography ml={1} variant='subtitle2'>{` ${ t('labels.rows')}`}</Typography>
      </Box>
    </Box>
  )
}

export const CustomSelectComponent = ({
  rowData,
  options,
  disabled,
  setRowData,
  stopEditing,
  noTranslation,
  onSelectChange,
  initialValueName,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(setInitialValue(R.or(options, []), rowData, initialValueName))

  useEffect(() => {
    if (R.and(H.notEquals(rowData, value), H.isNotObject(rowData))) {
      setValue(rowData)
    }
  }, [rowData]) // eslint-disable-line

  const handleChange = event => {
    const targetValue = H.getEventTargetValue(event)

    setValue(targetValue)
    setRowData(targetValue)
    onSelectChange && onSelectChange(targetValue)
    setTimeout(stopEditing, 0) // eslint-disable-line
  }

  const getSelectName = name => {
    if (R.or(H.isTrue(noTranslation), H.isNumber(name))) return name

    return H.capitalizeFirstStrCharacter(t(`labels.${name}`))
  }

  return (
    <Box width='95%' display='flex' alignSelf='center'>
      <FormControl fullWidth>
        <Select
          value={R.or(value, '')}
          id='demo-simple-select'
          onChange={handleChange}
          MenuProps={SelectMenuProps}
          sx={{
            fieldset: { border: 'none' },
            maxHeight: { sm: '30px', xs: '20px' },
            '.MuiOutlinedInput-input': {
              textAlign: 'center',
              textTransform: 'none',
              color: t => t.palette.text.primary,
              backgroundColor: t => t.colors.secondaryTableRow,
            },
          }}
        >
          {
            R.map(
              ({ label, value }) =>
                <MenuItem
                  key={value}
                  value={value}
                  disabled={disabled}
                  sx={{
                    fontSize: 14,
                    textTransform: 'none',
                    whiteSpace: 'break-spaces',
                    wordBreak: 'break-word !important',
                  }}
                >
                  {getSelectName(label)}
                </MenuItem>,
              options,
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

export const CustomSearchableSelectComponent = ({
  rowData,
  options,
  disabled,
  setRowData,
  noTranslation,
  initialValueName,
}) => {
  const { t } = useTranslation()

  const [value, setValue] = useState(setInitialValue(R.or(options, []), rowData, initialValueName))

  useEffect(() => {
    if (R.and(H.notEquals(rowData, value), H.isNotObject(rowData))) {
      setValue(rowData)
    }
  }, [rowData]) // eslint-disable-line

  const handleInput = value => {
    setValue(value)
  }

  const handleSelect = ({ value }) => {
    setValue(value)
    setRowData(value)
  }

  const getSelectName = name => {
    if (R.or(H.isTrue(noTranslation), H.isNumber(name))) return name

    return H.capitalizeFirstStrCharacter(t(`labels.${name}`))
  }

  const filterOptions = (options, input) => R.filter(
    option => {
      const name = R.pathOr('', ['name'], option)
      const inputValue = R.pathOr('', ['inputValue'], input)

      if (R.and(H.isNilOrEmpty(name), H.isNilOrEmpty(inputValue))) return true

      return R.includes(
        R.toLower(inputValue), R.toLower(name),
      )
    },
    options,
  )

  return (
    <Box width='100%' height='35px'>
      <FormControl height='35px' fullWidth>
        <Autocomplete
          freeSolo
          size='small'
          forcePopupIcon
          disableClearable
          disabled={disabled}
          defaultValue={rowData}
          id='demo-simple-select'
          filterSelectedOptions={true}
          filterOptions={filterOptions}
          options={R.or(options, rowData)}
          getOptionLabel={option => R.pathOr('', ['name'], option)}
          onInputChange={(event, newInputValue) => handleInput(newInputValue)}
          onChange={(event, newValue, operation, option) => handleSelect(newValue, operation, option)}
          groupBy={option => {
            if (H.isNilOrEmpty(R.pathOr('', ['name'], option))) return

            return R.take(1, R.pathOr('', ['name'], option))
          }}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
          renderOption={(props, { name }) => (
            <ListItemText
              {...props}
              primaryTypographyProps={{
                color: t => t.palette.text.primary,
              }}
              sx={{
                fontSize: 14,
                height: '29px',
                textTransform: 'none',
                '& :hover': {
                  color: t => t.palette.primary.main,
                },
              }}
            >
              {getSelectName(name)}
            </ListItemText>
          )}
          sx={{
            '.MuiFormControl-root': {
              paddingTop: '3px',
            },
            fieldset: {
              borderWidth: '1px !important',
            },
            '.MuiAutocomplete-popupIndicator': {
              marginTop: '-4px',
            },
            '.MuiOutlinedInput-notchedOutline': {
              maxHeight: '35px',
            },
            input: {
              paddingTop: '0px !important',
              fontSize: '12px !important',
            },
          }}
        />
      </FormControl>
    </Box>
  )
}

export const CustomMultipleSelectComponent = ({
  rowData,
  options,
  disabled,
  maxLimit,
  setRowData,
  noTranslation,
  withEmptyOptionObject = false,

}) => {
  const { t } = useTranslation()
  const { spacing, palette } = useTheme()

  const newOptions = H.ifElse(
    withEmptyOptionObject,
    R.prepend(C.EMPTY_OPTION_OBJECT, options),
    options,
  )

  const transformedArr = arr => R.compose(
    R.uniq,
    R.map(item => {
      const option = R.find(R.propEq(C.FIELD_VALUE, item), options)

      const option2 = R.find(R.propEq(C.FIELD_VALUE, R.prop(C.FIELD_ID, item)), options)

      if (H.isNilOrEmpty(option)) return H.getValueFromObject(option2)

      return H.getValueFromObject(option)
    }),
  )(R.or(arr, []))

  let selected = transformedArr(rowData)

  useEffect(() => {
    if (R.and(H.notEquals(rowData, selected), H.isNotObject(rowData))) {
      // eslint-disable-next-line
      selected = rowData
    }
  }, [rowData]) // eslint-disable-line

  const renderValueFunc = (arr, pathName) => R.compose(
    R.join(', '),
    R.uniq,
    R.map(item => {
      const option = R.find(R.propEq(C.FIELD_VALUE, item), options)

      if (H.isNilOrEmpty(option)) return

      if (R.equals(pathName, C.FIELD_VALUE)) return H.getValueFromObject(option)

      return H.getNameFromObject(option)
    }),
  )(arr)

  const getSelectName = name => {
    if (R.isEmpty(name)) return

    if (R.or(H.isTrue(noTranslation), H.isNumber(name))) return name

    return H.capitalizeFirstStrCharacter(t(`labels.${name}`))
  }

  const isLengthsEquals = R.equals(R.length(selected), R.length(options))
  const isAllSelected = R.and(R.gt(R.length(options), 0), isLengthsEquals)

  const handleChange = (event, child) => {
    const targetValue = H.getEventTargetValue(event)
    const childValue = R.path(['props', 'value'], child)

    if (R.and(R.equals('all', childValue), H.isTrue(isAllSelected))) {
      return setRowData([])
    }

    if (R.and(R.equals('all', childValue), H.isFalse(isAllSelected))) {
      const allValues = R.map(R.prop(C.FIELD_VALUE), options)

      return setRowData(allValues)
    }

    setRowData(targetValue)
  }

  return (
    <Box width='95%' height={30} display='flex' alignSelf='center'>
      <FormControl
        style={{
          width: 200,
          margin: spacing(0),
        }}
      >
        <Select
          multiple
          value={selected}
          onChange={handleChange}
          MenuProps={SelectMenuProps}
          renderValue={renderValueFunc}
          sx={{
            maxHeight: '29px',
            fieldset: { border: 'none' },
            '.MuiOutlinedInput-input': {
              padding: 1,
              width: '80%',
              textTransform: 'none',
              backgroundColor: palette.background.lightGrey,
            },
          }}
        >
          <MenuItem
            value='all'
            disabled={H.isNotNilAndNotEmpty(maxLimit)}
            sx={{
              height: '29px',
              ':root': isAllSelected
                ? {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                }
                : '',
            }}
          >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                style={{ indeterminate: { color: palette.primary.main } }}
                indeterminate={
                  selected?.length > 0 && selected?.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              primary={t('labels.selectAll')}
              style={{ primary: { fontWeight: 500 } }}
            />
          </MenuItem>
          {
            R.map(
              option => {
                const optionName = H.getNameFromObject(option)
                const optionValue = H.getValueFromObject(option)

                const checked = R.indexOf(optionValue, selected) > -1

                const isItemDisabled = H.isTrue(
                  R.and(
                    R.gte(R.length(selected), maxLimit),
                    R.not(R.includes(optionValue, selected)),
                  ),
                )

                return (
                  <MenuItem
                    key={optionValue}
                    value={optionValue}
                    disabled={R.or(disabled, isItemDisabled)}
                    sx={{ fontSize: 14, display: 'flex', alignItems: 'start' }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked}
                        disabled={isItemDisabled}
                        sx={{
                          '&.Mui-disabled': {
                            pointerEvents: 'auto',
                            cursor: 'not-allowed',
                            borderColor: t => `${t.palette.text.disabled} !important`,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                            '& [class*=MuiSvgIcon-root]': {
                              fill: t => t.palette.text.disabled,
                            },
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={getSelectName(optionName)}
                      sx={{
                        textTransform: 'none',
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-word !important',
                      }}
                    />
                  </MenuItem>
                )
              },
              newOptions,
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

export const CustomDatePickerComponent = props => {
  const { focus, rowData, disabled, setRowData, inputStyles, stopEditing } = props

  const width = R.pathOr('140px', ['width'], inputStyles)

  const ref = useRef()

  const handleChange = date => {
    const dateTimeString = H.stringifyAndParseJson(date)

    if (H.isNotNilAndNotEmpty(dateTimeString)) {
      const utcDate = H.transformDateToUTC(date)

      setRowData(utcDate)
      setTimeout(stopEditing, 0) // eslint-disable-line
    } else {
      if (H.isNotNaN(R.path(['$y'], date))) {
        setRowData(null)
      }
    }
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.select()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  return (
    <Box width='100%' height={30} display='flex' justifyContent='center'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <DatePicker
            inputRef={ref}
            value={rowData}
            disabled={disabled}
            onChange={handleChange}
            inputFormat={C.DEFAULT_DATE_FORMAT}
            renderInput={
              params =>
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={event => {
                    if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) stopEditing({ nextRow: false })
                  }}
                  sx={{
                    maxWidth: R.or(width, '140px'),
                    '.MuiButtonBase-root': {
                      padding: 0,
                      '&:hover': { background: 'none' },
                    },
                    '.Mui-disabled': {
                      color: t => `${t.palette.text.primary} !important`,
                      'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
                    },
                    '.MuiOutlinedInput-input': {
                      paddingY: 1,
                      paddingX: 1,
                      paddingRight: 0,
                      textAlign: 'center',
                    },
                    '.MuiOutlinedInput-root': {
                      fontSize: '13px',
                      maxHeight: '30px',
                      width: R.or(width, '140px'),
                    },
                    '.MuiSvgIcon-root': {
                      padding: 0,
                      fontSize: '18px',
                      color: t => disabled && `${t.palette.text.disabled} !important`,
                      '&:hover': { color: t => t.palette.background.transparentGrey },
                      '&.Mui-selected': { color: t => t.palette.background.transparentGrey },
                    },
                  }}
                />
            }
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  )
}

export const CustomDateTimePickerComponent = props => {
  const { focus, rowData, disabled, setRowData, stopEditing } = props

  const ref = useRef()
  const theme = useTheme()
  const isDarkTheme = H.isDarkTheme(theme)

  const handleChange = date => {
    const dateTimeString = H.stringifyAndParseJson(date)

    if (H.isNotNilAndNotEmpty(dateTimeString)) {
      setRowData(dateTimeString)
      setTimeout(stopEditing, 0) // eslint-disable-line
    }
  }

  useLayoutEffect(() => {
    if (focus) {
      ref.current?.select()
    } else {
      ref.current?.blur()
    }
  }, [focus])

  return (
    <Box
      height={30}
      width='100%'
      display='flex'
      justifyContent='center'
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <DateTimePicker
            ampm={false}
            inputRef={ref}
            value={rowData}
            disabled={disabled}
            onChange={handleChange}
            inputFormat={C.DEFAULT_DATE_TIME_FORMAT}
            renderInput={
              params =>
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  onKeyDown={event => {
                    if (R.propEq('keyCode', C.EVENT_KEY_CODE_TAB, event)) stopEditing({ nextRow: false })
                  }}
                  sx={{
                    maxWidth: '150px',
                    '.MuiButtonBase-root': {
                      padding: 0,
                      '&:hover': { background: 'none' },
                    },
                    '.Mui-disabled': {
                      color: t => `${t.palette.text.primary} !important`,
                      'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
                    },
                    '.MuiOutlinedInput-input': {
                      paddingY: 1,
                      paddingX: 1,
                      paddingRight: 0,
                    },
                    '.MuiOutlinedInput-root': {
                      width: '150px',
                      fontSize: '13px',
                      maxHeight: '30px',
                    },
                    '.MuiSvgIcon-root': {
                      padding: 0,
                      fontSize: '18px',
                      color: R.and(isDarkTheme, disabled)
                        ? t => t.colors.toolbarBackgroundColor
                        : t => t.palette.text.disabled,
                      '&:hover': { color: t => t.palette.background.transparentGrey },
                      '&.Mui-selected': { color: t => t.palette.background.transparentGrey },
                    },
                  }}
                />
            }
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  )
}

const getOriginDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return null

  const { id, city } = rowData

  return {
    id,
    label: city,
    suggestion: rowData,
  }
}

export const PlacesAutocomplete = ({ rowData, disabled, setRowData }) => {
  const {
    setValue,
    clearSuggestions,
    suggestions: { data },
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  const defaultValue = getOriginDefaultValue(rowData)

  const handleInput = value => {
    setValue(value)
  }

  const handleSelect = value => {
    const { suggestion: { description } } = value
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false)
    clearSuggestions()

    getGeocode({ address: description }).then(results => {
      const rowDataId = H.getIdFromObject(rowData)

      if (H.isNotNilAndNotEmpty(rowDataId)) {
        const newRowData = R.assoc('apiId', rowDataId, results[0])

        return setRowData(newRowData)
      }

      setRowData({ ...results[0], ...H.getGoogleResultFields(results[0]) })
    })
  }

  // TODO: check send place_id to API
  const options = data.map(suggestion => {
    const {
      structured_formatting: { main_text, secondary_text },
    } = suggestion

    const label = `${main_text} ${secondary_text}`

    return { label, suggestion }
  })

  return (
    <Box width='100%' height='38px'>
      <Autocomplete
        freeSolo
        disableClearable
        disabled={disabled}
        filterOptions={x => x}
        id='PlacesAutocomplete'
        options={R.or(options, [])}
        defaultValue={defaultValue}
        onChange={(event, newValue) => handleSelect(newValue)}
        onInputChange={(event, newInputValue) => handleInput(newInputValue)}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '.MuiInputBase-root': {
            padding: 0,
            fontSize: '14px',
            color: t => t.palette.text.primary,
          },
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            sx={{
              '.Mui-disabled': {
                'WebkitTextFillColor': t => `${t.palette.text.secondary} !important`,
              },
            }}
          />
        )}
      />
    </Box>
  )
}

const getDestinationDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return []

  return R.map(item => {
    const { id, city, state } = item

    return {
      id,
      suggestion: item,
      label: R.or(city, state),
    }
  }, rowData)
}

export const PlacesAutocompleteMultiple = ({ rowData, limitTags, setRowData }) => {
  const {
    setValue,
    clearSuggestions,
    suggestions: { data },
  } = usePlacesAutocomplete({
    // NOTE: use for scope filtering, google not support only states scope :/
    // requestOptions: {
    //   /* Define search scope here */
    //   types: ['(regions)'],
    // },
    debounce: 300,
  })

  const defaultValue = getDestinationDefaultValue(rowData)

  const handleInput = value => {
    setValue(value)
  }

  const handleSelect = (value, operation, option) => {
    clearSuggestions()

    if (R.equals(operation, 'removeOption')) {
      const optionId = H.getIdFromObject(option.option)
      const newRowData = R.reject(R.propEq(C.FIELD_ID, optionId), rowData)

      return setRowData(newRowData)
    }

    const selectedValue = R.last(value)
    const { suggestion: { description } } = selectedValue

    getGeocode({ address: description }).then(results => {
      const result = results[0]

      const newRowData = R.append(result, R.or(rowData, []))

      setRowData(newRowData)
    })
  }

  const options = data.map(suggestion => {
    const {
      structured_formatting: { main_text, secondary_text },
    } = suggestion

    const label = `${main_text} ${secondary_text}`

    return { label, suggestion }
  })

  return (
    <Box width='100%' height='38px'>
      <Autocomplete
        multiple
        freeSolo
        size='small'
        disableClearable
        filterOptions={x => x}
        options={R.or(options, [])}
        defaultValue={defaultValue}
        limitTags={R.or(limitTags, 1)}
        id='PlacesAutocompleteMultiple'
        onInputChange={(event, newInputValue) => handleInput(newInputValue)}
        onChange={(event, newValue, operation, option) => handleSelect(newValue, operation, option)}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '.MuiInputBase-root': {
            maxHeight: '40px',
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingTop: '4px !important',
            paddingLeft: '6px !important',
            paddingBottom: '0px !important',
          },
        }}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
    </Box>
  )
}

const getStateDestinationDefaultValue = rowData => {
  if (H.isNilOrEmpty(rowData)) return []

  return R.map(({ id, state, country }) => ({ country, label: state, value: state, id: R.or(id, state) }), rowData)
}

export const StatesAutocompleteMultiple = ({ rowData, disabled, limitTags, setRowData }) => {
  const data = useStates()

  const defaultValue = getStateDestinationDefaultValue(rowData)

  const usaOptions = R.map(
    ({ name: label, abbreviation: value }) => ({
      label,
      value,
      id: value,
      state: value,
      country: C.UNITED_STATES,
    }),
    R.or(data, []),
  )

  const caOptions = R.map(
    ({ name: label, abbreviation: value }) => ({
      label,
      value,
      id: value,
      state: value,
      country: C.CANADA,
    }),
    R.or(provinces, []),
  )

  const options = R.concat(usaOptions, caOptions)

  const filterOptions = (options, input) => R.filter(
    option =>
      R.includes(R.toLower(H.getPropFromObject('inputValue', input)), R.toLower(H.getPropFromObject('label', option))),
    options,
  )

  const selectedStates = R.map(({ value, state }) => R.or(state, value), R.or(rowData, []))

  const handleSelect = (value, operation, option) => {
    const selectedValue = R.last(value)

    if (R.equals(operation, 'removeOption')) {
      const optionId = H.getIdFromObject(option.option)
      const newRowData = R.reject(R.propEq(C.FIELD_ID, optionId), rowData)

      return setRowData(newRowData)
    }

    setRowData(R.append(selectedValue, R.or(rowData, [])))
  }

  return (
    <Box width='100%' height='38px'>
      <Autocomplete
        freeSolo
        multiple
        openOnFocus
        size='small'
        disableClearable
        options={options}
        disabled={disabled}
        disableCloseOnSelect
        filterSelectedOptions
        defaultValue={defaultValue}
        filterOptions={filterOptions}
        limitTags={R.or(limitTags, 2)}
        id='StatesAutocompleteMultiple'
        onInputChange={(event, newInputValue) => newInputValue}
        getOptionLabel={option => R.pathOr('', ['value'], option)}
        groupBy={option => R.take(1, R.pathOr('', ['value'], option))}
        onChange={(event, newValue, operation, option) => handleSelect(newValue, operation, option)}
        getOptionDisabled={option => R.includes(H.getPropFromObject('value', option), selectedStates)}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        renderOption={(props, { label, value }) => (
          <ListItemText
            {...props}
            primaryTypographyProps={{
              color: t => t.palette.text.primary,
            }}
            sx={{
              '& :hover': {
                color: t => t.palette.primary.main,
              },
            }}
          >
            {label}&nbsp;({value})
          </ListItemText>
        )}
        sx={{
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '.MuiInputBase-root': {
            maxHeight: '40px',
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        }}
      />
    </Box>
  )
}

export const CustomMultipleTextColumnComponent = ({ rowData }) => {
  if (H.isNotNilAndNotEmpty(rowData))
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          '.MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '.MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '.MuiTypography-root': {
            fontSize: 12,
            fontFamily: 'Roboto',
            color: t => t.palette.text.primary,
          },
        }}
      >
        <List sx={{ width: 'fit-content', blockSize: 'fit-content' }}>
          {
            rowData.map(item => (
              <ListItem key={item}>
                <ListItemText
                  primary={item}
                  sx={{
                    paddingX: 1,
                    paddingY: 0.5,
                    margin: '2px',
                    borderRadius: 10,
                    backgroundColor: t => t.palette.background.multiInputTextBg,
                  }}
                />
              </ListItem>
            ))
          }
        </List>
      </Box>
    )
}

// TODO: not use as have copy/paste issue,
// use import { createTextColumn } from 'react-datasheet-grid' for custom text component
export const CustomTextFieldComponent = props => {
  const { focus, active, rowData, setRowData } = props

  const ref = useRef(null)

  const handleChange = event => setRowData(H.getEventTargetValue(event))

  useLayoutEffect(() => {
    if (active) {
      ref.current?.focus()
    } else {
      ref.current?.blur()
    }
  }, [active])

  return (
    <Tooltip
      followCursor={true}
      title={R.or(rowData, '')}
      open={H.isAllTrue(H.isNotNilAndNotEmpty(rowData), H.isTrue(active), H.isFalse(focus))}
      sx={{
        width: '100%',
        '.MuiOutlinedInput-notchedOutline': { border: 'none !important' },
        '& .MuiInputBase-input': {
          paddingTop: '4px',
          textAlign: 'center',
          paddingBottom: '4px',
          textOverflow: 'ellipsis',
        },
        '& .MuiInputBase-root': {
          width: '100%',
          height: '12px',
          fontSize: '12px',
        },
      }}
    >
      <input
        ref={ref}
        className='dsg-input'
        value={R.or(rowData, '')}
        onChange={e => handleChange(e)}
      />
    </Tooltip>
  )
}

export const CustomTextColumn = ({ rowData }) => (
  <Box width='100%'>
    <Typography
      className='dsg-input'
      title={R.or(rowData, '')}
      sx={{
        fontSize: 13,
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        textTransform: 'none',
        textOverflow: 'ellipsis',
        color: t => t.palette.text.primary,
      }}
    >
      {R.or(rowData, '')}
    </Typography>
  </Box>
)

export const CustomSelectAllRowsTitleComponent = ({ handleSelectAllRowInputChange }) =>
  <Checkbox size='small' onChange={handleSelectAllRowInputChange} />

export const RowHeaderTitleComponent = ({ title }) => (
  <Translation>
    {t => <p title={t(`${ title }`)}>{t(`${ title }`)}</p>}
  </Translation>
)

export const ChipComponent = ({ icon, label, color, maxHeight, customColor, borderRadius, backgroundColor }) => (
  <Stack spacing={1} direction='row' justifyContent='center'>
    <Chip
      icon={icon}
      color={color}
      title={label}
      label={label}
      variant='outlined'
      sx={{
        cursor: 'text',
        border: 'none',
        backgroundColor,
        padding: '5px 0',
        textTransform: 'uppercase',
        maxHeight: R.or(maxHeight, 20),
        borderRadius: R.or(borderRadius, '16px'),
        '.MuiChip-label': { color: customColor },
        fontSize: { lg: 12, md: 12, sm: 10, xs: 10 },
      }}
    />
  </Stack>
)

export const CustomPaginationComponent = ({ t, page, setPage, countPage }) => {
  const handleChange = (event, value) => setPage(R.subtract(value, 1))

  return (
    <Box p={1} display='flex' flexWrap='wrap' alignItems='center' justifyContent='center'>
      <Typography ml={1} variant='subtitle2'>{t('labels.page')}</Typography>
      <Stack spacing={2}>
        <Pagination page={page} color='primary' count={countPage} onChange={handleChange} />
      </Stack>
    </Box>
  )
}

const StatusIcon = ({ status }) => {
  if (R.equals(status, C.SUBSCRIPTION_STATUSES.EXPIRED)) {
    return <EventBusyIcon sx={{ cursor: 'default', color: t => t.palette.background.orange }} />
  }

  if (R.equals(status, C.SUBSCRIPTION_STATUSES.ACTIVE)) {
    return <CheckCircleOutlineIcon sx={{ cursor: 'default', color: t => t.palette.background.green }} />
  }

  if (R.equals(status, C.SUBSCRIPTION_STATUSES.CANCELLED)) {
    return <HighlightOffIcon sx={{ cursor: 'default', color: t => t.palette.background.error }} />
  }

  return <SearchOffIcon sx={{ cursor: 'default', color: t => t.rgbaColors.blue192 }} />
}

export const StatusSubscriptionComponent = ({ rowData }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const isDarkTheme = H.isDarkTheme(theme)

  const status = R.pathOr('', [C.FIELD_STATUS], rowData)

  return (
    <Box pl={1} width={120} display='flex' alignItems='center'>
      <StatusIcon status={status} />
      <Typography
        sx={{
          ml: 1,
          fontSize: 14,
          textTransform: 'none',
          color: isDarkTheme ? t => t.palette.text.disabled : t => t.palette.text.primary,
        }}
      >
        {H.ifElse(H.isNilOrEmpty(status), t('labels.notExist'), t(`labels.${status}`))}
      </Typography>
    </Box>
  )
}

const TextFieldModal = ({ data, error, handleAction }) => {
  const { t } = useTranslation()
  const [note, setNote] = useState(data)

  const handleChangeNote = ({ target }) => setNote(target.value)

  const handleSave = () => handleAction(note)

  return (
    <Box pt={1}>
      <TextField
        rows={4}
        multiline
        value={note}
        label={t('labels.note')}
        onChange={handleChangeNote}
        inputProps={{ maxLength: 2000 }}
        error={R.and(error, H.isNilOrEmpty(note))}
        sx={{
          width: '100%',
          background: t => t.palette.background.menuListBg,
        }}
      />
      <Box width='100%' display='flex' justifyContent='end'>
        <BaseButton
          title='save'
          type='submit'
          handleClick={handleSave}
          startIcon={<CheckCircleOutlineIcon />}
          sx={{ mt: 2, ml: 0, mr: 0, minWidth: { xs: '80px', sm: '100px' } }}
        />
      </Box>
    </Box>
  )
}

export const FreeAccessComponent = ({ companyId, companiesData, fetchCompanies }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const companiesRequestBody = useSelector(makeSelectAdminPanelCompaniesRequestBody)

  const [trial, { isLoading }] = useTrialMutation()

  const submitToastrErrorMessage = 'pleaseEnterNote'

  const rowData = H.getObjectById(companyId, companiesData)

  const note = H.getPropFromObject(C.FIELD_NOTE, rowData)
  const campaignName = H.getPropFromObject(C.FIELD_NAME, rowData)
  const freeAccess = H.getPropFromObject(C.FIELD_FREE_ACCESS, rowData)
  const trialEmailLimit = H.getPropFromObject(C.FIELD_TRIAL_EMAIL_LIMIT, rowData)

  const title = H.ifElse(
    freeAccess,
    `${t('actions.removeFreeAccessFrom')} ${campaignName}`,
    `${t('actions.grantFreeAccessTo')} ${campaignName}`,
  )

  const handleOpenModal = () => {
    setOpen(true)
    setError(false)
  }

  const handleTrialCompany = async note => {
    const body = {
      note,
      companyId,
      trialEmailLimit,
      freeAccess: R.not(freeAccess),
    }

    try {
      if (H.isNilOrEmpty(note)) {
        setError(true)

        return H.showToastrMessageSimple('error', t(`errors.${submitToastrErrorMessage}`))
      }

      await trial(body)
        .unwrap()
        .then(() => {
          setOpen(false)
          fetchCompanies(companiesRequestBody)
        })
        .catch(({ data, status }) => H.getRequestAPIError({ data, status }))
    }
    catch (e) {
      H.getCatchError(e)
    }
  }

  return (
    <Box
      title={title}
      display='flex'
      alignItems='center'
      justifyContent='space-around'
    >
      <Checkbox color='secondary' checked={freeAccess} onClick={handleOpenModal} />
      <ModalComponent
        opened={open}
        setOpen={setOpen}
        modalTitle={title}
        isLoading={isLoading}
        onClick={() => setOpen(false)}
        component={<TextFieldModal data={note} error={error} handleAction={handleTrialCompany} />}
        additionalStyles={{ width: { xs: 300, sm: 400, md: 600 }, backgroundColor: 'background.default' }}
      />
    </Box>
  )
}

export const UpdateNoteComponent = ({ companyId, companiesData, fetchCompanies }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const companiesRequestBody = useSelector(makeSelectAdminPanelCompaniesRequestBody)

  const rowData = H.getObjectById(companyId, companiesData)
  const note = H.getPropFromObject(C.FIELD_NOTE, rowData)

  const [updateCompanyNote, { isLoading }] = useUpdateCompanyNoteMutation()

  const handleClick = () => setOpen(true)

  const handleUpdateNote = async note => {
    const body = { note, companyId }

    try {
      await updateCompanyNote(body)
        .unwrap()
        .then(() => {
          setOpen(false)
          fetchCompanies(companiesRequestBody)
        })
        .catch(({ data, status }) => H.getRequestAPIError({ data, status }))
    }
    catch (e) {
      H.getCatchError(e)
    }
  }

  return (
    <Box p={1} width='100%' height='38px' display='flex' justifyContent='space-between'>
      <Typography
        title={R.or(note, '')}
        sx={{
          fontSize: 13,
          maxWidth: '100%',
          overflow: 'hidden',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          textTransform: 'none',
          textOverflow: 'ellipsis',
          color: t =>
            t.palette.mode === 'light'
              ? t.palette.background.black
              : t.colors.whiteColorMain,
        }}
      >
        {note}
      </Typography>
      <CreateIcon
        cursor='pointer'
        color='secondary'
        onClick={handleClick}
        titleAccess={t('actions.edit')}
      />
      <ModalComponent
        opened={open}
        setOpen={setOpen}
        isLoading={isLoading}
        onClick={() => setOpen(false)}
        modalTitle={t('actions.editNote')}
        component={<TextFieldModal data={note} error={false} handleAction={handleUpdateNote} />}
        additionalStyles={{ width: { xs: 300, sm: 400, md: 600 }, backgroundColor: 'background.default' }}
      />
    </Box>
  )
}

const CustomMultiTextElements = ({ elements, visibleItems }) => (
  <>
    {
      elements.map((item, index) => (
        <Box
          key={index}
          title={item}
          sx={{
            px: 1,
            m: '4px',
            height: 20,
            fontSize: 13,
            textAlign: 'center',
            borderRadius: '16px',
            textTransform: 'none',
            justifyContent: 'center',
            color: t => t.palette.text.primary,
            display: R.lt(index, visibleItems) ? 'flex' : 'none',
            background: t => t.palette.background.multiInputTextBg,
          }}
        >
          {item}
        </Box>
      ))
    }
  </>
)

export const CustomMultiTextColumn = ({ field, rowData }) => {
  const containerRef = useRef(null)

  const { width } = useWindowSize()

  const [showItems, setShowItems] = useState(true)
  const [visibleItems, setVisibleItems] = useState(0)

  const elements = R.map(item => R.prop(field, item), R.or(rowData, []))

  useLayoutEffect(() => {
    const updateItems = () => {
      if (containerRef.current) {
        const container = containerRef.current
        const containerWidth = container.getBoundingClientRect().width

        let widthItem = 0
        let widthItems = 0
        const widthPopover = 40

        setVisibleItems(0)

        elements.some((__, index) => {
          const tempContainer = document.createElement('div') // eslint-disable-line

          tempContainer.style.left = '-9999px'
          tempContainer.style.font = '16px Arial'
          tempContainer.style.visibility = 'hidden'
          tempContainer.style.position = 'absolute'

          tempContainer.innerHTML = elements[index]

          document.body.appendChild(tempContainer) // eslint-disable-line

          widthItem = tempContainer.offsetWidth

          document.body.removeChild(tempContainer) // eslint-disable-line

          if (R.gt(R.subtract(containerWidth, widthPopover), R.add(widthItems, widthItem))) {
            widthItems = R.add(widthItem, widthItems)

            setVisibleItems(R.add(1, index))

            return false
          } else {
            return true
          }
        })

        setShowItems(R.gt(containerWidth, widthItem))
      }
    }

    updateItems()
  }, [width, elements])

  return (
    <Box width='100%' display='flex' ref={containerRef} alignItems='center' justifyContent='center'>
      {
        showItems && <CustomMultiTextElements elements={elements} visibleItems={visibleItems} />
      }
      {
        R.and(H.isNotNilAndNotEmpty(elements), R.lt(visibleItems, R.length(elements))) &&
          <MultiValuePopover
            count={R.subtract(R.length(elements), visibleItems)}
            multiValues={<CustomMultiTextElements elements={elements} visibleItems={R.length(elements)} />}
          />
      }
    </Box>
  )
}

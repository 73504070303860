import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

// TODO: check how to invalidate only one item or cache
// Providing errors to the cache

export const truckAPI = createApi({
  reducerPath: 'truckAPI',
  tagTypes: ['TruckList'],
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchTrucks: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.truck,
      }),
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'TruckList'),
    }),
    createTrucks: build.mutation({
      query: body => (
        {
          body,
          url: E.ENDPOINTS.truck,
          method: E.METHODS.POST,
        }
      ),
      transformResponse: response => response.elements,
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'TruckList'),
    }),
    getTruck: build.query({
      query: id => `${E.ENDPOINTS.truck}/${id}`,
      providesTags: (result, error, id) => [{ type: 'TruckList', id }],
    }),
    updateTruck: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.truck,
      }),
      async onCacheEntryAdded(entity, { dispatch, getCacheEntry, cacheDataLoaded }) {
        await cacheDataLoaded

        const data = R.pathOr({}, ['data'], getCacheEntry())

        const { id } = data

        if (H.isNotNilAndNotEmpty(id)) {
          dispatch(truckAPI.util.updateQueryData(
            'fetchTrucks',
            undefined,
            R.map(item => H.ifElse(R.propEq(C.FIELD_ID, id, item), data, item)),
          ))
        }
      },
    }),
    deleteTruck: build.mutation({
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.truck}/${id}`,
      }),
      async onCacheEntryAdded(id, { dispatch, cacheDataLoaded }) {
        await cacheDataLoaded

        if (H.isNotNilAndNotEmpty(id)) {
          dispatch(truckAPI.util.updateQueryData(
            'fetchTrucks',
            undefined,
            R.reject(R.propEq(C.FIELD_ID, id)),
          ))
        }
      },
    }),
    fetchAvailableTrucks: build.mutation({
      extraOptions: { useLoader: true },
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'TruckList'),
      query: ({ page, companyId, truckOriginLocations }) => ({
        method: E.METHODS.POST,
        url: E.ENDPOINTS.truckAvailable,
        body: {
          page,
          companyId,
          limit: 20,
          truckOriginLocations,
        },
      }),
    }),
    massUpdateTrucks: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.truckBulk,
      }),
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'TruckList'),
    }),
    massDeleteTrucks: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.DELETE,
        url: E.ENDPOINTS.truckBulk,
      }),
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'TruckList'),
    }),
  }),
})

export const {
  useGetTruckQuery,
  useFetchTrucksQuery,
  useDeleteTruckMutation,
  useUpdateTruckMutation,
  useCreateTrucksMutation,
  useMassUpdateTrucksMutation,
  useMassDeleteTrucksMutation,
  useFetchAvailableTrucksMutation,
} = truckAPI

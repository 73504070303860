import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import { METHODS, ENDPOINTS } from '../utilities/endpoints'
//////////////////////////////////////////////////

export const companyPaymentAPI = createApi({
  baseQuery: H.baseQueryWithReAuth,
  reducerPath: 'companyPaymentAPI',
  tagTypes: ['CompanyPaymentList'],
  endpoints: build => ({
    createCompanyPayment: build.mutation({
      extraOptions: { useLoader: true },
      query: entity => ({
        method: METHODS.POST,
        url: ENDPOINTS.companyPayment,
        body: R.dissoc('callback', entity),
        async onCacheEntryAdded({ callback }, { cacheDataLoaded }) {
          await cacheDataLoaded

          if (H.isFunction(callback)) callback()
        },
      }),
    }),
  }),
})

export const { useGetCompanyPaymentPageMutation } = companyPaymentAPI

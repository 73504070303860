import * as R from 'ramda'
import { useSelector } from 'react-redux'
// material
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// helpers
import * as H from '../helpers'
// components
import { OpenTourButton } from './buttons'
// features auth
import { makeSelectAuthStore, makeSelectIsAdminToken } from '../features/auth/authSlice'
//////////////////////////////////////////////////

export const ListTitle = props => {
  const { text, openTourButtonSettings = [] } = props

  const auth = useSelector(makeSelectAuthStore)
  const isAdminToken = useSelector(makeSelectIsAdminToken)
  const isAdmin = H.isAdmin(H.getPropFromObject('role', auth))
  const isAdminPredicate = R.and(isAdmin, H.isFalse(isAdminToken))

  return (
    <Box
      display='flex'
      flexWrap='wrap'
      flexDirection='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Typography
        fontWeight={600}
        color='text.primary'
        fontSize={{ xl: 24, lg: 22, xs: 20 }}
      >
        {text}
      </Typography>
      {
        H.isFalse(isAdminPredicate) && H.isNotNilAndNotEmpty(openTourButtonSettings) &&
        <OpenTourButton buttonSettings={openTourButtonSettings} />
      }
    </Box>
  )
}

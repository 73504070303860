export default {
  actions: {
    yes: 'Так',
    map: 'Карта',
    menu: 'Меню',
    next: 'Далі',
    back: 'Назад',
    add: 'Додати',
    other: 'Інше',
    login: 'Увійти',
    search: 'Пошук',
    show: 'Показати',
    save: 'Зберегти',
    close: 'Закрити',
    reset: 'Скинути',
    signIn: 'Увійти',
    import: 'Імпорт',
    export: 'Експорт',
    send: 'Надіслати',
    update: 'Оновити',
    hide: 'Приховати',
    payment: 'Оплата',
    goTo: 'Перейти до',
    create: 'Створити',
    edit: 'Редагувати',
    delete: 'Видалити',
    refresh: 'Оновити',
    cancel: 'Скасувати',
    preview: 'Перегляд',
    tutorial: 'Навчання',
    complete: 'Завершити',
    confirm: 'Підтвердити',
    skipTour: 'Пропустити',
    reserve: 'Резервувати',
    toCall: 'Зателефонувати',
    logo: 'Логотип компанії',
    subscribe: 'Підписатися',
    subscription: 'Підписка',
    massActions: 'Масові дії',
    goMap: 'Перейти до карти',
    signUp: 'Зареєструватися',
    updateDates: 'Оновити дати',
    selectDate: 'Виберіть дату',
    smtpUpdate: 'Оновлення SMTP',
    addTruck: 'Додати вантажівку',
    massDelete: 'Масове видалення',
    addGroup: 'Створити нову групу',
    editNote: 'Редагувати примітку',
    rememberMe: 'Запам\'ятати мене',
    contactUs: 'Зв\'язатися з нами',
    cancelledDate: 'Дата скасування',
    updateProfile: 'Оновити Профіль',
    confirmEmail: 'Підтвердити пошту',
    editProfile: 'Редагувати профіль',
    smtpAuthorize: 'SMTP Авторизація',
    listOfTrucks: 'Cписок вантажівок',
    write: 'Написати повідомлення до',
    unsubscribe: 'Скасувати підписку',
    editCompany: 'Редагувати компанію',
    uploadIcon: 'Завантажити картинку',
    paymentHistory: 'Історія платежів',
    addSubscription: 'Додати підписку',
    uploadAvatar: 'Завантажити аватар',
    addYourLogo: 'Додайте свій логотип',
    authorize: 'Авторизуватись з Google',
    emailList: 'Список електронних пошт',
    openTourSteps: 'Відкрити кроки туру',
    forgotPassword: 'Забули свій пароль?',
    addNewTruck: 'Додати нову вантажівку',
    emailGroups: 'Групи електронної пошти',
    switchCompanyTo: 'Змінити компанію на',
    termsAndConditions: 'Правила та умови',
    downloadTemplate: 'Завантажити шаблон',
    selectDriverType: 'Виберіть тип водія',
    accountSettings: 'Налаштування акаунта',
    reserveTrucks: 'Резервувати вантажівки',
    clickToVisit: 'Натисніть, щоб відвідати',
    resumeSubscription: 'Відновити підписку',
    uploadCompanyLogo: 'Завантажити логотип',
    viewFullText: 'Переглянути повний текст',
    cancelSubscription: 'Скасувати підписку',
    uploadExcelFile: 'Завантажити файл Excel',
    selectTrailerType: 'Виберіть тип трейлера',
    selectTruckType: 'Виберіть тип вантажівки',
    viewInvoice: 'Переглянути рахунок-фактуру',
    manuallyCampaign: 'Додати кампанію вручну',
    editSocials: 'Редагувати соціальні мережі',
    privacyPolicy: 'Політика конфіденційності',
    previewReservation: 'Перегляд резервування',
    downloadExcelFile: 'Завантажити файл Excel',
    massAddToGroup: 'Масове додавання до групи',
    addNewEmail: 'Додати нову електронну адресу',
    addNewMailGroup: 'Додати нову поштову групу',
    runFullTour: 'Запустити повний тур сторінки',
    subscriptionAndPayments: 'Підписка та оплата',
    scheduleNewCampaign: 'Розклад нової кампанії',
    addNewManuallyCampaign: 'Нова кампанія вручну',
    grantFreeAccessTo: 'Надати безкоштовний доступ',
    playVideoTutorial: 'Відтворити відеоінструкцію',
    addDestinationZone: 'Створити зону призначення',
    newDestinationZone: 'Створити зону призначення',
    goToEmailGroups: 'Перейти до груп електронної пошти',
    addNewDestinationZone: 'Додати нову зону призначення',
    removeFreeAccessFrom: 'Забрати безкоштовний доступ в',
    confirmInformationDelete: 'Підтвердження видалення рядка',
    addNewEmailTemplate: 'Додати новий шаблон електронної пошти',
    goToProfilePage: 'Перейти на сторінку профілю та підписатися',
    confirmDeleteAuthorization: 'Підтвердження видалити авторизацію',
    // TODO: check title
    submit: 'Надіслати',
  },
  placeholders: {
    search: 'Пошук',
    password: 'Пароль',
    loading: 'Завантаження',
    groupName: 'Назва групи',
    email: 'Адреса електронної пошти',
    typeToSearch: 'Bведіть для пошуку',
    enterPhoneNumber: 'Введіть номер телефону',
  },
  templatePlaceholders: {
    title: 'Позиція',
    mobile: 'Телефон',
    firstName: 'Ім\'я',
    body: 'Тіло листа',
    website: 'Веб-сайт',
    title2: 'Заголовок',
    lastName: 'Прізвище',
    address1: 'Адреса 1',
    address2: 'Адреса 2',
    email: 'Електронна пошта',
    phone: 'Номер телефону компанії',
    userAvatarUrl: 'URL-адреса аватара',
    farewell: 'Підпис електронної пошти',
    myCompanyChannel: 'Канал моєї компанії',
    MyCompanyTwitter: 'Twitter моєї компанії',
    MyCompanyFacebook: 'Facebook моєї компанії',
    MyCompanyLinkedin: 'Linkedin моєї компанії',
    MyCompanyInstagram: 'Instagram моєї компанії',
    companyLogoUrl: 'URL-адреса логотипу компанії',
    phoneExtension: 'Додатковий номер телефону компанії',
  },
  errors: {
    error: 'Помилка',
    success: 'Успіх',
    warning: 'УВАГА',
    exportFail: 'Помилка експорту',
    serverError: 'Помилка серверу',
    fieldIsNotValid: 'Поле недійсне',
    exportSuccess: 'Успіх експорту',
    shouldBeNumber: 'Має бути число',
    unknownError: 'Невідома помилка',
    pleaseEnterA: 'Будь ласка, введіть',
    downloadFail: 'Помилка завантаження',
    pleaseEnterAn: 'Будь ласка, введіть',
    pleaseSelectA: 'Будь ласка, виберіть',
    noFileUploaded: 'Файл не завантажено!',
    downloadSuccess: 'Завантажено успішно',
    canNotBeEmpty: 'не має бути порожнім.',
    pleaseSelectAn: 'Будь ласка, виберіть',
    validationError: 'Помилка підтвердження',
    sessionHasExpired: 'Ваш сеанс закінчився',
    noDataFoundInFile: 'Немає даних у файлі!',
    passwordMustMatch: 'Пароль має співпадати',
    fileSizeIsTooBig: ' розмір файлу завеликий!',
    weSorryToSeeYouGo: 'Нам шкода, що ви йдете!',
    shouldBeDateType: 'Має бути дійсний тип дати',
    cannotBeDateBefore: 'Не може бути дата раніше',
    shouldBeLessOrEqual: 'Має бути менше або рівно',
    loadedFileWith: 'Завантажено файл за допомогою',
    pleaseEnterNote: 'Будь ласка, введіть примітку',
    fieldIsRequired: 'Це поле не може бути порожнім',
    enterValidUrlAddress: 'Введіть дійсну URL адресу',
    yourBrowser: 'Ваш браузер не підтримує тег відео.',
    fieldLengthShouldBeFrom: 'Довжина поля має бути від',
    sorryYouHaveNoAccess: 'Вибачте, у вас немає доступу',
    subscriptionExpired: 'Термін дії підписки закінчився',
    maxSizeImage: 'Максимальний розмір має бути менше 5 Мб.',
    shouldBePhoneNumber: 'Має бути значення номера телефону',
    fileMustBeSmallerThan2MB: 'Файл має бути меншим за 2 МБ!',
    extensionIsNotSupported: 'Це розширення не підтримується!',
    enterValidEmail: 'Введіть дійсну адресу електронної пошти',
    pleaseSelectLocationWithState: 'Виберіть локацію зі штатом',
    textConfirmSubscribe: 'Ви впевнені, що хочете підписатися?',
    pleaseSelectAnyTruck: 'Виберіть вантажівку та повторіть спробу',
    wrongUsernameOrPassword: 'Неправильна електронна адреса або пароль',
    youCanOnlyUploadExcelFile: 'Ви можете завантажити лише файл Excel!',
    textConfirmUnSubscribe: 'Ви впевнені, що хочете скасувати підписку?',
    pleaseEnterRequiredInfo: 'Будь ласка, введіть необхідну(*) інформацію.',
    shouldBeSpecialSymbol: 'Пароль повинен містити 1 і більше спеціальних символів',
    noLeadingAndTrailingSpaces: 'Поле не може містити пробіли на початку та в кінці',
    confirmMassDelete: 'Ви впевнені, що бажаєте видалити всю інформацію для цих елементів?',
    confirmDelete: 'Ви впевнені, що бажаєте видалити всю інформацію для цього запису елемента?',
    pleaseSelectAnyEmail: 'Будь ласка, виберіть будь-яку адресу електронної пошти, щоб продовжити',
    addSubscription: 'Додати підписку - Ви повинні додати підписку; дані картки та виберіть план.',
    ifVerificationOccurred: 'Якщо перевірка відбулася без прапорців, програма не зможе надсилати електронні листи!',
    chooseWhetherYouWant: 'Виберіть, чи хочете ви використовувати Gmail, SMTP/Outlook або обидва як службу розсилки.',
    conformationDeleteEmailAuthorization: 'Ви впевнені, що хочете видалити цю авторизацію з цією електронною поштою??',
    noPermission: `${'Введена адреса електронної пошти або пароль неправильні.'}
     ${' Будь ласка, ще раз перевірте свою реєстраційну інформацію та повторіть спробу'}`,
    pleaseCheckSpam: `${'Будь ласка, перевірте папку небажаної пошти/спаму на наявність пропущених повідомлень'}
     ${' або новин із MyTruckList.'}`,
    myTruckListIsNotResponsible: `${'MyTruckList не несе відповідальності за те, чи повідомлення,'}
     ${' які надіслані через нашу платформу, успішно доставлені одержувачам.'}`,
    sizeAvatar: `${'Для коректного відображення аватару,'}
     ${' оберіть зображення максимальні розміри якого висота - 90, ширина - 90.'}`,
    sizeCompanyLogo: `${'Для коректного відображення логотипу компанії,'}
     ${' оберіть зображення максимальні розміри якого висота - 50, ширина - 300.'}`,
    shouldBeCorrectPassword: `${'Пароль має містити принаймні шість символів, принаймні одна літера,'}
     ${' одна цифра та один спеціальний символ'}`,
    provideFeedback: `${'Provide Feedback: Ми цінуємо ваш внесок і будемо раді почути про ваш досвід.'}
     ${' Якщо ми можемо щось покращити, поділіться з нами своїм відгуком.'}`,
    enterValidPassword: `${'Мінімум 8 символів (суміш літер і цифр), 1 верхній регістр і 1 спеціальний'}
     ${' Символ, наприклад, (@ $ ! % * # ? ^ &)'}`,
    useTheFormatPlaceholders: `${'Використовуйте формат: ${FirstName}, ${LastName} та/або ${CompanyName}'}
    ${' будь-де в темі, тексті та/або підписі. MyTruckList автоматично заповнить відповідну інформацію з ваших'}
    ${' списків/груп електронної пошти. Перевірте це за допомогою кнопки попереднього перегляду нижче!'}`,
    pleaseSelectAllCheckboxes: `${'Коли My Truck List запитує доступ до вашого цифрового запису Google,'}
     ${' позначте всі непозначені поля та підтвердьте доступ.'}`,
    ifYouStillLike: `${'Якщо ви все ще хочете скасувати, продовжте, натиснувши кнопку нижче. Ми цінуємо'}
     ${' ваша підтримка та сподіваємося на те, що ми знову будемо служити вам у майбутньому.'}`,
    fieldCanContainAnyCharacters: `${'Поле може містити будь-які символи, але якщо воно містить інтерполяцію,'}
     ${' тоді дозволені лише значення FirstName, LastName і CompanyName.'}`,
    cancelSubscription: `${'Скасувати підписку: якщо у вас виникли запитання або потрібна додаткова допомога,'}
     ${' будь ласка, не соромтеся звернутися до нашої служби підтримки клієнтів.'}`,
    beforeYouCancel: `${'Перед тим, як скасовувати підписку, будь ласка, приділіть хвилинку і врахуйте наступне'}
     ${' options:'}`,
    weSorryToInform: `${'На жаль, повідомляємо, що термін дії картки, пов’язаної з вашим обліковим записом,'}
     ${' закінчився. В порядку щоб продовжувати користуватися нашими послугами без будь-яких перерв, ми просимо вас'}
     ${' оновити платіж інформація з дійсною карткою.'}`,
    placeholdersPersonalizeYourEmails: `${'Персоналізуйте свої електронні листи, додавши ім’я, прізвище та/або'}
     ${' назву компанії своїх клієнтів.'}`,
    authorizeGmail: `${' Authorize - авторизацію Gmail потрібно авторизувати (якщо ви вибрали Gmail як свою поштову'}
     ${' розсилку) або програма не може надсилати електронні листи.'}`,
    authorizeSMTP: `${'SMTP Authorize - авторизацію SMTP/Outlook потрібно авторизувати (якщо ви вибрали SMTP/Outlook'}
     ${' як ваша поштова служба) або програма не може надсилати електронні листи.'}`,
    ifYouAreUsingAnSMTPOutlookIntegration: `${'Якщо ви використовуєте інтеграцію SMTP/Outlook, ви погоджуєтеся'}
     ${' прийняти відповідальність за моніторинг обмежень надсилання електронної пошти. MyTruckList.app не несе'}
     ${' відповідальності, якщо ви отримаєте заблоковано/у чорному списку ваших серверів електронної пошти через'}
     ${' великий обсяг надсилання.'}`,
    reminderVerificationEmailAddresses: `${'Нагадування: надсилайте електронні листи лише на перевірені адреси'}
     ${' електронної пошти та на електронні листи, які погоджуються отримувати від вас контакт. Це гарантує, що'}
     ${' ваша скринька електронної пошти не потрапить у чорний список/заблокована. Надсилаючи електронні листи,'}
     ${' ви погоджуєтесь, що MyTruckList.com не несе відповідальності, якщо вас занесуть у чорний список/забанять.'}`,
    cancelSubscriptionNoRefund: `${'Натискаючи «Скасувати підписку», ви погоджуєтеся та розумієте,'}
     ${' що всі витрати MyTruckList Inc. не повертаються'}`,
  },
  warnings: {
    subscription: 'Щоб отримати повний доступ до всіх функцій MyTruckList, просто натисніть кнопку «Додати підписку»!',
    mailingConfigs: `${'Щоб почати надсилати електронні листи, увійдіть у свій профіль і виконайте кроки,'}
     ${' щоб налаштувати та авторизувати доступ до електронної пошти.'}`,
  },
  errorCodes: {
    'myTruckList.error.entity.notFound': 'Неможлива обробка',
    'myTrackList.validation.truck.limit': 'Перевищено ліміт вантажівок',
    'myTruckList.error.entity.serverError': 'Внутрішня помилка сервера',
    'myTruckList.validation.image.empty': 'Будь ласка, додайте зображення',
    'mytrucklist.validation.emailGroup.limit': 'Перевищено ліміт емейл груп',
    'myTruckList.validation.email.exist': 'Поточна електронна адреса вже існує',
    'mytrucklist.stripe.subscription': 'Ваша транзакція не може бути оброблена',
    'myTruckList.validation.image.incorrectType': 'Неправильний тип зображення',
    'mytrucklist.validation.email.limit': 'Перевищено ліміт електронних листів',
    'mytrucklist.validation.mailing.paymentPeriodExpired': 'Період платежу минув',
    'myTruckList.validation.emailGroup.exist': 'Група електронної пошти вже існує',
    'myTruckList.validation.destinationZone.limit': 'Перевищено ліміт зон призначення',
    'myTruckList.validation.destinationZone.exist': 'Поточна зона призначення вже існує',
    'mytrucklist.validation.companyTemplate.limit': 'Перевищено ліміт шаблонів компанії',
    'mytrucklist.validation.mailingConfig.limit': 'Перевищено ліміт конфігурації розсилки',
    'mytrucklist.subscription.noActiveSubscription': 'Підписка неактивна або закінчилася.',
    'myTruckList.validation.user.exist': 'Користувач з такою електронною поштою вже існує',
    'mytrucklist.validation.mailingConfig.exists': 'Поточна конфігурація розсилки вже існує',
    'myTruckList.validation.email.notUnique': 'Адреса електронної пошти має бути унікальною',
    'mytrucklist.validation.companyPayment.amount.invalid': 'Сума платежу компанії недійсна',
    'myTruckList.validation.mailingJob.limit': 'Перевищено ліміт кампаній електронною поштою',
    'myTruckList.validation.destinationZone.inUse': 'Поточна зона призначення використовується',
    'myTruckList.access.token.expire': 'Термін дії маркера доступу закінчився або він недійсний',
    'myTruckList.validation.truck.truckNumber.notUnique': 'Номер вантажівки має бути унікальним',
    'myTruckList.validation.emailGroup.inUse': 'Поточна група електронної пошти використовується',
    'myTruckList.validation.truck.truckId.notUnique': 'Ідентифікатор вантажівки має бути унікальним',
    'mytrucklist.validation.template.placeholder.notUnique': 'Заповнювач шаблону має бути унікальним',
    'mytrucklist.validation.mailingSmtpConfig.host.required': 'Будь ласка, введіть хост конфігурації smtp',
    'mytrucklist.validation.mailingSmtpConfig.port.required': 'Будь ласка, введіть порт конфігурації smtp',
    'mytrucklist.validation.companyTemplate.inUse': 'Цей шаблон зараз використовується і не може бути видалений',
    'mytrucklist.validation.destination': 'Зона призначення або призначення вантажівки не повинні бути порожніми',
    'mytrucklist.validation.companyTemplate.placeholder.notUnique': 'Заповнювач шаблону компанії має бути унікальним',
    'myTruckList.validation.truck.emptyDestinationZoneAndDestinationLocations': 'Будь ласка, виберіть штати або зону',
    'myTruckList.validation.signUp.exist.mcNumberAndDotNumber.empty':
      'Будь ласка, виберіть MC номер або DOT номер',
    'myTruckList.validation.mailingJob.groupIdsOrEmailsIdsMustExist':
      'Будь ласка, виберіть групи або електронні адреси',
    'myTruckList.validation.company.exist.mcNumberAndDotNumber.empty':
      'Будь ласка, виберіть MC номер або DOT номер',
    'mytrucklist.validation.mailingSmtpConfig.securityProtocol.required':
      'Будь ласка, виберіть протокол безпеки конфігурації smtp',
    'mytrucklist.validation.companyTemplate.placeholder.notAllRequired':
      'Будь ласка, додайте всі необхідні заповнювачі шаблонів',
    'mytrucklist.validation.mailing.emptyEmailIdsAndGroupIds':
      'Будь ласка, виберіть ідентифікатори електронної пошти та ідентифікатори груп',
    'mytrucklist.validation.mailingConfig.inUse':
      'Неможливо видалити конфігурацію розсилки, конфігурація розсилки використовується',
    'myTruckList.validation.mailingJob.startDateAfterEndDate':
      'Дата початку кампанії електронною поштою пізніша за дату завершення кампанії електронною поштою',
    'myTruckList.validation.gmail': `${'Ця авторизація більше не активна або була відхилена постачальником.'}
      ${' Видаліть її та створіть нову авторизацію електронної пошти Google або SMTP.'}`,
    'mytrucklist.validation.mailingSmtpConfig.connectionError':
      `${'Помилка підключення: облікові дані користувача'}\n${'неправильні або обліковий запис заблоковано.'}`,
    'mytrucklist.validation.mailing.subscription.expired': `${'Платіж для цієї компанії прострочено.'}
      ${' Щоб забезпечити постійний доступ до MyTruckList, перегляньте свою платіжну інформацію на сторінці профілю.'}`,
    'mytrucklist.validation.mailingConfig.error': `${'Виникла проблема авторизації. Будь ласка, перевірте свій'}
      ${' авторизація.'}`,
    'mytrucklist.validation.companyTemplate.placeholder.value.invalid': `${'Тільки інтерполяційні заповнювачі для'}
      ${' ${FirstName}, ${LastName} та ${CompanyName}, заповнені без пробілів і камелкейсом, дозволені.'}`,
    'mytrucklist.validation.email.empty': `${'Адреси електронної пошти не вибрано або скасовано підписку. Будь ласка,'}
      ${' перевірте свою електронну пошту(ти)/підписку і повторіть спробу.'}`,
  },
  labels: {
    id: 'id',
    no: 'Ні',
    to: 'до',
    and: 'та',
    all: 'Усі',
    yes: 'Так',
    from: 'від',
    days: 'Дні',
    type: 'Тип',
    host: 'Хост',
    port: 'Порт',
    map: 'Карта',
    new: 'Новий',
    name: 'Ім\'я',
    form: 'Форма',
    rows: 'рядки',
    other: 'Інше',
    none: 'Нічого',
    size: 'Розмір',
    group: 'Група',
    amount: 'Сума',
    login: 'Логін',
    tab: 'Вкладка',
    male: 'Чоловік',
    female: 'Жінка',
    hours: 'Години',
    gender: 'Стать',
    logOut: 'Вийти',
    locales: 'Мови',
    groups: 'Групи',
    trial: 'Пробні',
    note: 'Примітка',
    main: 'Основний',
    page: 'Сторінка',
    button: 'Кнопка',
    mcNumber: 'MC #',
    status: 'Статус',
    canada: 'Канада',
    count: 'рахунок',
    phone: 'Телефон',
    section: 'Розділ',
    country: 'Країна',
    ext: 'Розширення',
    active: 'Активна',
    dotNumber: 'DOT #',
    attention: 'Увага',
    profile: 'Профіль',
    firstName: 'Ім\'я',
    info: 'Інформація',
    clients: 'Клієнти',
    password: 'Пароль',
    contact: 'Контакт',
    delete: 'Видалити',
    thatIsIt: 'Це все!',
    company: 'Компанія',
    description: 'Опис',
    position: 'Позиція',
    address1: 'Адреса1',
    address2: 'Адреса2',
    payments: 'Платежі',
    signature: 'Підпис',
    expired: 'Завершена',
    users: 'Користувачі',
    lastName: 'Прізвище',
    trucks: 'Вантажівки',
    noGroup: 'Без групи',
    social: 'Соціальний',
    notExist: 'Не існує',
    noSubject: 'Без теми',
    provider: 'Провайдер',
    characters: 'символи',
    listOfGroups: 'Групи',
    searchBy: 'Шукати за',
    subject: 'Тема листа',
    approved: 'Погоджено',
    cancelled: 'Скасована',
    completed: 'Завершено',
    reserve: 'Резервувати',
    sortBy: 'Сортувати за',
    driverType: 'Тип водія',
    fullName: 'Повне ім\'я',
    contractor: 'Підрядник',
    selectAll: 'Вибрати все',
    employee: 'Співробітник',
    darkMode: 'Темний режим',
    timeZone: 'Часовий пояс',
    subscribed: 'Підписався',
    subscription: 'Підписка',
    createdBy: 'Ким створено',
    signUp: 'Зареєструватися',
    locations: 'Розташування',
    oilChange: 'Заміна масла',
    tiresChange: 'Заміна шин',
    email: 'Електронна пошта',
    startDate: 'Дата початку',
    middleName: 'По батькові',
    driverName: 'Ім\'я водія',
    updatedBy: 'Ким оновлено',
    reserved: 'Зарезервований',
    memberSince: 'Підписано з',
    cardNumber: 'Номер Картки',
    myTruckList: 'MyTruckList',
    endDate: 'Дата завершення',
    activeUntil: 'Активний до',
    emails: 'Електронні Листи',
    paymentDate: 'Дата Оплати',
    heavyHaul: 'Важкий вантаж',
    lightMode: 'Світлий режим',
    invoiceNumber: 'Рахунок #',
    requestDate: 'Дата запиту',
    truckType: 'Тип вантажівки',
    trailerType: 'Тип трейлера',
    garbageCan: 'Сміттєвий бак',
    learnHowTo: 'Дізнайтеся, як',
    alreadySent: 'Вже надіслано',
    freeAccess: 'Вільний доступ',
    updatedDate: 'Дата оновлення',
    checkList: 'Перевірка Списку',
    emptyPage: 'Порожня сторінка',
    mailingLog: 'Журнал розсилки',
    createdDate: 'Дата створення',
    companyName: 'Назва компанії',
    availableDate: 'Дата доступу',
    creditCard: 'Кредитна картка',
    longHaul: 'Далекі перевезення',
    campaignName: 'Назва кампанії',
    changeLanguage: 'Змінити мову',
    mailingService: 'Емейл Сервіс',
    useAvatar: 'Показувати аватар',
    contactName: 'Контактне ім\'я',
    companyLogo: 'Логотип компанії',
    truckNumber: 'Номер вантажівки',
    trailerNumber: 'Номер трейлера',
    phoneNumber: 'Телефонний номер',
    localHaul: 'Місцевий транспорт',
    rememberMe: 'Запам\'ятати мене',
    equipmentType: 'Тип обладнання',
    toEmails: 'На електронні пошти',
    nameOfTemplate: 'Назва шаблону',
    pickUpDate: 'Дата відправлення',
    listOfMails: 'Список адресатів',
    unitedStates: 'Сполучені Штати',
    notSubscribed: 'Немає підписки',
    noRefunds: 'Немає відшкодувань',
    notActiveFrom: 'Не активний від',
    confirmAction: 'Підтвердити дію',
    searchLocation: 'Знайти локацію',
    fromEmail: 'З електронної пошти',
    ownerOperator: 'Власник Оператор',
    pricingPlan: 'План ціноутворення',
    listOfTrucks: 'Список вантажівок',
    emailCampaigns: 'Компанії Поштою',
    haveAccount: 'Є обліковий запис?',
    mailingServices: 'Поштові служби',
    signIn: 'Увійти до My Truck List',
    attentionNeeded: 'Зверніть увагу',
    companyTemplate: 'Шаблон Компанії',
    unsubscribed: 'Скасовано підписку',
    addSubscription: 'Додати підписку',
    listOfCompanies: 'Список компаній',
    socialNetworks: 'Соціальні мережі',
    truckId: 'Ідентифікатор вантажівки',
    originLocations: 'Місця походження',
    locationPin: 'Pin-код розташування',
    destinationZone: 'Зона призначення',
    securityProtocol: 'Протокол захисту',
    expirationDate: 'Термін Придатності',
    paymentDetails: 'Платіжні реквізити',
    destinationZones: 'Зони призначення',
    supportedFiles: 'Підтримувані файли',
    dontHaveAccount: 'Не зареєстровані?',
    confirmPassword: 'Підтвердити пароль',
    forgotPassword: 'Забули свій пароль?',
    truckIds: 'Ідентифікатори вантажівок',
    clickSubmit: 'Натисніть "Надіслати".',
    numberOfTrucks: 'Кількість вантажівок',
    subscriptionDetails: 'Деталі підписки',
    originOfTruck: 'Походження вантажівки',
    specialtyHaul: 'Спеціальний транспорт',
    emailGroups: 'Групи електронної пошти',
    inputInfoOptional: 'Вхідна інформація',
    originLocationCity: 'Місто походження',
    originLocationState: 'Штат походження',
    desireDestination: 'Бажане призначення',
    cardholderName: 'Ім\'я власника картки',
    selectMassActions: 'Оберіть масову дію',
    campaignDateTime: 'Дата / час кампанії',
    destinationOfTruck: 'Місце призначення',
    emailAddress: 'Адреса електронної пошти',
    truckLocation: 'Розташування вантажівки',
    haveYouChangedYourMind: 'Ви передумали?',
    listOfMailGroups: 'Список поштових груп',
    enterNewPassword: 'Введіть новий пароль',
    personalInfo: 'Ваша особиста інформація',
    trialEmailLeft: 'Залишок пробних листів',
    confirmSubscribe: 'Підтвердити підписку',
    typeYourNote: 'Введіть свою примітку...',
    emailPreview: 'Попередній перегляд пошти',
    emailTemplate: 'Шаблон електронної пошти',
    reservedUnits: 'Зарезервовані вантажівки',
    nextBillingDate: 'Дата наступного платежу',
    emailAddresses: 'Адреси електронної пошти',
    listOfMailsGroup: 'Список адресатів/Групи',
    originLocationCountry: 'Країна походження',
    useCompanyLogo: 'Показувати лого компанії',
    selectPricingPlan: 'Виберіть тарифний план',
    clientInformation: 'Інформація про клієнта',
    companyInfo: 'Інформація про вашу компанію',
    reservationRequests: 'Запити на бронювання',
    listOfMailsIsEmpty: 'Список листів порожній',
    emailTemplates: 'Шаблони електронних листів',
    mailingLogIsEmpty: 'Журнал розсилки порожній',
    companyPhoneNumber: 'Номер телефону компанії',
    yourPasswordChanged: 'Ваш пароль було змінено',
    subscriptionWasSuccessful: 'Підписка успішна!',
    inputTheGroupName: 'Введіть назву нової групи',
    doNotHaveAccount: 'Не маєте облікового запису?',
    authorizeEmail: 'Авторизувати електронну пошту',
    authorizationInfo: 'Інформація про авторизацію',
    contactPhoneNumber: 'Контактний номер телефону',
    originOfTruckCity: 'Походження вантажівки Місто',
    originOfTruckState: 'Походження вантажівки Штат',
    adminPanel: 'Інформаційна панель адміністратора',
    listOfDestinationZones: 'Список зон призначення',
    listOfTrucksIsEmpty: 'Список вантажівок порожній',
    yourCampaignName: 'Описова назва вашої кампанії.',
    wouldYouPreferToSubscribe: 'Бажаєте підписатися?',
    paymentHistoryIsEmpty: 'Історія платежів порожня',
    desiredDestinationCity: 'Бажане призначення Місто',
    desiredDestinationState: 'Бажане призначення Штат',
    listOfCompanyTemplates: 'Список шаблонів компанії',
    signUpWasSuccessful: 'Реєстрація пройшла успішно!',
    reserveRequestsList: 'Список запитів на бронювання',
    chooseDate: 'Виберіть дату запуску вашої кампанії.',
    questions: 'Питання? Зв\'яжіться з нами за адресою',
    emailTemplateName: 'Назва шаблону електронної пошти',
    listOfAvailableTrucks: 'Список доступних вантажівок',
    attachLogos: 'За бажанням додайте логотипи/аватарки.',
    performConfirmation: 'Щоб виконати цю дію, натисніть',
    originOfTruckCountry: 'Походження вантажівки Держава',
    nameOfCompanyTemplate: 'Назва шаблону вашої компанії',
    originOfTruckLatitude: 'Походження вантажівки Широта',
    editProfileInfo: 'Редагувати інформацію свого профілю',
    officialPartner: 'Офіційні партнери Дізнайтеся більше',
    confirmUnSubscribe: 'Підтвердити відмову від підписки',
    genericMaintenance: 'Загальне технічне обслуговування',
    desireDestinationCountry: 'Бажане призначення Держава',
    desireDestinationLatitude: 'Бажане призначення Широта',
    changeNameOfYourCompany: 'Змінити назву вашої компанії',
    originOfTruckLongitude: 'Походження вантажівки Довгота',
    trialEmailLimit: 'Ліміт пробних електронних повідомлень',
    desireDestinationLongitude: 'Бажане призначення Довгота',
    availableTimeForPickUp: 'Доступний час для завантаження',
    listOfMailGroupsIsEmpty: 'Список поштових груп порожній',
    versionOfTheTemplate: 'Версія шаблону електронної пошти',
    editCompanyInfo: 'Відредагувати інформацію про компанію',
    selectTypeOfTrailer: 'Виберіть тип доступного трейлера.',
    trialEmailsLeft: 'Залишилося пробних електронних листів',
    contactEmailAddress: 'Контактна адреса електронної пошти',
    availableDatesForPickUp: 'Доступні дати для завантаження',
    yourCampaignCreatedDate: 'Дата створення вашої кампанії.',
    wouldYouPreferToUnsubscribe: 'Бажаєте скасувати підписку?',
    manageSubscription: 'Керуйте своєю підпискою та платежами',
    selectPlanAndEnter: 'Виберіть план і введіть дані картки.',
    availableTrucksDetails: 'Відомості про доступні вантажівки',
    clickAddSubscription: 'Натисніть кнопку "Додати підписку".',
    optionalSocialMedia: 'Додатково: додайте соціальні мережі.',
    companyPhoneNumberExt: 'Додатковий номер телефону компанії',
    changeNameOfCompanyTemplate: 'Змініть назву шаблону компанії',
    listOfCampaignIsEmpty: 'Список електронних кампаній порожній',
    googleAuthorizeWasSuccessful: 'Авторизація з Google успішна!',
    limitUpdateWasSuccessful: 'Оновлення ліміту виконано успішно!',
    manageYourCompanyTemplates: 'Керуйте шаблонами вашої компанії',
    dailyEmailLimit: 'Щоденний ліміт надсилання електронної пошти',
    byClickingSign: 'Натиснувши "Увійти", ви погоджуєтеся з нашими',
    desiredTruckDestination: 'Пункт призначення вантажівки бажання',
    listOfDestinationZonesIsEmpty: 'Список зон призначення порожній',
    listOfCompanyTemplatesIsEmpty: 'Список шаблонів компанії порожній',
    trucksReservationWasSuccessful: 'Резервування вантажівок успішне!',
    yourCampaignDescription: 'Опис результатів подання вашої кампанії.',
    listOfAvailableTrucksIsEmpty: 'Список доступних вантажівок порожній',
    gooGoogleAuthorization: 'Авторизація Google: дотримуйтесь інструкцій.',
    enterNameOfThePerson: 'Введіть ім\'я особи, яка керуватиме вантажівкою',
    yourCampaignService: 'Поштова служба, з якої надсилається ваша кампанія.',
    enterPickupDate: 'Введіть дату, коли вашу вантажівку можна буде забрати.',
    listOfReservationRequestIsEmpty: 'Список запитів на резервування порожній',
    inputNumberOfTrucks: 'Введіть кількість вантажівок, які ви можете забрати.',
    specifyDate: 'Вкажіть дату, коли ви хочете припинити показ вашої кампанії.',
    companyEditClick: 'Компанія: натисніть "Редагувати компанію", введіть дані.',
    currentModeIs: 'Переключатися між темним і світлим режимом (на даний момент',
    indicateIfYouHaveSingleDriver: 'Укажіть, чи є у вас один водій або команда.',
    attentionNeededToAuthEmail: 'Зверніть увагу на авторизацію електронної пошти',
    step2ManualCampaign: 'Крок 2: Ручна кампанія: розпочніть свою кампанію зараз',
    emailsGroups: 'Це поле показує спосок емейлів які привязані до певної групи.',
    emailAuthorizationsInfo: 'Інформація про авторизацію вашої електронної пошти',
    yourCampaignStatus: 'Статус доставки вашої кампанії успішний або неуспішний.',
    clickCancelTemplate: 'Натисніть "Скасувати", щоб припинити створення шаблону.',
    selectEmail: 'Виберіть електронну пошту, з якої ви хочете надсилати кампанію.',
    clickCreateTemplate: 'Натисніть "Створити", щоб додати свій шаблон до списку.',
    inputNameForNewTemplate: 'Введіть ім\'я для вашого шаблону електронної пошти.',
    clickToRefreshCounter: 'Натисніть, щоб оновити щоденний ліміт надсилання пошти',
    selectTheEmail: 'Виберіть електронну пошту, з якої ви хочете надсилати кампанію.',
    smtpEmailAuthorizationSuccess: 'Авторизація електронної пошти SMTP пройшла успішно!',
    clickCreate: `${'Натисніть "Створити", щоб додати свою вантажівку до списку нижче.'}`,
    smtpAuthorization: 'Авторизація SMTP: Виберіть тип електронної пошти, введіть деталі.',
    choosePricePlan: 'Виберіть тарифний план: $27/місяць або $279/рік ($23/місяць щороку).',
    clickAuthorization: 'Додати/редагувати авторизації "G Authorize" або "SMTP Authorize".',
    clickSaveToEditYourInfo: 'Натисніть "Зберегти", щоб редагувати свою особисту інформацію',
    personalEditProfileClick: 'Особисті: натисніть "Редагувати профіль", додайте інформацію.',
    selectYourTimeZones: 'Виберіть часові пояси, щоб забезпечити своєчасний запуск кампанії.',
    clickSaveToEditYourCompany: 'Натисніть "Зберегти", щоб редагувати інформацію про компанію',
    addPlaceholdersOfCompanyTemplate: 'Додайте заповнювачі текстового вмісту шаблону компанії',
    yourCampaignProvider: 'Постачальник електронної пошти, з якого надсилається ваша кампанія.',
    refreshAuthorizeWasSuccessful: 'Процес оновлення електронної авторизації успішно завершено!',
    giveYourCampaignName: 'Дайте своїй кампанії описову назву, яка допоможе вам її відстежувати.',
    pleaseCheckYourEmailToConfirm: 'Будь ласка, перевірте свою електронну пошту для підтвердження',
    clickPreviewTemplate: 'Натисніть "Попередній перегляд", щоб побачити, як виглядає ваш шаблон.',
    inputFirstName: 'Введіть ім\'я сторони, яку MyTruckList автоматично надішле електронною поштою.',
    inputLastName: 'Введіть прізвище сторони, яку MyTruckList автоматично надішле електронною поштою.',
    changeContactPlaceholdersOfCompanyTemplate: 'Змініть заповнювачі вмісту контактів шаблону компанії',
    changeSignaturePlaceholdersOfCompanyTemplate: 'Змініть заповнювачі вмісту підпису шаблону компанії',
    thisLimitWillBeUsed: 'Цей ліміт використовуватиметься за замовчуванням для всіх нових користувачів.',
    inputUnique: 'Введіть унікальний ідентифікатор для вашої вантажівки, який полегшить її відстеження.',
    chooseOneOrMoreEmail: 'Виберіть одну або кілька адрес електронної пошти, щоб отримати вашу кампанію.',
    byClickingPaymentHistory: 'Натиснувши "Історія платежів", ви можете перевірити свою історію платежів',
    inputNameForNewZone: 'Введіть назву вашої нової зони, яка точно представляє місця, які вона охоплює.',
    clickCreateEmailCampaign: 'Натисніть "Створити", щоб додати свою електронну кампанію до списку нижче.',
    clickCreateEmail: 'Натисніть "Створити", щоб створити новий контакт електронної пошти до списку нижче.',
    chooseTypeOfTruck: 'Виберіть тип доступної вантажівки, включаючи місцеву, важку, далеку чи спеціальну.',
    inputMiddleName: 'Введіть по батькові сторони, яку MyTruckList автоматично надішле електронною поштою.',
    enterYourEmailAddress: 'Введіть свою електронну адресу, і ми надішлемо вам посилання для зміни пароля.',
    ifYouNeedToDelete: 'Якщо вам потрібно видалити всю інформацію для цього запису елемента, натисніть тут.',
    manualCampaignSuccessful: 'Запит на ручну кампанію успішно! Будь ласка, перевірте свій журнал розсилки!',
    inputEmail: 'Введіть адресу електронної пошти сторони. Сюди надсилатимуться автоматичні електронні листи.',
    removeTemplate: 'Якщо вам більше не потрібен шаблон, клацніть значок кошика, щоб видалити його зі списку.',
    inputPhoneNumber: 'Введіть номер телефону сторони, яку MyTruckList автоматично надішле електронною поштою.',
    indicateWhereTruckIs: 'Укажіть, куди прямує ваша вантажівка, вибравши або ввівши бажані пункти призначення.',
    thisIsTheNumber: ' Це кількість електронних листів, які ви можете використовувати протягом пробного періоду.',
    inputMCNumber: 'Введіть номер MC для диспетчерів. Це допоможе з відстеженням і дотриманням нормативних вимог.',
    clickHereToViewYourDestinationZones: 'Натисніть тут, щоб переглянути зони призначення та кількість вантажівок.',
    inputDOTNumber: 'Введіть номер DOT для диспетчерів. Це допоможе з відстеженням і дотриманням нормативних вимог.',
    enterCatchySubject: `${'Введіть помітну тему, щоб отримати повідомлення диспетчера'}
     ${' звернути увагу та повідомити їм про електронний лист.'}`,
    step2: `${'Крок 2: Створіть нову зону призначення для ваших доступних вантажівок.'}
     ${' Щоб створити нову зону призначення для ваших вантажівок, натисніть кнопку "Створити зону призначення".'}`,
    step3: `${'Крок 3: Перегляньте свої зони призначення та доступні номери вантажівок.'}
     ${' Щоб переглянути зони призначення та доступні номери вантажівок для кожної зони, натисніть кнопку "Карта".'}`,
    inputCompanyAddress1: `${'Введіть адресу1 вашої компанії сторони, яку MyTruckList буде'}
     ${' автоматично електронною поштою.'}`,
    inputSubscribed: `${'У цьому полі вказується, чи підписався цей користувач на розсилку електронних новин чи ні.'}
     ${' Ви можете змінити значення, натиснувши на кнопку праворуч.'}`,
    inputCompanyAddress2: `${'Введіть адресу вашої компанії2 сторони, яку MyTruckList буде'}
     ${' автоматично електронною поштою.'}`,
    clickAddToCreateGroup: `${'Натисніть "Додати", щоб створити нову групу. Після додавання,'}
     ${' ви зможете вибрати цю групу під час додавання або зміни електронних листів у своєму списку розсилки.'}`,
    paymentSection: `${'У цьому розділі ви можете легко заповнити інформацію про свої підписки'}
     ${' і платежі.'}`,
    selectDays: `${'Виберіть дні тижня, коли ви бажаєте, щоб ваша кампанія була розіслана, будь то'}
     ${' у будні або вихідні.'}`,
    inputLocationOfTruck: `${'Введіть місце розташування вашої вантажівки, включаючи адресу, місто,'}
     ${' штат або поштовий індекс.'}`,
    enterMainContent: `${'Включіть інформацію про шаблон вашої компанії, таку як тема, назва, текст'}
     ${' і підпис електронної пошти.'}`,
    inputPosition: `${'Введіть посаду особи, яку MyTruckList автоматично надішле електронною поштою.'}
     ${' Ця інформація допоможе вам краще націлювати електронні листи.'}`,
    enterTitle: `${'Введіть вступний заголовок для першого рядка в електронному листі або збережіть те,'}
     ${' що містить список MyTruckList.'}`,
    allThatIsLeft: `${'Все, що залишилося зробити, це натиснути кнопку "Зберегти", і все готово. Усі ваші'}
      ${' бронювання, інформація про клієнтів, статуси та примітки акуратно зібрані в одному місці!'}`,
    inputTheName: `${'Введіть назву організації, якій ви надсилатимете електронний лист, щоб переконатися,'}
     ${' що електронний лист досягне правильний одержувач.'}`,
    inputWebsiteUrl: `${'Введіть URL-адресу веб-сайту вашої компанії, якій MyTruckList автоматично надішле'}
     ${' електронний лист.'}`,
    clientInformationIsFound: `${'Інформація про клієнта знаходиться зліва. Для прямого контакту з клієнтом'}
     ${' за номером телефону та/або електронною поштою, ви можете просто натиснути відповідне поле.'}`,
    inputCompanyPhoneNumber: `${'Введіть номер телефону вашої компанії, якій MyTruckList автоматично надішле'}
     ${' електронний лист.'}`,
    ifYouHaveEmailAccount: `${'Якщо у вас є обліковий запис електронної пошти за межами Gmail, виберіть'}
     ${' "Авторизувати SMTP" і надайте необхідну пов’язану інформацію, перш ніж додати її до свого профілю. '}`,
    uploadYourCompanyLogo: `${'Завантажте логотип вашої компанії, який відображатиметься у верхньому лівому'}
     ${' куті панелі навігації.'}`,
    inputTwitterUrl: `${'Введіть URL-адресу вашої компанії в Twitter сторони, яку MyTruckList автоматично надішле'}
     ${' електронною поштою.'}`,
    inputCompanyPhoneNumberExt: `${'Введіть додатковий номер телефону вашої компанії сторони, яку MyTruckList буде'}
     ${' автоматично електронною поштою.'}`,
    inputFacebookUrl: `${'Введіть URL-адресу вашої компанії у Facebook сторони, яку MyTruckList автоматично надішле'}
     ${'електронною поштою.'}`,
    inputLinkedinUrl: `${'Введіть URL-адресу своєї компанії в Linkedin сторони, яку MyTruckList автоматично надішле'}
     ${' електронною поштою.'}`,
    inputInstagramUrl: `${'Введіть URL-адресу своєї компанії в Instagram сторони, яку MyTruckList автоматично надішле'}
     ${' електронною поштою.'}`,
    inputYoutubeUrl: `${'Введіть URL-адресу каналу Youtube вашої компанії сторони, яку MyTruckList автоматично надішле'}
     ${' електронною поштою.'}`,
    enterLocations: `${'Якщо ви введете місцезнаходження відправлення, це відфільтрує список вантажівок і надішле лише'}
     ${' вантажівки з відповідним місцезнаходженням.'}`,
    enterEmailSignature: `${'Закінчіть свою електронну пошту підписом електронної пошти або збережіть те,'}
     ${' що містить список MyTruckList.'}`,
    selectYourEmailTemplate: `${'Виберіть свій шаблон електронної пошти, який ви хочете використовувати у своїй'}
     ${' кампанії електронної пошти.'}`,
    reservationRequestsStep1: `${'Кожен рядок представляє окремий запит на бронювання, що містить життєво важливі'}
     ${' відомості про клієнта, як-от назва компанії та контактна інформація'}
     ${' (номер телефону або електронна адреса).'}`,
    enterInformationAboutEditCompany: `${'Натисніть «Редагувати компанію», щоб легко змінити назву компанії, MC & DOT'}
     ${' номери, номер телефону, адресу та часовий пояс. Тримайте цю інформацію оновленою, щоб гарантувати, що ваша'}
     ${' компанія інформація точна та синхронізована з вашими операціями.'}`,
    enterInformationAboutEditProfile: `${'Натисніть «Редагувати профіль» Натисніть «Редагувати профіль», щоб оновити'}
     ${' своє ім’я, посада та номер телефону. Лише кількома кліками миші ви можете переконатися, що інформація'}
     ${' вашого профілю актуальна і відображає вашу роль.'}`,
    enterInformationAboutEditSocialLinks: `${'Натисніть «Редагувати соціальні мережі», щоб легко оновити свій'}
     ${' веб-сайт, канал YouTube та інші посилання в соціальних мережах. Це гарантує збереження вашої інформації в'}
     ${' соціальних мережах актуальні та привабливі для вашої аудиторії».'}`,
    clickAddToSendYourCampaign: `${'Натисніть "Створити", щоб надіслати свою кампанію. Усе готово! MyTruckList'}
     ${' автоматично заповнює всю інформацію в електронному листі та надсилає його вибраним одержувачам.'}`,
    welcomeToTutorial: `${'Ласкаво просимо до цього покрокового посібника щодо функції автоматичної електронної'}
     ${' кампанії MyTruckList!'}`,
    selectTimeOfDay: `${'Виберіть час доби, коли ви хочете, щоб ваша кампанія була розіслана, беручи до уваги'}
     ${' часовий пояс і розпорядок дня вашої аудиторії.'}`,
    selectTrucksYouWant: `${'Оберіть вантажівки, які ви хочете включити до цієї електронної кампанії, зі'}
     ${' "Списку вантажівок" вкладки. Щоб змінити або створити новий шаблон, перейдіть до "Списку вантажівок".'}`,
    pleaseEnterYourNewPassword: `${'Будь ласка, введіть свій новий пароль двічі, щоб ми могли перевірити,'}
     ${' що ви ввели його правильно.'}`,
    enterInformationAboutYourself: `${'Введіть інформацію про себе та свою компанію, щоб допомогти MyTruckList'}
     ${' надіслати ваші електронні листи з легкістю.'}`,
    welcomeToProfile: `${'Ласкаво просимо на вкладку профілю. У цьому розділі ви можете легко заповнити інформацію'}
     ${' про себе, свою компанію, інформацію про авторизацію та платежі.'}`,
    clickAddToCreateDestinationZone: `${'Натисніть "Додати", щоб створити нову зону призначення. Після додавання,'}
     ${' ви зможете вибрати цю зону під час додавання або зміни вантажівок у вашому списку вантажівок.'}`,
    underReservedUnits: `${'У розділі "Зарезервовані одиниці" ви можете записати кількість зарезервованих одиниць'}
     ${' із загальної кількості номер, наданий клієнтом, який відображається в розділі "Кількість одиниць".'}`,
    byClickingAddSubscription: `${'Натиснувши "Додати/скасувати підписку", ви можете створювати підписки вручну та'}
     ${' змінити наявну інформацію про підписку'}`,
    toLearnThisStepAgain: `${'Щоб вивчити цей крок знову, натисніть кнопку "Відкрити кроки підручника" та виберіть'}
     ${' зі списку потрібний крок підручника'}`,
    enterSocials: `${'Введіть URL-адреси свого каналу соціальних мереж, щоб зв’язатися з одержувачами та допомогти'}
     ${' створити ви посилите присутність в Інтернеті.'}`,
    selectOneOrMoreGroups: `${'Виберіть одну або кілька груп зі свого списку розсилки. Якщо у вас немає створеної,'}
     ${' групи натисніть "Додати групу" у верхньому правому куті вкладки "Список розсилки", щоб створити нову групу.'}`,
    enterInformationAboutSocials: `${'Enter information about your company social networks to help MyTruckList send'}
     ${' ваші електронні листи з легкістю.'}`,
    clickEditYourTemplate: `${'Щоб редагувати будь-які існуючі шаблони у вашому списку, просто клацніть піктограму.'}
      ${' олівця Ви можете змінити будь-які деталі шаблону, щоб краще відповідати вашим потребам.'}`,
    clickTheButtonBelowToSubscribe: `${'Будь ласка, введіть свою електронну адресу, а потім натисніть кнопку нижче,'}
     ${' щоб підписатися на повідомлення'}`,
    enterSignatures: `${'Включіть вашу особисту інформацію, таку як ваше ім\'я, електронна адреса, номер(и) телефону'}
     ${' і URL-адресу логотипу компанії в розділі підпису, щоб ваші одержувачі могли легко зв’язатися з вами.'}`,
    chooseOneOrMoreLocations: `${'Виберіть одне або кілька місць, щоб створити зону призначення для своїх вантажівок'}
     ${', щоб подорожувати. Це може бути місто, штат або поштовий індекс, що допоможе вам краще керувати автопарком.'}`,
    previewReservation: `${'Щоб отримати доступ до інформації про конкретний запит на бронювання, просто натисніть на'}
      ${' "Попереднє бронювання."'}`,
    clickTheButtonBelowToUnsubscribe: `${'Будь ласка, введіть свою електронну адресу, а потім натисніть кнопку нижче,'}
     ${' щоб скасувати підписку на повідомлення'}`,
    unsubscriptionWasSuccessful: `${'Скасування підписки пройшло успішно! Ми повідомили відправника цього листа, що ви'}
     ${' не хочете отримувати більше електронних листів від них.'}`,
    step1: `${'Крок 1: Додайте нову вантажівку до свого списку. Щоб почати, натисніть кнопку "Додати нову вантажівку".'}
     ${' Це дозволить вам ввести важливу інформацію про доступні вантажівки.'}`,
    ifYouHaveDestinationZones: `${'Якщо ви визначили зони призначення, виберіть одну зі спадного меню.'}
     ${' Якщо ні, ви можете створити нову зону, натиснувши кнопку "Створити нову зону призначення" '}
     ${' у верхньому правому куті сторінки.'}`,
    myTruckListWillSend: `${'MyTruckList автоматично розішле вашу кампанію у вказану дату та час,'}
     ${' використовуючи всю інформацію, яку ви ввели. За допомогою цих простих кроків ви можете переконатися,'}
     ${' що ваші кампанії електронної пошти надсилаються ефективно та результативно.'}`,
    finallyOnTheRightHandSide: `${'Нарешті, праворуч є ще один розділ приміток для розділ бронювання,'}
     ${' який допоможе вам бути організованим протягом усього процесу бронювання. Натисніть на значок олівця'}
     ${' для перегляду текстового поля.'}`,
    onTheRightSide: `${'Праворуч є спеціальний розділ нотаток, де ви можете легко зберігати інформацію'}
     ${' про запит на бронювання. Не потрібно шукати ручку та папір, ваші нотатки завжди під рукою'}
     ${' fingertips, поруч із бронюванням.'}`,
    byClickAuthorize: `${'Натиснувши "G Авторизувати", просто зв’яжіть потрібний обліковий запис Gmail'}
     ${' MyTruckList для автоматичного надсилання електронних кампаній. Якщо ви бажаєте додати більше облікових'}
     ${' записів Gmail, повторити той самий процес.'}`,
    chooseEmailTemplate: `${'Виберіть шаблон електронної пошти, який найкраще підходить для вашої кампанії.'}
     ${' Щоб змінити або створити новий, перейдіть на вкладку "Шаблони електронної пошти",'}
     ${' щоб вибрати один із попередньо розроблених шаблонів MyTruckLists.'}`,
    selectEmailTemplate: `${'Виберіть шаблон електронної пошти, який найкраще підходить для вашої кампанії.'}
     ${' Щоб змінити або створити новий шаблон, перейдіть на вкладку "Шаблони електронної пошти",'}
     ${' щоб вибрати один із попередньо розроблених шаблонів MyTruckLists.'}`,
    welcomeDestinationZonesList: `${'Ласкаво просимо на вкладку "Список зон призначення". У цьому розділі ви'}
     ${' можете легко заповніть інформацію про вашу зону призначення. Щоб почати, натисніть кнопку'}
     ${' "Додати нову зону призначення"'}`,
    withTheseFeaturesManagingYourTruck: `${'За допомогою цих функцій, керування списком вантажівок і зонами'}
     ${' призначення буде легкою справою, що дозволить вам оптимізувати свою роботу та допомогти MyTruckList'}
     ${' легко надсилати електронні листи. '}`,
    enterBody: `${'Надайте будь-яку додаткову інформацію, яку ви хотіли б знати диспетчеру, або збережіть те, що'}
     ${' MyTruckList містить список. Не турбуйтеся про деталі вибраних вантажівок – MyTruckList буде '}
     ${' автоматично включити їх із вкладки "Список вантажівок".'}`,
    welcomeToEmailTemplates: `${'Ласкаво просимо на вкладку Шаблони електронної пошти. У цьому розділі ви можете'}
     ${' легко заповнити інформація про шаблони електронної пошти, щоб MyTruckList міг автоматично надсилати'}
     ${' електронні листи вашій аудиторії. Щоб розпочати, натисніть кнопку "Додати новий шаблон компанії".'}`,
    welcomeMailingLog: `${'Ласкаво просимо на вкладку "Список журналу розсилки". Легко знайдіть усі ваші попередні'}
     ${' кампанії електронною поштою, перейшовши на вкладку "Журнал електронної пошти" та визначивши статус доставки'}
     ${' як успішний або невдалий.'}`,
    welcomeEmailGroupsList: `${'Ласкаво просимо на вкладку Список груп електронної пошти. У цьому розділі ви можете'}
     ${' легко заповніть інформацію про ваші групи електронної пошти. Щоб почати, виберіть опцію "Додати нову групу'}
     ${' групу електронної пошти".'}`,
    step1Templates: `${'Крок 1: Щоб розпочати створення шаблонів електронної пошти, натисніть "Додати новий шаблон".'}
     ${' У вас буде можливість вибрати один із двох попередньо створених шаблонів, розроблених спеціально'}
     ${' для користувачів MyTruckList.'}`,
    chooseOneOrMoreGroups: `${'Виберіть одну або кілька груп зі свого списку розсилки, на які ви хочете націлити свою'}
     ${' кампанія. Якщо у вас немає створеної групи, натисніть "Створити нову групу" у верхньому правому куті вкладки'}
     ${' "Список розсилки", щоб створити нову групу.'}`,
    step1MailingList: `${'Крок 1: Додайте нові контакти електронної пошти до свого списку розсилки. Щоб почати'}
     ${' додавати нові контакти до свого, натисніть кнопку "Додати нову електронну адресу". Потім MyTruckList створить'}
     ${' і надішле ваш електронною поштою автоматично.'}`,
    welcomeToListOfTrucks: `${'Ласкаво просимо на вкладку "Список вантажівок". У цьому розділі ви можете легко'}
     ${' заповнити інформація про вашу вантажівку. Щоб почати, у вас є два варіанти: додати нову вантажівку та зону'}
     ${' призначення вашої вантажівки.'}`,
    inputGroups: `${'Виберіть одну або кілька груп, які MyTruckList надішле електронною поштою від організації.'}
     ${' Якщо у вас немає створеної групи, натисніть "Створити нову групу" у верхньому правому куті сторінки,'}
     ${' щоб створити нову групу.'}`,
    thisWillTakeYouToPage: `${'Це переведе вас на сторінку, де ви зможете переглянути всі свої зони призначення,'}
     ${' а також кількість вантажівок, доступних у кожній зоні. Ця інформація допоможе вам краще розподілити'}
     ${' ваші ресурси та переконайтеся, що у вас є потрібні вантажівки, коли і де вони потрібні.'}`,
    welcomeMailingList: `${'Ласкаво просимо на вкладку "Список розсилки". У цьому розділі ви можете легко заповнити'}
     ${' інформацію про вашу електронну адресу. Щоб почати, у вас є два варіанти: Додати нову електронну пошту та'}
     ${' Групу вашої електронної пошти'}`,
    byClickingSMTPAuthorize: `${'Натиснувши "Авторизувати SMTP", просто додайте потрібний Outlook або інший обліковий'}
     ${' запис SMTP ви хочете, щоб MyTruckList автоматично надсилав кампанії електронною поштою. Якщо ви бажаєте'}
     ${' додати більше SMTP облікових записів, повторіть той самий процес.'}`,
    welcomeToEmailCampaigns: `${'Ласкаво просимо на вкладку Кампанії електронною поштою.'}
     ${' У цьому розділі ви можете легко заповнити інформацію про кампанію електронною поштою,'}
     ${' щоб MyTruckList міг автоматично надсилати електронні листи вашій аудиторії.'}
     ${' Для початку у вас є два варіанти: запланувати нову кампанію або створити кампанію вручну.'}`,
    authorizeMailingServiceTour: `${'Якщо ви не налаштували ваш email це можна зробити в профілі. Щоб отримати доступ'}
     ${' до профілю, натисніть значок шестірні у верхньому правому куті на екрані та виберіть Профіль. Перейшовши на'}
     ${' екран профілю, ви повинні авторизувати електронну адресу, яку хочете використовувати.'}`,
    changeReservationRequestStatus: `${'У цьому розділі ви можете вибрати'}
     ${' статус кожного запиту, що дозволяє покращити організацію та відстеження. Ви можете позначити'}
     ${' позначте запит як "Виконується", якщо ви активно працюєте над забезпеченням бронювання, або позначте'}
     ${' це як "Завершено", коли ви підтвердите домовленості зі своїм клієнтом і будете готові до відправки.'}`,
    atTheBottomOfThePage: `${'Унизу сторінки ви знайдете доступні відомості про вантажівки, які клієнт'}
     ${' цікавить, включаючи дату доступності, кількість одиниць, типи вантажівок і причепів тощо.'}
     ${' Ці дані служать зручною довідкою під час зв\'язку з клієнтом щодо його запиту та, зрештою,'}
     ${' завершення бронювання.'}`,
    welcomeToReservationRequests: `${'Ласкаво просимо до посібника із запитів на бронювання MTL, тут усі ваші'}
     ${' запити на бронювання знайдуть свій дім. Цей інструмент дає вашим клієнтам можливість забронювати ваші'}
     ${' вантажівки, надаючи вам внутрішнє керування статусом і додаткову інформацію, щоб ваші вантажівки були'}
     ${' заброньовані. Давайте зануримося!'}`,
    step1EmailCampaigns: `${'Крок 1: якщо ви хочете запланувати кампанію на пізнішу дату, просто виберіть Розклад'}
     ${' Нова кампанія. Створюючи нову кампанію електронною поштою в MyTruckList, вам потрібно буде надати деякі'}
     ${' ключові дані деталі, щоб переконатися, що ваше повідомлення досягне потрібних людей у потрібний час.'}
     ${' Ось що вам потрібно буде ввести:'}`,
    ifYouLooking: `${'Якщо ви шукаєте простий і ефективний спосіб надсилати кампанії електронною поштою зі'}
     ${' списками вантажівок своїм диспетчерам, то ви в правильному місці. Виконуючи ці покрокові інструкції,'}
     ${' ви зможете створити та легко й ефективно запускати автоматичні кампанії електронною поштою, заощаджуючи ваш'}
     ${' час і зусилля. Отже, давайте розпочали й дізнайтеся, як MyTruckList може оптимізувати ваш процес електронного'}
     ${' маркетингу.'}`,
    step2MailingList: `${'Крок 2: Додайте групи до свого списку розсилки. Щоб створити нову групу до свого списку,'}
     ${' розсилки натисніть кнопку "Створити нову групу". Додавання нової групи дозволить MyTruckList краще націлювати'}
     ${' ваші електронні листи і допомогти переконатися, що вони охоплять потрібних людей.'}
     ${' Завдяки цим функціям керування вашим списком розсилки будьте простішим, ніж будь-коли,'}
     ${' дозволяючи вам ефективніше спілкуватися зі своїми контактами та спрощувати ваші операції.'}`,
  },
}

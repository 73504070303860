import * as R from 'ramda'
import { useRef, useEffect } from 'react'
// helpers
import { getRequestAPIError } from '../helpers'
//////////////////////////////////////////////////

const useToastrError = ({ error, isLoading }) => {
  const loadingRef = useRef(isLoading)

  useEffect(() => {
    const prevDataChangeLoading = loadingRef.current
    loadingRef.current = isLoading

    if (R.or(R.not(error), isLoading)) return

    if (R.equals(prevDataChangeLoading, isLoading)) return

    getRequestAPIError({
      data: error?.data,
      status: error?.status,
    })
  }, [isLoading, error])
}

export default useToastrError

import * as R from 'ramda'
import i18next from 'i18next'
// constants
import * as C from '../../../constants'
// features tour
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import {
  defaultStep,
  renderTitle,
  buttonTitle,
  getStateObj,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

const defaultEmailTemplatesRows = [
  {
    [C.FIELD_ID]: 1,
    [C.FIELD_NAME]: 'Template A',
    [C.FIELD_TEMPLATE_NAME]: 'Company Template v1',
  },
]

export const openEmailTemplatesTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
  {
    title: 'actions.addNewEmailTemplate',
    action: setStartedTourStep(TOUR_STEPS.STEP1),
  },
  {
    title: 'labels.manageYourCompanyTemplates',
    action: setStartedTourStep(TOUR_STEPS.STEP2),
  },
]

export const emailTemplatesTourPreviewSteps = [
  getDefaultFirstStepSettings('emailTemplates'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_EMAIL_TEMPLATES),
    content: renderTitle(i18next.t('labels.emailTemplates'), i18next.t('labels.welcomeToEmailTemplates')),
  },
]

export const emailTemplatesTourAddNewTemplateSteps = [
  {
    ...defaultStepsSettings,
    target: '#addNewEmailTemplate_button',
    content: renderContent(i18next.t('labels.step1Templates')),
    title: renderTitle(`${i18next.t('actions.addNewEmailTemplate')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    target: '#select_emailTemplate',
    title: renderTitle(i18next.t('labels.emailTemplates')),
    content: renderContent(i18next.t('labels.selectYourEmailTemplate')),
  },
  {
    ...defaultStepsSettings,
    target: '#name',
    title: renderTitle(i18next.t('labels.name')),
    content: renderContent(i18next.t('labels.inputNameForNewTemplate')),
  },
  {
    ...defaultStepsSettings,
    target: '#Main_section',
    content: renderContent(i18next.t('labels.enterMainContent')),
    title: renderTitle(`${i18next.t('labels.main')} ${i18next.t('labels.section')}`),
  },
  {
    ...defaultStepsSettings,
    target: '#subject_placeholder',
    title: renderTitle(i18next.t('labels.subject')),
    content: renderContent(i18next.t('labels.enterCatchySubject')),
  },
  {
    ...defaultStepsSettings,
    target: '#body_placeholder',
    content: renderContent(i18next.t('labels.enterBody')),
    title: renderTitle(i18next.t('templatePlaceholders.body')),
  },
  {
    ...defaultStepsSettings,
    target: '#farewell_placeholder',
    content: renderContent(i18next.t('labels.enterEmailSignature')),
    title: renderTitle(i18next.t('templatePlaceholders.farewell')),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#Signature_section',
    content: renderContent(i18next.t('labels.enterSignatures')),
    title: renderTitle(`${i18next.t('labels.signature')} ${i18next.t('labels.section')}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#Social_section',
    content: renderContent(i18next.t('labels.enterSocials')),
    title: renderTitle(`${i18next.t('labels.social')} ${i18next.t('labels.section')}`),
  },
  {
    ...defaultStepsSettings,
    target: '#Preview_button',
    content: renderContent(i18next.t('labels.clickPreviewTemplate')),
    title: renderTitle(`${i18next.t('actions.preview')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#Cancel_button',
    content: renderContent(i18next.t('labels.clickCancelTemplate')),
    title: renderTitle(`${i18next.t('actions.cancel')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#Create_button',
    content: renderContent(i18next.t('labels.clickCreateTemplate')),
    title: renderTitle(`${i18next.t('actions.create')} ${buttonTitle}`),
  },
]

export const emailTemplatesTourAddTemplateSteps = [
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.name')),
    content: renderContent(i18next.t('labels.nameOfCompanyTemplate')),
    target: '#datasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(2)',
  },
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.emailTemplate')),
    content: renderContent(i18next.t('labels.versionOfTheTemplate')),
    target: '#datasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    content: renderContent(i18next.t('labels.clickEditYourTemplate')),
    title: renderTitle(`${i18next.t('actions.edit')} ${buttonTitle}`),
    target: '#datasheet > div > div.dsg-container > div > div:nth-child(2) > div.dsg-cell.dsg-cell-sticky-right > div > svg:nth-child(1)', // eslint-disable-line
  },
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.garbageCan')),
    content: renderContent(i18next.t('labels.removeTemplate')),
    target: '#datasheet > div > div.dsg-container > div > div:nth-child(2) > div.dsg-cell.dsg-cell-sticky-right > div > svg:nth-child(2)', // eslint-disable-line
  },
]

export const emailTemplatesFullTourSteps = [
  ...emailTemplatesTourPreviewSteps,
  ...emailTemplatesTourAddNewTemplateSteps,
  ...emailTemplatesTourAddTemplateSteps,
]

export const handleEmailTemplatesTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenMap,
  setOpenModal,
  setTourModalId,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
  setTourHiddenObjects,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenMap(false))
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setOpenAsideMenu(false))
      dispatch(setStartedTourStep(null))
      dispatch(setDisableButtons(false))
      dispatch(setTourHiddenObjects([]))
      dispatch(setDisableScrolling(false))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.EMAIL_TEMPLATES,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    if (R.equals(index, 0)) {
      dispatch(setTourDefaultDataStep(defaultEmailTemplatesRows))

      setTimeout(() => { // eslint-disable-line
        setState({ run: true })
      }, 400)
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP1)) {
      if (R.equals(index, 0)) {
        dispatch(setTourModalId('selectFirstTemplate'))
        dispatch(setDisableButtons(true))
        dispatch(setDisableScrolling(true))

        setTimeout(() => { // eslint-disable-line
          setState({ run: true })
        }, 200)
      }

      if (R.equals(index, 2) && R.not(isPrevAction)) {
        dispatch(setTourModalId('addTemplate_main_section1'))
        dispatch(setTourHiddenObjects([
          'hideSocialGroup',
          'hideActionsBtns',
          'hideSignatureGroup',
          'hideAttentionNeeded',
          'hideEmailTemplateName',
          'hideAvatarLogoSwitchers',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 3) && isPrevAction) {
        dispatch(setTourHiddenObjects([
          'hideMainGroup',
          'hideSocialGroup',
          'hideSignatureGroup',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 4) && isPrevAction) {
        dispatch(setTourModalId('selectFirstTemplate'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 6) && R.not(isPrevAction)) {
        dispatch(setTourModalId('addTemplate_main_section2'))
        dispatch(setTourHiddenObjects([
          'hideMainGroup',
          'hideAttentionNeeded',
          'hideEmailTemplateName',
          'hideAvatarLogoSwitchers',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 7) && isPrevAction) {
        dispatch(setTourModalId('addTemplate_main_section1'))
        dispatch(setTourHiddenObjects([
          'hideSocialGroup',
          'hideActionsBtns',
          'hideSignatureGroup',
          'hideAttentionNeeded',
          'hideEmailTemplateName',
          'hideAvatarLogoSwitchers',
        ]))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 9) && R.not(isPrevAction)) {
        dispatch(setTourModalId('addTemplate_main_section2'))
        defaultStep(setState, nextStepIndex)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP2)) {
      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenMap(false))
        dispatch(setDisableButtons(true))
        defaultStep(setState, nextStepIndex)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
          dispatch(setDisableScrolling(true))
          dispatch(setTourHiddenObjects([
            'hideMainGroup',
            'hideSocialGroup',
            'hideSignatureGroup',
          ]))
        }, 400)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAsideMenu(true))
        dispatch(setDisableScrolling(true))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setOpenModal(true))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
        dispatch(setTourModalId('selectFirstTemplate'))
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
        dispatch(setTourModalId('selectFirstTemplate'))
      }

      if (R.equals(index, 4) && R.not(isPrevAction)) {
        dispatch(setTourHiddenObjects([
          'hideSocialGroup',
          'hideActionsBtns',
          'hideSignatureGroup',
          'hideAttentionNeeded',
          'hideEmailTemplateName',
          'hideAvatarLogoSwitchers',
        ]))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, nextStepIndex)
        }, 400)
      }

      if (R.equals(index, 5) && isPrevAction) {
        dispatch(setTourHiddenObjects([
          'hideMainGroup',
          'hideSocialGroup',
          'hideSignatureGroup',
        ]))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, nextStepIndex)
        }, 400)
      }

      if (R.equals(index, 7) && R.not(isPrevAction)) {
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 7) && isPrevAction) {
        dispatch(setTourModalId('addTemplate_main_section1'))
        dispatch(setTourHiddenObjects([
          'hideSocialGroup',
          'hideActionsBtns',
          'hideSignatureGroup',
          'hideAttentionNeeded',
          'hideEmailTemplateName',
          'hideAvatarLogoSwitchers',
        ]))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 8) && R.not(isPrevAction)) {
        dispatch(setTourHiddenObjects([
          'hideMainGroup',
          'hideAttentionNeeded',
          'hideEmailTemplateName',
          'hideAvatarLogoSwitchers',
        ]))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, nextStepIndex)
        }, 200)
      }

      if (R.equals(index, 8) && isPrevAction) {
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 9) && R.not(isPrevAction)) {
        dispatch(setTourModalId('addTemplate_main_section2'))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 9) && isPrevAction) {
        dispatch(setTourModalId('addTemplate_main_section2'))
        dispatch(setTourHiddenObjects([
          'hideSocialGroup',
          'hideSignatureGroup',
          'hideAttentionNeeded',
          'hideAvatarLogoSwitchers',
        ]))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, nextStepIndex)
        }, 100)
      }

      if (R.equals(index, 10) && isPrevAction) {
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 14)) {
        dispatch(setTourDefaultDataStep(defaultEmailTemplatesRows))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 18) && isPrevAction) {
        dispatch(setTourDefaultDataStep(defaultEmailTemplatesRows))
        defaultStep(setState, nextStepIndex)
      }

      defaultStep(setState, nextStepIndex, 400)
    }
  }

  consoleShowTourLogs(data, type, status)
}

export const renderEmailTemplatesTour = ({
  pathname,
  dispatch,
  setState,
  setOpenModal,
  setTourModalId,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
  setTourHiddenObjects,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_EMAIL_TEMPLATES)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenModal(true))
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      dispatch(setTourModalId('selectFirstTemplate'))
      setState(getStateObj(emailTemplatesFullTourSteps))
      dispatch(setTourDefaultDataStep(defaultEmailTemplatesRows))
      dispatch(setTourHiddenObjects([
        'hideMainGroup',
        'hideSocialGroup',
        'hideSignatureGroup',
      ]))
    }

    if (R.equals(TOUR_STEPS.STEP1, startedTourStep)) {
      dispatch(setOpenModal(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      dispatch(setTourModalId('selectFirstTemplate'))
      setState(getStateObj(emailTemplatesTourAddNewTemplateSteps))
      dispatch(setTourDefaultDataStep(defaultEmailTemplatesRows))
      dispatch(setTourHiddenObjects([
        'hideMainGroup',
        'hideSocialGroup',
        'hideSignatureGroup',
      ]))
    }

    if (R.equals(TOUR_STEPS.STEP2, startedTourStep)) {
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setDisableButtons(true))
      setState(getStateObj(emailTemplatesTourAddTemplateSteps))
      dispatch(setTourDefaultDataStep(defaultEmailTemplatesRows))
    }
  }
}

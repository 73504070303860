import { useTranslation } from 'react-i18next'
// material
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// icons-material
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'
// helpers
import * as H from '../helpers'
// components
import { BaseButton } from './buttons'
//////////////////////////////////////////////////

const BaseComponent = ({
  t,
  icon,
  title,
  subTitle,
  wrapperStyles,
  handleButtonTitle,
  onHandleButtonClick,
  handleButtonStartIcon,
}) => (
  <Box
    p={0}
    m={0}
    zIndex={1}
    display='flex'
    height='100vh'
    alignItems='center'
    justifySelf='center'
    justifyItems='center'
    justifyContent='center'
    backgroundColor='background.default'
    sx={{ ...wrapperStyles }}
  >
    <Box
      p={10}
      display='flex'
      minWidth='35%'
      flexWrap='wrap'
      alignItems='center'
      justifySelf='center'
      justifyItems='center'
      flexDirection='column'
      justifyContent='center'
      sx={{
        borderRadius: '8px',
        bgcolor: 'background.paper',
        mb: { lg: 0, md: 0, sm: 2, xs: 2 },
      }}
    >
      {
        icon
          ? icon
          : (
            <DraftsOutlinedIcon
              color='secondary'
              sx={{
                fontSize: 64,
                '&.MuiSvgIcon-root': {
                  cursor: 'default',
                  '&:hover': { color: t => `${t.palette.background.darkGrey} !important` },
                  '&.Mui-selected': { color: t => `${t.palette.background.darkGrey} !important` },
                },
              }}
            />
          )
      }
      <Typography
        mt={2}
        mb={2}
        fontWeight={500}
        variant='subtitle1'
        color='text.primary'
        fontSize={{ lg: 24, md: 24, sm: 18, xs: 18 }}
      >
        {title ? t(`labels.${title}`) : t('labels.confirmAction')}
      </Typography>
      <Typography
        mt={2}
        mb={6}
        fontWeight={500}
        variant='subtitle1'
        color='text.secondary'
        fontSize={{ lg: 16, md: 16, sm: 12, xs: 12 }}
      >
        {subTitle ? subTitle : t('labels.performConfirmation')}:
      </Typography>
      <BaseButton
        title={handleButtonTitle}
        handleClick={onHandleButtonClick}
        sx={{ width: '100%', m: '0 !important' }}
        startIcon={handleButtonStartIcon && handleButtonStartIcon}
      />
    </Box>
  </Box>
)

export const ConfirmActionComponent = ({
  icon,
  title,
  subTitle,
  isFullWidth,
  wrapperStyles,
  handleButtonTitle,
  onHandleButtonClick,
  handleButtonStartIcon,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {
        H.isTrue(isFullWidth) ? (
          <Box
            width='100vw'
            overflow='auto'
            minHeight='100vh'
            maxHeight='100vh'
            position='relative'
            backgroundColor='background.default'
            sx={{
              background: 'linear-gradient(180deg, #F2F5F9 45.8%, rgba(217, 216, 239, 0.5) 54.2%)',
            }}
          >
            <BaseComponent
              t={t}
              icon={icon}
              title={title}
              subTitle={subTitle}
              wrapperStyles={wrapperStyles}
              handleButtonTitle={handleButtonTitle}
              onHandleButtonClick={onHandleButtonClick}
              handleButtonStartIcon={handleButtonStartIcon}
            />
          </Box>
        ) : (
          <BaseComponent
            t={t}
            icon={icon}
            title={title}
            subTitle={subTitle}
            wrapperStyles={wrapperStyles}
            handleButtonTitle={handleButtonTitle}
            onHandleButtonClick={onHandleButtonClick}
            handleButtonStartIcon={handleButtonStartIcon}
          />
        )
      }
    </>
  )
}

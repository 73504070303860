import * as R from 'ramda'
import i18next from 'i18next'
import { equals } from 'ramda'
// material
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// helpers
import * as H from '../../helpers'
// components
import { BaseButton } from '../buttons'
// features tour
import { TOUR_PARTS, TOUR_EVENTS, TOUR_STATUS, TOUR_ACTIONS } from './constants'
import {
  setOpenMap,
  setOpenModal,
  setTourRoute,
  setOpenGroups,
  setTourModalId,
  setOpenAddMode,
  setOpenAsideMenu,
  setDisableButtons,
  setOpenAccountMenu,
  setStartedTourStep,
  setDisableScrolling,
  setTourHiddenObjects,
  setTourDefaultDataStep,
} from './tourSlice'
//////////////////////////////////////////////////

const defaultStyles = {
  lineHeight: 1.3,
  hyphens: 'auto',
  textAlign: 'left',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
}

export const cleanedTour = {
  [TOUR_PARTS.PROFILE]: true,
  [TOUR_PARTS.MAILING_LOG]: true,
  [TOUR_PARTS.MAILING_LIST]: true,
  [TOUR_PARTS.LIST_OF_TRUCKS]: true,
  [TOUR_PARTS.EMAIL_TEMPLATES]: true,
  [TOUR_PARTS.EMAIL_CAMPAIGNS]: true,
  [TOUR_PARTS.DESTINATION_ZONES]: true,
}

export const getRouteTarget = path => R.concat('#\\', path)

export const buttonTitle = R.toLower(i18next.t('labels.button'))

export const getIsPrevAction = action => R.equals(action, TOUR_ACTIONS.PREV)

export const getNextStepIndex = (index, action) =>
  R.add(index, (H.ifElse(R.equals(action, TOUR_ACTIONS.PREV), -1, 1)))

export const defaultStep0 = (index, setState) => {
  if (R.equals(index, 0)) {
    setTimeout(() => { // eslint-disable-line
      setState({ run: true })
    }, 400)
  }
}

export const defaultStep = (setState, nextStepIndex, timeout) => {
  setState({
    run: false,
    stepIndex: nextStepIndex,
  })

  setTimeout(() => { // eslint-disable-line
    setState(prevState => ({
      ...prevState,
      run: true,
    }))
  }, R.or(timeout, 400))
}

export const getStateObj = steps => R.assoc(
  'steps',
  R.concat(steps, R.of(defaultLastTourStep)),
  { run: true, stepIndex: 0, steps: [] },
)

export const consoleShowTourLogs = (data, type, status) => {
  if (equals(process.env.NODE_ENV, 'development')) { // eslint-disable-line
    return H.logGroup(H.ifElse(R.equals(type, TOUR_EVENTS.TOUR_STATUS), `${type}:${status}`, type), data)
  }
}

export const setSkippedOrFinishedTourStep = ({ status, setState, dispatch, tourPart }) => {
  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    dispatch(setOpenMap(false))
    dispatch(setOpenModal(false))
    dispatch(setOpenGroups(false))
    dispatch(setTourModalId(null))
    dispatch(setOpenAddMode(false))
    dispatch(setOpenAsideMenu(false))
    dispatch(setStartedTourStep(null))
    dispatch(setTourHiddenObjects([]))
    dispatch(setDisableButtons(false))
    dispatch(setOpenAccountMenu(false))
    dispatch(setDisableScrolling(false))
    dispatch(setTourDefaultDataStep([]))
    dispatch(setTourRoute(H.jsonStringify({})))
    setState({ run: false, steps: [], stepIndex: 0 })
    H.setTourSessions(R.assoc(tourPart, false, H.getTourSessions()))
  }
}

export const renderTitle = (titleText, subtitleText) => (
  <Box sx={{ maxWidth: 250 }}>
    <Typography
      fontWeight={700}
      variant='subtitle1'
      color='primary.main'
      fontSize={{ lg: 16, md: 14, sm: 12, xs: 12 }}
      sx={{ textAlign: 'center', lineHeight: 1.3 }}
    >
      {titleText}
    </Typography>
    {
      subtitleText && (
        <Typography
          mt={2}
          fontWeight={500}
          sx={defaultStyles}
          variant='subtitle2'
          color='text.primary'
          fontSize={{ lg: 15, md: 14, sm: 14, xs: 12 }}
        >
          {subtitleText}
        </Typography>
      )
    }
  </Box>
)

export const renderContent = contentText => (
  <Typography
    variant='subtitle2'
    color='text.primary'
    fontSize={{ lg: 14, md: 14, sm: 12, xs: 12 }}
    sx={{
      ...defaultStyles,
      maxWidth: 250,
    }}
  >
    {contentText}
  </Typography>
)

export const defaultStepsSettings = {
  placement: 'bottom',
  disableBeacon: true,
  hideCloseButton: true,
  spotlightClicks: true,
  disableOverlayClose: true,
  styles: {
    options: { zIndex: 10000 },
    beacon: { display: 'none' },
  },
  locale: {
    skip: (
      <BaseButton
        title='skipTour'
        aria-label='skip'
        variant='outlined'
        sx={{
          mt: 0,
          ml: 0,
          height: 30,
          borderRadius: '4px',
          fontSize: { lg: 14, md: 14, sm: 12, xs: 12 },
          '&.Mui-selected': {
            backgroundColor: t => t.palette.background.main,
          },
        }}
      />
    ),
  },
}

export const renderTourPreviewStep = page => (
  <>
    <Box display='flex' justifyContent='center'>
      {renderTitle(i18next.t(`labels.${page}`))}
    </Box>
    <Typography
      fontWeight={600}
      variant='subtitle1'
      color='primary.main'
      fontSize={{ lg: 18, md: 18, sm: 14, xs: 12 }}
      sx={{
        ...defaultStyles,
        mt: 2,
        mb: 2,
      }}
    >
      {i18next.t('labels.welcomeToTutorial')}
    </Typography>
    <Typography
      fontWeight={500}
      sx={defaultStyles}
      variant='subtitle2'
      color='text.primary'
      fontSize={{ lg: 18, md: 18, sm: 14, xs: 12 }}
    >
      {i18next.t('labels.ifYouLooking')}
    </Typography>
  </>
)

export const getDefaultFirstStepSettings = page => ({
  ...defaultStepsSettings,
  placement: 'center',
  target: '#react-joyride-portal',
  content: renderTourPreviewStep(page),
})

export const defaultLastTourStep = {
  ...defaultStepsSettings,
  placement: 'right',
  target: '#openTour_button',
  content: renderContent(i18next.t('labels.toLearnThisStepAgain')),
  title: renderTitle(`${i18next.t('actions.openTourSteps')} ${R.toLower(i18next.t('labels.button'))}`),
}

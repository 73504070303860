import * as R from 'ramda'
import i18next from 'i18next'
// constants
import * as C from '../../../constants'
// features tour
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import {
  defaultStep,
  renderTitle,
  buttonTitle,
  getStateObj,
  defaultStep0,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

export const openListEmailCampaignsTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
  {
    title: 'actions.scheduleNewCampaign',
    action: setStartedTourStep(TOUR_STEPS.STEP1),
  },
  {
    title: 'actions.manuallyCampaign',
    action: setStartedTourStep(TOUR_STEPS.STEP2),
  },
]

export const listOfEmailCampaignsTourPreviewSteps = [
  getDefaultFirstStepSettings('emailCampaigns'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_EMAIL_CAMPAIGNS),
    content: renderTitle(i18next.t('labels.emailCampaigns'), i18next.t('labels.welcomeToEmailCampaigns')),
  },
]

export const listEmailCampaignsTourScheduleCampaignSteps = [
  {
    ...defaultStepsSettings,
    target: '#scheduleNewCampaign_button',
    content: renderContent(i18next.t('labels.step1EmailCampaigns')),
    title: renderTitle(`${i18next.t('actions.scheduleNewCampaign')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'right',
    title: renderTitle(i18next.t('labels.campaignName')),
    content: renderContent(i18next.t('labels.giveYourCampaignName')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(2)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.startDate')),
    content: renderContent(i18next.t('labels.chooseDate')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.endDate')),
    content: renderContent(i18next.t('labels.specifyDate')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.hours')),
    content: renderContent(i18next.t('labels.selectTimeOfDay')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.days')),
    content: renderContent(i18next.t('labels.selectDays')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(6)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.originLocations')),
    content: renderContent(i18next.t('labels.enterLocations')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.groups')),
    content: renderContent(i18next.t('labels.chooseOneOrMoreGroups')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.emails')),
    content: renderContent(i18next.t('labels.chooseOneOrMoreEmail')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.mailingService')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(6)',
    content:
    <>
      {renderContent(i18next.t('labels.selectEmail'))}
      {renderContent(i18next.t('labels.authorizeMailingServiceTour'))}
    </>,
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.companyTemplate')),
    content: renderContent(i18next.t('labels.chooseEmailTemplate')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(7)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#delete_row_button',
    title: renderTitle(i18next.t('labels.garbageCan')),
    content: renderContent(i18next.t('labels.ifYouNeedToDelete')),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#create_row_button',
    content: renderContent(i18next.t('labels.clickCreateEmailCampaign')),
    title: renderTitle(`${i18next.t('actions.create')} ${buttonTitle}`),
  },
]

export const listOfEmailCampaignsTourManuallyCampaignSteps = [
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#manuallyCampaign_button_on_mail_campaign_list',
    content: renderContent(i18next.t('labels.step2ManualCampaign')),
    title: renderTitle(`${i18next.t('actions.manuallyCampaign')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    target: '#mailingConfigId',
    title: renderTitle(i18next.t('labels.provider')),
    content: renderContent(i18next.t('labels.selectTheEmail')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#companyTemplateId',
    title: renderTitle(i18next.t('labels.companyTemplate')),
    content: renderContent(i18next.t('labels.selectEmailTemplate')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#emailIds',
    title: renderTitle(i18next.t('labels.emails')),
    content: renderContent(i18next.t('labels.chooseOneOrMoreEmail')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#emailGroupIds',
    title: renderTitle(i18next.t('labels.groups')),
    content: renderContent(i18next.t('labels.selectOneOrMoreGroups')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#truckIds',
    title: renderTitle(i18next.t('labels.truckIds')),
    content: renderContent(i18next.t('labels.selectTrucksYouWant')),
  },
  {
    ...defaultStepsSettings,
    target: '#Send',
    placement: 'top',
    content: renderContent(i18next.t('labels.clickAddToSendYourCampaign')),
    title: renderTitle(`${i18next.t('actions.send')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const listOfEmailCampaignsFullTourSteps = [
  ...listOfEmailCampaignsTourPreviewSteps,
  ...listEmailCampaignsTourScheduleCampaignSteps,
  ...listOfEmailCampaignsTourManuallyCampaignSteps,
]

export const handleEmailCampaignsTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenModal,
  setTourModalId,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setOpenAsideMenu(false))
      dispatch(setStartedTourStep(null))
      dispatch(setDisableButtons(false))
      dispatch(setDisableScrolling(false))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.EMAIL_CAMPAIGNS,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    defaultStep0(index, setState)

    if (R.equals(startedTourStep, TOUR_STEPS.STEP1)) {
      if (R.any(R.equals(index), [1, 2]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAddMode(true))
          dispatch(setDisableButtons(true))
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 5) && R.not(isPrevAction)) {
        dispatch(setTourModalId('editCampaign'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 6) && isPrevAction) {
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 12)) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 12) && isPrevAction) {
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [10, 11, 12]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 12 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 12 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP2)) {
      if (R.any(R.equals(index), [1, 2]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          dispatch(setDisableButtons(true))
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 6)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 6) && isPrevAction) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [4, 5, 6]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 6 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 6 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
        }, 400)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAsideMenu(true))
        dispatch(setDisableScrolling(true))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setOpenAddMode(true))
        dispatch(setOpenAsideMenu(false))
        dispatch(setDisableScrolling(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 7) && R.not(isPrevAction)) {
        dispatch(setTourModalId('editCampaign'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 8) && isPrevAction) {
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [13, 14, 15]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 14 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 14 }))
        }, 400)
      }

      if (R.equals(index, 14)) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 14) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 15) && R.not(isPrevAction)) {
        dispatch(setOpenAddMode(false))
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          defaultStep(setState, nextStepIndex, 400)
        }, 400)
      }

      if (R.equals(index, 15) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex, 100)
      }

      if (R.equals(index, 16) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex, 100)
      }

      if (R.any(R.equals(index), [15, 16, 17]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 15 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 15 }))
        }, 400)
      }

      if (R.equals(index, 21)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 21) && isPrevAction) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [19, 20, 21]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 21 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 21 }))
        }, 400)
      }
    }

    defaultStep(setState, nextStepIndex, 400)
  }

  consoleShowTourLogs(data, type, status)
}

export const renderListOfEmailCampaignsTour = ({
  pathname,
  dispatch,
  setState,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_EMAIL_CAMPAIGNS)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(listOfEmailCampaignsFullTourSteps))
    }

    if (R.equals(TOUR_STEPS.STEP1, startedTourStep)) {
      dispatch(setOpenAddMode(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(listEmailCampaignsTourScheduleCampaignSteps))
    }

    if (R.equals(TOUR_STEPS.STEP2, startedTourStep)) {
      dispatch(setDisableButtons(true))
      setState(getStateObj(listOfEmailCampaignsTourManuallyCampaignSteps))
    }
  }
}

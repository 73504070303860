import * as R from 'ramda'
import i18next from 'i18next'
// constants
import * as C from '../../../constants'
// features tour
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import {
  defaultStep,
  renderTitle,
  buttonTitle,
  getStateObj,
  defaultStep0,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

export const openListOfTrucksTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
  {
    title: 'actions.addTruck',
    action: setStartedTourStep(TOUR_STEPS.STEP1),
  },
  {
    title: 'actions.addDestinationZone',
    action: setStartedTourStep(TOUR_STEPS.STEP2),
  },
  {
    title: 'actions.goMap',
    action: setStartedTourStep(TOUR_STEPS.STEP3),
  },
]

export const listOfTrucksTourPreviewSteps = [
  getDefaultFirstStepSettings('listOfTrucks'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_TRUCKS),
    content: renderTitle(i18next.t('labels.listOfTrucks'), i18next.t('labels.welcomeToListOfTrucks')),
  },
]

export const listOfTrucksTourAddTruckSteps = [
  {
    ...defaultStepsSettings,
    target: '#addNewTruck_button',
    content: renderContent(i18next.t('labels.step1')),
    title: renderTitle(`${i18next.t('actions.addTruck')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'right',
    title: renderTitle(i18next.t('labels.truckId')),
    content: renderContent(i18next.t('labels.inputUnique')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(2) > input',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.availableDate')),
    content: renderContent(i18next.t('labels.enterPickupDate')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.truckLocation')),
    content: renderContent(i18next.t('labels.inputLocationOfTruck')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.destinationZones')),
    content: renderContent(i18next.t('labels.ifYouHaveDestinationZones')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.destinationOfTruck')),
    content: renderContent(i18next.t('labels.indicateWhereTruckIs')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(6)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.trailerType')),
    content: renderContent(i18next.t('labels.selectTypeOfTrailer')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.truckType')),
    content: renderContent(i18next.t('labels.chooseTypeOfTruck')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.driverType')),
    content: renderContent(i18next.t('labels.indicateIfYouHaveSingleDriver')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#delete_row_button',
    title: renderTitle(i18next.t('labels.garbageCan')),
    content: renderContent(i18next.t('labels.ifYouNeedToDelete')),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#create_row_button',
    content: renderContent(i18next.t('labels.clickCreate')),
    title: renderTitle(`${i18next.t('actions.create')} ${buttonTitle}`),
  },
]

export const listOfTrucksTourAddZoneSteps = [
  {
    ...defaultStepsSettings,
    placement: 'left',
    content: renderContent(i18next.t('labels.step2')),
    target: '#addDestinationZone_button_on_list_of_trucks',
    title: renderTitle(`${i18next.t('actions.addDestinationZone')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    target: '#name',
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.name')),
    content: renderContent(i18next.t('labels.inputNameForNewZone')),
  },
  {
    ...defaultStepsSettings,
    placement: 'bottom',
    target: '#locations',
    title: renderTitle(i18next.t('labels.locations')),
    content: renderContent(i18next.t('labels.chooseOneOrMoreLocations')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#Create',
    content: renderContent(i18next.t('labels.clickAddToCreateDestinationZone')),
    title: renderTitle(`${i18next.t('actions.create')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const listOfTrucksShowMapSteps = [
  {
    ...defaultStepsSettings,
    placement: 'right',
    target: '#Map_button',
    title: renderTitle(`${i18next.t('actions.map')} ${buttonTitle}`),
    content: (
      <>
        {renderContent(i18next.t('labels.step3'))}
        {renderContent(i18next.t('labels.thisWillTakeYouToPage'))}
        {renderContent(i18next.t('labels.withTheseFeaturesManagingYourTruck'))}
      </>
    ),
  },
]

export const listOfTrucksFullTourSteps = [
  ...listOfTrucksTourPreviewSteps,
  ...listOfTrucksTourAddTruckSteps,
  ...listOfTrucksTourAddZoneSteps,
  ...listOfTrucksShowMapSteps,
]

export const handleListOfTrucksTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenMap,
  setOpenModal,
  setTourModalId,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenMap(false))
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setOpenAsideMenu(false))
      dispatch(setDisableButtons(false))
      dispatch(setStartedTourStep(null))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.LIST_OF_TRUCKS,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    defaultStep0(index, setState)

    if (R.equals(startedTourStep, TOUR_STEPS.STEP1)) {
      if (R.any(R.equals(index), [1, 2]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAddMode(true))
        dispatch(setDisableButtons(true))
        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 5) && R.not(isPrevAction)) {
        dispatch(setTourModalId('editTruckList'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 6) && isPrevAction) {
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 10)) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 10) && isPrevAction) {
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [8, 9, 10]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 10 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 10 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP2)) {
      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          dispatch(setDisableButtons(true))
          defaultStep(setState, 1, 200)
        }, 100)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 2, 200)
      }

      if (R.equals(index, 3) && R.not(isPrevAction)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 3) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 3) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        defaultStep(setState, 3, 200)
      }

      if (R.any(R.equals(index), [3, 4, 5]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 3 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 3 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP3)) {
      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setDisableButtons(true))
        defaultStep(setState, nextStepIndex)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
        }, 400)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAsideMenu(true))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setOpenAddMode(true))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 7) && R.not(isPrevAction)) {
        dispatch(setTourModalId('editTruckList'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 8) && isPrevAction) {
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [11, 12, 13]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 12 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 12 }))
        }, 400)
      }

      if (R.equals(index, 12)) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 12) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 13) && R.not(isPrevAction)) {
        dispatch(setOpenAddMode(false))
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          defaultStep(setState, nextStepIndex, 400)
        }, 400)
      }

      if (R.equals(index, 13) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex, 100)
      }

      if (R.equals(index, 14) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex, 100)
      }

      if (R.any(R.equals(index), [13, 14, 15]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 13 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 13 }))
        }, 400)
      }

      if (R.equals(index, 16)) {
        dispatch(setOpenMap(true))
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 16) && isPrevAction) {
        dispatch(setOpenMap(false))
        dispatch(setOpenModal(true))
        defaultStep(setState, nextStepIndex, 100)
      }

      if (R.equals(index, 17)) {
        dispatch(setOpenMap(true))
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex, 400)
      }

      if (R.equals(index, 17) && isPrevAction) {
        dispatch(setOpenMap(false))
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          defaultStep(setState, nextStepIndex, 200)
        }, 100)
      }

      if (R.any(R.equals(index), [15, 16, 17]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 16 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 16 }))
        }, 400)
      }

      defaultStep(setState, nextStepIndex, 400)
    }

    defaultStep(setState, nextStepIndex)
  }

  consoleShowTourLogs(data, type, status)
}

export const renderListOfTrucksTour = ({
  pathname,
  dispatch,
  setState,
  setOpenMap,
  setOpenModal,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_TRUCKS)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(listOfTrucksFullTourSteps))
    }

    if (R.equals(TOUR_STEPS.STEP1, startedTourStep)) {
      dispatch(setOpenAddMode(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(listOfTrucksTourAddTruckSteps))
    }

    if (R.equals(TOUR_STEPS.STEP2, startedTourStep)) {
      dispatch(setOpenModal(false))
      dispatch(setDisableButtons(true))
      setState(getStateObj(listOfTrucksTourAddZoneSteps))
    }

    if (R.equals(TOUR_STEPS.STEP3, startedTourStep)) {
      dispatch(setOpenMap(true))
      dispatch(setDisableButtons(true))
      setState(getStateObj(listOfTrucksShowMapSteps))
    }
  }
}

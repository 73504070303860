import * as R from 'ramda'
import i18next from 'i18next'
// constants
import * as C from '../../../constants'
// features tour
import { setStartedTourStep, setTourDefaultDataStep } from '../tourSlice'
import { TOUR_PARTS, TOUR_STEPS, TOUR_EVENTS, TOUR_STATUS } from '../constants'
import {
  defaultStep,
  renderTitle,
  buttonTitle,
  getStateObj,
  defaultStep0,
  renderContent,
  getRouteTarget,
  getIsPrevAction,
  getNextStepIndex,
  consoleShowTourLogs,
  defaultStepsSettings,
  getDefaultFirstStepSettings,
  setSkippedOrFinishedTourStep,
} from '../common'
//////////////////////////////////////////////////

export const openMailingListTourButtonSettings = [
  { showVideoPlayBtn: true },
  {
    title: 'actions.runFullTour',
    label: 'actions.runFullTour',
    action: setStartedTourStep(TOUR_STEPS.ALL),
  },
  {
    title: 'actions.addNewEmail',
    action: setStartedTourStep(TOUR_STEPS.STEP1),
  },
  {
    title: 'actions.addGroup',
    action: setStartedTourStep(TOUR_STEPS.STEP2),
  },
  {
    title: 'actions.goToEmailGroups',
    action: setStartedTourStep(TOUR_STEPS.STEP3),
  },
]

export const mailingListTourPreviewSteps = [
  getDefaultFirstStepSettings('listOfMailsGroup'),
  {
    ...defaultStepsSettings,
    target: getRouteTarget(C.ROUTE_PATH_MAILS),
    content: renderTitle(i18next.t('labels.listOfMailsGroup'), i18next.t('labels.welcomeMailingList')),
  },
]

export const mailingListTourAddMailSteps = [
  {
    ...defaultStepsSettings,
    target: '#addNewEmail_button',
    content: renderContent(i18next.t('labels.step1MailingList')),
    title: renderTitle(`${i18next.t('actions.addNewEmail')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    placement: 'right',
    title: renderTitle(i18next.t('labels.companyName')),
    content: renderContent(i18next.t('labels.inputTheName')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(2)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.mcNumber')),
    content: renderContent(i18next.t('labels.inputMCNumber')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.dotNumber')),
    content: renderContent(i18next.t('labels.inputDOTNumber')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.firstName')),
    content: renderContent(i18next.t('labels.inputFirstName')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.lastName')),
    content: renderContent(i18next.t('labels.inputLastName')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(6)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.emailAddress')),
    content: renderContent(i18next.t('labels.inputEmail')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(4)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.groups')),
    content: renderContent(i18next.t('labels.inputGroups')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(5)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.position')),
    content: renderContent(i18next.t('labels.inputPosition')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(6)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    title: renderTitle(i18next.t('labels.subscribed')),
    content: renderContent(i18next.t('labels.inputSubscribed')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(7)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#delete_row_button',
    title: renderTitle(i18next.t('labels.garbageCan')),
    content: renderContent(i18next.t('labels.ifYouNeedToDelete')),
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#create_row_button',
    content: renderContent(i18next.t('labels.clickCreateEmail')),
    title: renderTitle(`${i18next.t('actions.create')} ${buttonTitle}`),
  },
]

export const mailingListTourAddGroupSteps = [
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#addGroup_button_on_mailing_list',
    content: renderContent(i18next.t('labels.step2MailingList')),
    title: renderTitle(`${i18next.t('actions.addGroup')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    target: '#name',
    placement: 'bottom',
    title: renderTitle(i18next.t('labels.group')),
    content: renderContent(i18next.t('labels.inputTheGroupName')),
  },
  {
    ...defaultStepsSettings,
    placement: 'top',
    target: '#Create',
    content: renderContent(i18next.t('labels.clickAddToCreateGroup')),
    title: renderTitle(`${i18next.t('actions.create')} ${R.toLower(i18next.t('labels.button'))}`),
  },
]

export const mailingListTourShowEmailGroupsSteps = [
  {
    ...defaultStepsSettings,
    placement: 'right',
    target: '#Group_button',
    content: renderTitle(
      i18next.t('labels.emailGroups'),
      i18next.t('labels.welcomeEmailGroupsList'),
    ),
  },
  {
    ...defaultStepsSettings,
    target: '#addNewMailGroup_button',
    content: renderContent(i18next.t('labels.clickAddToCreateGroup')),
    title: renderTitle(`${i18next.t('actions.addNewMailGroup')} ${buttonTitle}`),
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.name')),
    content: renderContent(i18next.t('labels.inputTheGroupName')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(2)',
  },
  {
    ...defaultStepsSettings,
    title: renderTitle(i18next.t('labels.emails')),
    content: renderContent(i18next.t('labels.emailsGroups')),
    target: '#addDatasheet > div > div.dsg-container > div > div:nth-child(2) > div:nth-child(3)',
  },
  {
    ...defaultStepsSettings,
    placement: 'left',
    target: '#create_row_button',
    content: renderContent(i18next.t('labels.clickAddToCreateGroup')),
    title: renderTitle(`${i18next.t('actions.create')} ${buttonTitle}`),
  },
]

export const mailingListFullTourSteps = [
  ...mailingListTourPreviewSteps,
  ...mailingListTourAddMailSteps,
  ...mailingListTourAddGroupSteps,
  ...mailingListTourShowEmailGroupsSteps,
]

export const handleMailingListTourCallback = ({
  data,
  setState,
  dispatch,
  setOpenModal,
  setOpenGroups,
  setTourModalId,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
}) => {
  const { type, index, action, status } = data

  const clearAllTourPredictions = () => {
    setTimeout(() => { // eslint-disable-line
      dispatch(setOpenModal(false))
      dispatch(setTourModalId(null))
      dispatch(setOpenAsideMenu(false))
      dispatch(setDisableButtons(false))
      dispatch(setStartedTourStep(null))
      dispatch(setTourDefaultDataStep([]))
    }, 400)
  }

  if (R.includes(status, [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED])) {
    clearAllTourPredictions()
  }

  setSkippedOrFinishedTourStep({
    status,
    setState,
    dispatch,
    tourPart: TOUR_PARTS.MAILING_LIST,
  })

  if (R.includes(type, [TOUR_EVENTS.STEP_AFTER, TOUR_EVENTS.TARGET_NOT_FOUND])) {
    const isPrevAction = getIsPrevAction(action)
    const nextStepIndex = getNextStepIndex(index, action)

    defaultStep0(index, setState)

    if (R.equals(startedTourStep, TOUR_STEPS.STEP1)) {
      if (R.any(R.equals(index), [1, 2, 3]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAddMode(true))
        dispatch(setDisableButtons(true))
        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 5) && R.not(isPrevAction)) {
        dispatch(setTourModalId('editMailList'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 6) && isPrevAction) {
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 11)) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 11) && isPrevAction) {
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [9, 10, 11]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 11 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 11 }))
        }, 400)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP2)) {
      if (R.any(R.equals(index), [1, 2, 3]) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          dispatch(setDisableButtons(true))
          defaultStep(setState, 1, 200)
        }, 100)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && R.not(isPrevAction)) {
        dispatch(setOpenModal(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [2, 3]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 2 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 2 }))
        }, 200)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.STEP3)) {
      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setDisableButtons(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 4)) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 4) && isPrevAction) {
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [2, 3, 4]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 4 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 4 }))
        }, 200)
      }
    }

    if (R.equals(startedTourStep, TOUR_STEPS.ALL)) {
      if (R.equals(index, 0)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenAsideMenu(true))
          dispatch(setDisableButtons(true))
        }, 400)
      }

      if (R.equals(index, 1) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        dispatch(setOpenAsideMenu(true))

        setTimeout(() => { // eslint-disable-line
          defaultStep(setState, 1, 400)
        }, 400)
      }

      if (R.equals(index, 1)) {
        dispatch(setOpenAddMode(true))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 1) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 2) && isPrevAction) {
        dispatch(setOpenAddMode(false))
        dispatch(setOpenAsideMenu(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 7) && R.not(isPrevAction)) {
        dispatch(setTourModalId('editMailList'))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 8) && isPrevAction) {
        dispatch(setTourModalId(null))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [12, 13, 14]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 13 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 13 }))
        }, 400)
      }

      if (R.equals(index, 13)) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 13) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 14) && R.not(isPrevAction)) {
        dispatch(setOpenAddMode(false))
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          defaultStep(setState, nextStepIndex, 400)
        }, 200)
      }

      if (R.equals(index, 14) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.equals(index, 15) && isPrevAction) {
        dispatch(setOpenModal(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex, 100)
      }

      if (R.any(R.equals(index), [14, 15]) && R.not(isPrevAction) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          dispatch(setOpenGroups(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 15 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          dispatch(setOpenModal(true))
          dispatch(setOpenGroups(false))
          setState(prevState => ({ ...prevState, run: true, stepIndex: 15 }))
        }, 400)
      }

      if (R.equals(index, 16) && R.not(isPrevAction)) {
        dispatch(setOpenModal(false))
        dispatch(setOpenGroups(true))
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 16) && isPrevAction) {
        dispatch(setOpenModal(true))
        dispatch(setOpenGroups(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [15, 16, 17]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 16 }))
        }, 50)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 16 }))
        }, 150)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 16 }))
        }, 250)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 16 }))
        }, 500)
      }

      if (R.equals(index, 17)) {
        dispatch(setOpenModal(false))
        dispatch(setOpenGroups(true))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex, 400)
      }

      if (R.equals(index, 17) && isPrevAction) {
        dispatch(setOpenModal(true))
        dispatch(setOpenGroups(false))
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex, 200)
      }

      if (R.any(R.equals(index), [18, 19]) && R.not(isPrevAction) && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 18 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 18 }))
        }, 400)
      }

      if (R.equals(index, 21)) {
        dispatch(setOpenAddMode(false))
        defaultStep(setState, nextStepIndex)
      }

      if (R.equals(index, 21) && isPrevAction) {
        dispatch(setOpenAddMode(true))
        defaultStep(setState, nextStepIndex)
      }

      if (R.any(R.equals(index), [20, 21]) && isPrevAction && R.equals(type, TOUR_EVENTS.TARGET_NOT_FOUND)) {
        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 21 }))
        }, 100)

        setTimeout(() => { // eslint-disable-line
          setState(prevState => ({ ...prevState, run: true, stepIndex: 21 }))
        }, 400)
      }
    }

    defaultStep(setState, nextStepIndex)
  }

  consoleShowTourLogs(data, type, status)
}

export const renderMailingListTour = ({
  pathname,
  dispatch,
  setState,
  setOpenModal,
  setOpenGroups,
  setOpenAddMode,
  startedTourStep,
  setOpenAsideMenu,
  setDisableButtons,
  setDisableScrolling,
}) => {
  if (R.equals(pathname, C.ROUTE_PATH_MAILS)) {
    if (R.equals(TOUR_STEPS.ALL, startedTourStep)) {
      dispatch(setOpenAsideMenu(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(mailingListFullTourSteps))
    }

    if (R.equals(TOUR_STEPS.STEP1, startedTourStep)) {
      dispatch(setOpenAddMode(true))
      dispatch(setDisableButtons(true))
      dispatch(setDisableScrolling(true))
      setState(getStateObj(mailingListTourAddMailSteps))
    }

    if (R.equals(TOUR_STEPS.STEP2, startedTourStep)) {
      dispatch(setOpenModal(false))
      dispatch(setDisableButtons(true))
      setState(getStateObj(mailingListTourAddGroupSteps))
    }

    if (R.equals(TOUR_STEPS.STEP3, startedTourStep)) {
      dispatch(setOpenGroups(true))
      dispatch(setOpenAddMode(true))
      dispatch(setDisableButtons(true))
      setState(getStateObj(mailingListTourShowEmailGroupsSteps))
    }
  }
}

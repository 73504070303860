import * as Yup from 'yup'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// forms
import FormWrapper from './form-wrapper'
//////////////////////////////////////////////////

const nextDayDate = H.getDateWithFormat(H.getCurrentDate(), C.DATE_TIME_FORMAT_FOR_NEXT_DATE_VALIDATION)

export const validationSchemaMassUpdateDatesObject = {
  [C.FIELD_PICK_UP_DATE_TIME]: Yup.date()
    .nullable(false)
    .required(H.getRequiredLocaleTxt())
    .typeError(H.getShouldBeDateLocaleTxt('availableDate')),
}

const initialValues = { [C.FIELD_PICK_UP_DATE_TIME]: nextDayDate }

const validationSchema = Yup.object().shape(validationSchemaMassUpdateDatesObject)

const fieldSettings = () => [
  {
    type: 'date',
    required: true,
    label: 'labels.availableDate',
    fieldName: C.FIELD_PICK_UP_DATE_TIME,
  },
]

const massAddRequestDataSettings = () => ({
  fieldSettings,
  formikSettings: {
    initialValues,
    validationSchema,
  },
  buttonSettings: {
    useIconBtn: true,
    useCancelBtn: true,
  },
})

export const MassUpdateDateForm = props => <FormWrapper {...props} {...massAddRequestDataSettings(props)} />

import * as R from 'ramda'
//////////////////////////////////////////////////

const openWindow = data => {
  const { url, name, reloadLocation } = data

  // eslint-disable-next-line
  const params = ` location=1,width=800,height=650, left=${R.divide(R.subtract(window.screen.width, 800), 2)}, top=${R.divide(R.subtract(window.screen.height, 650), 2)}`

  const connectedWindow = window.open(url, name, params) // eslint-disable-line

  const runner = setInterval(() => { // eslint-disable-line
    if (connectedWindow.closed) {
      clearInterval(runner) // eslint-disable-line

      if (reloadLocation) {
        location.reload() // eslint-disable-line
      }
    }
  }, 1000)
}

const closeWindow = () => window.close() // eslint-disable-line

const reloadPreviousPage = () => window.opener.location.reload() // eslint-disable-line

const cleanWindowHistory = () => window.history.pushState(null, null, window.location.href) // eslint-disable-line

const logGroup = (type, data) => {
  console.groupCollapsed(type) // eslint-disable-line
  console.log(data) // eslint-disable-line
  console.groupEnd() // eslint-disable-line
}

export {
  logGroup,
  openWindow,
  closeWindow,
  cleanWindowHistory,
  reloadPreviousPage,
}

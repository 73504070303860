// translations
import i18n from './../translations/index'
//////////////////////////////////////////////////

export const getRequiredLocaleTxt = () => i18n.t('errors.fieldIsRequired')

export const getFieldIsNotValidEmailTxt = () => i18n.t('errors.enterValidEmail')

export const getFieldIsNotValidLocaleTxt = () => i18n.t('errors.fieldIsNotValid')

export const getShouldBeIntegerLocaleTxt = () => i18n.t('errors.shouldBeNumber')

export const getShouldBeConfirmPassword = () => i18n.t('errors.passwordMustMatch')

export const getShouldBePhoneLocaleTxt = () => i18n.t('errors.shouldBePhoneNumber')

export const getShouldBeValidPasswordTxt = () => i18n.t('errors.enterValidPassword')

export const getShouldBeSpecialSymbol = () => i18n.t('errors.shouldBeSpecialSymbol')

export const getShouldBeCorrectPasswordTxt = () => i18n.t('errors.shouldBeCorrectPassword')

export const getShouldBeEmailOrGroupLocaleText = () => i18n.t('errors.pleaseSelectEmailOrGroup')

export const getCorrectInterpolationFieldsText = () => i18n.t('errors.fieldCanContainAnyCharacters')

export const getNoIncludeLeadingAndTrailingSpaces = () => i18n.t('errors.noLeadingAndTrailingSpaces')

export const getShouldBeLessOrEqualLocaleTxt = maxValue =>
  `${i18n.t('errors.shouldBeLessOrEqual')} ${maxValue}`

export const getShouldBeFromToLocaleTxt = (from = 0, to = 200) =>
  `${i18n.t('errors.fieldLengthShouldBeFrom')} ${from} ${i18n.t('labels.to')} ${to}`

export const getShouldBeDateLocaleTxt = fieldName => `${i18n.t(`labels.${fieldName}`)}
 ${i18n.t('errors.shouldBeDateType')}`

export const getShouldNotBeDateBeforeLocaleTxt = fieldName => `${i18n.t(`labels.${fieldName}`)}
 ${i18n.t('errors.cannotBeDateBefore')}`

export const getShouldBeFromToCharLocaleTxt = (from = 0, to = 20) =>
  `${i18n.t('errors.fieldLengthShouldBeFrom')} ${from} ${i18n.t('labels.to')} ${to} ${i18n.t('labels.characters')}`

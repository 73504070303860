import * as R from 'ramda'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
// api
import { useLoginMutation } from '../api/authAPI'
// components
import { Layout } from '../components'
// features
import { setAuthInfo, makeSelectAuthStore } from '../features/auth/authSlice'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
//////////////////////////////////////////////////

export const PrivateOutlet = ({ isAdminView, isAdminRole }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [login] = useLoginMutation()

  const { t } = useTranslation()

  const { loggedIn, access_token, isExpiredRefreshToken } = useSelector(makeSelectAuthStore)

  const refresh_token = H.getRefreshTokenFromSession()
  const company_access_token = H.getCompanyAuthTokenFromSession()
  const company_refresh_token = H.getCompanyRefreshTokenFromSession()

  const handleGetAuthToken = async (isAdminToken, refreshToken) => {
    const body = H.getFormData({
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    })

    await login(body)
      .unwrap()
      .then(({ access_token, refresh_token }) => {
        const role = H.getRoleFromToken(access_token)

        H.setAuthTokenToSession(access_token)
        H.setRefreshTokenToSession(refresh_token)

        dispatch(setAuthInfo({
          role,
          access_token,
          refresh_token,
          loggedIn: true,
          isAdminToken: isAdminToken,
        }))
      })
      .catch(() => {
        navigate(C.ROUTE_PATH_LOGIN)

        dispatch(setAuthInfo({
          user: null,
          loggedIn: false,
          access_token: null,
          refresh_token: null,
        }))
      })
  }

  useEffect(() => {
    if (isExpiredRefreshToken) H.showToastrMessageSimple('error', t('errors.sessionHasExpired'))
  }, [t, isExpiredRefreshToken])

  useEffect(() => {
    if (R.and(H.isNilOrEmpty(access_token), H.isNotNilAndNotEmpty(refresh_token))) {
      if (R.and(H.isNotNilAndNotEmpty(company_access_token), H.isNotNilAndNotEmpty(company_refresh_token))) {
        handleGetAuthToken(true, company_refresh_token)
      } else {
        handleGetAuthToken(false, refresh_token)
      }
    }

    if (H.isAllNilOrEmpty([access_token, refresh_token])) return navigate(C.ROUTE_PATH_LOGIN)
  }, []) // eslint-disable-line

  if (H.isNilOrEmpty(access_token)) return null

  return loggedIn ? (
    <Layout isAdminView={isAdminView} isAdminRole={isAdminRole} />
  ) : (
    <Navigate to={C.ROUTE_PATH_LOGIN} />
  )
}

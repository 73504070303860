import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailAPI = createApi({
  reducerPath: 'mailAPI',
  tagTypes: ['MailList'],
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchMails: build.query({
      extraOptions: { useLoader: true },
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.mail,
      }),
    }),
    createMails: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        url: E.ENDPOINTS.mail,
        method: E.METHODS.POST,
      }),
    }),
    updateMail: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mail,
      }),
      async onCacheEntryAdded(entity, { dispatch, getCacheEntry, cacheDataLoaded }) {
        await cacheDataLoaded

        const data = R.pathOr({}, ['data'], getCacheEntry())

        const { id } = data

        if (H.isNotNilAndNotEmpty(data)) {
          dispatch(mailAPI.util.updateQueryData(
            'fetchMails',
            undefined,
            R.map(item => H.ifElse(R.propEq(C.FIELD_ID, id, item), data, item)),
          ))
        }
      },
    }),
    massAddGroups: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailMassAddGroups,
      }),
    }),
    massDeleteGroups: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.DELETE,
        url: E.ENDPOINTS.mailBulk,
      }),
    }),
    deleteMail: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.mail}/${id}`,
      }),
    }),
    subscribeEmail: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailSubscribe,
      }),
    }),
    unsubscribeEmail: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailUnsubscribe,
      }),
    }),
    subscribePublicEmail: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailPublicSubscribe,
      }),
    }),
    unsubscribePublicEmail: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailList'),
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailPublicUnsubscribe,
      }),
    }),
  }),
})

export const {
  useFetchMailsQuery,
  useDeleteMailMutation,
  useUpdateMailMutation,
  useCreateMailsMutation,
  useMassAddGroupsMutation,
  useSubscribeEmailMutation,
  useMassDeleteGroupsMutation,
  useUnsubscribeEmailMutation,
  useSubscribePublicEmailMutation,
  useUnsubscribePublicEmailMutation,
} = mailAPI

import * as R from 'ramda'
import { createSlice, createSelector } from '@reduxjs/toolkit'
//////////////////////////////////////////////////

const scope = 'trucks'

const initialState = {
  data: [],
}

export const truckSlice = createSlice({
  initialState,
  name: `${scope}`,
  reducers: {
    addTrucks: (state, { payload }) => R.assoc('data', payload, state),
  },
})

export const { addTrucks } = truckSlice.actions

const store = ({ trucks }) => trucks

export const makeSelectTrucks = createSelector(
  store,
  ({ data }) => data,
)

export default truckSlice.reducer

import * as R from 'ramda'
import { Crisp } from 'crisp-sdk-web'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMemo, useEffect, createContext } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
// material
import Box from '@mui/material/Box'
// api
import { useFetchProfileQuery } from '../api/userAPI'
import { useFetchCompanyQuery } from '../api/companyAPI'
import { useFetchConfigsQuery } from '../api/mailingConfigAPI'
import { useFetchMailingUsageQuery } from '../api/mailingUsageAPI'
import { removeCompanyLogoByDispatch } from '../api/companyLogoAPI'
import { useFetchCompanySubscriptionQuery } from '../api/companySubscriptionApi'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// components
import Tour from './tour'
import { Loader } from './loader'
import { ModalComponent } from './modal'
import { TOUR_PARTS } from './tour/constants'
import Header from '../components/header/index'
import { defaultLastTourStep } from './tour/common'
import { makeSelectLoaderOpened } from './loader/loaderSlice'
import { setVideoTourIsOpened, makeSelectVideoTourIsOpened } from './tour/tourSlice'
import {
  renderProfileTour,
  profileFullTourSteps,
  handleProfileTourCallback,
} from './tour/components/profile-page-tour'
import {
  renderMailingLogTour,
  mailingLogFullTourSteps,
  handleMailingLogTourCallback,
} from './tour/components/mailing-log-tour'
import {
  renderMailingListTour,
  mailingListFullTourSteps,
  handleMailingListTourCallback,
} from './tour/components/mailing-list-page-tour'
import {
  renderDestinationZonesListTour,
  destinationZonesListFullTourSteps,
  handleDestinationZonesListTourCallback,
} from './tour/components/destination-zones-tour'
import {
  renderEmailTemplatesTour,
  emailTemplatesFullTourSteps,
  handleEmailTemplatesTourCallback,
} from './tour/components/email-templates-page-tour'
import {
  renderListOfTrucksTour,
  listOfTrucksFullTourSteps,
  handleListOfTrucksTourCallback,
} from './tour/components/list-of-trucks-page-tour'
import {
  renderListOfEmailCampaignsTour,
  handleEmailCampaignsTourCallback,
  listOfEmailCampaignsFullTourSteps,
} from './tour/components/email-campaigns-page-tour'
import {
  renderReservationRequestsTour,
  reservationRequestsFullTourSteps,
  handleReservationRequestsTourCallback,
} from './tour/components/reservation-requests-page-tour'
//////////////////////////////////////////////////

export const WarningContext = createContext({ showWarning: false })

const getTourSettings = props => {
  const { pathname } = props

  const tourSessions = H.getTourSessions()

  const isFirstProfileSession = R.pathOr(true, [TOUR_PARTS.PROFILE], tourSessions)
  const isFirstMailingLogSession = R.pathOr(true, [TOUR_PARTS.MAILING_LOG], tourSessions)
  const isFirstMailingListSession = R.pathOr(true, [TOUR_PARTS.MAILING_LIST], tourSessions)
  const isFirstListOfTrucksSession = R.pathOr(true, [TOUR_PARTS.LIST_OF_TRUCKS], tourSessions)
  const isFirstEmailTemplatesSession = R.pathOr(true, [TOUR_PARTS.EMAIL_TEMPLATES], tourSessions)
  const isFirstListOfEmailCampaignsSession = R.pathOr(true, [TOUR_PARTS.EMAIL_CAMPAIGNS], tourSessions)
  const isFirstDestinationZonesListSession = R.pathOr(true, [TOUR_PARTS.DESTINATION_ZONES], tourSessions)
  const isFirstReservationRequestsListSession = R.pathOr(true, [TOUR_PARTS.RESERVATION_REQUESTS], tourSessions)

  let renderTour
  let tourSteps = []
  let videoTourName = ''
  let handleTourCallback = () => null

  if (R.equals(pathname, C.ROUTE_PATH_TRUCKS)) {
    renderTour = renderListOfTrucksTour
    videoTourName = 'L.O.TOfficialVideo'
    handleTourCallback = handleListOfTrucksTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstListOfTrucksSession),
      R.concat(listOfTrucksFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_MAILS)) {
    renderTour = renderMailingListTour
    videoTourName = 'EmailListOfficialVideo'
    handleTourCallback = handleMailingListTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstMailingListSession),
      R.concat(mailingListFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_EMAIL_TEMPLATES)) {
    renderTour = renderEmailTemplatesTour
    videoTourName = 'EmailTempOfficialVideo'
    handleTourCallback = handleEmailTemplatesTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstEmailTemplatesSession),
      R.concat(emailTemplatesFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_EMAIL_CAMPAIGNS)) {
    videoTourName = 'EmailCampOfficialVideo'
    renderTour = renderListOfEmailCampaignsTour
    handleTourCallback = handleEmailCampaignsTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstListOfEmailCampaignsSession),
      R.concat(listOfEmailCampaignsFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_MAILING_LOG)) {
    renderTour = renderMailingLogTour
    videoTourName = 'MailingLogOfficialVideo'
    handleTourCallback = handleMailingLogTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstMailingLogSession),
      R.concat(mailingLogFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_DESTINATION_ZONES)) {
    videoTourName = 'D.Z.OfficialVideo'
    renderTour = renderDestinationZonesListTour
    handleTourCallback = handleDestinationZonesListTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstDestinationZonesListSession),
      R.concat(destinationZonesListFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_RESERVATION_REQUESTS)) {
    renderTour = renderReservationRequestsTour
    videoTourName = 'ReservationRequestsOfficialVideo'
    handleTourCallback = handleReservationRequestsTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstReservationRequestsListSession),
      R.concat(reservationRequestsFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  if (R.equals(pathname, C.ROUTE_PATH_PROFILE)) {
    renderTour = renderProfileTour
    videoTourName = 'ProfileOfficialVideo'
    handleTourCallback = handleProfileTourCallback
    tourSteps = H.ifElse(
      H.isTrue(isFirstProfileSession),
      R.concat(profileFullTourSteps, R.of(defaultLastTourStep)),
      [],
    )
  }

  return {
    tourSteps,
    renderTour,
    videoTourName,
    handleTourCallback,
  }
}

export const Layout = ({ isAdminView, isAdminRole }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const loaderOpened = useSelector(makeSelectLoaderOpened)
  const videoTourIsOpened = useSelector(makeSelectVideoTourIsOpened)

  const { data: profileData, refetch: profileDataRefetch } = useFetchProfileQuery(
    undefined,
    { skip: H.isTrue(isAdminRole) },
  )

  const { data: companyData, refetch: companyDataRefetch, isLoading: companyDataIsLoading } = useFetchCompanyQuery(
    undefined,
    { skip: H.isTrue(isAdminRole) },
  )

  const {
    data: mailingUsageData,
    refetch: mailingUsageRefetch,
    isLoading: mailingUsageIsLoading,
  } = useFetchMailingUsageQuery(
    undefined,
    { skip: H.isTrue(isAdminRole) },
  )

  const {
    data: companySubscriptionData = [],
    isLoading: companySubscriptionIsLoading,
  } = useFetchCompanySubscriptionQuery()

  const {
    data: mailingConfigsData = [],
    isLoading: mailingConfigsIsLoading,
  } = useFetchConfigsQuery()

  const isTrialActive = R.gt(R.pathOr(0, [C.FIELD_TRIAL_EMAILS_LEFT], mailingUsageData), 0)

  // TODO: check useEffect with this staff
  const name = R.pathOr('', [C.FIELD_NAME], companyData)
  const email = R.pathOr('', [C.FIELD_EMAIL], profileData)
  const lastName = R.pathOr('', [C.FIELD_LAST_NAME], profileData)
  const firstName = R.pathOr('', [C.FIELD_FIRST_NAME], profileData)

  const freeAccess = R.path([C.FIELD_FREE_ACCESS], companyData)

  useEffect(() => {
    if (R.equals(pathname, C.ROUTE_PATH_ADMIN_PANEL)) {
      removeCompanyLogoByDispatch(dispatch)

      H.cleanWindowHistory()
    }
  }, [pathname])  // eslint-disable-line

  useEffect(() => {
    if (H.isFalse(isAdminRole)) {
      profileDataRefetch()
      companyDataRefetch()
      mailingUsageRefetch()
    }
  }, [profileDataRefetch, companyDataRefetch]) // eslint-disable-line

  const handleNavigateToProfilePage = () => navigate(C.ROUTE_PATH_PROFILE)

  useEffect(() => {
    const isAnyLoading = R.any(H.isTrue,
      [
        companyDataIsLoading,
        mailingUsageIsLoading,
        mailingConfigsIsLoading,
        companySubscriptionIsLoading,
      ],
    )

    if (R.any(H.isTrue,
      [
        freeAccess,
        isAdminRole,
        isAnyLoading,
        isTrialActive,
      ],
    )) return

    if (R.any(H.isTrue,
      [
        H.isNilOrEmpty(mailingConfigsData),
        H.isNilOrEmpty(companySubscriptionData),
      ],
    )) handleNavigateToProfilePage()
  }, [companyData, mailingUsageData, mailingConfigsData, companySubscriptionData]) // eslint-disable-line

  // TODO: with prod key
  useEffect(() => {
    if (H.isFalse(isAdminRole)) {
      const id = process.env.REACT_APP_CRISP_WEBSITE_ID // eslint-disable-line

      if (H.isNilOrEmpty(id)) return

      Crisp.configure(id)
      Crisp.user.setEmail(email)
      Crisp.user.setCompany(name)
      Crisp.user.setNickname(`${firstName} ${lastName}`)
    }
  }, [name, email, lastName, firstName, isAdminRole]) // eslint-disable-line

  const showWarningNotFreeAndTrial = useMemo(() => {
    return (
      H.isFalse(freeAccess) &&
      H.isFalse(isAdminRole) &&
      H.isFalse(isTrialActive) &&
      H.isFalse(mailingConfigsIsLoading) &&
      H.isFalse(companySubscriptionIsLoading) &&
      R.or(H.isNilOrEmpty(mailingConfigsData), H.isNilOrEmpty(companySubscriptionData))
    )
  // eslint-disable-next-line max-len
  }, [freeAccess, isAdminRole, isTrialActive, mailingConfigsIsLoading, companySubscriptionIsLoading, mailingConfigsData, companySubscriptionData])

  const showWarningFreeAndTrial = useMemo(() => {
    return (
      R.or(H.isTrue(freeAccess), H.isTrue(isTrialActive)) &&
      H.isFalse(isAdminRole) &&
      H.isNilOrEmpty(mailingConfigsData) &&
      H.isFalse(mailingConfigsIsLoading)
    )
  }, [freeAccess, isAdminRole, isTrialActive, mailingConfigsIsLoading, mailingConfigsData])

  const showWarning = useMemo(() => R.or(
    showWarningNotFreeAndTrial,
    showWarningFreeAndTrial,
  ), [showWarningNotFreeAndTrial, showWarningFreeAndTrial])

  const warningContextObject = useMemo(() => ({
    showWarning, showWarningFreeAndTrial, showWarningNotFreeAndTrial,
  }), [showWarning, showWarningFreeAndTrial, showWarningNotFreeAndTrial])

  const {
    tourSteps,
    renderTour,
    videoTourName,
    handleTourCallback,
  } = getTourSettings({ pathname })

  const contentMT = H.ifElse(R.and(showWarning, R.not(isAdminView)), '120px', '60px')

  return (
    <Box
      width='100%'
      height='100%'
      position='relative'
      backgroundColor='background.default'
      overflow={H.ifElse(loaderOpened, 'hidden', 'auto')}
    >
      <Header showWarning={showWarning} showWarningFreeAndTrial={showWarningFreeAndTrial} />
      <Loader loaderOpened={loaderOpened} />
      <Box width='100%' mt={contentMT}>
        <WarningContext.Provider value={warningContextObject}>
          <Outlet />
        </WarningContext.Provider>
      </Box>
      {
        H.isFalse(isAdminView) && (
          <Tour
            pathname={pathname}
            tourSteps={tourSteps}
            renderTour={renderTour}
            handleTourCallback={handleTourCallback}
          />
        )
      }
      <ModalComponent
        closeBtnTop='3px'
        closeBtnPadding='4px'
        opened={videoTourIsOpened}
        additionalStyles={{ p: '30px', width: '80%', minWidth: 300, maxWidth: 1400 }}
        setOpen={() => dispatch(setVideoTourIsOpened(false))}
        component={
          <video controls autoPlay id='video' width='100%' height='100%'>
            <source type='video/mp4' src={`/videos/${videoTourName}.mp4`} />
            {t('errors.yourBrowser')}
          </video>
        }
      />
    </Box>
  )
}

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailingLogAPI = createApi({
  tagTypes: ['MailingLogList'],
  reducerPath: 'mailingLogAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchMailingLog: build.mutation({
      extraOptions: { useLoader: true },
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailingLogList'),
      query: page => ({
        method: E.METHODS.POST,
        url: E.ENDPOINTS.mailingLog,
        body: {
          limit: 50,
          page: page,
        },
      }),
    }),
  }),
})

export const { useFetchMailingLogMutation } = mailingLogAPI

export const TOUR_STEPS = {
  ALL: 0,
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
  STEP5: 5,
}

export const TOUR_STATUS = {
  IDLE: 'idle',
  ERROR: 'error',
  READY: 'ready',
  PAUSED: 'paused',
  RUNNING: 'running',
  WAITING: 'waiting',
  SKIPPED: 'skipped',
  FINISHED: 'finished',
}

export const TOUR_PARTS = {
  PROFILE: 'profile',
  MAILING_LOG: 'mailingLog',
  EMAIL_GROUPS: 'emailGroups',
  MAILING_LIST: 'mailingList',
  LIST_OF_TRUCKS: 'listOfTrucks',
  EMAIL_CAMPAIGNS: 'emailCampaigns',
  EMAIL_TEMPLATES: 'emailTemplates',
  DESTINATION_ZONES: 'destinationZones',
  RESERVATION_REQUESTS: 'reservationRequests',
}

export const TOUR_EVENTS = {
  ERROR: 'error',
  BEACON: 'beacon',
  TOOLTIP: 'tooltip',
  TOUR_END: 'tour:end',
  TOOLTIP_CLOSE: 'close',
  STEP_AFTER: 'step:after',
  TOUR_START: 'tour:start',
  STEP_BEFORE: 'step:before',
  TOUR_STATUS: 'tour:status',
  TARGET_NOT_FOUND: 'error:target_not_found',
}

export const TOUR_ACTIONS = {
  GO: 'go',
  SKIP: 'skip',
  STOP: 'stop',
  INIT: 'init',
  PREV: 'prev',
  NEXT: 'next',
  CLOSE: 'close',
  INDEX: 'index',
  RESET: 'reset',
  START: 'start',
  UPDATE: 'update',
  RESTART: 'restart',
}

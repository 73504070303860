import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useContext } from 'react'
// material
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import List from '@mui/material/List'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import AppBar from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListItemButton from '@mui/material/ListItemButton'
// icons-material
import Alert from '@mui/material/Alert'
import MailIcon from '@mui/icons-material/Mail'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToApp from '@mui/icons-material/ExitToApp'
import SettingsIcon from '@mui/icons-material/Settings'
import ChevronRight from '@mui/icons-material/ChevronRight'
import AccountCircle from '@mui/icons-material/AccountCircle'
import RefreshIcon from '@mui/icons-material/RefreshOutlined'
import DarkModeIcon from '@mui/icons-material/DarkModeOutlined'
import LightModeIcon from '@mui/icons-material/LightModeOutlined'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined'
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined'
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined'
import MarkAsUnreadOutlinedIcon from '@mui/icons-material/MarkAsUnreadOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import MarkunreadMailboxOutlinedIcon from '@mui/icons-material/MarkunreadMailboxOutlined'
// helpers/constants
import * as H from '../../helpers'
import * as C from '../../constants'
// hooks
import useToastrError from '../../hooks/use-toaster-error'
// components
import Locales from '../locales'
import { AlertInfoPair } from '../alert'
import { ColorModeContext } from '../../App'
import {
  makeSelectDisableTourButtons,
  makeSelectTourAsideMenuOpened,
  makeSelectTourAccountMenuOpened,
} from '../tour/tourSlice'
// api
import { useFetchCompanyQuery } from '../../api/companyAPI'
import { useGetCompanyLogoQuery } from '../../api/companyLogoAPI'
import { useFetchCompanySubscriptionQuery } from '../../api/companySubscriptionApi'
import { mailingUsageAPI, useFetchMailingUsageQuery, useRefreshMailingUsageMutation } from '../../api/mailingUsageAPI'
// features auth
import { setAuthInfo, makeSelectAuthStore, makeSelectIsAdminToken } from '../../features/auth/authSlice'
//////////////////////////////////////////////////

// NOTE: added to correctly display the background mask for the tour component
const headerWidth = `${window.screen.width} !important` // eslint-disable-line

const routes = [
  { label: 'labels.listOfTrucks', path: C.ROUTE_PATH_TRUCKS, icon: <LocalShippingOutlinedIcon /> },
  { label: 'labels.listOfMailsGroup', path: C.ROUTE_PATH_MAILS, icon: <MarkunreadMailboxOutlinedIcon /> },
  { label: 'labels.emailTemplates', path: C.ROUTE_PATH_EMAIL_TEMPLATES, icon: <AttachEmailOutlinedIcon /> },
  { label: 'labels.emailCampaigns', path: C.ROUTE_PATH_EMAIL_CAMPAIGNS, icon: <ContactMailOutlinedIcon /> },
  { label: 'labels.mailingLog', path: C.ROUTE_PATH_MAILING_LOG, icon: <MarkAsUnreadOutlinedIcon /> },
  { label: 'labels.destinationZones', path: C.ROUTE_PATH_DESTINATION_ZONES, icon: <PinDropOutlinedIcon /> },
  { label: 'labels.reservationRequests', path: C.ROUTE_PATH_RESERVATION_REQUESTS, icon: <RequestPageOutlinedIcon /> },
]

const WarningMessage = ({ t, type }) => {
  const messagesMap = {
    mailing: 'warnings.mailingConfigs',
    subscription: 'warnings.subscription',
  }

  return (
    <Box
      width='100%'
      height='60px'
      display='flex'
      severity='info'
      justifySelf='center'
      justifyContent='center'
    >
      <Alert
        severity='error'
        sx={{
          width: '100%',
          display: 'flex',
          justifySelf: 'center',
          justifyContent: 'center',
          color: t => t.palette.primary.error,
          background: t => t.palette.mode === 'light'
            ? t.palette.background.middleGreen
            : t.rgbaColors.lightGrey132_8,
          '& .MuiAlert-icon': {
            color: t => t.palette.primary.main,
            '.MuiSvgIcon-root': { cursor: 'default' },
            '&:hover': { color: t => t.palette.primary.main },
            '&.Mui-selected': { color: t => t.palette.primary.main },
          },
        }}
      >
        <Box display='flex' justifyContent='center'>
          <AlertInfoPair
            textColor='primary'
            text={t(R.path([type], messagesMap))}
            style={{
              m: 'auto',
              display: 'flex',
              justifySelf: 'center',
              justifyContent: 'center',
            }}
          />
          {
            R.equals(type, 'mailing') &&
              <Link
                to={C.ROUTE_PATH_PROFILE}
                title={t('labels.profile')}
              >
                <Typography
                  component='h1'
                  align='center'
                  variant='body2'
                  sx={{
                    ml: 2,
                    borderRadius: '5px',
                    padding: '4px 10px',
                    color: 'background.white',
                    textDecoration: 'underline',
                    backgroundColor: 'background.main',
                    fontSize: { lg: 14, md: 13, sm: 10, xs: 8 },
                    '&:hover': {
                      color: t => t.palette.primary.main,
                      backgroundColor: t => t.palette.primary.light,
                    },
                  }}
                >
                  {t('labels.profile')}
                </Typography>
              </Link>
          }
        </Box>
      </Alert>
    </Box>
  )
}

const MailsCounter = ({ t, count, dispatch, freeAccess, refreshMailingUsage, mailingUsageIsLoading }) => {
  const limit = R.pathOr(0, [C.FIELD_LIMIT], count)
  const sentCount = R.pathOr(0, [C.FIELD_SENT_COUNT], count)
  const trialEmailsLeft = R.pathOr(0, [C.FIELD_TRIAL_EMAILS_LEFT], count)

  const getColor = t => {
    if (R.lt(sentCount, 80)) return t.palette.background.menuListBg

    return t.palette.background.lightErrorColor
  }

  const handleRefreshMailCounter = async () => {
    try {
      await refreshMailingUsage()
        .unwrap()
        .then(data => dispatch(
          mailingUsageAPI.util.updateQueryData(
            'fetchMailingUsage',
            undefined,
            () => data,
          )),
        )
        .catch(({ data, status }) => H.getRequestAPIError({ error: data?.error, status, message: data?.message }))
    } catch (e) {
      H.getCatchError(e)
    }
  }

  const renderCount = (count, limit) => (
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography
        component='h1'
        align='center'
        variant='body2'
        sx={{
          fontSize: 9,
          fontWeight: 700,
          color: t => getColor(t),
        }}
      >
        {count}
      </Typography>
      {
        limit &&
          <Typography
            component='h1'
            align='center'
            variant='body2'
            sx={{
              fontSize: 9,
              fontWeight: 700,
              color: t => t.palette.background.menuListBg,
            }}
          >
            {`/${limit}`}
          </Typography>
      }
    </Box>
  )

  return (
    <Box mr={1} sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={t('labels.clickToRefreshCounter')}>
        <span>
          <IconButton onClick={handleRefreshMailCounter} disabled={mailingUsageIsLoading}>
            <RefreshIcon
              color='primary'
              variant='contained'
              sx={{
                '&.MuiIconButton-root:hover': {
                  color: t => t.palette.primary.light,
                  backgroundColor: t => t.palette.background.white,
                },
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
      {
        R.gte(trialEmailsLeft, 1) && R.not(freeAccess) &&
          <>
            <Typography component='span' fontSize={{ sm: 16, xs: 13 }}>
              {`${t('labels.trial')}: `}
            </Typography>
            <Badge
              max={999999}
              badgeContent={renderCount(trialEmailsLeft)}
              title={`${t('labels.trialEmailsLeft')}: ${trialEmailsLeft}`}
              sx={{
                cursor: 'default',
                mr: { lg: 4, md: 4, xs: 3, sm: 3 },
                color: t => t.palette.primary.main,
                '& .MuiBadge-badge': {
                  right: -5,
                  padding: '0 4px',
                  background: t => t.palette.primary.main,
                  color: t => t.palette.background.menuListBg,
                  border: t => `2px solid ${t.palette.background.paper}`,
                },
              }}
            >
              <ForwardToInboxIcon sx={{ cursor: 'default' }} />
            </Badge>
          </>
      }
      <Badge
        max={999999}
        badgeContent={renderCount(sentCount, limit)}
        title={` ${t('labels.alreadySent')}: ${sentCount},${'\n'} ${t('labels.dailyEmailLimit')}: ${limit}`}
        sx={{
          cursor: 'default',
          mr: { lg: 2, md: 2, xs: 2, sm: 2 },
          color: t => t.palette.primary.main,
          '& .MuiBadge-badge': {
            right: -5,
            padding: '0 4px',
            background: t => t.palette.primary.main,
            color: t => t.palette.background.menuListBg,
            border: t => `2px solid ${t.palette.background.paper}`,
          },
        }}
      >
        <MailIcon sx={{ cursor: 'default' }} />
      </Badge>
    </Box>
  )
}

const MenuLogo = ({ isLoading, companyLogo, isDarkTheme }) => (
  <Box display='flex' alignItems='center' m={{ xs: 0, sm: '0 16px' }}>
    {
      H.isNotNilAndNotEmpty(companyLogo) && H.isFalse(isLoading)
        ? (
          <img
            alt='Logo'
            src={companyLogo}
            style={{ maxWidth: 300, maxHeight: 50 }}
          />
        )
        : (
          <img
            alt='Logo'
            style={{ maxWidth: 150, maxHeight: 24 }}
            src={isDarkTheme ? C.DEFAULT_LOGO_DARK_URL : C.DEFAULT_LOGO_URL}
          />
        )
    }
  </Box>
)

const AccountMenu = ({
  t,
  setOpen,
  isAdmin,
  isMobile,
  onLogout,
  pathname,
  isDarkTheme,
  isAdminToken,
  toggleColorMode,
  openedAccountMenu,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Tooltip title={t('actions.accountSettings')}>
        <span>
          <IconButton onClick={({ currentTarget }) => setAnchorEl(currentTarget)}>
            <SettingsIcon
              color='primary'
              variant='contained'
              sx={{
                '&.MuiIconButton-root:hover': {
                  color: t => t.palette.primary.light,
                  backgroundColor: t => t.palette.background.white,
                },
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        open={H.ifElse(openedAccountMenu, true, Boolean(anchorEl))}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            paddingLeft: 2,
            paddingRight: 2,
            top: '60px !important',
            backgroundColor: t => t.palette.background.white,
          },
        }}
      >
        <AsideButtons
          t={t}
          setOpen={setOpen}
          isAdmin={isAdmin}
          isMobile={isMobile}
          onLogout={onLogout}
          pathname={pathname}
          isDarkTheme={isDarkTheme}
          isAdminToken={isAdminToken}
          toggleColorMode={toggleColorMode}
        />
      </Menu>
    </>
  )
}

const MenuComponent = ({
  t,
  open,
  setOpen,
  isAdmin,
  onLogout,
  pathname,
  freeAccess,
  isDarkTheme,
  isAdminToken,
  checkIsAdmin,
  isTrialActive,
  toggleColorMode,
  areButtonsDisabled,
  isSubscriptionAvailable,
}) => (
  <Drawer
    open={open}
    anchor='right'
    onClose={() => setOpen(false)}
    ModalProps={{ keepMounted: false }}
    sx={{
      display: { xs: 'block' },
      '& .MuiDrawer-paper': {
        width: 'auto',
        maxWidth: '50%',
        boxSizing: 'border-box',
        paddingLeft: { sm: 2, xs: 1 },
        paddingRight: { sm: 2, xs: 1 },
      },
    }}
  >
    <Box
      onClick={() => setOpen(false)}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <IconButton title={t('actions.back')}><ChevronRight /></IconButton>
    </Box>
    {
      checkIsAdmin() &&
      <>
        <Divider />
        <List>
          {
            routes.map((route, index) => (
              <ListItemButton
                key={index}
                id={route.path}
                to={route.path}
                component={Link}
                title={t(route.label)}
                onClick={() => setOpen(false)}
                onKeyDown={() => setOpen(R.not(open))}
                disabled={R.or(
                  areButtonsDisabled,
                  R.all(R.equals(false), [freeAccess, isTrialActive, isSubscriptionAvailable]),
                )}
                sx={{
                  justifyContent: 'flex-start',
                  borderRadius: '1px !important',
                  '&:hover': {
                    width: '100%',
                    borderRadius: '8px !important',
                    backgroundColor: t => t.palette.background.multiInputTextBg,
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '35px',
                    color: t => t.palette.background.darkGrey,
                    '.MuiSvgIcon-root': {
                      width: { sm: '26px', xs: '22px' },
                      height: { sm: '26px', xs: '22px' },
                      color: t => R.equals(pathname, route.path) && t.palette.primary.main,
                    },
                  }}>
                  {route.icon}
                </ListItemIcon>
                <ListItemText sx={{
                  '.MuiTypography-root': {
                    fontSize: { sm: '1rem', xs: '0.8rem' },
                    color: t => R.equals(pathname, route.path) && t.palette.primary.main,
                  },
                }}
                >
                  {t(route.label)}
                </ListItemText>
              </ListItemButton>
            ))
          }
        </List>
      </>
    }
    <Divider sx={{ mb: 1 }} />
    <AsideButtons
      t={t}
      isMobile={true}
      setOpen={setOpen}
      isAdmin={isAdmin}
      pathname={pathname}
      onLogout={onLogout}
      isDarkTheme={isDarkTheme}
      isAdminToken={isAdminToken}
      toggleColorMode={toggleColorMode}
    />
  </Drawer>
)

const AsideButtons = ({
  t,
  setOpen,
  isAdmin,
  isMobile,
  onLogout,
  pathname,
  isDarkTheme,
  isAdminToken,
  toggleColorMode,
}) => {
  const dispatch = useDispatch()
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)

  const getModeTitle = () => {
    if (isDarkTheme) return `${t('labels.currentModeIs')} ${R.toLower(t('labels.darkMode'))})`

    return `${t('labels.currentModeIs')} ${R.toLower(t('labels.lightMode'))})`
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      sx={{
        ml: '10px',
        '.MuiIconButton-root': {
          justifyContent: 'flex-start',
          borderRadius: '1px !important',
        },
        '.MuiIconButton-root:hover': {
          width: '100%',
          borderRadius: '8px !important',
          backgroundColor: t => t.palette.background.multiInputTextBg,
        },
      }}
    >
      <IconButton
        color='inherit'
        title={getModeTitle()}
        onClick={toggleColorMode}
        disabled={areButtonsDisabled}
        sx={{ '.MuiSvgIcon-root': { width: { sm: '26px', xs: '22px' }, height: { sm: '26px', xs: '22px' } } }}
      >
        {isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />}
        <Typography ml={1} fontSize={{ sm: 16, xs: 13 }}>
          {isDarkTheme ? t('labels.lightMode') : t('labels.darkMode')}
        </Typography>
      </IconButton>
      <IconButton
        titles={t('labels.locales')}
        disabled={areButtonsDisabled}
        sx={{ '.MuiSvgIcon-root': { width: { sm: '26px', xs: '22px' }, height: { sm: '26px', xs: '22px' } } }}
      >
        <LanguageOutlinedIcon />
        <Locales ml={1} />
      </IconButton>
      {
        R.or(H.isFalse(isAdmin), H.isTrue(isAdminToken)) && (
          <IconButton
            component={Link}
            id='Profile_button'
            to={C.ROUTE_PATH_PROFILE}
            title={t('labels.profile')}
            disabled={areButtonsDisabled}
            onClick={() => H.isTrue(isMobile) && setOpen(false)}
            sx={{
              '.MuiSvgIcon-root': {
                width: { sm: '26px', xs: '22px' },
                height: { sm: '26px', xs: '22px' },
                color: t => R.equals(pathname, C.ROUTE_PATH_PROFILE) && t.palette.primary.main,
              },
            }}
          >
            <AccountCircle />
            <Typography
              ml={1}
              fontSize={{ sm: 16, xs: 13 }}
              color={t => R.equals(pathname, C.ROUTE_PATH_PROFILE) && t.palette.primary.main}
            >
              {t('labels.profile')}
            </Typography>
          </IconButton>
        )
      }
      {
        isAdmin && (
          <IconButton
            component={Link}
            to={C.ROUTE_PATH_ADMIN_PANEL}
            disabled={areButtonsDisabled}
            title={t('labels.adminPanel')}
            sx={{
              '.MuiSvgIcon-root': {
                width: { sm: '26px', xs: '22px' },
                height: { sm: '26px', xs: '22px' },
                color: t => R.equals(pathname, C.ROUTE_PATH_ADMIN_PANEL) && t.palette.primary.main,
              },
            }}
            onClick={() => {
              H.removeCompanyAuthTokenFromSession()
              H.removeCompanyRefreshTokenFromSession()

              const access_token = H.getAuthTokenFromSession()
              const refresh_token = H.getRefreshTokenFromSession()

              const role = H.getRoleFromToken(access_token)

              dispatch(setAuthInfo({
                role,
                access_token,
                refresh_token,
                isAdminToken: false,
              }))

              H.isTrue(isMobile) && setOpen(false)
            }}
          >
            <AdminPanelSettingsIcon />
            <Typography
              ml={1}
              fontSize={{ sm: 16, xs: 13 }}
              color={t => R.equals(pathname, C.ROUTE_PATH_ADMIN_PANEL) && t.palette.primary.main}
            >
              {t('labels.adminPanel')}
            </Typography>
          </IconButton>
        )
      }
      <IconButton
        onClick={onLogout}
        title={t('labels.logOut')}
        disabled={areButtonsDisabled}
        sx={{ '.MuiSvgIcon-root': { width: { sm: '26px', xs: '22px' }, height: { sm: '26px', xs: '22px' } } }}
      >
        <ExitToApp />
        <Typography ml={1} fontSize={{ sm: 16, xs: 13 }}>{t('labels.logOut')}</Typography>
      </IconButton>
    </Box>
  )
}

// TODO: check using some props from parent layout (like companySubscriptionData)
const Header = ({ showWarning, showWarningFreeAndTrial }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const isDarkTheme = H.isDarkTheme(theme)
  const auth = useSelector(makeSelectAuthStore)
  const colorMode = useContext(ColorModeContext)
  const isAdminToken = useSelector(makeSelectIsAdminToken)
  const toggleColorMode = () => colorMode.toggleColorMode()
  const isAdmin = H.isAdmin(H.getPropFromObject('role', auth))
  const openedAsideMenu = useSelector(makeSelectTourAsideMenuOpened)
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)
  const openedAccountMenu = useSelector(makeSelectTourAccountMenuOpened)

  const checkIsAdmin = () => {
    if (isAdmin) return H.ifElse(H.isTrue(isAdminToken), true, false)

    return true
  }

  const {
    data: companySubscriptionData = [],
    error: companySubscriptionError,
    refetch: companySubscriptionRefetch,
    isLoading: companySubscriptionIsLoading,
    isFetching: companySubscriptionIsFetching,
  } = useFetchCompanySubscriptionQuery()

  const {
    data: companyData,
    error: companyError,
    refetch: companyRefetch,
    isLoading: companyIsLoading,
    isFetching: companyIsFetching,
  } = useFetchCompanyQuery(undefined, { skip: H.isFalse(checkIsAdmin()) })

  const {
    data: companyLogo,
    error: companyLogoError,
    refetch: companyLogoRefetch,
    isLoading: companyLogoIsLoading,
    isFetching: companyLogoIsFetching,
  } = useGetCompanyLogoQuery()

  const {
    data: mailingUsage,
    error: mailingUsageError,
    refetch: mailingUsageRefetch,
    isLoading: mailingUsageIsLoading,
    isFetching: mailingUsageIsFetching,
  } = useFetchMailingUsageQuery(undefined, { skip: H.isFalse(checkIsAdmin()) })

  const [
    refreshMailingUsage,
    {
      error: refreshMailingUsageError,
      isLoading: refreshMailingUsageIsLoading,
      isFetching: refreshMailingUsageIsFetching,
    },
  ] = useRefreshMailingUsageMutation()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const freeAccess = R.pathOr('', [C.FIELD_FREE_ACCESS], companyData)

  const isTrialActive = R.gt(R.pathOr('', [C.FIELD_TRIAL_EMAILS_LEFT], mailingUsage), 0)

  const subscriptionStatus = H.getPropFromObject(C.FIELD_STATUS, R.head(R.or(companySubscriptionData, [])))
  const isSubscriptionExpired = R.equals(subscriptionStatus, C.SUBSCRIPTION_STATUSES.EXPIRED)

  const isSubscriptionAvailable
    = (H.isFalse(companySubscriptionIsLoading) &&
      H.isNotNilAndNotEmpty(companySubscriptionData) &&
      H.isFalse(isSubscriptionExpired)
    ) || isAdmin

  const warningType = R.and(H.isNilOrEmpty(companySubscriptionData), H.isFalse(showWarningFreeAndTrial))
    ? 'subscription' : 'mailing'

  const handleLogout = () => {
    H.removeAuthTokenFromSession()
    H.removeRefreshTokenFromSession()
    H.removeCompanyAuthTokenFromSession()
    H.removeCompanyRefreshTokenFromSession()

    location.reload() // eslint-disable-line
  }

  useEffect(() => {
    if (H.isNilOrEmpty(companyLogo)) companyLogoRefetch()

    companySubscriptionRefetch()

    if (R.and(H.isTrue(checkIsAdmin()), H.isNilOrEmpty(companyData))) companyRefetch()

    if (R.and(H.isTrue(checkIsAdmin()), H.isNilOrEmpty(mailingUsage))) mailingUsageRefetch()
  }, [isAdmin, companyLogoRefetch, mailingUsageRefetch, companySubscriptionRefetch]) // eslint-disable-line

  useEffect(() => {
    if (R.and(isMobile, H.isTrue(openedAsideMenu))) setOpen(true)

    if (H.isFalse(openedAsideMenu)) setOpen(false)
  }, [isMobile, openedAsideMenu]) // eslint-disable-line

  const isAnyError = R.any(H.isTrue,
    [
      companyError,
      companyLogoError,
      mailingUsageError,
      companySubscriptionError,
      refreshMailingUsageError,
    ],
  )

  const isAnyLoading = R.any(H.isTrue,
    [
      companyIsLoading,
      companyIsFetching,
      companyLogoIsLoading,
      companyLogoIsFetching,
      mailingUsageIsLoading,
      mailingUsageIsFetching,
      refreshMailingUsageIsLoading,
      companySubscriptionIsLoading,
      companySubscriptionIsFetching,
      refreshMailingUsageIsFetching,
    ],
  )

  useToastrError({
    error: isAnyError,
    isLoading: isAnyLoading,
  })

  return (
    <AppBar
      sx={{
        width: H.ifElse(isMobile, headerWidth, '100%'),
        maxHeight: H.ifElse(R.not(showWarning), '60px', H.ifElse(isMobile, '170px', '130px')),
      }}
    >
      {
        R.and(showWarning, H.isFalse(isAdmin)) && <WarningMessage t={t} type={warningType} />
      }
      <Toolbar
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: t => t.colors.toolbarBackgroundColor,
        }}
      >
        <Box display='flex'>
          <MenuLogo t={t} isLoading={isAnyLoading} companyLogo={companyLogo} isDarkTheme={isDarkTheme} />
          {
            R.and(checkIsAdmin(), H.isFalse(isMobile)) &&
              <Box sx={{ maxWidth: { md: 350, lg: 570, xl: 780, xxl: 980, xxxl: 1260 } }}>
                <Tabs
                  scrollButtons
                  variant='scrollable'
                  indicatorColor='primary'
                  value={R.findIndex(R.propEq('path', pathname), routes)}
                  sx={{
                    marginRight: 'auto',
                    '.MuiSvgIcon-root': { fontSize: 30 },
                    '.MuiButtonBase-root': { padding: '22px 0' },
                    '.MuiSvgIcon-root:hover': {
                      color: isDarkTheme ? t => t.palette.primary.main : t => t.palette.primary.light,
                    },
                  }}
                >
                  {
                    routes.map((route, index) => (
                      <Tab
                        key={index}
                        to={route.path}
                        id={route.path}
                        component={Link}
                        title={t(route.label)}
                        sx={{ ml: 2, mr: 2 }}
                        label={t(route.label)}
                        disabled={R.or(
                          areButtonsDisabled,
                          R.all(R.equals(false), [freeAccess, isTrialActive, isSubscriptionAvailable]),
                        )}
                      />
                    ))
                  }
                </Tabs>
              </Box>
          }
        </Box>
        <Box display='flex'>
          {
            H.isTrue(checkIsAdmin()) &&
              <MailsCounter
                t={t}
                dispatch={dispatch}
                count={mailingUsage}
                freeAccess={freeAccess}
                refreshMailingUsage={refreshMailingUsage}
                mailingUsageIsLoading={mailingUsageIsLoading}
              />
          }
          {
            isMobile
              ? (
                <IconButton
                  title={t('actions.menu')}
                  onClick={() => setOpen(true)}
                  sx={{
                    marginLeft: 'auto',
                    '&:hover': { backgroundColor: 'primary' },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )
              : (
                <AccountMenu
                  t={t}
                  isAdmin={isAdmin}
                  isMobile={isMobile}
                  pathname={pathname}
                  onLogout={handleLogout}
                  isDarkTheme={isDarkTheme}
                  isAdminToken={isAdminToken}
                  toggleColorMode={toggleColorMode}
                  openedAccountMenu={openedAccountMenu}
                />
              )
          }
          <MenuComponent
            t={t}
            open={open}
            isAdmin={isAdmin}
            setOpen={setOpen}
            pathname={pathname}
            onLogout={handleLogout}
            freeAccess={freeAccess}
            isDarkTheme={isDarkTheme}
            isAdminToken={isAdminToken}
            checkIsAdmin={checkIsAdmin}
            isTrialActive={isTrialActive}
            toggleColorMode={toggleColorMode}
            areButtonsDisabled={areButtonsDisabled}
            isSubscriptionAvailable={isSubscriptionAvailable}
          />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header

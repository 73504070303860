import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailingJobAPI = createApi({
  tagTypes: ['MailingJob'],
  reducerPath: 'mailingJobAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchMailingJobs: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.mailingJob,
      }),
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'MailingJob'),
    }),
    createMailingJobs: build.mutation({
      query: body => (
        {
          body,
          method: E.METHODS.POST,
          url: E.ENDPOINTS.mailingJob,
        }
      ),
      transformResponse: response => response.elements,
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailingJob'),
    }),
    getMailingJob: build.query({
      query: id => `${E.ENDPOINTS.mailingJob}/${id}`,
      providesTags: (result, error, id) => [{ type: 'MailingJob', id }],
    }),
    updateMailingJob: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailingJob,
      }),
    }),
    deleteMailingJob: build.mutation({
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.mailingJob}/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'MailingJob', id }],
    }),
  }),
})

export const {
  useGetMailingJobQuery,
  useFetchMailingJobsQuery,
  useDeleteMailingJobMutation,
  useUpdateMailingJobMutation,
  useCreateMailingJobsMutation,
} = mailingJobAPI

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailingUsageAPI = createApi({
  tagTypes: ['MailingUsage'],
  reducerPath: 'mailingUsageAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchMailingUsage: build.query({
      extraOptions: { useLoader: true },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.mailingUsage,
      }),
      providesTags: (result, error, id) => [{ type: 'MailingUsage', id }],
    }),
    refreshMailingUsage: build.mutation({
      extraOptions: { useLoader: true },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.mailingUsage,
      }),
      providesTags: (result, error, id) => [{ type: 'MailingUsage', id }],
    }),
  }),
})

export const {
  useFetchMailingUsageQuery,
  useRefreshMailingUsageMutation,
} = mailingUsageAPI

import * as R from 'ramda'
import { Link } from 'react-router-dom'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// material
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Grow from '@mui/material/Grow'
import Menu from '@mui/material/Menu'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import CircularProgress from '@mui/material/CircularProgress'
import ClickAwayListener from '@mui/material/ClickAwayListener'
// icons-material
import StartIcon from '@mui/icons-material/PlayArrow'
import EditIcon from '@mui/icons-material/EditOutlined'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
// components
import { ModalComponent } from './modal'
import { DeleteModalAlert } from './row-actions'
import { setVideoTourIsOpened, makeSelectDisableTourButtons } from './tour/tourSlice'
// forms
import { MassAddGroupsForm } from '../forms/mass-add-groups-form'
import { MassUpdateDateForm } from '../forms/mass-update-dates-form'
// helpers
import * as H from '../helpers'
import * as C from '../constants'
//////////////////////////////////////////////////

const setDefaultDisableBtnStyles = isDarkTheme => ({
  color: H.ifElse(isDarkTheme, 'background.main', 'darkGrey'),
  borderColor: H.ifElse(isDarkTheme, 'background.main', 'none'),
  backgroundColor: H.ifElse(isDarkTheme, t => t.rgbaColors.black0_012, t => t.rgbaColors.lightGrey_012),
})

export const BaseButton = ({
  id,
  sx,
  mt,
  ml,
  type,
  title,
  loading,
  disabled,
  startIcon,
  handleClick,
  variant = 'contained',
}) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isDarkTheme = H.isDarkTheme(theme)
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)

  const defaultStyles = {
    border: H.ifElse(isDarkTheme, '1px solid', 'none'),
    borderColor: H.ifElse(isDarkTheme, 'background.main', 'none'),
    color: H.ifElse(R.equals(variant, 'contained'), 'background.main', 'background.white'),
    backgroundColor: H.ifElse(
      R.equals(variant, 'contained'),
      'background.transparentMain',
      'background.main',
    ),
  }

  const disabledStyles = H.isTrue(areButtonsDisabled)
    ? defaultStyles
    : setDefaultDisableBtnStyles(isDarkTheme)

  return (
    <Button
      type={type}
      onClick={handleClick}
      title={t(`actions.${title}`)}
      startIcon={R.or(startIcon, null)}
      variant={R.or(variant, 'contained')}
      id={R.or(id, `${t(`actions.${title}`)}_button`)}
      disabled={R.or(disabled, H.isTrue(areButtonsDisabled))}
      sx={{
        mt: mt || 3,
        lineHeight: 1.2,
        boxShadow: 'none',
        height: { sm: 40, xs: 35 },
        ml: ml || { lg: 2, md: 2, sm: 2, xs: 1 },
        fontSize: { lg: 16, md: 14, sm: 12, xs: 12 },
        ...defaultStyles,
        '&.Mui-selected': {
          border: H.ifElse(isDarkTheme, '1px solid', 'none'),
          borderColor: H.ifElse(isDarkTheme, 'background.main', 'none'),
          color: H.ifElse(R.equals(variant, 'contained'), 'background.white', 'background.main'),
          backgroundColor: H.ifElse(
            R.equals(variant, 'contained'),
            'background.main',
            'background.transparentMain',
          ),
        },
        '&.Mui-disabled': disabledStyles,
        '&:hover': {
          border: H.ifElse(isDarkTheme, '1px solid', 'none'),
          borderColor: H.ifElse(isDarkTheme, 'background.main', 'none'),
          color: H.ifElse(R.equals(variant, 'contained'), 'background.white', 'background.main'),
          backgroundColor: H.ifElse(
            R.equals(variant, 'contained'),
            'background.main',
            'background.transparentMain',
          ),
        },
        ...sx, // eslint-disable-line
      }}
    >
      {
        loading
          ? <CircularProgress size={16} color='inherit' />
          : <>{t(`actions.${title}`)}</>
      }
    </Button>
  )
}

export const BaseFabBtn = props => {
  const { id, mt, ml, icon, title, onClick, fabTitle, boxStyles, alwaysViolet, areButtonsDisabled = false } = props

  const theme = useTheme()
  const isDarkTheme = H.isDarkTheme(theme)

  const textColor = H.ifElse(alwaysViolet, theme.colors.whiteColorMain, 'text.primary')
  const backgroundColor = H.ifElse(R.or(isDarkTheme, alwaysViolet), 'background.violet', 'background.lightGrey')
  const hoverBackgroundColor = H.ifElse(
    R.or(isDarkTheme, alwaysViolet), 'background.darkViolet', 'background.transparentMain',
  )

  const disabledStyles = H.isTrue(areButtonsDisabled)
    ? { backgroundColor, color: textColor }
    : setDefaultDisableBtnStyles(isDarkTheme)

  return (
    <Box
      id={id}
      ml={ml}
      mt={mt}
      display='flex'
      onClick={onClick}
      alignItems='center'
      disabled={H.isTrue(areButtonsDisabled)}
      sx={{
        ...H.spreadUiStyles(boxStyles),
      }}
    >
      <Fab
        size='small'
        title={fabTitle}
        component='span'
        variant='extended'
        disabled={H.isTrue(areButtonsDisabled)}
        sx={{
          backgroundColor,
          color: textColor,
          boxShadow: 'none',
          '&.Mui-disabled': disabledStyles,
          fontSize: { lg: 16, md: 14, sm: 12, xs: 12 },
          '&:hover': {
            backgroundColor: hoverBackgroundColor,
          },
        }}
      >
        {H.isNotNilAndNotEmpty(icon) && icon}
        {title}
      </Fab>
    </Box>
  )
}

const OpenTourButtonsList = ({ t, dispatch, buttonSettings }) => (
  <Box
    display='flex'
    flexDirection='column'
    alignItems='flex-start'
    sx={{
      '.MuiIconButton-root': {
        justifyContent: 'flex-start',
        borderRadius: '1px !important',
      },
      '.MuiIconButton-root:hover': {
        width: '100%',
        borderRadius: '8px !important',
        backgroundColor: t => t.palette.background.multiInputTextBg,
      },
    }}
  >
    {
      buttonSettings.map((setting, index) => {
        const renderButtonText
          = R.equals(index, 0) ? t(setting.title) : `${t('labels.learnHowTo')} ${R.toLower(t(setting.title))}`

        return (
          <Box key={index}>
            {
              H.isTrue(R.pathOr(false, ['showVideoPlayBtn'], setting))
                ? (
                  <IconButton
                    component={Link}
                    title={t('actions.playVideoTutorial')}
                    onClick={() => dispatch(setVideoTourIsOpened(true))}
                  >
                    <VideoLibraryIcon />
                    <Typography ml={1} sx={{ textAlign: 'start' }}>{t('actions.playVideoTutorial')}</Typography>
                  </IconButton>
                )
                : (
                  <IconButton
                    component={Link}
                    title={renderButtonText}
                    onClick={() => dispatch(setting.action)}
                  >
                    <StartIcon />
                    <Typography ml={1} sx={{ textAlign: 'start' }}>{renderButtonText}</Typography>
                  </IconButton>
                )
            }
          </Box>
        )
      })
    }
  </Box>
)

export const OpenTourButton = ({ buttonSettings }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)

  return (
    <>
      <BaseFabBtn
        alwaysViolet={true}
        id='openTour_button'
        boxStyles={{ mx: 2 }}
        title={t('actions.tutorial')}
        fabTitle={t('actions.openTourSteps')}
        areButtonsDisabled={areButtonsDisabled}
        onClick={({ currentTarget }) =>
          H.isTrue(areButtonsDisabled) ? setAnchorEl(null) : setAnchorEl(currentTarget)
        }
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            paddingLeft: 2,
            paddingRight: 2,
            top: '60px !important',
            display: H.isTrue(areButtonsDisabled) && 'none',
          },
        }}
      >
        <OpenTourButtonsList t={t} dispatch={dispatch} buttonSettings={buttonSettings} />
      </Menu>
    </>
  )
}

export const GoogleButton = ({ id, title, disabled = false, handleClick }) => {
  const { t, i18n } = useTranslation()

  const theme = useTheme()
  const isDarkTheme = H.isDarkTheme(theme)
  const isCurrentLanguageUA = R.equals(i18n.language, 'ua')
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)

  const defaultStyles = {
    backgroundColor: H.ifElse(isDarkTheme, t => t.colors.blueColor, t => t.colors.white),
    fontSize: { lg: isCurrentLanguageUA ? 13 : 16, md: isCurrentLanguageUA ? 13 : 14, sm: 12, xs: 12 },
    color: H.ifElse(isDarkTheme, t => t.colors.whiteColorMain, t => t.palette.background.darkGrey),
  }

  const disabledStyles = H.isTrue(areButtonsDisabled)
    ? defaultStyles
    : setDefaultDisableBtnStyles(isDarkTheme)

  return (
    <Button
      id={id}
      variant='contained'
      onClick={handleClick}
      title= {t('actions.authorize')}
      disabled={R.or(disabled, H.isTrue(areButtonsDisabled))}
      sx={{
        ...defaultStyles,
        width: 'auto',
        boxShadow: 'none',
        fontWeight: 'bold',
        borderRadius: '8px',
        mb: { sm: 0, xs: 2 },
        textTransform: 'none',
        height: { sm: 40, xs: 35 },
        padding: '4px 8px 4px 4px',
        fontFamily: 'Roboto-Medium',
        '&.Mui-disabled': disabledStyles,
        '&.Mui-selected': {
          backgroundColor: H.ifElse(isDarkTheme, t => t.colors.blueColor, 'background.white'),
          color: H.ifElse(isDarkTheme, t => t.colors.whiteColorMain, t => t.palette.background.darkGrey),
        },
        '&:hover': {
          color: H.ifElse(isDarkTheme, t => t.palette.text.secondary, t => t.colors.whiteColorMain),
          backgroundColor: H.ifElse(isDarkTheme, t => t.colors.whiteColorMain, t => t.colors.blueColor),
        },
      }}
    >
      <Box
        sx={{
          mr: 1,
          width: 30,
          height: 30,
          display: 'flex',
          borderRadius: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          background: t => t.colors.whiteColorMain,
        }}
      >
        <img
          alt='Google logo'
          src='https://developers.google.com/identity/images/g-logo.png'
          style={{
            width: '20px',
            height: '20px',
            background: 'transparent',
          }}
        />
      </Box>
      {t(`actions.${title}`)}
    </Button>
  )
}

export const MassActionsButton = ({
  groupsArr,
  currentList,
  handleMassAdd,
  handleMassDelete,
  isAnyRowSelected,
  massAddComponentProps,
  massDeleteComponentProps,
}) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const theme = useTheme()
  const anchorRef = useRef(null)
  const isDarkTheme = H.isDarkTheme(theme)
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)
  const disabled = R.or(massAddComponentProps.disabled, H.isTrue(areButtonsDisabled))
  const backgroundColor = H.ifElse(isDarkTheme, 'background.violet', 'background.lightGrey')
  const hoverBackgroundColor = H.ifElse(isDarkTheme, 'background.darkViolet', 'background.transparentMain')

  const modalComponentForm = () => {
    if (R.equals(selectedIndex, 0) && R.equals(currentList, C.LIST_TRUCKS)) {
      return (
        <MassUpdateDateForm
          submitAction={handleMassAdd}
          cancelAction={() => setOpen(false)}
          isLoading={massAddComponentProps.isLoading}
          submitText={massAddComponentProps.submitText}
        />
      )
    }

    if (R.equals(selectedIndex, 0) && R.equals(currentList, C.LIST_EMAILS)) {
      return (
        <MassAddGroupsForm
          groupsArr={groupsArr}
          submitAction={handleMassAdd}
          cancelAction={() => setOpen(false)}
          isLoading={massAddComponentProps.isLoading}
          submitText={massAddComponentProps.submitText}
        />
      )
    }

    if (R.equals(selectedIndex, 1)) {
      return (
        <DeleteModalAlert
          submitText='confirm'
          submitAction={handleMassDelete}
          cancelAction={() => setOpen(false)}
          infoSettings={[{ text: 'confirmMassDelete' }]}
          isLoading={massDeleteComponentProps.isLoading}
        />
      )
    }

    return null
  }

  const handleMenuItemClick = (event, index) => {
    if (H.isFalse(isAnyRowSelected)) {
      setOpen(false)

      return H.showToastrMessageSimple('error', massAddComponentProps.submitError)
    } else {
      setOpen(true)
    }

    setSelectedIndex(index)
    setOpenMenu(false)
  }

  const handleToggle = () => {
    setOpenMenu(prevOpenMenu => R.not(prevOpenMenu))
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return

    setOpenMenu(false)
  }

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label='mass actions button' title={t('actions.selectMassActions')}>
        <Fab
          size='small'
          variant='extended'
          disabled={disabled}
          onClick={handleToggle}
          sx={{
            backgroundColor,
            boxShadow: 'none',
            color: 'text.primary',
            borderRadius: '32px 0 0 32px',
            fontSize: { lg: 16, md: 14, sm: 12, xs: 12 },
            '&:hover': {
              backgroundColor: hoverBackgroundColor,
            },
          }}
        >
          <EditIcon titleAccess={t('actions.massActions')} />
          {t('actions.massActions')}
        </Fab>
        <Fab
          size='small'
          variant='extended'
          disabled={disabled}
          onClick={handleToggle}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-controls={openMenu ? 'split-button-menu' : undefined}
          sx={{
            backgroundColor,
            boxShadow: 'none',
            color: 'text.primary',
            borderRadius: '0 32px 32px 0',
            '&:hover': {
              backgroundColor: hoverBackgroundColor,
            },
          }}
        >
          <ArrowDropDownIcon />
        </Fab>
      </ButtonGroup>
      <Popper
        transition
        disablePortal
        open={openMenu}
        role={undefined}
        anchorEl={anchorRef.current}
        sx={{
          zIndex: 1,
        }}
      >
        {
          ({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem id='split-button-menu'>
                    <MenuItem
                      selected={0 === selectedIndex}
                      onClick={event => handleMenuItemClick(event, 0)}
                      sx={{ fontSize: { lg: 16, md: 14, sm: 12, xs: 12 } }}
                    >
                      {massAddComponentProps.titleIcon}
                      {massAddComponentProps.title}
                    </MenuItem>
                    <MenuItem
                      selected={1 === selectedIndex}
                      onClick={event => handleMenuItemClick(event, 1)}
                      sx={{ fontSize: { lg: 16, md: 14, sm: 12, xs: 12 } }}
                    >
                      {massDeleteComponentProps.titleIcon}
                      {massDeleteComponentProps.title}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        }
      </Popper>
      <ModalComponent
        opened={open}
        setOpen={setOpen}
        component={modalComponentForm()}
        modalTitle={selectedIndex === 0 ? massAddComponentProps.modalTitle : massDeleteComponentProps.modalTitle}
      />
    </>
  )
}

import * as R from 'ramda'
// helpers
import * as H from './common'
// components
import { SelectColumn, MultipleSelectColumn } from '../components/datasheet-component/select'
//////////////////////////////////////////////////

const handleCopyOrPasteValue = ({ value, options, operationType }) => {
  const isCopy = R.equals(operationType, 'copy')
  const key = H.ifElse(isCopy, 'name', 'value')
  let valueToPaste

  if (H.isString(value)) {
    valueToPaste = R.compose(
      R.join('_'),
      R.map(R.toUpper),
      R.split(' '),
      R.trim,
    )(value)
  }

  return R.compose(
    R.pathOr(null, [key]),
    R.find(R.propEq('value', H.ifElse(isCopy, value, valueToPaste))),
  )(options)
}

export const getSelectColumnOptions = (options, initialValueName) => ({
  keepFocus: true,
  disableKeys: true,
  deleteValue: () => null,
  customComponent: props => <SelectColumn {...props} options={options} initialValueName={initialValueName} />,
  pasteValue: ({ value }) => handleCopyOrPasteValue({ options, value, operationType: 'paste' }),
  copyValue: ({ rowData }) => handleCopyOrPasteValue({ options, value: rowData, operationType: 'copy' }),
})

export const getSelectMultipleColumnOptions = (options, maxLimit, noTranslation) => ({
  keepFocus: true,
  disableKeys: true,
  deleteValue: () => null,
  customComponent: props =>
    <MultipleSelectColumn {...props} options={options} maxLimit={maxLimit} noTranslation={noTranslation} />,
})

export const addIsDisabledToOptions = (options, valuesToDisable) =>
  R.map(option =>
    R.assoc(
      'isDisabled',
      R.includes(H.getPropFromObject('value', option), valuesToDisable),
      option,
    ), R.or(options, []),
  )

export const removeEnumsFromOptions = (options, enumsToRemove) =>
  R.filter(R.compose(
    R.not,
    R.includes(R.__, enumsToRemove),
    R.prop('value'),
  ), options)

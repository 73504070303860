export const truckListRowsExample = [
  {
    // quantity: 3, // TODO: clarify if it needed
    truckId: '1',
    driverType: 'SINGLE',
    // driverName: 'John Doe', // TODO: clarify if it needed
    truckType: 'HEAVY_HAUL',
    equipmentType: 'DRY_VAN',
    pickupDateTime: '01/01/2023',
    destinationLocations: 'IL, CA',
    originLocation: 'Chicago, IL, USA',
    destinationZones: 'My first Destination Zone, My second Destination Zone',
  },
]

export const truckListColumnsExample = [
  {
    width: 18.75,
    disabled: false,
    key: 'truckId',
    header: 'truckId',
  },
  // TODO: clarify if it needed
  // {
  //   width: 18.75,
  //   disabled: false,
  //   key: 'quantity',
  //   header: 'quantity',
  // },
  {
    width: 18.75,
    disabled: false,
    key: 'pickupDateTime',
    header: 'pickupDateTime',
  },
  {
    width: 28.125,
    disabled: false,
    key: 'originLocation',
    header: 'originLocation',
  },
  {
    width: 25,
    disabled: false,
    key: 'destinationZones',
    header: 'destinationZones',
  },
  {
    width: 25,
    disabled: false,
    key: 'destinationLocations',
    header: 'destinationLocations',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'equipmentType',
    header: 'equipmentType',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'truckType',
    header: 'truckType',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'driverType',
    header: 'driverType',
  },
  // TODO: clarify if it needed
  // {
  //   width: 18.75,
  //   disabled: false,
  //   key: 'driverName',
  //   header: 'driverName',
  // },
]

export const emailListRowsExample = [
  {
    lastName: 'Doe',
    mcNumber: '1122',
    firstName: 'John',
    subscribed: 'Yes',
    dotNumber: '2233',
    position: 'supervisor',
    companyName: 'trucking company',
    email: 'example@examplemail.com',
    groups: 'My first Group, My second Group',
  },
]

export const emailListColumnsExample = [
  {
    width: 18.75,
    disabled: false,
    key: 'companyName',
    header: 'companyName',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'mcNumber',
    header: 'mcNumber',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'dotNumber',
    header: 'dotNumber',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'firstName',
    header: 'firstName',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'lastName',
    header: 'lastName',
  },
  {
    width: 25,
    key: 'email',
    disabled: false,
    header: 'email',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'position',
    header: 'position',
  },
  {
    width: 18.75,
    key: 'groups',
    disabled: false,
    header: 'groups',
  },
  {
    width: 18.75,
    disabled: false,
    key: 'subscribed',
    header: 'Subscribed',
  },
]

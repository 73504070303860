// helpers/constants
import * as C from './common'
// translations
import i18n from '../translations'
//////////////////////////////////////////////////

export const ENUM_SMTP = 'SMTP'
export const ENUM_GMAIL = 'GMAIL'
// smtp type
export const ENUM_OTHER = 'OTHER'
export const ENUM_OUTLOOK = 'OUTLOOK'
// smtp security protocol
export const ENUM_SSL = 'SSL'
export const ENUM_TTLS = 'TTLS'
// driverType
export const ENUM_TEAM = 'TEAM'
export const ENUM_SINGLE = 'SINGLE'
// truckType
export const ENUM_LONG_HAUL = 'LONG_HAUL'
export const ENUM_LOCAL_HAUL = 'LOCAL_HAUL'
export const ENUM_HEAVY_HAUL = 'HEAVY_HAUL'
export const ENUM_SPECIALTY_TRUCKS = 'SPECIALTY_TRUCKS'
// equipmentType
export const ENUM_VAN = 'VAN'
export const ENUM_LOWBOY = 'LOWBOY'
export const ENUM_REEFER = 'REEFER'
export const ENUM_DRY_VAN = 'DRY_VAN'
export const ENUM_FLATBED = 'FLATBED'
export const ENUM_CONVEYOR = 'CONVEYOR'
export const ENUM_CONTAINER = 'CONTAINER'
export const ENUM_PNEUMATIC = 'PNEUMATIC'
export const ENUM_CONESTOGA = 'CONESTOGA'
export const ENUM_STEP_DECK = 'STEP_DECK'
export const ENUM_MOVING_VAN = 'MOVING_VAN'
export const ENUM_VAN_DOUBLE = 'VAN_DOUBLE'
export const ENUM_VAN_VENTED = 'VAN_VENTED'
export const ENUM_VAN_TRIPLE = 'VAN_TRIPLE'
export const ENUM_POWER_ONLY = 'POWER_ONLY'
export const ENUM_VAN_W_TEAM = 'VAN_W_TEAM'
export const ENUM_VAN_CURTAIN = 'VAN_CURTAIN'
export const ENUM_VAN_HAZ_MAT = 'VAN_HAZ_MAT'
export const ENUM_VAN_HOTSHOT = 'VAN_HOTSHOT'
export const ENUM_DOUBLE_DROP = 'DOUBLE_DROP'
export const ENUM_TANKER_STEEL = 'TANKER_STEEL'
export const ENUM_VAN_OPEN_TOP = 'VAN_OPEN_TOP'
export const ENUM_VAN_AIR_RIDE = 'VAN_AIR_RIDE'
export const ENUM_DUMP_TRAILER = 'DUMP_TRAILER'
export const ENUM_AUTO_CARRIER = 'AUTO_CARRIER'
export const ENUM_FLATBED_MAXI = 'FLATBED_MAXI'
export const ENUM_VAN_LOGISTICS = 'VAN_LOGISTICS'
export const ENUM_VAN_OR_REEFER = 'VAN_OR_REEFER'
export const ENUM_LOWBOY_OR_RGN = 'LOWBOY_OR_RGN'
export const ENUM_VAN_INSULATED = 'VAN_INSULATED'
export const ENUM_HOPPER_BOTTOM = 'HOPPER_BOTTOM'
export const ENUM_REEFER_DOUBLE = 'REEFER_DOUBLE'
export const ENUM_VAN_CONESTOGA = 'VAN_CONESTOGA'
export const ENUM_VAN_LIFT_GATE = 'VAN_LIFT_GATE'
export const ENUM_REEFER_W_TEAM = 'REEFER_W_TEAM'
export const ENUM_VAN_ROLLER_BED = 'VAN_ROLLER_BED'
export const ENUM_VAN_OR_FLATBED = 'VAN_OR_FLATBED'
export const ENUM_VAN_INTERMODAL = 'VAN_INTERMODAL'
export const ENUM_FLATBED_DOUBLE = 'FLATBED_DOUBLE'
export const ENUM_REEFER_HAZ_MAT = 'REEFER_HAZ_MAT'
export const ENUM_FLATBED_W_TEAM = 'FLATBED_W_TEAM'
export const ENUM_REEFER_AIR_RIDE = 'REEFER_AIR_RIDE'
export const ENUM_STRETCH_TRAILER = 'STRETCH_TRAILER'
export const ENUM_TANKER_ALUMINUM = 'TANKER_ALUMINUM'
export const ENUM_FLATBED_HAZ_MAT = 'FLATBED_HAZ_MAT'
export const ENUM_FLATBED_HOTSHOT = 'FLATBED_HOTSHOT'
export const ENUM_FLATBED_W_SIDES = 'FLATBED_W_SIDES'
export const ENUM_FLATBED_B_TRAIN = 'FLATBED_B_TRAIN'
export const ENUM_FLATBED_AIR_RIDE = 'FLATBED_AIR_RIDE'
export const ENUM_REEFER_LOGISTICS = 'REEFER_LOGISTICS'
export const ENUM_STEP_DECK_OR_RGN = 'STEP_DECK_OR_RGN'
export const ENUM_FLATBED_W_CHAINS = 'FLATBED_W_CHAINS'
export const ENUM_FLATBED_W_TARPS = 'Flatbed With Tarps'
export const ENUM_TANKER_INTERMODAL = 'TANKER_INTERMODAL'
export const ENUM_DROP_DECK_LANDOLL = 'DROP_DECK_LANDOLL'
export const ENUM_REEFER_INTERMODAL = 'REEFER_INTERMODAL'
export const ENUM_TRUCK_AND_TRAILER = 'TRUCK_AND_TRAILER'
export const ENUM_FLATBED_CONESTOGA = 'FLATBED_CONESTOGA'
export const ENUM_FLATBED_VAN_REEFER = 'FLATBED_VAN_REEFER'
export const ENUM_STEPDECK_CONESTOGA = 'STEPDECK_CONESTOGA'
export const ENUM_STRAIGHT_BOX_TRUCK = 'STRAIGHT_BOX_TRUCK'
export const ENUM_VAN_W_BLANKET_WRAP = 'VAN_W_BLANKET_WRAP'
export const ENUM_CONTAINER_INSULATED = 'CONTAINER_INSULATED'
export const ENUM_REMOVABLE_GOOSENECK = 'REMOVABLE_GOOSENECK'
export const ENUM_FLATBED_OR_STEP_DECK = 'FLATBED_OR_STEP_DECK'
export const ENUM_REEFER_OR_VENTED_VAN = 'REEFER_OR_VENTED_VAN'
export const ENUM_LOWBOY_OVER_DIMENSION = 'LOWBOY_OVER_DIMENSION'
export const ENUM_VAN_W_PALLET_EXCHANGE = 'VAN_W_PALLET_EXCHANGE'
export const ENUM_CONTAINER_REFRIGERATED = 'CONTAINER_REFRIGERATED'
export const ENUM_FLATBED_OVER_DIMENSION = 'FLATBED_OVER_DIMENSION'
export const ENUM_VAN_OR_FLATBED_W_TARPS = 'VAN_OR_FLATBED_W_TARPS'
export const ENUM_INSULATED_VAN_OR_REEFER = 'INSULATED_VAN_OR_REEFER'
export const ENUM_REEFER_W_PALLET_EXCHANGE = 'REEFER_W_PALLET_EXCHANGE'
// days
export const ENUM_MONDAY = 'MONDAY'
export const ENUM_FRIDAY = 'FRIDAY'
export const ENUM_SUNDAY = 'SUNDAY'
export const ENUM_TUESDAY = 'TUESDAY'
export const ENUM_THURSDAY = 'THURSDAY'
export const ENUM_SATURDAY = 'SATURDAY'
export const ENUM_WEDNESDAY = 'WEDNESDAY'
// hours
export const ENUM_H_0 = 'H_0'
export const ENUM_H_1 =	'H_1'
export const ENUM_H_2 =	'H_2'
export const ENUM_H_3 =	'H_3'
export const ENUM_H_4 =	'H_4'
export const ENUM_H_5 =	'H_5'
export const ENUM_H_6 =	'H_6'
export const ENUM_H_7 =	'H_7'
export const ENUM_H_8 =	'H_8'
export const ENUM_H_9 =	'H_9'
export const ENUM_H_10 = 'H_10'
export const ENUM_H_11 = 'H_11'
export const ENUM_H_12 = 'H_12'
export const ENUM_H_13 = 'H_13'
export const ENUM_H_14 = 'H_14'
export const ENUM_H_15 = 'H_15'
export const ENUM_H_16 = 'H_16'
export const ENUM_H_17 = 'H_17'
export const ENUM_H_18 = 'H_18'
export const ENUM_H_19 = 'H_19'
export const ENUM_H_20 = 'H_20'
export const ENUM_H_21 = 'H_21'
export const ENUM_H_22 = 'H_22'
export const ENUM_H_23 = 'H_23'
// reservation-requests status
export const ENUM_NEW = 'NEW'
export const ENUM_COMPLETED = 'COMPLETED'
export const ENUM_IN_PROGRESS = 'INPROGRESS'
// error-messages
const translate = value => i18n.t(`${value}`)

export const ERROR_MESSAGES_PHRASES_TO_RENAME = {
  [C.FIELD_NAME]: translate('labels.name'),
  [C.FIELD_HOURS]: translate('labels.hours'),
  [C.FIELD_TRUCKS]: translate('labels.trucks'),
  [C.FIELD_END_DATE]: translate('labels.endDate'),
  [C.FIELD_TRUCK_ID]: translate('labels.truckId'),
  [C.FIELD_EMAIL]: translate('labels.emailAddress'),
  [C.FIELD_START_DATE]: translate('labels.startDate'),
  [C.FIELD_EMAILS]: translate('labels.emailAddresses'),
  [C.FIELD_EMAIL_GROUP_IDS]: translate('labels.groups'),
  [C.FIELD_CAMPAIGN_NAME]: translate('labels.campaignName'),
  [C.FIELD_LOCATIONS]: translate('labels.destinationOfTruck'),
  [C.LIST_EMAIL_CAMPAIGNS]: translate('labels.emailCampaigns'),
  [C.FIELD_ORIGIN_LOCATION]: translate('labels.truckLocation'),
  [C.FIELD_PICK_UP_DATE_TIME]: translate('labels.availableDate'),
  [C.FIELD_MAILING_CONFIG_ID]: translate('labels.mailingServices'),
  [C.FIELD_COMPANY_TEMPLATE_ID]: translate('labels.companyTemplate'),
  [C.FIELD_DESTINATION_ZONE_ID]: translate('labels.destinationZone'),
  [C.FIELD_DESTINATION_ZONE_IDS]: translate('labels.destinationZones'),
  [C.FIELD_DESTINATION_LOCATION]: translate('labels.destinationOfTruck'),
  [C.FIELD_DESTINATION_LOCATIONS]: translate('labels.destinationOfTruck'),
  'must not be null': translate('errors.canNotBeEmpty'),
  'must not be blank': translate('errors.canNotBeEmpty'),
  'must not be empty': translate('errors.canNotBeEmpty'),
}
// toggles
export const TOGGLE_SETTINGS = {
  TRACK_LIST: 0,
  EMAIL_LIST: 2,
  EMAIL_GROUPS: 3,
  MAP_COMPONENT: 1,
}

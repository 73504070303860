import { createSlice } from '@reduxjs/toolkit'
//////////////////////////////////////////////////

const scope = 'datasheet'

const initialState = { selectedAllRows: false }

export const datasheetSlice = createSlice({
  initialState,
  name: `${scope}`,
})

export default datasheetSlice.reducer

import * as R from 'ramda'
import { createSlice, createSelector } from '@reduxjs/toolkit'
// helpers
import * as H from '../../helpers'
//////////////////////////////////////////////////

const scope = 'tour'

const initialState = {
  tourRoute: {},
  openedMap: false,
  tourModalId: null,
  openedModal: false,
  openedGroups: false,
  tourDefaultData: [],
  openedAddMode: false,
  disableButtons: false,
  startedTourStep: null,
  tourHiddenObjects: [],
  openedAsideMenu: false,
  disableScrolling: false,
  videoTourIsOpened: false,
  openedAccountMenu: false,
}

export const tourSlice = createSlice({
  initialState,
  name: `${scope}`,
  reducers: {
    setOpenMap: (state, { payload }) => R.assoc('openedMap', payload, state),
    setOpenModal: (state, { payload }) => R.assoc('openedModal', payload, state),
    setOpenGroups: (state, { payload }) => R.assoc('openedGroups', payload, state),
    setTourModalId: (state, { payload }) => R.assoc('tourModalId', payload, state),
    setOpenAddMode: (state, { payload }) => R.assoc('openedAddMode', payload, state),
    setOpenAsideMenu: (state, { payload }) => R.assoc('openedAsideMenu', payload, state),
    setDisableButtons: (state, { payload }) => R.assoc('disableButtons', payload, state),
    setStartedTourStep: (state, { payload }) => R.assoc('startedTourStep', payload, state),
    setTourRoute: (state, { payload }) => R.assoc('tourRoute', H.jsonParse(payload), state),
    setDisableScrolling: (state, { payload }) => R.assoc('disableScrolling', payload, state),
    setOpenAccountMenu: (state, { payload }) => R.assoc('openedAccountMenu', payload, state),
    setTourDefaultDataStep: (state, { payload }) => R.assoc('tourDefaultData', payload, state),
    setTourHiddenObjects: (state, { payload }) => R.assoc('tourHiddenObjects', payload, state),
    setVideoTourIsOpened: (state, { payload }) => R.assoc('videoTourIsOpened', payload, state),
  },
})

export const {
  setOpenMap,
  setOpenModal,
  setTourRoute,
  setOpenGroups,
  setTourModalId,
  setOpenAddMode,
  setOpenAsideMenu,
  setDisableButtons,
  setOpenAccountMenu,
  setStartedTourStep,
  setDisableScrolling,
  setTourHiddenObjects,
  setVideoTourIsOpened,
  setTourDefaultDataStep,
} = tourSlice.actions

const store = ({ tour }) => tour

export const makeSelectTourModalId = createSelector(
  store,
  ({ tourModalId }) => tourModalId,
)

export const makeSelectTourRoute = createSelector(
  store,
  ({ tourRoute }) => tourRoute,
)

export const makeSelectTourMapOpened = createSelector(
  store,
  ({ openedMap }) => openedMap,
)

export const makeSelectTourGroupsOpened = createSelector(
  store,
  ({ openedGroups }) => openedGroups,
)

export const makeSelectStartedTourStep = createSelector(
  store,
  ({ startedTourStep }) => startedTourStep,
)

export const makeSelectTourModalOpened = createSelector(
  store,
  ({ openedModal }) => openedModal,
)

export const makeSelectTourDefaultData = createSelector(
  store,
  ({ tourDefaultData }) => tourDefaultData,
)

export const makeSelectTourHiddenObjects = createSelector(
  store,
  ({ tourHiddenObjects }) => tourHiddenObjects,
)

export const makeSelectTourAddModeOpened = createSelector(
  store,
  ({ openedAddMode }) => openedAddMode,
)

export const makeSelectVideoTourIsOpened = createSelector(
  store,
  ({ videoTourIsOpened }) => videoTourIsOpened,
)

export const makeSelectDisableTourButtons = createSelector(
  store,
  ({ disableButtons }) => disableButtons,
)

export const makeSelectTourAsideMenuOpened = createSelector(
  store,
  ({ openedAsideMenu }) => openedAsideMenu,
)

export const makeSelectDisableTourScrolling = createSelector(
  store,
  ({ disableScrolling }) => disableScrolling,
)

export const makeSelectTourAccountMenuOpened = createSelector(
  store,
  ({ openedAccountMenu }) => openedAccountMenu,
)

export default tourSlice.reducer

import { createApi } from '@reduxjs/toolkit/query/react'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const authAPI = createApi({
  reducerPath: 'authAPI',
  baseQuery: H.baseQuery,
  endpoints: build => ({
    signUp: H.buildQueryAPI(
      build,
      E.METHODS.POST,
      E.ENDPOINTS.signUp,
      {
        'Content-Type': E.CONTENT_TYPES.APPLICATION_JSON,
      },
    ),
    signUpConfirm: build.mutation({
      extraOptions: {
        useLoader: true,
      },
      query: ({ params }) => ({
        params,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.signUpConfirm,
        headers: H.makeRequestHeaders(),
      }),
      invalidatesTags: [{ type: 'MailingConfigsList', id: 'LIST' }],
    }),
    login: H.buildQueryAPI(
      build,
      E.METHODS.POST,
      E.ENDPOINTS.login,
      {
        'Authorization': 'Basic bWFpbGluZzpRdjUjeVQvZVYvOHkmYlFh',
      },
    ),
    forgotPassword: H.buildQueryAPI(
      build,
      E.METHODS.POST,
      E.ENDPOINTS.forgotPassword,
      H.makeRequestHeaders(),
    ),
    changePassword: build.mutation({
      extraOptions: { useLoader: true },
      query: ({ params }) => ({
        method: E.METHODS.POST,
        url: E.ENDPOINTS.changePassword,
        body: { [C.FIELD_PASSWORD]: params.password },
        headers: {
          Authorization: `Bearer ${params.token}`,
          'Content-Type': E.CONTENT_TYPES.APPLICATION_JSON,
        },
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useSignUpMutation,
  useSignUpConfirmMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
} = authAPI

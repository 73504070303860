import * as R from 'ramda'
import i18next from 'i18next'
import provinces from 'provinces-ca'
import { useSelector } from 'react-redux'
import { useStates } from 'react-us-states'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { useRef, useMemo, useState, forwardRef } from 'react'
import { getIn, Field, Formik, useFormikContext } from 'formik'
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete'
// material
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Autocomplete from '@mui/material/Autocomplete'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// icons-material
import Visibility from '@mui/icons-material/Visibility'
import PublicIcon from '@mui/icons-material/PublicOutlined'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// components
import { makeSelectDisableTourButtons } from '../components/tour/tourSlice'
// forms
import CountrySelect from './components/country-select'
import { StyledTextField, StyledFormControl } from './ui'
//////////////////////////////////////////////////

const LIST_ITEM_HEIGHT = 53
const LIST_ITEM_PADDING_TOP = 8

const SelectMenuProps = width => ({
  variant: 'menu',
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  PaperProps: {
    style: {
      width,
      maxHeight: LIST_ITEM_HEIGHT * 4.5 + LIST_ITEM_PADDING_TOP,
    },
  },
})

const sortOptions = (options, order = 'ASC') => {
  const sortByValue = R.sortBy(R.prop(C.FIELD_LABEL))

  if (R.equals(order, 'DESC')) {
    return R.reverse(sortByValue(options))
  } else {
    return sortByValue(options)
  }
}

const handleSubmitButtonClick = (touched, errors, resetSubmitBtnType, submitToastrErrorMessage) => {
  if (H.isTrue(resetSubmitBtnType) && touched && H.isNotNilAndNotEmpty(errors)) return H.showToastrMessageSimple(
    'error',
    i18next.t(`errors.${submitToastrErrorMessage ? submitToastrErrorMessage : 'pleaseEnterRequiredInfo'}`),
  )

  return null
}

const getButtonType = (touched, errors, resetSubmitBtnType) => {
  if (H.isTrue(resetSubmitBtnType) && touched && H.isNotNilAndNotEmpty(errors)) {
    return 'button'
  }

  return 'submit'
}

export const Error = ({ error, errorText }) => H.isTrue(error) && (
  <Box
    sx={{
      fontSize: 13,
      marginLeft: 0,
      fontWeight: 400,
      marginBottom: 0,
      marginTop: '4px',
      textAlign: 'left',
      marginRight: '14px',
      color: t => t.palette.background.error,
    }}
  >
    {errorText}
  </Box>
)

const getSelectName = (t, name, noTranslation) => {
  if (R.isEmpty(name)) return

  if (R.or(H.isTrue(noTranslation), H.isNumber(name))) return name

  return H.capitalizeFirstStrCharacter(t(`labels.${name}`))
}

export const getFieldTouched = (touched, fieldName) => getIn(touched, fieldName)

export const getFieldError = (errors, touched, fieldName) => {
  const error = getIn(errors, fieldName)
  const touch = getIn(touched, fieldName)

  return R.and(touch, error) && R.prop(fieldName, errors)
}

const PlacesAutocompleteMultiple = props => {
  const { id, value, label, error, helperText } = props

  const theme = useTheme()

  const formikContext = useFormikContext()

  const { palette } = theme

  const { setFieldValue } = formikContext

  const {
    setValue,
    clearSuggestions,
    suggestions: { data },
  } = usePlacesAutocomplete({
    debounce: 300,
  })

  const getLabel = suggestion => {
    if (H.isNilOrEmpty(suggestion)) return []

    const {
      structured_formatting: { main_text, secondary_text },
    } = suggestion

    return `${main_text} ${secondary_text}`
  }
  const locationKeys = R.keys(R.indexBy(R.prop('description'), R.or(value, [])))
  const options = R.compose(
    R.map(suggestion => ({ label: getLabel(suggestion), suggestion })),
    R.reject(({ description }) => R.includes(description, locationKeys)),
  )(data)

  const handleChange = (event, selectedValue, operation, option) => {
    if (R.equals(operation, 'removeOption')) {
      const locations = R.reject(
        R.propEq('description', R.path(['option', 'suggestion', 'description'], option)),
        value,
      )

      return setFieldValue(id, locations)
    }

    const { suggestion: { description } } = R.last(selectedValue)

    setValue('', false)
    clearSuggestions()

    getGeocode({ address: description }).then(results => {
      const location = R.assoc('description', description, H.getGoogleResultFields(R.head(results)))
      const locations = R.compose(
        R.uniqBy(R.prop('description')),
        R.append(location),
      )(R.or(value, []))

      setFieldValue(id, locations)
    })
  }

  return (
    <>
      <Autocomplete
        id={id}
        freeSolo
        multiple
        size='small'
        disableClearable
        defaultValue={value}
        filterOptions={x => x}
        onChange={handleChange}
        options={R.or(options, [])}
        onInputChange={(event, newInputValue) => setValue(newInputValue)}
        renderInput={params => (
          <StyledTextField
            {...R.assocPath(['InputProps', 'type'], 'search', params)}
            error={error}
            label={label}
            sx={{
              '.MuiAutocomplete-tag': { backgroundColor: palette.background.default },
              '.MuiChip-deleteIcon': { stroke: palette.text.primary, fill: palette.background.default },
              '.MuiChip-deleteIcon:hover': { fill: palette.text.primary, stroke: palette.background.default },
            }}
          />
        )}
      />
      <Error error={error} errorText={helperText} />
    </>
  )
}

const renderCountryLabel = label => (
  <Box sx={{ backgroundColor: t => t.palette.background.menuListBg }}>
    <ListItem sx={{ display: 'flex', justifyContent: 'center', backgroundColor: t => t.palette.background.menuListBg }}>
      <PublicIcon sx={{ mr: 1, cursor: 'default', color: t => t.palette.primary.secondary }} />
      <Typography
        color='default'
        variant='subtitle1'
        style={{
          fontSize: 13,
          textTransform: 'none',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word !important',
        }}
      >
        {i18next.t(`labels.${label}`)}
      </Typography>
    </ListItem>
    <Divider sx={{ width: '95%', margin: '0 auto' }} />
  </Box>
)

const StatesAutocomplete = props => {
  const { id, value, error, label, onBlur, disabled, multiple, limitTags, helperText, isDisabled, isDarkTheme } = props

  const theme = useTheme()
  const data = useStates()
  const formikContext = useFormikContext()

  const { palette } = theme

  const { setFieldValue } = formikContext

  const usaOptions = useMemo(() => R.map(({ name: label, abbreviation: value }) => ({
    label,
    value,
    id: value,
    group: 'unitedStates',
    country: C.UNITED_STATES,
  }), data), [data])

  const caOptions = useMemo(() => R.map(({ name: label, abbreviation: value }) => ({
    label,
    value,
    id: value,
    group: 'canada',
    country: C.CANADA,
  }), provinces), [])

  const options = useMemo(() => R.concat(sortOptions(usaOptions), sortOptions(caOptions)), [usaOptions, caOptions])

  const filterOptions = (options, input) => R.filter(
    option =>
      R.includes(R.toLower(H.getPropFromObject('inputValue', input)), R.toLower(H.getPropFromObject('label', option))),
    options,
  )

  const isOptionDisabled = option => R.includes(H.getPropFromObject('value', option), selectedStates)

  const selectedStates = useMemo(() => R.map(({ value, state }) => R.or(state, value), R.or(value, [])), [value])

  const handleChange = (event, selectedValue) => setFieldValue(id, R.filter(H.isNotString, selectedValue))

  const countryLabels = useMemo(() =>  // eslint-disable-line
    R.compose(
      R.reject(R.isNil),
      R.uniq,
      R.pluck('group'),
    )(options),
  [options],
  )

  return (
    <>
      <Autocomplete
        id={id}
        freeSolo
        openOnFocus
        size='small'
        value={value}
        onBlur={onBlur}
        disableClearable
        options={options}
        multiple={multiple}
        disableCloseOnSelect
        filterSelectedOptions
        limitTags={limitTags}
        onChange={handleChange}
        filterOptions={filterOptions}
        getOptionDisabled={isOptionDisabled}
        disabled={R.or(disabled, isDisabled)}
        getOptionLabel={option => R.pathOr('', ['value'], option)}
        groupBy={option => H.getPropFromObject(C.FIELD_GROUP, option)}
        PaperComponent={({ children }) =>
          <Paper style={{ width: 200, backgroundColor: palette.background.menuListBg }}>{children}</Paper>
        }
        renderGroup={params => {
          const groupLabel = H.getPropFromObject('group', params)
          const children = H.getPropFromObject('children', params)

          if (R.includes(groupLabel, countryLabels)) {
            return (
              <Box sx={{ backgroundColor: palette.background.menuListBg }}>
                {renderCountryLabel(groupLabel)}
                {children}
              </Box>
            )
          }

          return null
        }}
        renderInput={params => (
          <StyledTextField
            {...params}
            label={label}
            error={error}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            sx={{
              marginTop: 2,
              '.MuiAutocomplete-tag': { backgroundColor: palette.background.default },
              '& .MuiInputLabel-root': isDisabled && setDefaultDisableStyles(isDarkTheme),
              '.MuiChip-deleteIcon': { stroke: palette.text.primary, fill: palette.background.default },
              '& .MuiOutlinedInput-input': { color: isDisabled && setDefaultDisableStyles(isDarkTheme) },
              '.MuiChip-deleteIcon:hover': { fill: palette.text.primary, stroke: palette.background.default },
              '& .Mui-disabled': {
                color: t => `${t.palette.text.primary} !important`,
                'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
              },
            }}
          />
        )}
        renderOption={(props, { label, value }) => (
          <ListItemText
            {...props}
            sx={{
              '.MuiListItemText-primary': {
                fontSize: 13,
                color: props['aria-disabled'] ? palette.primary.main : palette.text.primary,
              },
              '&.MuiAutocomplete-option': {
                margin: 0,
                opacity: '1 !important',
                backgroundColor: palette.background.menuListBg,
                '&:hover': {
                  backgroundColor: palette.background.secondaryMainColor,
                },
                '&[aria-disabled="true"]': {
                  backgroundColor: palette.background.default,
                },
              },
            }}
          >
            {label}&nbsp;({value})
          </ListItemText>
        )}
      />
      <Error error={error} errorText={helperText} />
    </>
  )
}

const PasswordField = ({ shrink, fullWidth, inputProps, helperText, marginBottom }) => {
  const { width, padding } = inputProps

  const [showPassword, setShowPassword] = useState(false)

  return (
    <StyledTextField
      {...inputProps}
      fullWidth={fullWidth}
      helperText={helperText}
      InputLabelProps={{ shrink }}
      type={H.ifElse(showPassword, 'text', 'password')}
      sx={{
        width,
        marginBottom,
        '.MuiOutlinedInput-input': {
          height: 40,
          maxHeight: 40,
          padding: R.or(padding, '0 10px'),
        },
      }}
      InputProps={{
        endAdornment: <InputAdornment position='end'>
          <IconButton
            edge='end'
            onClick={() => setShowPassword(R.not)}
            aria-label='toggle password visibility'
          >
            {
              showPassword
                ? <Visibility titleAccess={i18next.t('actions.hide')} color='secondary' sx={{ fontSize: 24 }} />
                : <VisibilityOff titleAccess={i18next.t('actions.show')} color='secondary' sx={{ fontSize: 24 }} />
            }
          </IconButton>
        </InputAdornment>,
      }}
    />
  )
}

const TimeZoneField = ({ inputProps }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors, palette, rgbaColors } = useTheme()

  const optionColorsMap = {
    transparent: 'transparent',
    '#DEEBFF': palette.background.secondaryMainColor,
    '#2684FF': palette.background.multiInputTextBg,
  }

  return (
    <Field {...inputProps}>
      {({ field, form }) => {
        const { name } = field

        const { setFieldValue } = form

        return (
          <Box id={R.prop(C.FIELD_NAME, inputProps)}>
            <TimezoneSelect
              {...field}
              menuPlacement='top'
              value={inputProps.value}
              onChange={newValue => setFieldValue(name, newValue.value)}
              timezones={{
                'Europe/Kiev': 'Europe/Kiev',
                ...R.dissoc('Europe/Moscow', R.mapObjIndexed((value, key) => key, allTimezones)),
              }}
              styles={{
                container: provided => ({
                  ...provided,
                  zIndex: 2,
                  marginTop: 8,
                  marginBottom: 8,
                  width: inputProps.width,
                }),
                control: provided => ({
                  ...provided,
                  height: 40,
                  minHeight: 40,
                  fontSize: '1rem',
                  boxShadow: 'none',
                  background: 'none',
                  alignContent: 'center',
                  color: palette.text.primary,
                  borderColor: palette.background.darkGrey,
                  ':hover': { borderColor: palette.text.primary },
                }),
                singleValue: provided => ({
                  ...provided,
                  color: `${palette.text.primary} !important`,
                }),
                menuList: provided => ({
                  ...provided,
                  maxHeight: 230,
                  background: palette.background.menuListBg,
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontSize: '1rem',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'dotted',
                  borderBottomColor: palette.primary.light,
                  color: state.isSelected ? palette.primary.main : palette.text.greyColor,
                  background: R.pathOr(null, [provided.backgroundColor], optionColorsMap),
                  ':active': {
                    background: colors.transparentMain,
                  },
                  ':hover': {
                    background: H.ifElse(
                      R.propEq('backgroundColor', palette.background.darkGrey, provided),
                      rgbaColors.violet0_12,
                    ),
                  },
                }),
              }}
            />
          </Box>
        )
      }}
    </Field>
  )
}

export const SelectField = ({ t, inputProps, helperText, noTranslation }) => {
  const { palette } = useTheme()

  const {
    label,
    error,
    width,
    bgcolor,
    options,
    disabled,
    selectSxStyles = {},
    selectItemStyles = {},
    formControlSxStyles = {},
  } = inputProps

  return (
    <StyledFormControl
      {...inputProps}
      sx={{
        width: R.or(width, '100%'),
        '.MuiOutlinedInput-input': {
          backgroundColor: bgcolor,
        },
        '.Mui-disabled': {
          'WebkitTextFillColor': t => disabled && `${t.palette.background.lightGrey} !important`,
        },
        ...formControlSxStyles,
      }}
    >
      <InputLabel id='SelectField-label'>{label}</InputLabel>
      <Select
        {...inputProps}
        id='SelectField'
        labelId='SelectField-label'
        input={<OutlinedInput id='OutlinedInput' label={label} />}
        sx={{
          height: 40,
          minHeight: 40,
          fontSize: '1rem',
          ...selectSxStyles,
        }}
      >
        {
          options.map(({ name, value }) =>
            <MenuItem
              key={value}
              value={value}
              disabled={disabled}
              sx={{
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: palette.background.secondaryMainColor,
                },
                '&.Mui-selected': {
                  color: palette.primary.main,
                  backgroundColor: palette.background.default,
                },
                ...selectItemStyles,
              }}
            >
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: palette.mode === 'light'
                    ? palette.text.secondary
                    : palette.text.whiteColor,
                }}
              >
                {getSelectName(t, name, noTranslation)}
              </Typography>
            </MenuItem>,
          )
        }
      </Select>
      <Error error={error} errorText={helperText} />
    </StyledFormControl>
  )
}

// eslint-disable-next-line react/display-name
const TextFieldPhoneInput = forwardRef((inputProps, ref ) => {
  const { padding, isDisabled = false, isDarkTheme = false } = inputProps

  return (
    <StyledTextField
      {...inputProps}
      inputRef={ref}
      InputLabelProps={{ shrink: true }}
      sx={{
        '& .MuiOutlinedInput-input': {
          height: 40,
          maxHeight: 40,
          padding: R.or(padding, '0 10px'),
          color: isDisabled && setDefaultDisableStyles(isDarkTheme),
        },
        '& .MuiInputLabel-root': isDisabled && setDefaultDisableStyles(isDarkTheme),
        '& .Mui-disabled': {
          color: t => `${t.palette.text.primary} !important`,
          'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
        },
      }}
    />
  )
})

export const PhoneNumber = ({ t, inputProps, helperText, wrapperProps }) => {
  const { palette } = useTheme()

  const { width, error } = inputProps

  const wrapperMb = R.pathOr('10px', ['mb'], wrapperProps)
  const wrapperPhoneInputCountry = H.getPropFromObject( 'phoneInputCountry', wrapperProps)

  const formikContext = useFormikContext()

  const { setFieldValue } = formikContext

  return (
    <Box
      id={R.prop(C.FIELD_NAME, inputProps)}
      sx={{
        mb: wrapperMb,
        width: R.or(width, '100%'),
        '.PhoneInputInput': { fontSize: '1rem' },
        '.PhoneInputCountry': wrapperPhoneInputCountry,
        '.PhoneInputCountrySelect': {
          color: palette.text.primary,
          backgroundColor: palette.background.menuListBg,
        },
      }}
    >
      <PhoneInput
        {...inputProps}
        flags={flags}
        defaultCountry='US'
        international={true}
        errors={R.or('', R.toString(error))}
        inputComponent={TextFieldPhoneInput}
        value={R.propOr('', C.FIELD_VALUE, inputProps)}
        placeholder={t('placeholders.enterPhoneNumber')}
        onChange={value => setFieldValue(R.prop(C.FIELD_NAME, inputProps), R.or(value, ''))}
      />
      <Error error={error} errorText={helperText} />
    </Box>
  )
}

const SelectMultiple = ({ t, inputProps, palette, helperText, noTranslation }) => {
  const formikContext = useFormikContext()

  const { setFieldValue } = formikContext

  const { label, value, error, width, bgcolor, options, disabled } = inputProps

  const isLengthsEquals = R.equals(R.length(value), R.length(options))
  const isAllSelected = R.and(R.gt(R.length(options), 0), isLengthsEquals)

  const handleChange = (event, child) => {
    const targetName = H.getEventTargetName(event)
    const targetValue = H.getEventTargetValue(event)
    const childValue = R.path(['props', 'value'], child)

    if (R.and(R.equals('all', childValue), H.isTrue(isAllSelected))) {
      return setFieldValue(targetName, [])
    }

    if (R.and(R.equals('all', childValue), H.isFalse(isAllSelected))) {
      const allValues = R.map(R.prop(C.FIELD_VALUE), options)

      return setFieldValue(targetName, allValues)
    }

    setFieldValue(targetName, targetValue)
  }

  const renderValueFunc = selected => R.compose(
    R.join(', '),
    R.uniq,
    R.map(item => {
      const option = R.find(R.propEq(C.FIELD_VALUE, item), options)

      return H.getNameFromObject(option)
    }),
  )(selected)

  return (
    <StyledFormControl
      {...inputProps}
      sx={{
        width: R.or(width, '100%'),
        '.MuiOutlinedInput-input': {
          backgroundColor: bgcolor,
        },
      }}
    >
      <InputLabel id='SelectMultiple-label'>{label}</InputLabel>
      <Select
        {...inputProps}
        multiple
        value={value}
        id='SelectMultiple'
        onChange={handleChange}
        renderValue={renderValueFunc}
        labelId='SelectMultiple-label'
        MenuProps={SelectMenuProps('auto')}
        input={<OutlinedInput label={inputProps.label} />}
        sx={{
          height: 40,
          minHeight: 40,
          fontSize: '1rem',
        }}
      >
        <MenuItem
          value='all'
          sx={{
            margin: '0 5px',
            textTransform: 'none',
            borderBottom: '1px solid',
            borderColor: `${palette.text.disabled} !important`,
            '&:hover': {
              backgroundColor: palette.background.secondaryMainColor,
            },
            '&.Mui-selected': {
              backgroundColor: palette.background.default,
            },
            '& .MuiSvgIcon-root': {
              color: isAllSelected ? `${palette.primary.main} !important` : `${palette.text.primary} !important`,
            },
          }}
        >
          <ListItemIcon>
            <Checkbox
              name='select-all'
              checked={isAllSelected}
              style={{ indeterminate: { color: palette.primary.main } }}
              indeterminate={
                value.length > 0 && value.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={t('labels.selectAll')}
            style={{ primary: { fontWeight: 500 } }}
            primaryTypographyProps={{
              textTransform: 'none',
              color: `${palette.text.primary} !important`,
              '&.Mui-selected': {
                color: `${palette.primary.main} !important`,
              },
            }}
          />
        </MenuItem>
        {
          R.map(
            option => {
              const optionName = H.getNameFromObject(option)
              const optionValue = H.getValueFromObject(option)

              const checked = R.indexOf(optionValue, value) > -1

              return (
                <MenuItem
                  key={optionValue}
                  value={optionValue}
                  disabled={disabled}
                  sx={{
                    margin: '0 5px',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: palette.background.secondaryMainColor,
                    },
                    '&.Mui-selected': {
                      backgroundColor: palette.background.default,
                    },
                  }}
                >
                  <ListItemIcon>
                    <Checkbox checked={checked} name='select-item' />
                  </ListItemIcon>
                  <ListItemText
                    primary={getSelectName(t, optionName, noTranslation)}
                    sx={{ whiteSpace: 'normal', wordBreak: 'break-word !important' }}
                    primaryTypographyProps={{
                      textTransform: 'none',
                      fontSize: { lg: 16, xs: 15 },
                      color: `${palette.text.primary} !important`,
                      '&.Mui-selected': {
                        color: `${palette.primary.main} !important`,
                      },
                    }}
                  />
                </MenuItem>
              )
            },
            options,
          )
        }
      </Select>
      <Error error={error} errorText={helperText} />
    </StyledFormControl>
  )
}

const DateField = ({ inputProps, helperText }) => {
  const {
    name,
    value,
    width,
    padding,
    disabled = false,
    marginBottom,
  } = inputProps

  const ref = useRef()
  const formikContext = useFormikContext()
  const [dateValue, setDateValue] = useState(value)

  const { setFieldValue } = formikContext

  const handleChange = date => {
    const dateString = H.stringifyAndParseJson(date)

    if (H.isNotNilAndNotEmpty(dateString)) {
      setDateValue(dateString)
      setFieldValue(name, dateString)
    }
  }
  return (
    <Box width='100%' display='flex' justifyContent='center'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} width={R.or(width, '100%')}>
          <DatePicker
            {...inputProps}
            ampm={false}
            inputRef={ref}
            value={dateValue}
            disabled={disabled}
            onChange={handleChange}
            inputFormat={C.DEFAULT_DATE_FORMAT}
            renderInput={
              params =>
                <StyledTextField
                  {...inputProps}
                  {...params}
                  autoComplete='false'
                  helperText={helperText}
                  error={H.isString(helperText)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width,
                    marginBottom,
                    '.MuiInputLabel-root': { fontSize: 14 },
                    '.MuiOutlinedInput-root': { fontSize: 14 },
                    '.MuiOutlinedInput-input': {
                      height: 40,
                      maxHeight: 40,
                      padding: R.or(padding, '0 10px'),
                    },
                    '.MuiButtonBase-root': {
                      padding: 0,
                      '&:hover': { background: 'none' },
                    },
                    '.Mui-disabled': {
                      color: t => `${t.palette.text.primary} !important`,
                      'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
                    },
                    '.MuiSvgIcon-root': {
                      fontSize: 25,
                      marginRight: '10px',
                      color: t => disabled && `${t.palette.text.disabled} !important`,
                      '&:hover': { color: t => t.palette.background.transparentGrey },
                      '&.Mui-selected': { color: t => t.palette.background.transparentGrey },
                    },
                  }}
                />
            }
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  )
}

const DateTimeField = ({ inputProps, helperText }) => {
  const {
    name,
    value,
    width,
    padding,
    disabled = false,
    marginBottom,
  } = inputProps
  const ref = useRef()
  const formikContext = useFormikContext()
  const [dateTimeValue, setDateTimeValue] = useState(value)

  const { setFieldValue } = formikContext

  const handleChange = date => {
    const dateTimeString = H.stringifyAndParseJson(date)

    if (H.isNotNilAndNotEmpty(dateTimeString)) {
      setDateTimeValue(dateTimeString)
      setFieldValue(name, dateTimeString)
    }
  }

  return (
    <Box width='100%' display='flex' justifyContent='center'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} width={R.or(width, '100%')}>
          <DateTimePicker
            {...inputProps}
            ampm={false}
            inputRef={ref}
            disabled={disabled}
            value={dateTimeValue}
            onChange={handleChange}
            inputFormat={C.DEFAULT_DATE_TIME_FORMAT}
            renderInput={
              params =>
                <StyledTextField
                  {...inputProps}
                  {...params}
                  autoComplete='false'
                  helperText={helperText}
                  error={H.isString(helperText)}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width,
                    marginBottom,
                    '.MuiInputLabel-root': { fontSize: 14 },
                    '.MuiOutlinedInput-root': { fontSize: 14 },
                    '.MuiOutlinedInput-input': {
                      height: 40,
                      maxHeight: 40,
                      padding: R.or(padding, '0 10px'),
                    },
                    '.MuiButtonBase-root': {
                      padding: 0,
                      '&:hover': { background: 'none' },
                    },
                    '.Mui-disabled': {
                      color: t => `${t.palette.text.primary} !important`,
                      'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
                    },
                    '.MuiSvgIcon-root': {
                      fontSize: 25,
                      marginRight: '10px',
                      color: t => disabled && `${t.palette.text.disabled} !important`,
                      '&:hover': { color: t => t.palette.background.transparentGrey },
                      '&.Mui-selected': { color: t => t.palette.background.transparentGrey },
                    },
                  }}
                />
            }
          />
        </Stack>
      </LocalizationProvider>
    </Box>
  )
}

const setDefaultDisableStyles = isDarkTheme => ({
  color: t => H.ifElse(isDarkTheme, t.colors.whiteColorMain, t.palette.text.primary),
})

const Inputs = ({
  t,
  type,
  shrink,
  palette,
  fullWidth,
  inputProps,
  helperText,
  customField,
  marginBottom,
  noTranslation,
}) => {
  const { width, padding, isDisabled, isDarkTheme } = inputProps

  if (H.isNotNilAndNotEmpty(customField)) return customField(inputProps)

  const inputsMap = {
    countrySelect: <CountrySelect {...inputProps} />,
    timeZoneSelect: <TimeZoneField inputProps={inputProps} />,
    date: <DateField inputProps={inputProps} helperText={helperText} />,
    checkbox: <FormControlLabel control={<Checkbox />} {...inputProps} />,
    dateTime: <DateTimeField inputProps={inputProps} helperText={helperText} />,
    phoneNumber: <PhoneNumber t={t} inputProps={inputProps} helperText={helperText} />,
    statesAutoComplete: <StatesAutocomplete {...inputProps} palette={palette} helperText={helperText} />,
    placesAutoCompleteMultiple: (
      <PlacesAutocompleteMultiple
        {...inputProps}
        palette={palette}
        helperText={helperText}
      />
    ),
    select: (
      <SelectField
        t={t}
        inputProps={inputProps}
        helperText={helperText}
        noTranslation={noTranslation}
      />
    ),
    selectMultiple: (
      <SelectMultiple
        t={t}
        palette={palette}
        inputProps={inputProps}
        helperText={helperText}
        noTranslation={noTranslation}
      />
    ),
    password:
      <PasswordField
        shrink={shrink}
        fullWidth={fullWidth}
        inputProps={inputProps}
        helperText={helperText}
        marginBottom={marginBottom}
      />,
    text:
      <StyledTextField
        {...inputProps}
        autoComplete='false'
        fullWidth={fullWidth}
        helperText={helperText}
        InputLabelProps={{ shrink }}
        value={R.propOr('', 'value', inputProps)}
        sx={{
          width,
          marginBottom,
          '.MuiInputLabel-root': isDisabled && setDefaultDisableStyles(isDarkTheme),
          '.MuiOutlinedInput-input': {
            height: 40,
            maxHeight: 40,
            padding: R.or(padding, '0 10px'),
            color: isDisabled && setDefaultDisableStyles(isDarkTheme),
          },
          '.Mui-disabled': {
            color: t => `${t.palette.text.primary} !important`,
            'WebkitTextFillColor': t => `${t.palette.text.primary} !important`,
          },
        }}
      />,
  }

  return inputsMap[type]
}

const FormFooter = ({
  btnType,
  isLoading,
  submitText,
  useIconBtn,
  cancelText,
  isDarkTheme,
  cancelAction,
  useCancelBtn,
  onSubmitClick,
  wrapperStyles,
  additionalBtns,
  cancelBtnStyles,
  submitBtnStyles,
  areButtonsDisabled,
  useSubmitBtn = true,
  disableSubmitButton,
}) => {
  const defaultStyles = {
    color: 'background.white',
    backgroundColor: 'background.main',
    border: H.ifElse(isDarkTheme, '1px solid', 'none'),
    borderColor: H.ifElse(isDarkTheme, 'background.main', 'none'),
  }

  const disabledStyles = H.isTrue(areButtonsDisabled)
    ? defaultStyles
    : {
      color: H.ifElse(isDarkTheme, 'background.main', 'darkGrey'),
      borderColor: H.ifElse(isDarkTheme, 'background.main', 'none'),
      backgroundColor: H.ifElse(isDarkTheme, t => t.rgbaColors.black0_012, t => t.rgbaColors.lightGrey_012),
    }

  return (
    <Stack
      mt={2}
      width='100%'
      direction='row'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...H.spreadUiStyles(wrapperStyles),
      }}
    >
      {
        useCancelBtn && (
          <Button
            type='button'
            title={cancelText}
            disabled={isLoading}
            onClick={cancelAction}
            variant={isDarkTheme ? 'outlined' : 'contained'}
            sx={{
              fontSize: 14,
              borderRadius: 2,
              color: 'text.primary',
              width: { lg: 100, md: 100, sm: 100, xs: 90 },
              backgroundColor: 'background.secondaryMainColor',
              ...H.spreadUiStyles(cancelBtnStyles),
            }}
          >
            {cancelText}
          </Button>
        )
      }
      {
        useSubmitBtn && (
          <Button
            type={btnType}
            id={submitText}
            title={submitText}
            onClick={onSubmitClick}
            variant={isDarkTheme ? 'outlined' : 'contained'}
            disabled={isLoading || disableSubmitButton || areButtonsDisabled}
            sx={{
              fontSize: 14,
              borderRadius: 2,
              width: { lg: 100, md: 100, sm: 100, xs: 90 },
              ...H.spreadUiStyles(submitBtnStyles),
              '&.Mui-disabled': disabledStyles,
            }}
          >
            {
              isLoading
                ? <CircularProgress size={16} color='inherit' />
                : (
                  <>
                    {useIconBtn && <AddCircleOutlineIcon sx={{ mr: 1, fontSize: 24 }} />}
                    {submitText}
                  </>
                )
            }
          </Button>
        )
      }
      {
        H.isArray(additionalBtns) &&
        additionalBtns.map(({ text, options }, index) => <Button {...options} key={index}>{text}</Button>)
      }
    </Stack>
  )
}

const getErrors = (errors, touched, fieldSettings) => R.map(
  item => H.ifTrueOrValue(getFieldError(errors, touched, R.prop('fieldName', item))),
  fieldSettings,
)

const setDisplay = (values, { display, customSetDisplayFunction }) => {
  if (H.isFunction(customSetDisplayFunction)) return customSetDisplayFunction(values)

  return display
}

const FormWrapper = ({
  isLoading,
  submitText,
  formStyles,
  cancelText,
  submitAction,
  cancelAction,
  fieldSettings,
  initialValues,
  formikSettings,
  buttonSettings,
  resetSubmitBtnType = false,
  disableSubmitButton = false,
  additionalComponent,
  submitToastrErrorMessage,
}) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const { palette } = theme

  const isDarkTheme = H.isDarkTheme(theme)
  const areButtonsDisabled = useSelector(makeSelectDisableTourButtons)
  const isDisabled = H.isTrue(areButtonsDisabled)

  return (
    <Formik
      {...formikSettings}
      initialTouched={false}
      onSubmit={submitAction}
      initialValues={H.isObject(initialValues) ? initialValues : formikSettings.initialValues}
    >
      {({
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <Stack
          component='form'
          textAlign='start'
          onSubmit={handleSubmit}
          sx={{
            fontSize: 14,
            width: '100%',
            ...H.spreadUiStyles(formStyles),
          }}
        >
          {
            fieldSettings(values).map((item, index) => {
              const {
                type,
                label,
                shrink,
                options,
                disabled,
                required,
                fieldName,
                customField,
                placeholder,
                inputStyles,
                noTranslation,
                inputWrapperStyles,
              } = item

              const fullWidth = R.propOr(null, 'fullWidth', inputStyles)
              const helperText = getFieldError(errors, touched, fieldName)
              const marginBottom = R.propOr(0, 'marginBottom', inputStyles)
              const inputRestStyles = Object.assign({}, inputStyles)
              delete inputRestStyles.fullWidth
              delete inputRestStyles.marginBottom

              const value = values[fieldName]
              const inputProps = {
                width: '100%',
                size: 'small',
                margin: 'dense',
                padding: '0 10px',
                ...inputRestStyles,
                value,
                touched,
                options,
                isDisabled,
                placeholder,
                isDarkTheme,
                id: fieldName,
                name: fieldName,
                onBlur: handleBlur,
                onChange: handleChange,
                error: H.ifTrueOrValue(helperText),
                disabled: H.isTrue(areButtonsDisabled) ? true : disabled,
                label: H.ifElse(H.isTrue(required), `${t(label)} *`, t(label)),
              }

              return (
                <Box key={index} sx={{ ...inputWrapperStyles, display: setDisplay(values, item) }}>
                  <Inputs
                    t={t}
                    type={type}
                    shrink={shrink}
                    palette={palette}
                    fullWidth={fullWidth}
                    inputProps={inputProps}
                    helperText={helperText}
                    customField={customField}
                    marginBottom={marginBottom}
                    noTranslation={noTranslation}
                  />
                </Box>
              )
            })
          }
          {
            additionalComponent && additionalComponent(getErrors(errors, touched, fieldSettings(values)))
          }
          <FormFooter
            {...buttonSettings}
            isLoading={isLoading}
            isDarkTheme={isDarkTheme}
            cancelAction={cancelAction}
            areButtonsDisabled={areButtonsDisabled}
            disableSubmitButton={disableSubmitButton}
            submitText={R.or(submitText, t('actions.add'))}
            cancelText={R.or(cancelText, t('actions.cancel'))}
            btnType={getButtonType(touched, errors, resetSubmitBtnType)}
            onSubmitClick={() => handleSubmitButtonClick(touched, errors, resetSubmitBtnType, submitToastrErrorMessage)}
          />
        </Stack>
      )}
    </Formik>
  )
}

export default FormWrapper

// NOTE: Default theme viewer link:
// https://mui.com/material-ui/customization/default-theme/

// TODO: refactor with clarify naming and using colors

const SCROLL_WIDTH = '5px'
const bgColor = '#1B1B1D'
const menuListBg = '#2F2F2F'
const scrollbarThumbColor = menuListBg
const tableHeaderTextColor = '#E3E3E3'

const whiteColor = bgColor
const greyColor = '#E3E3E3'
const mainColor = '#B8E900'
const blueColor = '#4285F4'
const blackColor = '#282828'
const errorColor = '#FF0000'
const greenColor = '#008000'
const violetColor = '#6E61EA'
const orangeColor = '#FF7400'
const lightMainColor = bgColor
const darkGreyColor = '#787878'
const lightRedColor = '#FECACA'
const mainLimeColor = '#B8E900'
const whiteColorMain = '#FFFFFF'
const darkBlackColor = '#1A1921'
const lightGreyColor = '#D5D5D5'
const lightErrorColor = '#E74C3C'
const darkVioletColor = '#601FEB'
const lightGreenColor = '#A6F4D0'
const darkOrangeColor = '#FFB356'
const lightYellowColor = '#9B870C'
const middleGreenColor = '#dcf2e2'
const lighterGreyColor = '#1F2937'
const lightVioletColor = '#D7D7FF'
const multiInputTextBg = '#121212'
const secondaryMainColor = '#3A8333'
const secondaryBlackColor = '#242526'
const transparentGreyColor = '#DADADA'
const transparentBlackColor = '#1D1D27'
const secondaryTableRowColor = '#374051'

export const darkTheme = mode => ({
  colors: {
    white: whiteColor,
    violet: violetColor,
    blueColor: blueColor,
    mainLime: mainLimeColor,
    lightGrey: lightGreyColor,
    darkOrange: darkOrangeColor,
    darkViolet: darkVioletColor,
    borderButtonColor: mainColor,
    lightViolet: lightVioletColor,
    whiteColorMain: whiteColorMain,
    modalBGColor: secondaryTableRowColor,
    tableHeaderText: tableHeaderTextColor,
    secondaryTableRow: secondaryTableRowColor,
    toolbarBackgroundColor: secondaryBlackColor,
  },
  rgbaColors: {
    red135: 'rgb(255, 104, 135)',
    blue192: 'rgb(22, 101, 192)',
    blue249: 'rgb(144, 202, 249)',
    red00_8: 'rgba(255, 0, 0, 0.08)',
    black0_04: 'rgba(0, 0, 0, 0.04)',
    darkGrey0_4: 'rgba(40, 40, 40, 0.4)',
    black0_012: 'rgba(255, 255, 255, 0.12)',
    violet00_8: 'rgba(115, 105, 197, 0.08)',
    violet0_12: 'rgba(115, 105, 197, 0.12)',
    lightGrey132_8: 'rgb(132 164 176 / 8%)',
  },
  palette: {
    mode,
    primary: {
      main: mainColor,
      error: errorColor,
      light: lightMainColor,
    },
    text: {
      primary: greyColor,
      disabled: lightGreyColor,
      secondary: darkGreyColor,
      violetColor: violetColor,
      whiteColor: whiteColorMain,
    },
    background: {
      menuListBg,
      grey: greyColor,
      main: mainColor,
      lightErrorColor,
      default: bgColor,
      multiInputTextBg,
      error: errorColor,
      white: whiteColor,
      black: blackColor,
      green: greenColor,
      secondaryMainColor,
      violet: violetColor,
      orange: orangeColor,
      darkGrey: darkGreyColor,
      lightRed: lightRedColor,
      lightGrey: lightGreyColor,
      darkBlack: darkBlackColor,
      darkViolet: darkVioletColor,
      lightGreen: lightGreenColor,
      middleGreen: middleGreenColor,
      lightYellow: lightYellowColor,
      lighterGrey: lighterGreyColor,
      transparentGrey: transparentGreyColor,
      transparentMain: transparentBlackColor,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
      xxxl: 1600,
    },
  },
  zIndex: {
    fab: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          padding: 12,
          fontSize: 16,
          lineHeight: 3,
          borderRadius: 8,
          alignSelf: 'center',
          textTransform: 'capitalize',
        },
        contained: {
          color: whiteColor,
          backgroundColor: mainColor,
          '&.Mui-selected': {
            color: mainColor,
            backgroundColor: lightMainColor,
          },
          '&:hover': {
            color: mainColor,
            backgroundColor: lightMainColor,
          },
        },
        outlined: {
          border: 'none',
          color: mainColor,
          backgroundColor: lightMainColor,
          '&.Mui-selected': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
          '&:hover': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: 40,
          marginLeft: 12,
          marginRight: 12,
          '&.Mui-selected': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
          '&:hover': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: mainColor,
          backgroundColor: lightMainColor,
          '&.Mui-selected': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
          '&:hover': {
            color: whiteColor,
            backgroundColor: mainColor,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          padding: 2,
          fontSize: 26,
          cursor: 'pointer',
          alignSelf: 'center',
        },
        colorDisabled: { color: transparentGreyColor },
        colorPrimary: {
          color: mainColor,
          '&:hover': { color: lightMainColor },
          '&.Mui-selected': { color: lightMainColor },
        },
        colorSecondary: {
          color: darkGreyColor,
          '&:hover': { color: transparentGreyColor },
          '&.Mui-selected': { color: transparentGreyColor },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            maxHeight: 40,
            width: '100%',
          },
          '.MuiOutlinedInput-input': {
            fontSize: 13,
            textTransform: 'capitalize',
          },
          fieldset: { borderColor: `${transparentGreyColor} !important` },
          '.MuiInputBase-input.MuiOutlinedInput-input': {
            paddingTop: '4px',
            paddingBottom: '4px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fieldset: { borderColor: darkGreyColor },
          '& .Mui-focused fieldset': { borderColor: `${darkGreyColor} !important` },
          '.MuiOutlinedInput-input:-webkit-autofill': { 'WebkitTransitionDelay': '9999s' },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          width: '100vw',
          display: 'flex',
          backgroundColor: whiteColor,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 60,
          variant: 'scrollable',
          textDecoration: 'none',
          borderColor: 'divider',
          '.Mui-disabled': { color: `${darkGreyColor} !important` },
          '.MuiButtonBase-root': {
            color: greyColor,
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          color: darkGreyColor,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '60px !important',
          paddingLeft: '10px !important',
          paddingRight: '10px !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          textTransform: 'capitalize',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          size: 'small',
          color: darkGreyColor,
          variant: 'contained',
          '&:hover': { color: darkGreyColor },
          '&.Mui-selected': { color: darkGreyColor },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: whiteColor,
          textTransform: 'capitalize',
          '.MuiSvgIcon-root': {
            fontSize: 24,
            marginRight: 5,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 4,
          '& .MuiSvgIcon-root': { fontSize: 23, color: mainColor },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: secondaryMainColor,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: SCROLL_WIDTH,
            backgroundColor: lightGreyColor,
          },
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: SCROLL_WIDTH,
            height: SCROLL_WIDTH,
            color: scrollbarThumbColor,
            border: `${SCROLL_WIDTH} solid`,
            borderColor: scrollbarThumbColor,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          fontSize: 13,
          fontWeight: 400,
          marginTop: '4px',
          color: errorColor,
          fontStyle: 'normal',
          fontFamily: 'Roboto',
          letterSpacing: 'normal',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '.MuiTableCell-body': {
            background: lighterGreyColor,
            ':last-child td, :last-child th': { border: 0 },
            ':last-child': { borderRadius: '0 8px 8px 0 !important' },
            ':first-of-type': { borderRadius: '8px 0 0 8px !important' },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 13,
          border: 'none',
          cursor: 'text',
          padding: '10px',
          overflow: 'hidden',
          fontFamily: 'Roboto',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          '&:first-of-type': {
            left: 0,
            padding: '10px 0',
            position: 'sticky',
            width: '20px !important',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: bgColor,
          textTransform: 'uppercase',
          '.MuiTableCell-head': {
            fontSize: 11,
            fontWeight: 700,
            ':first-of-type': { zIndex: 4 },
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: { background: 'transparent' },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: '-4px',
          tableLayout: 'auto',
          borderSpacing: '0px 4px',
          borderCollapse: 'separate',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: { '& .MuiMenu-list': { maxHeight: 350 } },
      },
    },
  },
})

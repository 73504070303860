import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// endpoints
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

const getHandlerResponse = async response => {
  const handleResponse = await response.blob()

  if (R.equals(R.path(['size'], handleResponse), 0)) return null

  return H.createObjectURL(handleResponse)
}

const getCacheEntryResult = async (dispatch, getCacheEntry, cacheDataLoaded) => {
  await cacheDataLoaded

  const data = R.path(['data'], getCacheEntry())

  if (H.isNotNilAndNotEmpty(data)) {
    dispatch(
      companyLogoAPI.util.updateQueryData(
        'getCompanyLogo',
        undefined,
        () => data,
      ))
  }
}

const removeCompanyLogoByDispatch = dispatch => dispatch(
  companyLogoAPI.util.updateQueryData(
    'getCompanyLogo',
    undefined,
    () => null,
  ),
)

export const companyLogoAPI = createApi({
  tagTypes: ['CompanyLogo'],
  reducerPath: 'companyLogoAPI',
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    getCompanyLogo: build.query({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.companyLogo,
        providesTags: ['CompanyLogo'],
        responseHandler: response => getHandlerResponse(response),
        transformResponse: response => H.stringifyAndParseJson(response),
      }),
    }),
    addCompanyLogo: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.companyLogo,
        invalidatesTags: ['CompanyLogo'],
        responseHandler: response => getHandlerResponse(response),
        transformResponse: response => H.stringifyAndParseJson(response),
        headers: {
          'Media-Type': E.MEDIA_TYPES.IMAGE_JPEG,
        },
      }),
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, getCacheEntry }) {
        await getCacheEntryResult(dispatch, getCacheEntry, cacheDataLoaded)
      },
    }),
    deleteCompanyLogo: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.DELETE,
        url: E.ENDPOINTS.companyLogo,
        invalidatesTags: ['CompanyLogo'],
      }),
      async onCacheEntryAdded(arg, { dispatch }) {
        dispatch(
          companyLogoAPI.util.updateQueryData(
            'getCompanyLogo',
            undefined,
            () => null,
          ))
      },
    }),
    getCompanyLogoByCompanyId: build.query({
      query: companyId => ({
        companyId,
        method: E.METHODS.GET,
        providesTags: ['CompanyLogo'],
        url: `${E.ENDPOINTS.companyLogo}/${companyId}`,
        responseHandler: response => getHandlerResponse(response),
        transformResponse: response => H.stringifyAndParseJson(response),
      }),
    }),
  }),
})

const {
  useGetCompanyLogoQuery,
  useAddCompanyLogoMutation,
  useDeleteCompanyLogoMutation,
  useGetCompanyLogoByCompanyIdQuery,
} = companyLogoAPI

export {
  useGetCompanyLogoQuery,
  useAddCompanyLogoMutation,
  useDeleteCompanyLogoMutation,
  useGetCompanyLogoByCompanyIdQuery,
  //
  removeCompanyLogoByDispatch,
}

import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers/constants
import * as H from '../helpers'
import * as C from '../constants'
// utilities
import * as E from '../utilities/endpoints'
//////////////////////////////////////////////////

export const mailGroupAPI = createApi({
  reducerPath: 'mailGroupAPI',
  tagTypes: ['MailGroupList'],
  baseQuery: H.baseQueryWithReAuth,
  endpoints: build => ({
    fetchMailGroups: build.query({
      extraOptions: { useLoader: true },
      transformResponse: response => response.elements,
      providesTags: result => H.providesTagList(result, 'MailGroupList'),
      query: body => ({
        body,
        method: E.METHODS.GET,
        url: E.ENDPOINTS.mailGroup,
      }),
    }),
    createMailGroup: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailGroupList'),
      query: body => ({
        body,
        method: E.METHODS.POST,
        url: E.ENDPOINTS.mailGroup,
      }),
    }),
    updateMailGroup: build.mutation({
      query: body => ({
        body,
        method: E.METHODS.PUT,
        url: E.ENDPOINTS.mailGroup,
      }),
      async onCacheEntryAdded(entity, { dispatch, getCacheEntry, cacheDataLoaded }) {
        await cacheDataLoaded

        const data = R.pathOr({}, ['data'], getCacheEntry())

        const { id } = data

        if (H.isNotNilAndNotEmpty(id)) {
          dispatch(mailGroupAPI.util.updateQueryData(
            'fetchMailGroups',
            undefined,
            R.map(item => H.ifElse(R.propEq(C.FIELD_ID, id, item), data, item)),
          ))
        }
      },
    }),
    getMailGroup: H.buildQueryAPI(
      build,
      E.METHODS.GET,
      E.ENDPOINTS.mailGroup,
      {},
      'id',
    ),
    deleteMailGroup: build.mutation({
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'MailGroupList'),
      query: id => ({
        id,
        method: E.METHODS.DELETE,
        url: `${E.ENDPOINTS.mailGroup}/${id}`,
      }),
    }),
  }),
})

export const {
  useGetMailGroupQuery,
  useFetchMailGroupsQuery,
  useCreateMailGroupMutation,
  useDeleteMailGroupMutation,
  useUpdateMailGroupMutation,
} = mailGroupAPI

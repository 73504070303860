export default {
  actions: {
    yes: 'Yes',
    add: 'Add',
    map: 'Map',
    menu: 'Menu',
    show: 'Show',
    back: 'Back',
    hide: 'Hide',
    save: 'Save',
    send: 'Send',
    edit: 'Edit',
    next: 'Next',
    goTo: 'Go to',
    other: 'Other',
    close: 'Close',
    reset: 'Reset',
    toCall: 'Call',
    login: 'Log In',
    cancel: 'Cancel',
    skipTour: 'Skip',
    submit: 'Submit',
    create: 'Create',
    delete: 'Delete',
    search: 'Search',
    update: 'Update',
    import: 'Import',
    export: 'Export',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    confirm: 'Confirm',
    preview: 'Preview',
    refresh: 'Refresh',
    payment: 'Payment',
    reserve: 'Reserve',
    complete: 'Complete',
    logo: 'Logo Company',
    tutorial: 'Tutorial',
    addTruck: 'Add Truck',
    editNote: 'Edit Note',
    subscribe: 'Subscribe',
    goMap: 'Go to the map',
    emailList: 'Email List',
    contactUs: 'Contact Us',
    massDelete: 'Mass Delete',
    rememberMe: 'Remember me',
    uploadIcon: 'Upload Icon',
    smtpUpdate: 'SMTP Update',
    write: 'Write Message to',
    unsubscribe: 'Unsubscribe',
    massActions: 'Mass Actions',
    updateDates: 'Update Dates',
    editProfile: 'Edit Profile',
    selectDate: 'Select a Date',
    selectField: 'Select Field',
    editCompany: 'Edit Company',
    emailGroups: 'Email Groups',
    viewInvoice: 'View invoice',
    addGroup: 'Create New Group',
    addNewTruck: 'Add New Truck',
    addNewEmail: 'Add New Email',
    addYourLogo: 'Add your logo',
    confirmEmail: 'Confirm Email',
    uploadAvatar: 'Upload avatar',
    listOfTrucks: 'List of trucks',
    clickToVisit: 'Click to visit',
    updateProfile: 'Update Profile',
    privacyPolicy: 'Privacy Policy',
    smtpAuthorize: 'SMTP Authorize',
    reserveTrucks: 'Reserve Trucks',
    paymentHistory: 'Payment History',
    authorize: 'Authorize with Google',
    viewFullText: 'View the full text',
    massAddToGroup: 'Mass add to Group',
    accountSettings: 'Account settings',
    manuallyCampaign: 'Manual Campaign',
    addSubscription: 'Add Subscription',
    uploadExcelFile: 'Upload excel file',
    editSocials: 'Edit Social Networks',
    importExcelList: 'Import Excel List',
    switchCompanyTo: 'Switch company to',
    openTourSteps: 'Open tutorial steps',
    downloadTemplate: 'Download Template',
    addNewMailGroup: 'Add New Mail Group',
    selectTruckType: 'Select a Truck Type',
    forgotPassword: 'Forgot your password?',
    termsAndConditions: 'Terms & Conditions',
    selectMassActions: 'Select Mass Actions',
    downloadExcelFile: 'Download excel file',
    uploadCompanyLogo: 'Upload company logo',
    playVideoTutorial: 'Play video tutorial',
    selectDriverType: 'Select a Driver Type',
    grantFreeAccessTo: 'Grant Free Access to',
    previewReservation: 'Preview Reservation',
    cancelSubscription: 'Cancel Subscription',
    goToEmailGroups: 'Go to the Email Groups',
    resumeSubscription: 'Resume Subscription',
    selectTrailerType: 'Select a Trailer Type',
    newDestinationZone: 'New Destination Zone',
    scheduleNewCampaign: 'Schedule New Campaign',
    addNewManuallyCampaign: 'New Manual Campaign',
    addNewEmailTemplate: 'Add New Email Template',
    runFullTour: 'Run a full tutorial of the page',
    confirmInformationDelete: 'Confirm delete item',
    removeFreeAccessFrom: 'Remove Free Access from',
    addNewCompanyTemplate: 'Add New Company Template',
    addNewDestinationZone: 'Add New Destination Zone',
    addDestinationZone: 'Create New Destination Zone',
    goToProfilePage: 'Go to profile page and subscribe',
    confirmDeleteAuthorization: 'Confirm delete of Authorization',
  },
  placeholders: {
    search: 'Search',
    loading: 'Loading',
    password: 'Password',
    email: 'Email Address',
    groupName: 'Group Name',
    typeToSearch: 'Type to search',
    enterPhoneNumber: 'Enter phone number',
  },
  templatePlaceholders: {
    body: 'Body',
    email: 'Email',
    title2: 'Title',
    title: 'Position',
    website: 'Website',
    lastName: 'Last Name',
    address1: 'Address 1',
    address2: 'Address 2',
    mobile: 'Phone Number',
    firstName: 'First Name',
    userAvatarUrl: 'Avatar Url',
    farewell: 'Email Signature',
    MyCompanyChannel: 'Youtube',
    MyCompanyTwitter: 'Twitter',
    MyCompanyFacebook: 'Facebook',
    MyCompanyLinkedin: 'Linkedin',
    phone: 'Company Phone Number',
    MyCompanyInstagram: 'Instagram',
    companyLogoUrl: 'Company Logo Url',
    phoneExtension: 'Company Phone Number Ext.',
  },
  errors: {
    error: 'Error',
    success: 'Success',
    warning: 'WARNING',
    exportFail: 'Export Fail',
    serverError: 'Server Error',
    unknownError: 'Unknown error',
    pleaseEnterA: 'Please enter a',
    downloadFail: 'Downloaded Fail',
    exportSuccess: 'Export Success',
    pleaseEnterAn: 'Please enter an',
    pleaseSelectA: 'Please select a',
    canNotBeEmpty: 'cannot be empty.',
    pleaseSelectAn: 'Please select an',
    noFileUploaded: 'No file uploaded!',
    validationError: 'Validation error',
    loadedFileWith: 'Loaded a file with',
    fieldIsRequired: 'Field is required',
    shouldBeNumber: 'Should be a number',
    downloadSuccess: 'Downloaded Success',
    fieldIsNotValid: 'Field is not valid',
    pleaseEnterNote: 'Please enter a note',
    enterValidEmail: 'Enter a valid Email',
    passwordMustMatch: 'Password must match',
    fileSizeIsTooBig: ' file size is too big!',
    noDataFoundInFile: 'No data found in file!',
    cannotBeDateBefore: 'Cannot be a date before',
    sessionHasExpired: 'Your session has expired',
    shouldBeLessOrEqual: 'Should be less or equal',
    shouldBeDateType: 'Should be a valid date type',
    weSorryToSeeYouGo: 'We\'re sorry to see you go!',
    sorryYouHaveNoAccess: 'Sorry you have no access',
    wrongUsernameOrPassword: 'Wrong email or password',
    subscriptionExpired: 'Subscription has been expired',
    shouldBePhoneNumber: 'Should be a phone number value',
    fieldLengthShouldBeFrom: 'Field length should be from',
    maxSizeImage: 'The maximum size must be less than 5 MB.',
    fileMustBeSmallerThan2MB: 'File must be smaller than 2MB!',
    extensionIsNotSupported: 'This extension is not supported!',
    textConfirmSubscribe: 'Are you sure you want to subscribe?',
    yourBrowser: 'Your browser does not support the video tag.',
    youCanOnlyUploadExcelFile: 'You can only upload Excel file!',
    pleaseSelectAnyEmail: 'Please, select any email to continue!',
    pleaseSelectAnyTruck: 'Please select a truck(s) and try again',
    pleaseSelectEmailOrGroup: 'Please, select any email or group!',
    textConfirmUnSubscribe: 'Are you sure you want to unsubscribe?',
    iconTopRight: 'icon (top right of the screen) and select Profile.',
    pleaseSelectLocationWithState: 'Please select location with a state',
    toPurchaseSubscription: 'To purchase a subscription, click the Gear',
    pleaseEnterRequiredInfo: 'Please enter the required(*) information.',
    shouldBeSpecialSymbol: 'Password must contain 1 or more special characters',
    noLeadingAndTrailingSpaces: 'Field cannot include leading and trailing spaces',
    confirmMassDelete: 'Are you sure you want to delete all information for this items?',
    confirmDelete: 'Are you sure you want to delete all information for this item record?',
    withinYour: 'Within your Profile simply choose a plan and enter your card information.',
    conformationDeleteEmailAuthorization: 'Are you sure you want to delete this Email Authorization?',
    addSubscription: 'Add Subscription - You must add a subscription; card information and select a plan.',
    chooseWhetherYouWant: 'Choose whether you want to use Gmail, SMTP/Outlook, or both as the mailing service.',
    pleaseCheckSpam: 'Please check your junk/spam folder for any missed notifications or news from MyTruckList.',
    sizeAvatar: `${'For the correct display of the avatar,'}
     ${' choose an image of the maximum size height - 90, width - 90.'}`,
    sizeCompanyLogo: `${'For the correct display of the logo company\'s,'}
     ${' choose an image of the maximum size height - 50, width - 300.'}`,
    noPermission: `${'The email address or password you entered is incorrect.'}
     ${' Please double-check your login information and try again.'}`,
    shouldBeCorrectPassword: `${'Password must contain at least six characters,'}
     ${' one letter, one number and one special character'}`,
    ifVerificationOccurred: `${'If the verification occurred without the checkboxes selected,'}
     ${' the application will not be able to send emails!'}`,
    pleaseSelectAllCheckboxes: `${'When My Truck List asks for access to your Google digital record,'}
     ${' check all unchecked boxes and confirm access.'}`,
    myTruckListIsNotResponsible: `${'MyTruckList is not responsible for whether messages sent through our platform'}
     ${' are successfully delivered to the intended recipients or not.'}`,
    useTheFormatPlaceholders: `${'Use the format: ${FirstName}, ${LastName}, and/or ${CompanyName} anywhere'}
     ${' in the subject, body, and/or signature. MyTruckList will automatically fill in the relevant information from'}
     ${' your email lists/groups. Test it with the preview button below!'}`,
    cancelSubscription: `${'Cancel Subscription: If you have any questions or need further assistance,'}
     ${' please don\'t hesitate to reach out to our customer support team.'}`,
    placeholdersPersonalizeYourEmails: `${'Personalize your emails by adding your clients first name, last name,'}
     ${' and/or company name.'}`,
    beforeYouCancel: `${'Before you cancel your subscription, please take a moment to consider the following'}
     ${' options:'}`,
    fieldCanContainAnyCharacters: `${'The field can contain any characters, but if it contains interpolation,'}
     ${' then only the values FirstName, LastName and CompanyName are allowed.'}`,
    provideFeedback: `${'Provide Feedback: We value your input and would love to hear about your experience.'}
     ${' If there\'s anything we can do to improve, please share your feedback with us.'}`,
    enterValidPassword: `${'Minimum 8 characters (mixture of letters and numbers), 1 Upper case, and 1 Special'}
     ${' Character e.g., (@ $ ! % * # ? ^ &)'}`,
    ifYouStillLike: `${'If you\'re still like to cancel, please proceed by clicking the button below. We appreciate'}
     ${' your support and hope to serve you again in the future.'}`,
    authorizeGmail: `${' Authorize - Gmail Authorization needs to be authorized (if you choose Gmail as your mailing'}
     ${' service) or the application cannot send emails.'}`,
    authorizeSMTP: `${'SMTP Authorize - SMTP/Outlook Authorization needs to be authorized (if you choose SMTP/Outlook'}
     ${' as your mailing service) or the application cannot send emails.'}`,
    weSorryToInform: `${'We\'re sorry to inform you that the card associated with your account has expired. In order'}
     ${' to continue using our services without any interruption, we kindly request you to update your payment'}
     ${' information with a valid card.'}`,
    ifYouAreUsingAnSMTPOutlookIntegration: `${'If you are using an SMTP/Outlook integration, you agree that you take'}
     ${' responsibility to monitor email send limits. MyTruckList.app does not take responsibility if you get'}
     ${' blocked/black-listed with your email servers due to high send volumes.'}`,
    reminderVerificationEmailAddresses: `${'Reminder: only send emails to verified email addresses and to emails that'}
     ${' agree to receive contact from you. This will ensure that your email box doesn\'t get blacklisted/banned. By'}
     ${' sending the emails, you agree that MyTruckList.com takes no liability on itself if you get'}
     ${' blacklisted/banned.'}`,
    cancelSubscriptionNoRefund: `${'By clicking Cancel Subscription you agree to and understand that all'}
     ${' MyTruckList Inc. charges are non-refundable.'}`,
  },
  warnings: {
    subscription: `${'To enjoy complete access to all the features of MyTruckList, just click on'}
     ${' the "Add Subscription" button!'}`,
    mailingConfigs: `${'To start sending emails, access your Profile and follow the steps'}
     ${' to configure and authorize email access.'}`,
  },
  errorCodes: {
    'myTruckList.error.entity.notFound': 'Unprocessable Entity',
    'myTruckList.validation.truck.limit': 'Exceeded truck limit',
    'myTruckList.validation.image.empty': 'Please, add the image',
    'myTruckList.error.entity.serverError': 'Internal Server Error',
    'mytrucklist.validation.email.limit': 'Email limit has been exceeded',
    'mytrucklist.validation.emailGroup.limit': 'Exceeded email groups limit',
    'myTruckList.validation.email.notUnique': 'Email address must be unique',
    'myTruckList.validation.emailGroup.exist': 'Email group is already exists',
    'myTruckList.validation.mailingJob.limit': 'Exceeded email campaigns limit',
    'myTruckList.validation.image.incorrectType': 'Incorrect type of the image',
    'myTruckList.access.token.expire': 'Access token has expired or is invalid',
    'myTruckList.validation.truck.truckId.notUnique': 'Truck ID must be unique',
    'mytrucklist.stripe.subscription': 'Your transaction could not be processed',
    'mytrucklist.validation.mailingConfig.limit': 'Exceeded mailing config limit',
    'mytrucklist.validation.mailing.paymentPeriodExpired': 'Payment period expired',
    'myTruckList.validation.email.exist': 'Current email address is already exists',
    'myTruckList.validation.destinationZone.limit': 'Exceeded destination zones limit',
    'mytrucklist.validation.companyTemplate.limit': 'Exceeded company templates limit',
    'myTruckList.validation.truck.truckNumber.notUnique': 'Truck number must be unique',
    'myTruckList.validation.destinationZone.inUse': 'Current destination zone is in use',
    'myTruckList.validation.user.exist': 'A user with this email address already exists',
    'mytrucklist.validation.mailingSmtpConfig.host.required': 'Please, input correct host',
    'mytrucklist.validation.mailingSmtpConfig.port.required': 'Please, input correct port',
    'myTruckList.validation.emailGroup.inUse': 'The Current Email Address Group is in use',
    'mytrucklist.validation.mailingConfig.exists': 'Current mailing config is already exists',
    'myTruckList.validation.destinationZone.exist': 'Current destination zone is already exists',
    'mytrucklist.subscription.noActiveSubscription': 'Subscription is not active or has expired.',
    'mytrucklist.validation.template.placeholder.notUnique': 'Template placeholder must be unique',
    'mytrucklist.validation.companyPayment.amount.invalid': 'Company\'s payment amount is invalid',
    'mytrucklist.validation.mailing.emptyEmailIdsAndGroupIds': 'Please, select email ids and group ids',
    'myTruckList.validation.mailingJob.groupIdsOrEmailsIdsMustExist': 'Please, selects groups or emails',
    'myTruckList.validation.signUp.exist.mcNumberAndDotNumber.empty': 'Please, select MC number or DOT number',
    'myTruckList.validation.company.exist.mcNumberAndDotNumber.empty': 'Please, select MC number or DOT number',
    'mytrucklist.validation.companyTemplate.inUse': 'This template is currently in use and could not be deleted',
    'mytrucklist.validation.companyTemplate.placeholder.notUnique': 'Company template placeholder must be unique',
    'mytrucklist.validation.mailingConfig.inUse':
      'Unable to delete mailing configuration, mailing configuration is in use',
    'mytrucklist.validation.mailingSmtpConfig.securityProtocol.required':
      'Please, select smtp config security protocol',
    'mytrucklist.validation.companyTemplate.placeholder.notAllRequired':
      'Please add all required template placeholders',
    'myTruckList.validation.truck.emptyDestinationZoneAndDestinationLocations':
      'Please, select destination states or zone',
    'myTruckList.validation.mailingJob.startDateAfterEndDate':
      'The start date of the email campaign is later than the end date of the email campaign',
    'mytrucklist.validation.destination': `${'Truck Destination or Destination Zone(s) cannot be empty.'}
     ${' Please enter the Truck Destination(s) or Destination Zone(s).'}`,
    'mytrucklist.validation.mailing.subscription.expired': `${'Payment for this company is now overdue.'}
      ${' To ensure continued access to MyTruckList, please review your billing information on Profile page.'}`,
    'mytrucklist.validation.mailingConfig.error': `${'An authorization issue has occurred. Please check your'}
     ${' authorization.'}`,
    'mytrucklist.validation.mailingSmtpConfig.connectionError': `${'Connection error: The user credentials are'}
      ${' incorrect or the account has been locked.'}`,
    'mytrucklist.validation.companyTemplate.placeholder.value.invalid': `${'Only interpolation placeholders for'}
      ${' ${FirstName}, ${LastName} and ${CompanyName}, filled without spaces and in camelcase, are allowed.'}`,
    'myTruckList.validation.gmail': `${'This authorization is no longer active or has been rejected by the provider.'} 
      ${' Delete it and create a new Google or SMTP email authorization.'}`,
    'mytrucklist.validation.email.empty': `${'Email address(s) are unselected or unsubscribed. Please check your email'}
     ${' address(s)/subscriptions and try again.'}`,
  },
  labels: {
    no: 'No',
    id: 'id',
    to: 'to',
    and: 'and',
    all: 'All',
    tab: 'Tab',
    yes: 'Yes',
    map: 'Map',
    new: 'New',
    ext: 'Ext.',
    none: 'None',
    type: 'Type',
    rows: 'rows',
    male: 'Male',
    name: 'Name',
    from: 'from',
    days: 'Days',
    info: 'Info',
    size: 'Size',
    main: 'Main',
    host: 'Host',
    page: 'Page',
    port: 'Port',
    form: 'Form',
    note: 'Note',
    login: 'Login',
    users: 'Users',
    hours: 'Hours',
    count: 'count',
    other: 'Other',
    group: 'Group',
    email: 'Email',
    trial: 'Trial',
    phone: 'Phone',
    delete: 'Delete',
    canada: 'Canada',
    mcNumber: 'MC #',
    trucks: 'Trucks',
    groups: 'Groups',
    gender: 'Gender',
    female: 'Female',
    emails: 'Emails',
    button: 'Button',
    status: 'Status',
    amount: 'Amount',
    social: 'Social',
    active: 'Active',
    signUp: 'Sign Up',
    logOut: 'Log Out',
    sortBy: 'Sort By',
    expired: 'Expired',
    profile: 'Profile',
    dotNumber: 'DOT #',
    clients: 'Clients',
    company: 'Company',
    country: 'Country',
    section: 'Section',
    locales: 'Locales',
    contact: 'Contact',
    reserve: 'Reserve',
    noGroup: 'No Group',
    endDate: 'End Date',
    truckId: 'Truck ID',
    reserved: 'Reserved',
    password: 'Password',
    payments: 'Payments',
    employee: 'Employee',
    address1: 'Address1',
    address2: 'Address2',
    position: 'Position',
    provider: 'Provider',
    approved: 'Approved',
    searchBy: 'Search By',
    longHaul: 'Long Haul',
    lastName: 'Last Name',
    fullName: 'Full Name',
    timeZone: 'Time Zone',
    toEmails: 'To Emails',
    darkMode: 'Dark Mode',
    notExist: 'Not Exist',
    truckIds: 'Truck IDs',
    cancelled: 'Cancelled',
    completed: 'Completed',
    attention: 'Attention',
    checkList: 'CheckList',
    signature: 'Signature',
    listOfGroups: 'Groups',
    locations: 'Locations',
    firstName: 'First Name',
    noSubject: 'No Subject',
    lightMode: 'Light Mode',
    selectAll: 'Select All',
    localHaul: 'Local Haul',
    truckType: 'Truck Type',
    emptyPage: 'Empty Page',
    heavyHaul: 'Heavy Haul',
    startDate: 'Start Date',
    oilChange: 'Oil Change',
    createdBy: 'Created By',
    fromEmail: 'From Email',
    updatedBy: 'Updated By',
    thatIsIt: 'That\'s it!',
    useAvatar: 'Use Avatar',
    noRefunds: 'No Refunds',
    subject: 'Email Subject',
    subscribed: 'Subscribed',
    contractor: 'Contractor',
    characters: 'characters',
    listOfMails: 'Email List',
    driverType: 'Driver Type',
    garbageCan: 'Garbage Can',
    rememberMe: 'Remember me',
    cardNumber: 'Card Number',
    creditCard: 'Credit Card',
    adminPanel: 'Admin Panel',
    driverName: 'Driver Name',
    middleName: 'Middle Name',
    postalCode: 'Postal Code',
    mailingLog: 'Mailing Log',
    pickUpDate: 'PickUp Date',
    freeAccess: 'Free Access',
    invoiceNumber: 'Invoice #',
    myTruckList: 'MyTruckList',
    learnHowTo: 'Learn how to',
    description: 'Description',
    companyName: 'Company Name',
    phoneNumber: 'Phone Number',
    tiresChange: 'Tires Change',
    truckNumber: 'Truck Number',
    createdDate: 'Created Date',
    updatedDate: 'Updated Date',
    emailGroups: 'Email Groups',
    paymentDate: 'Payment Date',
    companyLogo: 'Company Logo',
    trailerType: 'Trailer Type',
    locationPin: 'Location Pin',
    memberSince: 'Member Since',
    pricingPlan: 'Pricing Plan',
    activeUntil: 'Active Until',
    alreadySent: 'Already sent',
    contactName: 'Contact Name',
    subscription: 'Subscription',
    unsubscribed: 'Unsubscribed',
    emailPreview: 'Email Preview',
    emailAddress: 'Email Address',
    campaignName: 'Campaign Name',
    unitedStates: 'United States',
    requestDate: 'Date of Request',
    clickSubmit: 'Click "Submit".',
    listOfTrucks: 'List of Trucks',
    confirmAction: 'Confirm Action',
    trailerNumber: 'Trailer Number',
    truckLocation: 'Truck Location',
    ownerOperator: 'Owner Operator',
    specialtyHaul: 'Specialty Haul',
    equipmentType: 'Equipment Type',
    haveAccount: 'Have an account?',
    cancelledDate: 'Cancelled Date',
    emailTemplate: 'Email Template',
    availableDate: 'Available Date',
    notSubscribed: 'Not Subscribed',
    reservedUnits: 'Reserved Units',
    notActiveFrom: 'Not Active From',
    companyInfo: 'Your company info',
    originOfTruck: 'Origin Of Truck',
    supportedFiles: 'Supported Files',
    emailAddresses: 'Email Addresses',
    changeLanguage: 'Change Language',
    socialNetworks: 'Social Networks',
    emailCampaigns: 'Email Campaigns',
    mailingService: 'Mailing Service',
    paymentDetails: 'Payment Details',
    expirationDate: 'Expiration Date',
    cardholderName: 'Cardholder Name',
    authorizeEmail: 'Authorize Email',
    emailTemplates: 'Email Templates',
    typeYourNote: 'Type your note...',
    personalInfo: 'Your personal info',
    trialEmailLeft: 'Trial Email Left',
    signIn: 'Sign In to My Truck List',
    numberOfTrucks: 'Number of Trucks',
    nameOfTemplate: 'Name of Template',
    useCompanyLogo: 'Use Company Logo',
    confirmPassword: 'Confirm password',
    addSubscription: 'Add Subscription',
    originLocations: 'Origin Locations',
    mailingServices: 'Mailing Services',
    destinationZone: 'Destination Zone',
    companyTemplate: 'Company Template',
    attentionNeeded: 'Attention needed',
    listOfCompanies: 'List of Companies',
    nextBillingDate: 'Next Billing Date',
    trialEmailLimit: 'Trial Email Limit',
    trialEmailsLeft: 'Trial emails left',
    listOfMailsGroup: 'Email List/Groups',
    securityProtocol: 'Security Protocol',
    confirmSubscribe: 'Confirm subscribe',
    destinationZones: 'Destination Zones',
    enterNewPassword: 'Enter New Password',
    searchLocation: 'Search for a Location',
    forgotPassword: 'Forgot your password?',
    desireDestination: 'Desire Destination',
    authorizationInfo: 'Authorization info',
    listOfMailGroups: 'List of Mail Groups',
    destinationOfTruck: 'Truck Destination',
    clientInformation: 'Client Information',
    campaignDateTime: 'Campaign Date / Time',
    selectPricingPlan: 'Select Pricing Plan',
    emailTemplateName: 'Email Template Name',
    truckLocationCity: 'Truck Location City',
    mailingLogIsEmpty: 'Mailing Log is empty',
    confirmUnSubscribe: 'Confirm unsubscribe',
    originOfTruckCity: 'Origin Of Truck City',
    genericMaintenance: 'Generic Maintenance',
    listOfMailsIsEmpty: 'Email List is empty',
    dontHaveAccount: 'Don\'t have an account?',
    companyPhoneNumber: 'Company Phone Number',
    truckLocationState: 'Truck Location State',
    inputInfoOptional: 'Input Info (Optional)',
    questions: 'Questions? Reach out to us at',
    contactPhoneNumber: 'Contact Phone Number',
    subscriptionDetails: 'Subscription Details',
    originOfTruckState: 'Origin Of Truck State',
    dailyEmailLimit: 'Daily mail sending limit',
    reservationRequests: 'Reservation Requests',
    contactEmailAddress: 'Contact Email Address',
    signUpWasSuccessful: 'Sign up was successful!',
    truckLocationCountry: 'Truck Location Country',
    listOfTrucksIsEmpty: 'List of Trucks is empty',
    attachLogos: 'Attach logos/avatars if desired.',
    doNotHaveAccount: 'Don\'t have an account yet?',
    officialPartner: 'Official Partners Learn More',
    originOfTruckCountry: 'Origin Of Truck Country',
    editProfileInfo: 'Edit your profile information',
    editCompanyInfo: 'Edit your company information',
    originOfTruckLatitude: 'Origin Of Truck Latitude',
    listOfAvailableTrucks: 'List of Available Trucks',
    paymentHistoryIsEmpty: 'Payment history is empty',
    companyPhoneNumberExt: 'Company Phone Number Ext.',
    desiredDestinationCity: 'Desired Destination City',
    availableTrucksDetails: 'Available trucks details',
    originOfTruckLongitude: 'Origin Of Truck Longitude',
    listOfDestinationZones: 'List of Destination Zones',
    listOfCompanyTemplates: 'List of Company Templates',
    desiredDestinationState: 'Desired Destination State',
    desiredTruckDestination: 'Desired Truck Destination',
    reservationRequestsList: 'Reservation Requests List',
    subscriptionAndPayments: 'Subscription and Payments',
    availableTimeForPickUp: 'Available Time For Pick Up',
    inputTheGroupName: 'Input the name of you new group.',
    yourCampaignName: 'Your campaign a descriptive name.',
    haveYouChangedYourMind: 'Have you changed your mind?',
    availableDatesForPickUp: 'Available Dates For PickUp',
    yourPasswordChanged: 'Your password has been changed',
    versionOfTheTemplate: 'Version of the email template.',
    optionalSocialMedia: 'Optional: Include social media.',
    changeNameOfYourCompany: 'Input name of your company.',
    performConfirmation: 'To perform this action click to',
    selectPlanAndEnter: 'Select plan and enter card info.',
    listOfCampaignIsEmpty: 'Email Campaigns List is empty',
    desireDestinationCountry: 'Desire Destination Country',
    byClickingSign: 'By clicking sign in you agree to our',
    nameOfCompanyTemplate: 'Name of your company template.',
    listOfMailGroupsIsEmpty: 'List Of Mail Groups Is Empty',
    limitUpdateWasSuccessful: 'Limit Update was successful!',
    desireDestinationLatitude: 'Desire Destination Latitude',
    emailAuthorizationsInfo: 'Your email authorization info',
    clickAddSubscription: 'Click "Add Subscription" button.',
    subscriptionWasSuccessful: 'Subscription was successful!',
    desireDestinationLongitude: 'Desire Destination Longitude',
    yourCampaignCreatedDate: 'Date your campaign was created.',
    manageSubscription: 'Manage your subscription and payments',
    manageYourCompanyTemplates: 'Manage your company templates',
    wouldYouPreferToSubscribe: 'Would you prefer to subscribe?',
    changeNameOfCompanyTemplate: 'Change name of company template',
    currentModeIs: 'Switch between dark and light mode (currently',
    unsubscriptionWasSuccessful: 'Un-subscription was successful!',
    wouldYouPreferToUnsubscribe: 'Would you prefer to unsubscribe?',
    inputNameForNewTemplate: 'Input a name for your email template.',
    companyEditClick: 'Company: Click "Edit Company," enter details.',
    listOfAvailableTrucksIsEmpty: 'List of Available Trucks is empty',
    attentionNeededToAuthEmail: 'Attention needed to authorize email',
    clickToRefreshCounter: 'Click to refresh daily mail sending limit',
    listOfCompanyTemplatesIsEmpty: 'List of Company Templates is empty',
    listOfDestinationZonesIsEmpty: 'List of Destination Zones is Empty',
    trucksReservationWasSuccessful: 'Trucks Reservation Was Successful',
    yourCampaignService: 'The email service your campaign is sent from.',
    gooGoogleAuthorization: 'Google Authorization: Follow instructions.',
    chooseDate: 'Choose the date when you want your campaign to launch.',
    pleaseCheckYourEmailToConfirm: 'Please, check your email to confirm',
    googleAuthorizeWasSuccessful: 'Authorize with Google was successful!',
    personalEditProfileClick: 'Personal: Click "Edit Profile," add info.',
    selectTypeOfTrailer: 'Select the type of trailer you have available.',
    clickCancelTemplate: 'Click "Cancel" to stop creating your template.',
    yourCampaignProvider: 'The email provider your campaign is sent from.',
    clickCreateTemplate: 'Click "Create" to add your template to the list.',
    listOfReservationRequestIsEmpty: 'List of Reservation Request is empty',
    step2ManualCampaign: 'Step 2: Manual Campaign: Start Your Campaign Now.',
    clickSaveToEditYourInfo: 'Click "Save" to edit your personal information',
    smtpEmailAuthorizationSuccess: 'SMTP Email Authorization was successful!',
    smtpAuthorization: 'SMTP Authorization: Select email type, enter details.',
    selectEmail: 'Select the email you want to use to send the campaign from.',
    yourCampaignDescription: 'Description of your campaign submission results.',
    clickSaveToEditYourCompany: 'Click "Save" to edit your company information',
    specifyDate: 'Specify the date when you want your campaign to stop running.',
    selectTheEmail: 'Select the email you want to use to send the campaign from.',
    clickPreviewTemplate: 'Click "Preview" to see how is your template looks like.',
    enterPickupDate: 'Enter the date when your truck will be available for pick up.',
    clickAuthorization: 'Add/edit "G Authorize" or "SMTP Authorize" authorizations.',
    selectYourTimeZones: 'Select your time zones to ensure timely campaign launches.',
    inputNumberOfTrucks: 'Input the number of trucks you have available for pick up.',
    enterNameOfThePerson: 'Enter the name of the person who will be driving the truck.',
    choosePricePlan: 'Choose plan: $27/month or $279/year ($23/month billed annually).',
    thisIsTheNumber: 'This is the number of emails you can use during the trial period.',
    chooseOneOrMoreEmail: 'Choose one or more email addresses to receive your campaign.',
    emailsGroups: 'This field shows a list of emails that are linked to a certain group.',
    clickCreateEmail: 'Click "Create" to create your new email contact to the list below.',
    inputLastName: 'Input the last name of the party MyTruckList will automatically email.',
    thisLimitWillBeUsed: 'This limit will be used by default for all new registered users.',
    inputUnique: 'Input a unique identifier for your truck that will make it easy to track.',
    clickCreateEmailCampaign: 'Click "Create" to add your email campaign to the list below.',
    inputFirstName: 'Input the first name of the party MyTruckList will automatically email.',
    indicateIfYouHaveSingleDriver: 'Indicate if you have a single driver or a team available.',
    giveYourCampaignName: 'Give your campaign a descriptive name that will help you track it.',
    inputMiddleName: 'Input the middle name of the party MyTruckList will automatically email.',
    ifYouNeedToDelete: 'If you need to delete all information for this item record, click here.',
    selectYourEmailTemplate: 'Select your email template you want to use in your email campaign.',
    inputPhoneNumber: 'Input the phone number of the party MyTruckList will automatically email.',
    byClickingPaymentHistory: 'By clicking "Payment history", you can check your payment history',
    addPlaceholdersOfCompanyTemplate: 'Add the text content placeholders of the company template',
    manualCampaignSuccessful: 'Manual Campaign request successful! Please check your Mailing Log!',
    yourCampaignStatus: 'The delivery status of your campaign is either successful or unsuccessful.',
    enterEmailSignature: 'End your email with a email signature, or keep what MyTruckList has listed.',
    inputEmail: 'Input the email address of the party. This is where the automated emails will be sent.',
    inputWebsiteUrl: 'Input your company website url of the party MyTruckList will automatically email.',
    inputTwitterUrl: 'Input your company Twitter url of the party MyTruckList will automatically email.',
    enterYourEmailAddress: 'Enter your email address and we\'ll send you a link to reset your password.',
    clickHereToViewYourDestinationZones: 'Click here to view your destination zones and number of trucks.',
    inputFacebookUrl: 'Input your company Facebook url of the party MyTruckList will automatically email.',
    inputLinkedinUrl: 'Input your company Linkedin url of the party MyTruckList will automatically email.',
    clickCreate: 'Click "Create" to add your truck to the list below and start managing your mailing list.',
    inputInstagramUrl: 'Input your company Instagram url of the party MyTruckList will automatically email.',
    uploadYourCompanyLogo: 'Upload your company logo to be displayed on the top left of the navigation bar.',
    removeTemplate: 'If you no longer need a template, click on the trash icon to remove it from your list.',
    inputLocationOfTruck: 'Input the location of your truck, including the address, city, state, or zip code.',
    inputMCNumber: 'Input a MC number for dispatchers. This will help with tracking and regulatory compliance.',
    inputYoutubeUrl: 'Input your company Youtube channel url of the party MyTruckList will automatically email.',
    inputCompanyPhoneNumber: 'Input your company phone number of the party MyTruckList will automatically email.',
    inputDOTNumber: 'Input a DOT number for dispatchers. This will help with tracking and regulatory compliance.',
    changeContactPlaceholdersOfCompanyTemplate: 'Change the contact content placeholders of the company template',
    refreshAuthorizeWasSuccessful: 'The process of refreshing email authorization has been completed successfully!',
    pleaseEnterYourNewPassword: 'Please enter your new password twice so we can verify you typed it in correctly.',
    inputNameForNewZone: 'Input a name for your new zone that accurately represents the locations it encompasses.',
    enterTitle: 'Enter an introduction title for your first line in the email, or keep what MyTruckList has listed.',
    indicateWhereTruckIs: 'Indicate where your truck is headed by choosing or inputting the desired destination(s).',
    changeSignaturePlaceholdersOfCompanyTemplate: 'Change the signature content placeholders of the company template',
    chooseTypeOfTruck: 'Choose the type of truck you have available, including local, heavy, long haul, or specialty.',
    welcomeToTutorial: `${'Welcome to this step-by-step tutorial on MyTruckList\'s automatic email campaign feature!'}`,
    step2: `${'Step 2: Create a New Destination Zone for Your Available Trucks.'}
     ${' To create a new destination zone for your trucks, click on the "Create Destination Zone" button.'}`,
    enterCatchySubject: `${'Enter a catchy subject line to grab the dispatcher\'s'}
     ${' attention and inform them about the email.'}`,
    inputCompanyAddress1: `${'Input your company address1 of the party MyTruckList will'}
     ${' automatically email.'}`,
    previewReservation: `${'To access information about a specific reservation request, simply click on'}
     ${' "Preview Reservation".'}`,
    inputSubscribed: `${'This field indicates whether this user has subscribed to the email newsletter or not.'}
     ${' You can change the value by clicking on the button on the right.'}`,
    inputCompanyAddress2: `${'Input your company address2 of the party MyTruckList will'}
     ${' automatically email.'}`,
    enterSignatures: `${'Include your personal information, such as your name, email, phone number(s),'}
     ${' and company logo URL, in the signature section so that your recipients can easily contact you.'}`,
    enterMainContent: `${'Include your company template information, such as your subject, title, body'}
     ${' and email signature.'}`,
    selectEmailTemplate: `${'Choose an email template that best fits your campaign. To modify or create'}
     ${' a new template, go to the "Email Templates" tab to choose one of MyTruckLists pre-designed templates. '}`,
    clickAddToCreateGroup: `${'Click "Create" to create your new group. Once added,'}
     ${' you will be able to choose this group when adding or modifying emails in your mailing list.'}`,
    step3: `${'Step 3: View Your Destination Zones and Available Truck Numbers. '}
     ${'To see your destination zones and the available truck numbers for each zone, click on the "Map" button.'}`,
    inputPosition: `${'Input the job position of the party MyTruckList will automatically email.'}
     ${' This information will help you better target emails.'}`,
    reservationRequestsStep1: `${'Each line represents a different reservation request, containing vital'}
     ${' details about the client, such as their company name and contact information (phone number or email).'}`,
    enterSocials: `${'Enter your social media channel URLs to connect with your recipients and help build'}
     ${' you a stronger online presence.'}`,
    allThatIsLeft: `${'All that is left to do is click the “Save” button, and you\'re all set. All of your'}
     ${' reservations, client information, statuses, and notes are neatly together in one place!'}`,
    clickAddToCreateDestinationZone: `${'Click "Create" to create your new destination zone. Once added,'}
     ${' you will be able to choose this zone when adding or modifying trucks in your truck list.'}`,
    finallyOnTheRightHandSide: `${'Finally, on the right-hand side, there is another notes section for the'}
     ${' reservation section to help you stay organized throughout the booking process. Click on the pencil'}
     ${' icon to view the text box.'}`,
    enterInformationAboutEditCompany: `${'Click “Edit Company” to easily change your company name, MC & DOT'}
     ${' numbers, phone number, address,  and time zone. Keep this information updated to ensure your company\'s'}
     ${' information is accurate and in sync with your operations.'}`,
    enterInformationAboutEditProfile: `${'Click “Edit Profile” Click on "Edit Profile" to update your name,'}
     ${' job position, and phone number. With just a few clicks, you can ensure your profile information is current'}
     ${' and reflective of your role.'}`,
    enterInformationAboutEditSocialLinks: `${'Click on "Edit Social Networks" to effortlessly update your'}
     ${' website, YouTube channel, and other social media links. This ensures you keep your social media information'}
     ${' current and engaging for your audience.'}`,
    step1Templates: `${'Step 1: To start creating your email templates, click on "Add New Email Template"'}
     ${' You\'ll have the option to choose from two pre-made templates designed specifically for MyTruckList users.'}`,
    paymentSection: `${'This section is where you can easily fill out information about your subscriptions'}
     ${' and payments.'}`,
    selectDays: `${'Select the days of the week when you want your campaign to be sent out, whether it\'s'}
     ${' on weekdays or weekends.'}`,
    clickTheButtonBelowToSubscribe: `${'Please, enter you email address and then click the button below to'}
     ${' subscribe on messages'}`,
    step1: `${'Step 1: Add a New Truck to Your List. To get started, click on the "Add New Truck" button.'}
     ${' This will allow you to input important details about your available truck(s).'}`,
    toLearnThisStepAgain: `${'To learn this step again, click the "Open Tutorial Steps" button and select'}
     ${' the desired tutorial step from list.'}`,
    welcomeEmailGroupsList: `${'Welcome to the Email Groups List tab. This section is where you can easily'}
     ${' fill out information about your email groups. To get started, choose option Add a New Email Group.'}`,
    inputCompanyPhoneNumberExt: `${'Input your company phone number extension of the party MyTruckList will'}
     ${' automatically email.'}`,
    chooseOneOrMoreLocations: `${'Choose one or more locations to create a destination zone for your trucks'}
     ${' to travel to. This can be a city, state, or zip code, and will help you better manage your fleet.'}`,
    clickTheButtonBelowToUnsubscribe: `${'Please, enter you email address and then click the button below to'}
     ${' unsubscribe from messages'}`,
    clickEditYourTemplate: `${'To edit any existing templates in your list, simply click on the pencil icon.'}
     ${' You can make changes to any of the template details to better fit your needs.'}`,
    welcomeMailingList: `${'Welcome to the Mailing List tab. This section is where you can easily fill out'}
     ${' information about your email. To get started, you have two options: Add a New Email and your email\'s Group'}`,
    chooseEmailTemplate: `${'Choose an email template that best fits your campaign. To modify or create a new'}
     ${' template, go to the "Email Templates" tab to choose one of MyTruckLists pre-designed templates.'}`,
    welcomeToProfile: `${'Welcome to the Profile tab. This section is where you can easily fill out information'}
     ${' about yourself, your company, your authorization info and your payments.'}`,
    clientInformationIsFound: `${'Client information is found on the left side. To directly contact the client'}
     ${' by phone number and/or email, you can simply click on the respective box.'}`,
    enterLocations: `${'If you enter an Origin Location(s) this will filter the list of trucks and send only the'}
     ${' trucks with a matching location(s).'}`,
    selectTimeOfDay: `${'Choose the time of day when you want your campaign to be sent out, taking into account'}
     ${' your audience\'s timezone and daily routine.'}`,
    welcomeMailingLog: `${'Welcome to the Mailing Log tab. Easily locate all your previous email campaigns by'}
     ${' navigating to the "Mailing Log" tab and identifying their delivery status as either successful or failed.'}`,
    withTheseFeaturesManagingYourTruck: `${'With these features, managing your truck list and destination zones'}
     ${' will be a breeze, allowing you to optimize your operations and help MyTruckList send emails with ease. '}`,
    inputTheName: `${'Input the name of the organization you will be emailing to make sure that the email reaches'}
     ${' the right recipient.'}`,
    enterInformationAboutYourself: `${'Enter information about yourself and your company to help MyTruckList send'}
     ${' your emails with ease.'}`,
    byClickingAddSubscription: `${'By clicking "Add/Cancel subscription", you can create manual subscriptions and'}
     ${' change existing subscription information'}`,
    ifYouHaveEmailAccount: `${'If you have an email account outside of Gmail, select "SMTP Authorize" and provide'}
     ${' the necessary linked information before adding it to your profile. '}`,
    underReservedUnits: `${'Under "Reserved Units" you can record the quantity of units reserved out of the total'}
     ${' number provided by the client, which is shown under "Number of Units".'}`,
    clickAddToSendYourCampaign: `${'Click "Send" to send your campaign. You\'re all set! MyTruckList automatically'}
     ${' populates all the information into an email and sends it to your selected recipients.'}`,
    selectTrucksYouWant: `${'Choose the trucks you want to include in this email campaign from the "List of Trucks"'}
     ${' tab. To modify or create a new template, go to the "List of Trucks".'}`,
    enterInformationAboutSocials: `${'Enter information about your company social networks to help MyTruckList send'}
     ${' your emails with ease.'}`,
    inputGroups: `${'Choose one or more groups for MyTruckList to email from the organization. If you do not have a'}
     ${' group created, click on "Create New Group" at the top right of the page to create a new group.'}`,
    selectOneOrMoreGroups: `${'Select one or more groups from your mailing list. If you do not have a group created,'}
     ${' click on "Create New Group" at the top right of "Mailing List" tab to create a new group.'}`,
    welcomeDestinationZonesList: `${'Welcome to the Destination Zones List tab. This section is where you can easily'}
     ${' fill out information about your destination zone. To get started, choose option Add a New Destination Zone.'}`,
    enterBody: `${'Provide any additional information you would like the dispatcher to know, or keep what'}
     ${' MyTruckList has listed. Don\'t worry about the details of the selected truck(s) - MyTruckList will'}
     ${' automatically include them from the "List of Trucks" tab.'}`,
    byClickingAuthorize: `${'By clicking "G Authorize", simply link the desired Gmail account you would like'}
     ${' MyTruckList to automatically send email campaigns from. If you wish to add more Gmail accounts,'}
     ${' repeat the same process.'}`,
    ifYouHaveDestinationZones: `${'If you have designated destination zones, choose one from the dropdown menu.'}
     ${' If not, you can create a new zone by clicking on the "Create New Destination Zone"'}
     ${' button on the top right of the page.'}`,
    thisWillTakeYouToPage: `${'This will take you to a page where you can view all of your destination zones,'}
     ${' as well as the number of trucks available in each zone. This information will help you better allocate'}
     ${' your resources and ensure that you have the right trucks available when and where they are needed.'}`,
    chooseOneOrMoreGroups: `${'Choose one or more groups from your mailing list that you want to target with your'}
     ${' campaign. If you do not have a group created, click on "Create New Group" at the top right of "Mailing List"'}
     ${' tab to create a new group.'}`,
    welcomeToEmailTemplates: `${'Welcome to the Email Templates tab. This section is where you can easily fill out'}
     ${' email templates information so that MyTruckList can automatically send emails to your audience.'}
     ${' To get started, you have click on "Add New Company Template" button.'}`,
    ifYouLooking: `${' If you\'re looking for a simple and efficient way to send truck list email campaigns to'}
     ${' your dispatchers, then you\'re in the right place. By following these step-by-step instructions, you\'ll'}
     ${' be able to create and launch automatic email campaigns effortlessly and efficiently, saving you time and'}
     ${' effort. So, let\'s get started and discover how MyTruckList can streamline your email marketing process.'}`,
    welcomeToListOfTrucks: `${'Welcome to the List Of Trucks tab. This section is where you can easily fill out'}
     ${' information about your truck. To get started, you have two options: Add a New Truck and your truck\'s'}
     ${' Destination Zone.'}`,
    welcomeToEmailCampaigns: `${'Welcome to the Email Campaigns tab. This section is where you can easily fill out'}
     ${' email campaign information so that MyTruckList can automatically send emails to your audience.'}
     ${' To get started, you have two options: Schedule a New Campaign or Manually Campaign.'}`,
    step1MailingList: `${'Step 1: Add New Email Contacts to Your Mailing List. To begin adding new contacts to your'}
     ${' emailing list, click on the "Add New Email" button. MyTruckList will then create and send your'}
     ${' emails automatically.'}`,
    onTheRightSide: `${'On the right side, there is a dedicated notes section where you can easily keep information'}
     ${' about the reservation request. No need to scramble for a pen and paper, your notes are always at your'}
     ${' fingertips, right alongside the reservation.'}`,
    myTruckListWillSend: `${'MyTruckList will automatically send out your campaign on the date and time indicated,'}
     ${' using all the information you\'ve inputted. With these easy steps, you can ensure that your email campaigns'}
     ${' are being sent out efficiently and effectively.'}`,
    byClickingSMTPAuthorize: `${'By clicking "SMTP Authorize", simply add the desired Outlook or other SMTP account'}
     ${' you would like MyTruckList to automatically send email campaigns from. If you wish to add more SMTP'}
     ${' accounts, repeat the same process.'}`,
    authorizeMailingServiceTour: `${'If you have not setup your email this can be done in the Profile. To access the'}
     ${' Profile click on the gear icon on the top right of the screen and select Profile. Once on the profile screen,'}
     ${' you must Authorize the email you want to use.'}`,
    changeReservationRequestStatus: `${'In this section, you have the ability to select the'}
     ${' status of each request, allowing for enhanced organization and tracking. You can mark'}
     ${' a request as "In Progress" if you\'re actively working on securing the booking, or mark'}
     ${' it as "Completed" once you have confirmed arrangements with your client and are ready for dispatch.'}`,
    welcomeToReservationRequests: `${'Welcome to the MTL reservation requests tutorial, here is where all your'}
     ${' reservation requests will find their home. This tool gives your clients the ability to reserve your trucks'}
     ${' while providing you with internal status management and additional information to keep your trucks booked.'}
     ${' Let’s dive in!'}`,
    step1EmailCampaigns: `${'Step 1: If you want to schedule a campaign for a later date, simply choose Schedule'}
     ${' New Campaign. When creating a new email campaign in MyTruckList, you\'ll need to provide some key details'}
     ${' to ensure that your message reaches the right people at the right time.'}
     ${' Here\'s what you\'ll need to input:'}`,
    atTheBottomOfThePage: `${'At the bottom of the page, you will find your available trucks details that the client'}
     ${' is interested in, including the availability date, the number of units, truck and trailer types, and more.'}
     ${' These details serve as a handy reference when contacting the client regarding their request and ultimately'}
     ${' finalizing the booking.'}`,
    step2MailingList: `${'Step 2: Add Groups to Your Mailing List. To add a new group to your mailing list,'}
     ${' click on the "Create New Group" button. Adding a new group will allow MyTruckList to better target your'}
     ${' emails and help ensure that they reach the right people. With these features, managing your mailing list'}
     ${' will be easier than ever, allowing you to communicate more effectively with your contacts and streamline'}
     ${' your operations.'}`,
  },
}

import * as R from 'ramda'
import { createSlice, createSelector } from '@reduxjs/toolkit'
//////////////////////////////////////////////////

const initialState = { openedTemplateProfile: false }

export const companyTemplatesSlice = createSlice({
  initialState,
  name: 'companyTemplates',
  reducers: {
    setOpenTemplateProfile: (state, { payload }) => R.assoc('openedTemplateProfile', payload, state),
  },
})

export const { setOpenTemplateProfile } = companyTemplatesSlice.actions

const store = ({ companyTemplates }) => companyTemplates

export const makeSelectAddCompanyTemplatesOpened = createSelector(
  store,
  ({ openedTemplateProfile }) => openedTemplateProfile,
)

export default companyTemplatesSlice.reducer

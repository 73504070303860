import * as R from 'ramda'
import { createApi } from '@reduxjs/toolkit/query/react'
// helpers
import * as H from '../helpers'
// utilities
import { METHODS, ENDPOINTS } from '../utilities/endpoints'
//////////////////////////////////////////////////

export const destinationZoneAPI = createApi({
  baseQuery: H.baseQueryWithReAuth,
  reducerPath: 'destinationZoneAPI',
  tagTypes: ['DestinationZoneList'],
  endpoints: build => ({
    fetchDestinationZones: build.query({
      query: body => ({
        body,
        method: METHODS.GET,
        url: ENDPOINTS.destinationZone,
      }),
      transformResponse: R.pathOr([], ['elements']),
      providesTags: result => H.providesTagList(result, 'DestinationZoneList'),
    }),
    createDestinationZone: build.mutation({
      extraOptions: {
        useLoader: true,
      },
      query: body => ({
        body,
        method: METHODS.POST,
        url: ENDPOINTS.destinationZone,
      }),
      invalidatesTags: (result, error) => H.invalidatesTagsWithError(result, error, 'DestinationZoneList'),
    }),
    updateDestinationZone: build.mutation({
      query: body => ({
        body,
        method: METHODS.PUT,
        url: ENDPOINTS.destinationZone,
      }),
    }),
    getDestinationZoneById: build.mutation({
      query: id => ({
        id,
        method: METHODS.GET,
        url: `${ENDPOINTS.destinationZone}/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'DestinationZoneList', id }],
    }),
    deleteDestinationZone: build.mutation({
      query: id => ({
        id,
        method: METHODS.DELETE,
        url: `${ENDPOINTS.destinationZone}/${id}`,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'DestinationZoneList', id }],
    }),
  }),
})

export const {
  useFetchDestinationZonesQuery,
  useCreateDestinationZoneMutation,
  useDeleteDestinationZoneMutation,
  useUpdateDestinationZoneMutation,
  useGetDestinationZoneByIdMutation,
} = destinationZoneAPI
